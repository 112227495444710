<station-header [headerModel]="header"></station-header>

<div class="station-container">


  <div class="sidbar">
    <station-sidebar></station-sidebar>
  </div>


  <div class="station-painel-right">
    <div class="sub-header">
      <div>
        <p>Grupo de classificação de aplicativos</p>
      </div>
      <div>
        <button class="statiton-button" (click)="newGroup()">Novo Grupo</button>
      </div>

    </div>

    <main class="">

      <div class="list-card">
        <input class="station-form-control search" type="text" placeholder="Busca de Grupo" [(ngModel)]="filtroLista">
        <div class="list-container-journey ">

          <div *ngFor="let cardJourney of journeyFiltered$ | async">

            <div class="item-direction-journey" (click)="edit(cardJourney)">
              <station-employee-card-journey [CardJourney]="cardJourney"></station-employee-card-journey>
            </div>

          </div>
        </div>
      </div>

      <div id="form" class="station-area-aplication" *ngIf="showNewGroup">

        <form [formGroup]="form">
          <input type="hidden" formControlName="action" name="action">
          <div class="mt-4">
            <h6 class="station-form-label-bold">ID</h6>
            <label class="station-form-label-bold">{{currentGroupApplication.id}}</label>
          </div>
          <div class="mt-4">
            <h6 class="station-form-label-bold">Nome do grupo</h6>
          </div>
          <div class="mt-4 station-group-form clear-flex">
            <div>
              <input [(ngModel)]="group.groupName" formControlName="groupName" class="station-form-control" type="text"
                required placeholder="Nome do grupo">
            </div>
          </div>

          <div #target class="mt-4">
            <h6 class="station-form-label-bold">Classificação:</h6>
          </div>

          <div class="station-group-form">
            <div>
              <label class="station-form-label-bold">Descritivo</label>
              <input [(ngModel)]="group.description" formControlName="description" class="station-form-control"
                type="text" required placeholder="Descritivo">
            </div>
            <div>
              <label class="station-form-label-bold">Categoria</label>
              <select [(ngModel)]="group.category" formControlName="category" class="station-form-control"
                id="seguimento" required placeholder="Categoria">
                <option value="Sites">Sites</option>
                <option value="Software">Software</option>
                <option value="Streaming">Streaming</option>
                <option value="Outros">Outros</option>
              </select>
            </div>
            <div>
              <label class="station-form-label-bold">Classificação</label>
              <select [(ngModel)]="group.classification" formControlName="classification" class="station-form-control"
                id="seguimento" required placeholder="Classificação">
                <option value="Produtivo">Produtivo</option>
                <option value="Improdutivo">Improdutivo</option>
              </select>
            </div>
          </div>

          <div class="station-group-form">
            <div class="">
              <input [(ngModel)]="group.ignoreIdleInputDevice" formControlName="ignoreIdleInputDevice" type="checkbox"
                class="checkbox-input" id="ignoreIdleInputDevice">
              <label class="station-form-label-bold label-checkbox" for="ignoreIdleInputDevice">
                <span class="checkbox ml-1"></span>
                Ignorar ociosidade dos periféricos?
              </label>
            </div>
          </div>


          <div class="mt-4">
            <h6 class="station-form-label-bold">Filtros:</h6>
          </div>

          <div class="station-group-form station-form-group">
            <div class="box-left">
              <mat-form-field class="terms-chip-list">
                <mat-label class="mt-2">Termos<span *ngIf="terms.length > 0"
                    class="badge badge-pill bg-secondary ml-1">{{terms.length}}</span></mat-label>
                <mat-chip-list #termsChipList aria-label="Termos" style="margin-bottom: 10px;">
                  <mat-chip *ngFor="let term of terms" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(term)">
                    {{term.name}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input #inputTerms placeholder="Novo termo..." [matChipInputFor]="termsChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
                  <input type="hidden" [(ngModel)]="group.term" formControlName="term">
                </mat-chip-list>
              </mat-form-field>
            </div>

            <div class="box-chechbox">

              <label class="station-form-label termo-label">Buscar em:</label>
              <div class="station-label">
                <input [(ngModel)]="group.title" formControlName="title" type="checkbox" class="checkbox-input"
                  id="title">
                <label class="station-form-label label-checkbox" for="title">
                  <span class="checkbox"></span>
                  Titulo

                </label>
              </div>

              <div class="station-label">
                <input [(ngModel)]="group.url" formControlName="url" type="checkbox" class="checkbox-input " id="url">
                <label class="station-form-label label-checkbox" for="url">
                  <span class="checkbox"></span>
                  Url
                </label>
              </div>

              <div class="station-label">
                <input [(ngModel)]="group.processName" formControlName="processName" type="checkbox"
                  class="checkbox-input" id="processName">
                <label class="station-form-label label-checkbox" for="processName">
                  <span class="checkbox"></span>
                  Nome do processo
                </label>
              </div>

              <div class="station-label">
                <label class="station-form-label pointer" data-bs-toggle="modal" data-bs-target="#myModal">
                  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                    height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <title>Nuvem de palavras</title>
                    <rect fill="none" height="24" width="24" y="0" />
                    <path
                      d="M7,20h4c0,1.1-0.9,2-2,2S7,21.1,7,20z M5,19h8v-2H5V19z M16.5,9.5c0,3.82-2.66,5.86-3.77,6.5H5.27 C4.16,15.36,1.5,13.32,1.5,9.5C1.5,5.36,4.86,2,9,2S16.5,5.36,16.5,9.5z M21.37,7.37L20,8l1.37,0.63L22,10l0.63-1.37L24,8 l-1.37-0.63L22,6L21.37,7.37z M19,6l0.94-2.06L22,3l-2.06-0.94L19,0l-0.94,2.06L16,3l2.06,0.94L19,6z" />
                  </svg>
                  <span class="ml-1">
                    Nuvem de palavras
                  </span>
                </label>
              </div>
            </div>
          </div>
        </form>

        <div class="">
          <button class="btn btn-filter" *ngIf="showButtonAddFilter" (click)="addFilter()">
            <span>{{currentAction}}</span>
          </button>
        </div>


        <div class="main-screen" *ngFor="let filtros of applicationFilterGroupsFiltered; let index = index">
          <div class="screen-filter">
            <div class="screen-filter-header">
              <div>
                <b>{{filtros.description }} </b> <small>[ {{ filtros.category }} | {{ filtros.classification }}
                  ]</small>
              </div>
              <div>
                <mat-icon class="btn-options" (click)="showScreenOption(index)">more_horiz</mat-icon>
              </div>
            </div>
            <Table class="station-table">
              <thead>
                <tr>
                  <th>Termos</th>
                  <th>Buscar em</th>
                  <th>Ações</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of filtros.applicationFilter;  let i = index">

                  <td>{{item.term.join(', ')}}</td>
                  <td>{{ item.origin.join(', ') }}</td>
                  <td (click)="editInNewGroup(index, i, target)">
                    <span class="d-inline-block" title="Editar Filtros">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange"
                        class="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </span>
                    <span class="d-inline-block ms-1" title="Excluir Filtros" (click)="deleteFilter(index, i)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange"
                        viewBox="0 0 448 512">
                        <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                          d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm88 200H296c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
                      </svg>
                    </span>
                  </td>
                </tr>
                <tr class="mt-4">
                  <td></td>
                  <td></td>
                  <td class="btn-new-line">
                    <a routerLink="/form" class="btn" (click)="addNewFilter(index, target)">
                      <span class="btn-add">add</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div class="screen-options hide-screen" id="{{index}}">
            <ul>
              <li class="btn-action" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal"
                (click)="setIndexCurrentClassification(index,filtros.description)">
                <mat-icon class="icon-trash">delete</mat-icon>
                <span>Deletar</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="mt-3">
          <button (click)="saveGroup()" type="submit" class="btn-save" aria-label="Close" mat-button>
            <div>
              <span>Salvar</span>
            </div>
          </button>
        </div>

      </div>
      <!-- Modal -->
      <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <station-chart-word-cloud [words]="wordCloudData" (wordCloudClick)="wordCloudClick($event)">
              </station-chart-word-cloud>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Delete Confirmation -->
      <div class="modal fade" id="deleteConfirmationModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Tem certeza que deseja excluir a classificação: <strong>{{currentDescription}}</strong> ?</p>
              <div class="col-12 mt-4">
                <button class="btn bg-secondary text-white float-end btn-no" data-bs-dismiss="modal"
                  aria-label="Close">Não</button>
                <button class="btn bg-danger text-white float-end" data-bs-dismiss="modal" aria-label="Close"
                  (click)="deleteSubGroup()">Sim</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>