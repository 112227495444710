<div *ngIf="userNotifications.length" class="notification" style="position: fixed; z-index: 99999; top: 10px; right: 10px">
<div style="margin-right: 15px">
  <span
    class="notification-icon"
    (click)="showNotificationMenu()"
    style="
      font-family: Material Icons;
      font-size: 1.9em;
      color: #fff;
      cursor: pointer;
    ">notifications</span>
  <span *ngIf="countUnread > 0" class="position-absolute start-50 translate-middle rounded-pill badge" style="margin-top: 15px; font-size: 0.6em; background: #ff4c75" [title]="countUnread + ' mensagens não lidas'">{{countUnread > 99 ? "99+" : countUnread}}</span>
  <div *ngIf="notificationMenuShow" class="notification-menu" id="notificationMenu" (scroll)="onScroll()">
    <div class="notification-menu-title">Notificações</div>
      <div *ngFor="let userNotification of userNotifications" class="notification-menu-item" (click)="showMessage(userNotification)">
        <span *ngIf="userNotification.readedAt == null" class="notification-icon notification-icon-unread">mark_email_unread</span>
        <span *ngIf="userNotification.readedAt != null" class="notification-icon notification-icon">drafts</span>
        <div class="notification-menu-item-content">
          <div class="notification-menu-item-title text-truncate">{{userNotification.title}}</div>
          <div class="notification-menu-item-message text-truncate" [innerHTML]="userNotification.message"></div>
          <div class="notification-menu-item-time">{{calculateTimeAgo(userNotification.createdAt)}}</div>
        </div>
        <span *ngIf="userNotification.important" class="notification-icon notification-icon-priority">priority_high</span>
      </div>
  </div>
</div>
</div>

<!-- Conteudo do body do modal -->
<ng-template #modalTitle >
  <span *ngIf="selectedUserNotification.readedAt == null" class="modal-icon notification-icon-unread">mark_email_unread</span>
  <span *ngIf="selectedUserNotification.readedAt != null" class="modal-icon">drafts</span>
  <span *ngIf="selectedUserNotification.important" class="modal-icon notification-icon-priority">priority_high</span>
  <div class="modal-item-title">{{selectedUserNotification.title}}</div>
</ng-template>
<ng-template #modalContent>
  <div class="notification-menu-item-content">
    <div class="modal-item-message" [innerHTML]="selectedUserNotification.message"></div>
  </div>
</ng-template>
<ng-template #modalFooter >
  <div class="modal-footer-itens">
    <div class="modal-item-time">{{selectedUserNotification.createdAt}}</div>
    <div class="modal-footer-buttons">
      <button title="Marcar como não lido" class="btn btn-primary modal-button" (click)="markUnreadMessage(selectedUserNotification.id)"><mat-icon>email</mat-icon></button>
      <button title="Deletar" class="btn btn-danger modal-button" (click)="deleteUserNotification(selectedUserNotification.id)"><mat-icon>delete</mat-icon></button>
    </div>
  </div>
</ng-template>
