import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { LoginResponse } from '../pages/login/model/login-response';
import { environment } from 'src/environments/environment';
import { ChangePasswordRequest } from '../pages/alterar-senha/models/change-password-request.model';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { Semaphore } from '@station/employees';
import { Role } from '../shared/model/role.model';
import { LoginRequest } from '../pages/login/model/login-request';
import { ForgotPasswordRequest } from '../pages/forgot-password/models/forgot-password-request.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authUser!: LoginResponse;
  semaphores!: Semaphore[];
  private returnURL: string = '/';

  constructor(private httpClient: HttpClient) {}

  setReturnUrl(url: string) {
    this.returnURL = url;
  }
  clearReturnUrl() {
    this.returnURL = '';
  }

  getReturnUrl(): string {
    return this.returnURL;
  }

  setSemaphore(semaphore: Semaphore[]) {
    this.semaphores = semaphore;

    localStorage.setItem('semaphores', JSON.stringify(this.semaphores));
  }

  
  defaultUserPage(): string {
    let authUser = this.getAuthUser();

    if (authUser.jobPosition.trim().toLowerCase() == 'colaborador') {
      return 'painel-colaborador';
    } else {
      return 'painel-supervisor';
    }
  }

  getSemaphores() {
    let retrievedObject = localStorage.getItem('semaphores') || '{}';
    let result: Semaphore[] = JSON.parse(retrievedObject);

    return result;
  }

  geSemaphoreByDescription(description: string): Semaphore | undefined {
    let semaphores = this.getSemaphores();
    let semaphore = semaphores.find((s) => s.description == description);

    return semaphore;
  }

  public getRoles(): string[] {
    const token = this.getToken();

    if (!token) {
      return [];
    }

    try {
      const decodedToken: any = jwt_decode(token);
      const roles: string[] = decodedToken.role;

      if (roles) {
        return roles;
      }

      return [];
    } catch (error) {
      return [];
    }
  }

  public hasRole(role: string): boolean {
    try {
      const roles: string[] = this.getRoles();

      if (roles && roles.find((x) => x == role)) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  }
  setAuthUser(authUser: LoginResponse) {
    this.authUser = authUser;
    localStorage.setItem('user_auth', JSON.stringify(this.authUser));
  }

  getAuthUser(): LoginResponse {
    let retrievedObject = localStorage.getItem('user_auth') ?? '';
    let result: LoginResponse = JSON.parse(retrievedObject);

    result.roles = this.getRoles();

    return result;
  }

  logout(): void {
    this.signOut();
  }
  signOut(): void {
    window.sessionStorage.clear();
  }
  isAuthenticated(): Boolean {
    let retrievedObject = localStorage.getItem('user_auth') ?? '';

    this.authUser = JSON.parse(retrievedObject);

    return this.decodePayloadJWT() != null; // check if token is expired
  }

  public getToken(): string {
    let retrievedObject = localStorage.getItem('user_auth') ?? null;

    return retrievedObject != null ? JSON.parse(retrievedObject)?.token : null;
  }

  public decodePayloadJWT(): any {
    try {
      return jwt_decode(this.getToken());
    } catch (Error) {
      return null;
    }
  }
  changePassword(
    changePasswordRequest: ChangePasswordRequest
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.authApi}/users/changePassword`,
      changePasswordRequest
    );
  }

  forgotPassword(
    forgotPasswordRequest: ForgotPasswordRequest
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.authApi}/users/forgotPassword/changePassword`,
      forgotPasswordRequest
    );
  }

  getAllRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(
      `${environment.authApi}${environment.endpoints.getRoles}`
    );
  }
  public checkToken(): Observable<any> {
    let token = this.getToken();

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.httpClient
      .get(`${environment.authApi}/users/checkToken`, httpOptions)
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }
}
