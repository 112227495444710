import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
    selector: "[removeLink]"
})
export class RemoveLinkDirective implements AfterViewInit {
    constructor(private elem: ElementRef<HTMLElement>) { }

    ngAfterViewInit(): void {
        this.elem.nativeElement.querySelector("a")?.removeAttribute("href")
    }
}