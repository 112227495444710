export class RequestSemaphoreRanges {

    red: number;    
    yellow: number;
    green: number;

    constructor(red: number, yellow: number, green: number){

        this.red = red;
        this.yellow = yellow;
        this.green = green;
    }
}