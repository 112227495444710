import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Semaphore } from '@station/employees';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PanelHierarchyService } from '../../painel-hierarquia/services/panelHierarchyService.service';

import { LoginRequest } from '../model/login-request';
import { LoginResponse } from '../model/login-response';
import { LoginTwoFactorRequest } from '../model/login-two-factor-request';
import { CheckinRequest } from '../model/checkin-request';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private hierarchy: PanelHierarchyService) { }

  login(loginRequest: LoginRequest): Observable<LoginResponse> {

    return this.http.post<LoginResponse>(`${environment.authApi}/users/authenticate`, loginRequest);

  }
  checkin(checkinRequest: CheckinRequest): Observable<LoginResponse> {

    return this.http.post<LoginResponse>(`${environment.authApi}${environment.endpoints.userCheckin}`, checkinRequest);

  }
  loginWithTwoFactor(loginRequest: LoginTwoFactorRequest): Observable<LoginResponse> {

    return this.http.post<LoginResponse>(`${environment.authApi}${environment.endpoints.authenticateWithTwoFactor}`, loginRequest);

  }

  defaultUserPage(authUser: LoginResponse): string {

    if (authUser.jobPosition.trim().toLowerCase() == "colaborador") {
      return "painel-colaborador";
    }
    else{
      return "painel-supervisor";
    }

  }

  emailForgout(email: any) {
    return this.http.put(`${environment.authApi}/users/forgotPassword/${email}`, null);
  }


  getSemaphore(): Observable<Semaphore[]>{

    let url = `${environment.settingsApi}${environment.endpoints.getSemaphore}`
    return this.http.get<Semaphore[]>(url);
  }

}
