import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TwoFactorRegisterService } from '../service/twoFactorRegister.service';
import { TwoFactorRegisterResponse } from './model/two-factor-response';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  images: string[] = ['bg1.jpg', 'bg2.jpg', 'bg3.jpg', 'bg4.jpg', 'bg5.jpg']
  backgroundImage: string = 'bg2.jpg'
  qrCode!:string;

  constructor(
    private twoFactorRegisterService: TwoFactorRegisterService,   
    private router: Router   
  ) { }

  ngOnInit(): void {

    let ran = Math.round((Math.random() * 100) % 5)
    this.backgroundImage = (this.images[ran] == undefined) ? 'bg2.jpg' : this.images[ran]

    this.twoFactorRegisterService.register().subscribe((data:TwoFactorRegisterResponse) => this.qrCode = data.qrCode);

  }


  submit() {
      
    this.router.navigate(["login"])

  }

}
