<div class="content height-100 login" [style.background]="currentAnimation.BackgroundColor">

    <div class="row height-100">
        <div class="box-left col-lg-4">
            <div (click)="changeToLogin()" class="row back-logo">
                <div class="col-lg-6 d-flex align-items-start">
                    <picture>
                        <img src="./assets/img/logo-mutant.webp">
                    </picture>
                </div>
                <div class="col-lg-6">
                    <img src="./assets/img/station-logo-transparente.png">
                </div>
            </div>
            <div class="align-items-center height-100 d-flex">
                <img [src]="'./assets/img/' + currentAnimation.Filename" />
                <div class="word-container" [@typedAnimation]="typedWord">
                    {{ typedWord }}
                </div>
            </div>
        </div>

        <div class="box-right col-lg-8">
            <div class="form-section d-flex align-items-center"
                [ngStyle]="{'background-image': 'url(assets/img/' + backgroundImage + ')'}">

                <div [ngClass]="{'form-content-forgout':changeForForgout, 'form-content':!changeForForgout}">

                    <div class="form-logo">

                        <div style="display: flex; justify-content: space-between;">
                            <div class="back-button" *ngIf="changeForForgout" (click)="changeToLogin()">
                                <svg fill="white" xmlns="http://www.w3.org/2000/svg"
                                    shape-rendering="geometricPrecision" text-rendering="geometricPrecision"
                                    image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
                                    viewBox="0 0 512 376.83">
                                    <path fill-rule="nonzero"
                                        d="M156.88 372.7a12.026 12.026 0 0 0 17.09 1.06c5-4.47 5.46-12.2 1.04-17.25L38.96 200.69h460.89c6.71 0 12.15-5.5 12.15-12.28 0-6.77-5.44-12.27-12.15-12.27H38.95L175.01 20.32c4.42-5.05 3.96-12.78-1.04-17.25-5.01-4.47-12.66-4-17.09 1.05l-153.67 176c-4.17 4.55-4.32 11.64-.17 16.39L156.88 372.7z" />
                                </svg>
                            </div>
                            <div class="back-button text-white" *ngIf="changeForForgout" [ngbTooltip]="tipTodayButton">
                                <mat-icon class="info-icon" title="Saiba mais">info</mat-icon>
                            </div>
                        </div>

                        <img src="./assets/img/myra-icon.png">
                        <!-- <app-loading-animation></app-loading-animation> -->

                    </div>

                    <!-- <div *ngIf="showMensageError" class="d-flex justify-content-center">
                        <p>Usuario ou senha estão incorretas!</p>
                    </div> -->

                    <div class="form-forms">
                        <form [formGroup]="loginForm" (ngSubmit)="submit()" *ngIf="loginForm">
                            <div>
                                <label *ngIf="!changeForForgout" for="user">Usuário</label>
                                <label *ngIf="changeForForgout" for="user">Email</label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                    class="bi bi-person" viewBox="0 0 16 16">
                                    <path
                                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                </svg>
                                <input class="username" *ngIf="!changeForForgout" formControlName="login" type="text"
                                    name="login" (focus)="onPasswordFocus()" (blur)="onPasswordBlur()"
                                    [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}"
                                    placeholder="Usuário">
                                <input class="username" *ngIf="changeForForgout" formControlName="login" type="text"
                                    name="login" (focus)="onPasswordFocus()" (blur)="onPasswordBlur()"
                                    [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}"
                                    placeholder="Email">
                            </div>
                            <div *ngIf="!changeForForgout" title="Clique para exibir/ocultar a senha">
                                <label for="password">Senha</label>
                                <svg (click)="togglePasswordVisibility()" xmlns="http://www.w3.org/2000/svg" width="25"
                                    height="25" fill="currentColor" class="bi bi-shield-lock" viewBox="0 0 16 16">
                                    <path
                                        d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                                    <path
                                        d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
                                </svg>
                                <input *ngIf="isPasswordField; else textInput" formControlName="senha" type="password"
                                    name="senha" placeholder="Senha" (focus)="onPasswordFocus()"
                                    (blur)="onPasswordBlur()"
                                    [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}">
                                <ng-template #textInput><input formControlName="senha" type="text" name="senha"
                                        placeholder="Senha" (focus)="onPasswordFocus()" (blur)="onPasswordBlur()"
                                        [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}"></ng-template>
                            </div>
                            <div *ngIf="twoFactorEnabled && !changeForForgout">
                                <label for="twoFactorCode"
                                    title="Caso queria realizar o cadastro, basta informar o e-mail, senha e deixar o código de segurança em branco">Código
                                    de Segurança</label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="#000000"
                                        d="M18,8H17V6A5,5 0 0,0 12,1A5,5 0 0,0 7,6V8H6A2,2 0 0,0 4,10V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V10A2,2 0 0,0 18,8M8.9,6C8.9,4.29 10.29,2.9 12,2.9C13.71,2.9 15.1,4.29 15.1,6V8H8.9V6M16,16H13V19H11V16H8V14H11V11H13V14H16V16Z" />
                                </svg>
                                <input formControlName="twoFactorCode" class="two-factor-code" type="text"
                                    title="Caso queria realizar o cadastro, basta informar o e-mail, senha e deixar o código de segurança em branco"
                                    maxlength="6" minlength="6" name="twoFactorCode"
                                    placeholder="Código do Authenticator" required>
                            </div>
                            <div>
                                <button *ngIf="!changeForForgout" type="submit">Entrar</button>

                                <button *ngIf="changeForForgout" type="button"
                                    (click)="sendForgoutPassword()">Enviar</button>
                            </div>
                        </form>
                    </div>

                    <div class="d-flex justify-content-center forgout-button">
                        <a (click)="changeToForgoutPassword()" *ngIf="!changeForForgout">Esqueci minha senha</a>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>

<ng-template #tipTodayButton>Se você não receber o e-mail de recuperação de senha em sua caixa de entrada, verifique sua
    pasta de spam. Alguns provedores de e-mail podem direcionar nossas mensagens para lá.</ng-template>