import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'station-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {
  @Input() title!: string;
  @Input() modalTitle!: TemplateRef<any>;
  @Input() modalContent!: TemplateRef<any>;
  @Input() modalFooter!: TemplateRef<any>;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  modalClose()
  {
    this.activeModal.close();
  }
}
