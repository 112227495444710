import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChartColumnGroupModel, ChartItemModel, ChartLineModel } from '@station/charts';
import { Observable, Subject } from 'rxjs';
import { ConnectionComparisionModel } from 'src/app/shared/model/connection-comparesion';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  constructor(private http: HttpClient) { }


  markReadUserNotification(userNotificationId: string): Observable<any> {

    let url = `${environment.notificationApi}${environment.endpoints.markReadUserNotification}/${userNotificationId}`

    return this.http.post<any>(url, null);
  }
  markUnreadUserNotification(userNotificationId: string): Observable<any> {

    let url = `${environment.notificationApi}${environment.endpoints.markUnreadUserNotification}/${userNotificationId}`

    return this.http.post<any>(url, null);
  }
  deleteUserNotification(userNotificationId: string): Observable<any> {

    let url = `${environment.notificationApi}${environment.endpoints.deleteUserNotification}/${userNotificationId}`

    return this.http.delete<any>(url);
  }
}
