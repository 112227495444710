import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Journey } from '../models/journey';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {

  constructor(private http: HttpClient) { }

  
  getAllJourneys(): Observable<Journey[]>{
    return this.http.get<Journey[]>(`${environment.peopleApi}/api/v1/journey`);
  }

  postJourney(journey: Journey): Observable<Journey>{
    return this.http.post<Journey>(`${environment.peopleApi}/api/v1/journey`,journey);
  }

  putJourney(journey: Journey): Observable<Journey>{
    return this.http.put<Journey>(`${environment.peopleApi}/api/v1/journey/${journey.id}`, journey);
  }
}



