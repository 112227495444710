import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PanelHierarchyService } from '../../painel-hierarquia/services/panelHierarchyService.service';

@Injectable({
  providedIn: 'root'
})
export class PainelSupervisorGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, public route: ActivatedRoute, private panelHierarchyService: PanelHierarchyService) {}
  async canActivate(): Promise<boolean> {

    this.auth.checkToken().subscribe(() => {      
    
      
        if (this.auth.decodePayloadJWT()?.jobPosition.toLowerCase() == "colaborador") {
          this.router.navigate(['painel-colaborador']);
          return false;
        }        
        return true;
    });

    return true;
  }
}