import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'station-logout',
  template: 'lougout ...',

})
export class Logout implements OnInit {


  constructor(private router: Router) { }
  ngOnInit(): void {



    localStorage.clear();
    this.router.navigate(['login']);

  }

}