

interface String {
  GetFormatedISODate(): string;
}


String.prototype.GetFormatedISODate = function (): string {
  let dateObject = this.split("/");
  let year = dateObject[2];
  let month = dateObject[1];
  let day = dateObject[0];
  return `${year}-${month}-${day}`;
};
