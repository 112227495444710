import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GrupoDeFerramentaRoutingModule } from './grupo-de-ferramenta-routing.module';
import { ViewComponent } from './view/view.component';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';
import { ChartsModule } from '@station/charts';
import { EmployeesModule } from "@station/employees";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashHeaderModule } from 'src/app/shared/modules/dash-header/dash-header.module';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    CommonModule,
    GrupoDeFerramentaRoutingModule,
    HeaderModule,
    MatMenuModule,
    MatButtonModule,
    MenuModule,
    EmployeesModule,
    DashHeaderModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    NgbTooltipModule
  ]
})
export class GrupoDeFerramentaModule { }
