import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'station-modal-options',
  templateUrl: './modal-options.component.html',
  styleUrls: ['./modal-options.component.scss']
})
export class ModalOptionsComponent implements OnInit {

  showModalOptions!: boolean
  @Input() idModalOptions!: string;
  
  constructor() { }

  ngOnInit() {
    this.addEventClick()
  }


  openModalOptions() {
    this.showModalOptions = !this.showModalOptions
  }


  addEventClick() {

    document.addEventListener('click', (event) => {

      const meuElemento = document.getElementById(this.idModalOptions) as HTMLElement

      if (meuElemento == null) return

      if (!meuElemento.contains(event.target as Node)) {

        this.showModalOptions = false
      }

    });
  }

}
