import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { ViewComponent } from './view/view.component';
import { TwoFactorRegisterRoutingModule } from './two-factor-register-routing.module';



@NgModule({
    declarations: [
        ViewComponent
    ],
    imports: [
        CommonModule,
        TwoFactorRegisterRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        
    ]
})
export class TwoFactorRegisterModule { }
