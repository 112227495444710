<div class="painel-colaborador">
  <station-header [headerModel]="header"></station-header>
  <station-user-notification [countUnread]="countUnreadUserNotification" [userNotifications]="userNotifications"
    (loadMore)="loadMoreUserNotification($event)"></station-user-notification>
  <div class="painel-colaborador_conteudo">
    <station-sidebar [isEmployees]="true" [employeeId]="authUser.id" [employeeId$]="employeeId$" [employee$]="profile$"
      [refreshInterval]="intervalValue" (isOpened)="checkEmployeeMenu($event)"></station-sidebar>

    <div class="content">
      <div class="dash-header_content">
        <station_dash-header [isProfile]="isProfile" [employeesBadConnection]="employeesBadConnection"
          [employee$]="profile$">
        </station_dash-header>
      </div>

      <div class="dateFilter">
        <div class="toggle-switch-container" *ngIf="toggleRefreshEnabled">
          <input type="checkbox" id="toggle-switch" class="toggle-switch" (change)="toggleRefresh($event)" />
          <label for="toggle-switch" class="switch"></label>
          <label for="toggle-switch" class="switch-label">Atualizar?</label>

          <input type="number" id="input-time" value="5" min="5" pattern="[5-9]\d*"
            title="O número deve ser igual ou maior que 5 minutos" class="input-time" [(ngModel)]="intervalValue" />
          <label for="toggle-switch" class="switch-label">min.</label>
        </div>
        <div class="toggle-switch-container">
          <small><i><b>Última atualização:</b>
              {{ lastUpdate | date : "dd/MM/yyyy HH:mm:ss" }}</i></small>
        </div>
        <div class="d-flex btnSelector">
          <div class="d-flex flex-row-reverse me-2">
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('month')"
              [ngbTooltip]="tipMonthButton" placement="bottom" [ngClass]="
                availabilityChartsSelectOption === Periods.Month
                  ? 'periodActive'
                  : ''
              ">
              Mês
            </button>
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('week')"
              [ngbTooltip]="tipWeekButton" placement="bottom" [ngClass]="
                availabilityChartsSelectOption === Periods.Week
                  ? 'periodActive'
                  : ''
              ">
              Quinzena
            </button>
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('day')"
              [ngbTooltip]="tipTodayButton" placement="bottom" [ngClass]="
                availabilityChartsSelectOption === Periods.Day
                  ? 'periodActive'
                  : ''
              ">
              {{ dayLabel }}
            </button>
          </div>
          <div>
            <label *ngIf="visualizationTypeEnabled" _ngcontent-kpa-c172=""
              style="margin-right: 10px; font-size: 1em">Tipo de visualização:</label>
            <select *ngIf="visualizationTypeEnabled" name="" id="" [(ngModel)]="visualizationType"
              (change)="updatePanels()">
              <option value="gestao-direta">Gestão direta</option>
              <option value="todo-time">Todo o time</option>
            </select>
            <input type="date" [max]="maxDate" (change)="updatePanels()" [(ngModel)]="dateFiltered" />
          </div>
        </div>
      </div>

      <div class="big-number-content">
        <div class="big-number-info">
          <div class="big-number-info-title">
            <p class="highlightsAvailability">
              <station-modal-options [idModalOptions]="'Logados'">
                <div (click)="exportToExcelLogged()" title="Exportar planilha" class="modal-itens-optios">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                      class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                    </svg>
                    Exportar
                  </div>
                </div>
              </station-modal-options>
              Logados
              <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                data-bs-target="#modalDisponibilidade">info</mat-icon>
            </p>
          </div>
          <div>
            <station-employee-big-number [bigNumbers]="bigNumberAvailabilityTodayTeams" [ngbTooltip]="tipTeam"
              placement="bottom" style="width: 22%"></station-employee-big-number>
          </div>
        </div>

        <div class="chartComparativoTempoLogado">
          <div class="employee-chart">
            <station-chart-line-percentage-area [chartLineOptions$]="chartConexao" (newItemEvent)="
                dateFilterClick($event, 'station-chart-line-percentage-area')
              " (nenuItemEvent)="chartColumnGroupMenuItemClick($event)">
            </station-chart-line-percentage-area>
          </div>
        </div>
      </div>

      <div class="big-number-content">
        <div class="big-number-info">
          <div class="big-number-info-title">
            <p class="highlightsProductivity">
              <station-modal-options [idModalOptions]="'Produtividade'">
                <div (click)="exportToExcelproductivity()" title="Exportar planilha" class="modal-itens-optios">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                      class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                    </svg>
                    Exportar
                  </div>
                </div>
              </station-modal-options>
              Produtividade
              <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                data-bs-target="#modalProdutividade">info</mat-icon>
            </p>
          </div>
          <div style="display: flex; gap: 10px;">
            <mat-tab-group (selectedTabChange)="productivityTabsClick($event)">
              <mat-tab label="Tempo logado">
                <station-employee-big-number [bigNumbers]="bigNumberHighLightTodayTeam"></station-employee-big-number>
              </mat-tab>
              <mat-tab label="Produtividade (no tempo logado)">
                <station-employee-big-number
                  [bigNumbers]="employeeBigNumberHighlightsProductivityLogged"></station-employee-big-number>
              </mat-tab>
              <mat-tab label="Produtividade (total)">
                <station-employee-big-number
                  [bigNumbers]="employeeBigNumberHighlightsProductivity"></station-employee-big-number>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>

        <div>
          <station-chart-column-group [chartColumnGroupOptions$]="chartItemOptionsStacked"
            [ngbTooltip]="tipProductivityChart" placement="bottom" (newItemEvent)="
              dateFilterClick($event, 'station-chart-column-group')
            " (nenuItemEvent)="chartColumnGroupMenuItemClick($event)"></station-chart-column-group>
        </div>
      </div>
      <div class="table table-my-team">
        <div class="big-number-info-title">
          <p class="highlightsProductivity">
            <station-modal-options [idModalOptions]="'Visão Geral'">
              <div (click)="exportToExcelAllVision()" title="Exportar planilha" class="modal-itens-optios">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                    class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                  </svg>
                  Exportar
                </div>
              </div>
            </station-modal-options>
            Visão Geral
          </p>
        </div>
        <station-table-team-report [team]="teamReport" [alertSetting]="alertSetting" [dateFiltered]="dateFiltered"
          [subtitle]="subtitle" [isExport]="isExport" (resetReportMyTeam)="resetReport($event)"
          (getMyTeamReportByRangeTime)="getMyTeamReportByRangeTime($event)"></station-table-team-report>
      </div>
      <div class="indicadores">
        <div class="ranking-top-bottom" [ngbTooltip]="tipRankingBottom" placement="bottom">
          <ng-container *ngIf="employeesRakingProdutivo != null; else loading">
            <div class="big-number-info-title">
              <p class="highlightsProductivity">
                <station-modal-options [idModalOptions]="'Top-Produtivo'">
                  <div (click)="exportToExcelTopRanking('Top-Produtivo',employeesRakingProdutivo)"
                    title="Exportar planilha" class="modal-itens-optios">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                        class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                      </svg>
                      Exportar
                    </div>
                  </div>
                </station-modal-options>
                Top 5
                <mat-icon _ngcontent-tll-c48="" role="img" title="Saiba mais" data-bs-toggle="modal"
                  class="mat-icon notranslate info-icon material-icons mat-icon-no-color ng-star-inserted"
                  aria-hidden="true" data-bs-target="#modalRankingTop" data-mat-icon-type="font">info</mat-icon>
              </p>
            </div>
            <station-employee-ranking [employeesRanking]="employeesRakingProdutivo"></station-employee-ranking>
          </ng-container>
          <ng-template #loading>
            <station-spinners></station-spinners>
          </ng-template>
        </div>
        <div class="ranking-top-bottom" [ngbTooltip]="tipRankingTop" placement="bottom">
          <ng-container *ngIf="employeesRakingImprodutivo != null; else loading">
            <div class="big-number-info-title">
              <p class="highlightsProductivity">
                <station-modal-options [idModalOptions]="'Top-Improdutivo'">
                  <div (click)="exportToExcelTopRanking('Top-Improdutivo', employeesRakingImprodutivo)"
                    title="Exportar planilha" class="modal-itens-optios">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                        class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                      </svg>
                      Exportar
                    </div>
                  </div>
                </station-modal-options>
                Bottom 5
                <mat-icon _ngcontent-tll-c48="" role="img" title="Saiba mais" data-bs-toggle="modal"
                  class="mat-icon notranslate info-icon material-icons mat-icon-no-color ng-star-inserted"
                  aria-hidden="true" data-bs-target="#modalRankingTop" data-mat-icon-type="font">info</mat-icon>
              </p>
            </div>
            <station-employee-ranking [employeesRanking]="employeesRakingImprodutivo"
              id="table-ranking-bottom"></station-employee-ranking>
          </ng-container>
          <ng-template #loading>
            <station-spinners></station-spinners>
          </ng-template>
        </div>
      </div>
      <div class="table">
        <div class="container-prod">
          <div class="improdutivas">
            <div class="big-number-info-title">
              <p class="highlightsProductivity" style="font-size: 1.2rem;">
                <station-modal-options [idModalOptions]="'top-improdutivo'">
                  <div  title="Exportar planilha" class="modal-itens-optios" (click)="exportToExcelApplication('Top-improdutivas',' Top 20 | Aplicações Improdutiva')">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                        class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                      </svg>
                      Exportar
                    </div>
                  </div>
                </station-modal-options>
                Top 20 | Aplicações Improdutiva
                <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                  [attr.data-bs-target]="'#modalTopImprodutivas'">info</mat-icon>
              </p>
            </div>
            <station-table-aplicacoes [title]="titleTopImprodutivas" [subTitle]="subTitleTopIprodutivas"
              [ngbTooltip]="tipUnproductivityApplication" placement="right" [applications]="unproductiveApplications"
              [modalId]="'modalTopImprodutivas'" [modalIdPesquisaColaborador]="'modalPesquisaColaborador'"
              [IdTable]="'Top-improdutivas'"
              (searchApplication)="searchApplication($event)"></station-table-aplicacoes>
          </div>
          <div class="produtivas">
            <div class="big-number-info-title">
              <p class="highlightsProductivity" style="font-size: 1.2rem;">
                <station-modal-options [idModalOptions]="'top-produtiva'">
                  <div  title="Exportar planilha" class="modal-itens-optios" (click)="exportToExcelApplication('Top-Produtivas',' Top 20 | Aplicações Produtivas')">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                        class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                          d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                      </svg>
                      Exportar
                    </div>
                  </div>
                </station-modal-options>
                Top 20 | Aplicações Produtivas
                <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                  [attr.data-bs-target]="'#modalTopProdutivas'">info</mat-icon>
              </p>
            </div>
            <station-table-aplicacoes [title]="titleTopProdutivas" [subTitle]="subTitleTopProdutivas"
              [ngbTooltip]="tipProductivityApplication" placement="left" [applications]="productiveApplications"
              [modalId]="'modalTopProdutivas'" [modalIdPesquisaColaborador]="'modalPesquisaColaborador'"
              [IdTable]="'Top-Produtivas'"
              (searchApplication)="searchApplication($event)"></station-table-aplicacoes>
          </div>
        </div>
      </div>
      <div class="table">
        <div>
          <div class="big-number-info-title">
            <p class="highlightsProductivity">
              <station-modal-options [idModalOptions]="'sem-classificacao'">
                <div  title="Exportar planilha" class="modal-itens-optios" (click)="exportToExcelApplication('Top-sem-clasificacao',' Top 20 | Aplicações Sem Classificação')">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                      class="bi bi-filetype-xlsx" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z" />
                    </svg>
                    Exportar
                  </div>
                </div>
              </station-modal-options>
              Top 20 | Aplicações Sem Classificação
              <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                [attr.data-bs-target]="'#modalTopWithoutClassification'">info</mat-icon>
            </p>
          </div>
          <station-table-aplicacoes class="full-table" [title]="titleTopWithoutClassification"
            [subTitle]="subTitleTopWithoutClassifications" [ngbTooltip]="tipWithoutClassificationApplication"
            placement="left" [applications]="productiveWithouthapplications" [modalId]="'modalTopWithoutClassification'"
            [modalIdPesquisaColaborador]="'modalPesquisaColaborador'"
            [IdTable]="'Top-sem-clasificacao'"
            (searchApplication)="searchApplication($event)"></station-table-aplicacoes>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Disponibilidade -->
  <div class="modal fade" id="modalDisponibilidade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Saiba mais</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><b>Disponibilidade</b></p>
          <p>
            <span class="font-weight-normal">Refere-se ao tempo que um usuário passa logado e disponível para
              trabalhar.</span>
          </p>
          <p>
            <span class="font-weight-normal">É calculado o tempo disponível em relação à jornada de trabalho,
              descontando o tempo ocioso.</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Para calcular a disponibilidade de toda a equipe, somamos o tempo
              de trabalho planejado para cada colaborador (Jornada) e depois
              somamos quanto tempo cada um deles ficou realmente online durante
              o dia.</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">(Tempo total logado <b>/</b> Tempo da jornada)</span>
          </p>
          <p></p>
          <p><b>Exemplo:</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Total na equipe:<b> 5</b></span>
          </p>
          <p>
            <span class="font-weight-normal">Cada colaborador com 10 horas de jornada no dia: 10 * 5 =
              <b>50</b> horas</span>
          </p>
          <p>
            <span class="font-weight-normal">Cada colaborador com 8 horas de tempo logado no dia: 8 * 5 =
              <b>40</b> horas</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Resultando em um total de <b>80%</b> de Disponibilidade no dia
              para a equipe</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">A disponibilidade é uma métrica importante para medir o tempo em
              que os colaboradores estão online e disponíveis para trabalhar,
              permitindo avaliar a utilização eficiente do tempo de trabalho da
              equipe.</span>
          </p>
          <p></p>
          <p><b>Ociosidade</b></p>
          <p>
            <span class="font-weight-normal">Refere-se ao tempo em que o usuário não utiliza o mouse ou
              teclado.</span>
          </p>
          <p>
            <span class="font-weight-normal">É calculado o tempo ocioso dividido pelo tempo da jornada.</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Para calcular a Ociosidade de toda a equipe, somamos o tempo de
              trabalho planejado para cada colaborador (Jornada) e depois
              somamos quanto tempo cada um deles ficou realmente ocioso durante
              o dia.</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">(Tempo ocioso <b>/</b> Tempo da jornada)</span>
          </p>
          <p></p>
          <p><b>Exemplo:</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Total na equipe:<b> 5</b></span>
          </p>
          <p>
            <span class="font-weight-normal">Cada colaborador com 10 horas de jornada no dia: 10 * 5 =
              <b>50</b> horas</span>
          </p>
          <p>
            <span class="font-weight-normal">Cada colaborador com 2 horas de tempo ocioso no dia: 2 * 5 =
              <b>10</b> horas</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Resultando em um total de <b>20%</b> de Ociosidade no dia para a
              equipe</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">A ociosidade é uma métrica importante para medir o tempo em que
              os colaboradores estão "inativos" durante suas jornadas de
              trabalho, auxiliando na análise da eficiência e utilização do
              tempo.</span>
          </p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Produtividade -->
  <div class="modal fade" id="modalProdutividade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Saiba mais</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><b>Produtividade</b></p>
          <p>
            <span class="font-weight-normal">Refere-se ao tempo que um usuário passa acessando aplicações
              classificadas como Produtivas.</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Para calcular a Produtividade de toda a equipe, somamos o tempo
              de trabalho planejado para cada colaborador (Jornada) e depois
              somamos quanto tempo cada um deles ficou realmente em aplicações
              produtivas.</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">(Tempo em aplicações produtivas <b>/</b> Tempo na
              Jornada)</span><b>&nbsp;</b>
          </p>
          <p></p>
          <p><b>Exemplo:</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Total na equipe:<b> 5</b></span>
          </p>
          <p>
            <span class="font-weight-normal">Cada colaborador com 10 horas de jornada no dia: 10 * 5 =
              <b>50</b> horas</span>
          </p>
          <p>
            <span class="font-weight-normal">Cada colaborador com 6 horas de tempo em aplicações produtivas no
              dia: 6 * 5 = <b>30</b> horas</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Resultando em um total de <b>60%</b> de Produtividade no dia para
              a equipe</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">A produtividade é uma métrica importante para medir o tempo que a
              equipe gasta em atividades produtivas, auxiliando na avaliação da
              eficiência do trabalho realizado.</span>
          </p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Ranking Top -->
  <div class="modal fade" id="modalRankingTop" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Saiba mais</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><b>Ranking Top 5</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Esta seção exibe uma lista dos 5 colaboradores mais produtivos da
              equipe, ordenados por sua Produtividade Total (na Jornada),
              Disponibilidade e Produtividade (no Tempo Logado).</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Isso permite a identificação fácil dos colaboradores mais
              produtivos, destacando suas realizações e boas práticas.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Ranking Bottom -->
  <div class="modal fade" id="modalRankingBottom" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Saiba mais</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><b>Ranking Bottom 5</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Esta seção exibe uma lista dos 5 colaboradores com menor
              produtividade da equipe, ordenados por sua Produtividade Total (na
              Jornada), Disponibilidade e Produtividade (no Tempo Logado).</span>
          </p>
          <p></p>
          <p>
            <span class="font-weight-normal">Isso permite a identificação daqueles que podem precisar de mais
              apoio ou treinamento para melhorar sua produtividade, auxiliando
              na gestão do desempenho da equipe.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Top Produtivas -->
  <div class="modal fade" id="modalTopProdutivas" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Saiba mais</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><b>Top 20 aplicações Produtivas</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Nesta seção, apresentamos as 20 aplicações que se destacaram por
              contribuir significativamente para a produtividade da equipe, com
              base no tempo de acesso. A lista inclui as ferramentas e softwares
              que foram mais utilizados pelos colaboradores, refletindo as
              atividades que mais agregaram valor e eficiência ao trabalho.
              Essas aplicações produtivas desempenham um papel fundamental no
              sucesso da equipe e podem servir como exemplos inspiradores para
              impulsionar o desempenho geral. A análise detalhada dessas
              aplicações permite compreender melhor as práticas eficazes e
              direcionar a atenção para as áreas mais impactantes, incentivando
              o alinhamento com os objetivos estratégicos da organização.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Top Improdutivas -->
  <div class="modal fade" id="modalTopImprodutivas" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Saiba mais</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><b>Top 20 aplicações Improdutivas</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Nesta seção, destacamos as 20 aplicações que contribuíram menos
              para a produtividade da equipe, levando em consideração o tempo de
              acesso. Essas aplicações podem ser identificadas com base na
              quantidade de tempo gasto pelos colaboradores, fornecendo insights
              valiosos sobre áreas que podem necessitar de otimização ou
              revisão. A análise dessas aplicações menos produtivas pode ser
              útil para identificar oportunidades de treinamento ou melhorias
              nos processos, visando aprimorar a eficiência geral e garantir um
              uso mais eficaz dos recursos da equipe.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Top Sem classificacao -->
  <div class="modal fade" id="modalTopWithoutClassification" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Saiba mais</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p><b>Top 20 aplicações sem classificação</b></p>
          <p></p>
          <p>
            <span class="font-weight-normal">Nesta seção, destacamos as 20 aplicações que contribuíram para
              não termos mais registros classificados como produtivo ou
              improdutivo, levando em consideração o tempo de acesso. Essas
              aplicações podem ser identificadas com base na quantidade de tempo
              gasto pelos colaboradores, fornecendo insights valiosos sobre
              áreas que podem necessitar de otimização ou revisão. A análise
              dessas aplicações menos produtivas pode ser útil para identificar
              oportunidades de treinamento ou melhorias nos processos, visando
              aprimorar a eficiência geral e garantir um uso mais eficaz dos
              recursos da equipe. Sugerimos que analise essa listagem e adicione
              as mais relevantes nos filtros de termos em
              <b>Grupo de Ferramentas</b></span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modalPesquisaColaborador" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div style="border-bottom: 1px solid #adadad">
          <div class="modal-header">

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-header" style="display: flex; justify-content: space-around;">
            <p *ngIf="subTitle.length > 0" style="font-size: 14px;">Titulo: {{ subTitle.length > 70 ? (subTitle |
              slice:0:65) + '...' : subTitle }}</p>
          </div>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="employeeList.length > 0; else loading">
            <div *ngFor="let item of employeeList" style="margin-bottom: 10px;">
              <station-employee-info [employee]="item" [visualization_type]="visualizationType"
                (goToSomePage)="goToSomePage($event)">
              </station-employee-info>
            </div>
          </ng-container>
          <div *ngIf="showMessage" style="display: flex; justify-content: space-around;">
            <h5>Nenhum funcionário foi encontrado</h5>
          </div>
          <ng-template #loading>
            <div *ngIf="!showMessage">
              <station-spinners></station-spinners>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Pesquisa aplicação coloborador -->






  <!-- Tooltip Team -->
  <ng-template #tipTeam><b>Equipe:</b> Total de pessoas da equipe diretamente ligadas<br /><br /><b>Logado:</b>
    Total de pessoas logadas no momento<br /><br /><b>% Logado:</b> Percentual
    de pessoas logadas sobre o total da equipe</ng-template>
  <!-- Tooltip Availability Highlights Team -->
  <ng-template #tipAvailabilityHighlights><b>Hoje:</b> Percentual de Disponibilidade de toda a equipe no dia
    atual<br /><br /><b>Ontem:</b>
    Percentual de Disponibilidade de toda a equipe no dia de ontem<br /><br /><b>Sem:</b>
    Percentual de Disponibilidade de toda a equipe na semana atual<br /><br /><b>Mês:</b>
    Percentual de Disponibilidade de toda a equipe no mês atual</ng-template>
  <!-- Tooltip Availability Chart -->
  <ng-template #tipAvailabilityChart><b>Gráfico:</b> Exibe dados de Disponibilidade e Ociosidade da equipe nas
    visões de Hoje, Semana e Mês<br /><br /><b>Ociosidade:</b> Oculta ou exibe
    somente os dados de Ociosidade<br /><br /><b>Disponibilidade:</b> Oculta ou
    exibe somente os dados de Disponibilidade</ng-template>
  <!-- Tooltip Today button -->
  <ng-template #tipTodayButton>Exibe dados no gráfico com a visão hora a hora do dia de hoje</ng-template>
  <!-- Tooltip Week button -->
  <ng-template #tipWeekButton>Exibe dados no gráfico dos últimos 15 dias</ng-template>
  <!-- Tooltip Month button -->
  <ng-template #tipMonthButton>Exibe dados no gráfico dos últimos 12 meses</ng-template>
  <!-- Tooltip Productivity Highlights Team -->
  <ng-template #tipProductivityHighlights><b>Hoje:</b> Percentual de Produtividade de toda a equipe no dia
    atual<br /><br /><b>Ontem:</b>
    Percentual de Produtividade de toda a equipe no dia de ontem<br /><br /><b>Sem:</b>
    Percentual de Produtividade de toda a equipe na semana atual<br /><br /><b>Mês:</b>
    Percentual de Produtividade de toda a equipe no mês atual</ng-template>
  <!-- Tooltip Productivity Chart -->
  <ng-template #tipProductivityChart><b>Gráfico:</b> Exibe dados de Produtividade Prevista, Disponibilidade e
    Produtividade Realizada na Jornada da equipe nas visões de Hoje, Semana e
    Mês<br /><br /><b>Produtividade prevista:</b> Oculta ou exibe somente os
    dados de Produtividade Prevista<br /><br /><b>Disponibilidade:</b> Oculta ou
    exibe somente os dados de Disponibilidade<br /><br /><b>Produtividade realizada na jornada:</b>
    Oculta ou exibe somente os dados de Produtividade Realizada na
    Jornada</ng-template>
  <!-- Tooltip Ranking Top -->
  <ng-template #tipRankingTop><b>Produtividade Total:</b> Clique para ordenar entre os 5 colaboradores
    que tiveram a maior Produtividade Total essa semana<br /><br /><b>Disponibilidade (total logado):</b>
    Clique para ordenar entre os 5 colaboradores que tiveram a maior
    Disponibilidade essa semana<br /><br /><b>Produtividade (total logado):</b>
    Clique para ordenar entre os 5 colaboradores que tiveram a maior
    Produtividade no Tempo Logado</ng-template>
  <!-- Tooltip Ranking Bottom -->
  <ng-template #tipRankingBottom><b>Produtividade Total:</b> Clique para ordenar entre os 5 colaboradores
    que tiveram a menor Produtividade Total essa semana<br /><br /><b>Disponibilidade (total logado):</b>
    Clique para ordenar entre os 5 colaboradores que tiveram a menor
    Disponibilidade essa semana<br /><br /><b>Produtividade (total logado):</b>
    Clique para ordenar entre os 5 colaboradores que tiveram a menor
    Produtividade no Tempo Logado</ng-template>
  <!-- Tooltip Productivity Application -->
  <ng-template #tipProductivityApplication><b>Categoria:</b> Tipo de categoria cadastrada para as aplicações
    produtivas<br /><br /><b>Título:</b> identificação da aplicação utilizada
    pela equipe<br /><br /><b>Tempo total:</b> Soma do tempo de todos da equipe
    sobre uma aplicação</ng-template>
  <!-- Tooltip Unproductivity Application-->
  <ng-template #tipUnproductivityApplication><b>Categoria:</b> Tipo de categoria cadastrada para as aplicações
    improdutivas<br /><br /><b>Título:</b> identificação da aplicação utilizada
    pela equipe<br /><br /><b>Tempo total:</b> Soma do tempo de todos da equipe
    sobre uma aplicação</ng-template>
  <!-- Tooltip without Classification Application-->
  <ng-template #tipWithoutClassificationApplication><b>Título:</b> identificação da aplicação utilizada pela equipe sem
    classificação<br /><br /><b>Tempo total:</b> Soma do tempo de todos da
    equipe sobre uma aplicação</ng-template>
</div>