import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertSetting, BigNumberConfig, BigNumberIcon, BigNumberModel, EmployeeDataModel, EmployeeModel, EmployeeProductivityIndexModel, EmployeeTableProductivityModel, HeaderTableModel } from '@station/employees';
import { HeaderModel } from '@station/header';
import { AuthService } from 'src/app/services/auth.service';
import { ListaPessoasService } from '../services/listaPessoas.service';
import { Observable, Subject } from 'rxjs';
import { SelectorItemList, FilterSelectModel } from '../models/selector-list.models';
import { LoginResponse } from '../../login/model/login-response';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../painel-supervisor/services/dashboard.service';
import { UserNotification } from '../../painel-colaborador/models/userNotification.model';
import { ColaboradorService } from '../../painel-colaborador/services/colaborador.service';
import { Console } from 'console';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  authUser: any
  semaphoreDisp: any;
  semaphoreConn: any;
  semaphoreLat: any;
  intervalId: any;
  intervalValue = 1;
  header: HeaderModel = new HeaderModel("300062", "Heverton pablo", "Colaborador", false, "https://picsum.photos/100", true, "Buscar Funcionários");
  produtividadeListTemp: EmployeeTableProductivityModel[] = []
  tableProdutividadeSubject = new Subject<EmployeeTableProductivityModel[]>();
  produtividadeList$: Observable<EmployeeTableProductivityModel[]> = this.tableProdutividadeSubject.asObservable();
  filteredEmployees: FilterSelectModel[] = []
  bigNumberAvailabilityTodayTeams: BigNumberModel[] = [];
  dateFiltered!: string;
  maxDate!: string;
  lastUpdate!: Date;
  dayLabel:string = "Hoje"
  beforeDayLabel:string = "Ontem"
  toggleRefreshEnabled: boolean = true;
  public filterTableActions: SelectorItemList[] = [
    { id: "0", title: "Todos Colaboradores" },
    { id: "1", title: "Logados Agora" },
    { id: "2", title: "Deslogados (Deveria estar logado)" },
    { id: "3", title: "Deslogados (Deveria estar deslogado)" },
    //{ id: "4", title: "Não logado nas Ultimas 24 hrs" },
    //{ id: "5", title: "Não logado nas Ultimas 16 hrs úteis" },
    { id: "6", title: "Problema de Conexao" }
  ]
  public selectedFilter: string = '0';

  pageNumber: number = 1
  pageSize: number = 10
  totalPages:number = 1;
  totalCount:number = 0;
  requestLoading:boolean = false
  employeeListComplete = false
  alternativeEmployeesButton = false;
  visualizationTypeEnabled:boolean = true;
  visualizationType:string = "gestao-direta";

  myTeam!: any[];


  config1: BigNumberConfig = new BigNumberConfig(true, false, true);
  config2: BigNumberConfig = new BigNumberConfig(false, true, true);
  config3: BigNumberConfig = new BigNumberConfig(false, true, false);
  userNotifications:UserNotification[] = []
  countUnreadUserNotification: number = 0;

  constructor(
    private authService: AuthService,
    private listaPessoasService: ListaPessoasService,
    private dashboardService: DashboardService,
    private toastrService: ToastrService,
    private colaboradorService: ColaboradorService,
    private cdr: ChangeDetectorRef
  ) { }


  //#endregionFIM BIG NUMBER



  headerTable = new HeaderTableModel()
  produtividadeList: EmployeeTableProductivityModel[] = []


  ngOnInit(): void {

    this.dateFiltered = new Date().toFormattedString()
    this.maxDate = new Date().toFormattedString()
    this.lastUpdate = new Date()
    this.getCountUnreadUserNotifications();
    this.getUserNotifications();

    //TODO: Modificar acesso aos dados do usuario para o mesmo padrao das telas do supervidor, Colaborador e gerencial
    this.authUser = this.authService.decodePayloadJWT()
    this.semaphoreDisp = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')
    this.semaphoreConn = this.authService.geSemaphoreByDescription('Conexão (Banda)')
    this.semaphoreLat = this.authService.geSemaphoreByDescription('Conexão (Latência)')

    this.headerTable.addFirtColumn("Disponibilidade", "(tempo logado)")
    this.headerTable.addSecondarColumn("Produtividade total", "(na jornada)")
    this.headerTable.addThirdColumn("Produtividade", "(no tempo disponível)")
    this.headerTable.addForthColumn("Conexão")

    this.updateBadSignalHighlights()
    this.updateTodayTeamHighLights()
    this.udpateAvailabilityTeamHighlights()
    this.udpateProductivityTeamHighlights()
    //this.updateTeamReport();
    this.initialLoadTeam()

  }
  getUserNotifications(pageNumber:number = 1)
  {
    let loggedUserId = this.authService.authUser.id;

     this.colaboradorService.getNotificationByUserId(loggedUserId, pageNumber).subscribe(response => {

      this.userNotifications.length == 0 ? this.userNotifications = response : this.userNotifications = this.userNotifications.concat(response)

     });

  }
  getCountUnreadUserNotifications()
  {
    let loggedUserId = this.authService.authUser.id;

     this.colaboradorService.getCountUnreadNotificationByUserId(loggedUserId).subscribe(response => {

      this.countUnreadUserNotification = response;

     });

  }
  loadMoreUserNotification(pageNumber:number)
  {
    this.getUserNotifications(pageNumber);
  }
  private getPageRedirect(employee: LoginResponse) {
    return employee.jobPosition.toUpperCase() == "COLABORADOR" ? `/painel-colaborador/${employee.id}` :  `/painel-supervisor/${employee.id}`
  }
  private getPageDetailRedirect(employee: LoginResponse) {
    return employee.jobPosition.toUpperCase() == "COLABORADOR" ? '' : `/painel-colaborador/${employee.id}`
  }
  setMyTeamReportSetInterval() {
    this.updateTeamReport(1, this.pageNumber * this.pageSize)
    this.intervalId = setInterval(() => {
      this.updateTeamReport(1, this.pageNumber * this.pageSize)
    }, this.intervalValue * 60000)
  }

  stopMyTeamReportSetInterval() {
    clearInterval(this.intervalId);
  }

  setTodayTeam(data: any): BigNumberModel[] {

    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')
    let danger = semaphore!.ranges.red
    let warning = semaphore!.ranges.yellow
    let success = semaphore!.ranges.green

    let todayDate = new Date();

    todayDate.setHours(0, 0, 0, 0);

    let currentDate = new Date(this.dateFiltered);

    currentDate.setHours(0, 0, 0, 0);

    if(currentDate < todayDate)
    {
      data.totalLogged = data.totalFullDay;
    }



    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);
    let config1: BigNumberConfig = new BigNumberConfig(true, false, true);

    let teamAvailability: BigNumberModel[] = []

    let totalTeam = new BigNumberModel(data.totalTeam, '', '', 'Equipe', BigNumberIcon.circle, bigNumberAlert, config1)
    teamAvailability.push(totalTeam)

    let totalLogged = new BigNumberModel(data.totalLogged, '', '', 'Logado', BigNumberIcon.circle, bigNumberAlert, config1)
    teamAvailability.push(totalLogged)

    let loggedPercent = (data.totalLogged / data.totalTeam) * 100

    let today = new BigNumberModel(loggedPercent, '', '%', '% Logado', BigNumberIcon.circle, bigNumberAlert, config1)
    teamAvailability.push(today)



    return teamAvailability

  }
  setMyTeamReport(data: any) {


    if (this.produtividadeList.length > 0) {

      this.produtividadeList = []
    }


    let alertSetting = new AlertSetting(this.semaphoreConn!.ranges!.red, this.semaphoreConn!.ranges!.yellow, this.semaphoreConn!.ranges!.green);

    let alertSettingLat = new AlertSetting(this.semaphoreLat!.ranges!.red, this.semaphoreLat!.ranges!.yellow, this.semaphoreLat!.ranges!.green, false);

    for (let item of data) {

      let employee = new EmployeeModel(`${item.employee.firstName} ${item.employee.lastName}`,
        item.employee.jobPosition, item.employee.isOnline,
        this.getPageRedirect(item.employee),
        item.employee.photoPath,
        false,
        item.employee.badSignal,
        '',
        item.employee.shouldBeCurrentlyWorking,
        item.employee.isOnline,
        false,
        this.getPageDetailRedirect(item.employee)
        )

      employee.title = this.getEmployeeTitle(employee)


      let employeeData = new EmployeeDataModel(item.connectionLogMyTeam.mbps, "mbps", alertSetting)
      let employeeDatac = new EmployeeDataModel(item.connectionLogMyTeam.latency, "ms", alertSettingLat)

      this.produtividadeList.push(new EmployeeTableProductivityModel(
        employee,
        employeeData,
        employeeDatac,
        this.addItemsList(item.productivity),
        this.addItemsList(item.totalProductivity),
        this.addItemsList(item.availability)
      ))

    }

    this.tableProdutividadeSubject.next(this.produtividadeList)
    this.cdr.detectChanges();
  }




  bigNumberAvailabilityTeam!: BigNumberModel[];
  bigNumberLoggedTeam!: BigNumberModel[];

  getLoggedTeam(data: any): BigNumberModel[] {

    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')
    let danger = semaphore!.ranges.red
    let warning = semaphore!.ranges.yellow
    let success = semaphore!.ranges.green

    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);
    let config1: BigNumberConfig = new BigNumberConfig(true, false, true);

    let teamLogged: BigNumberModel[] = []

    let totalTeam = new BigNumberModel(data.totalTeam, '', '', 'Equipe', BigNumberIcon.circle, bigNumberAlert, config1)
    teamLogged.push(totalTeam)

    let totalLogged = new BigNumberModel(data.totalLogged, '', '', 'Logado', BigNumberIcon.circle, bigNumberAlert, config1)
    teamLogged.push(totalLogged)

    let loggedPercent = (data.totalLogged / data.totalTeam) * 100

    let today = new BigNumberModel(loggedPercent, '', '%', '% Logado', BigNumberIcon.circle, bigNumberAlert, config1)
    teamLogged.push(today)

    return teamLogged
  }
  getAvailabilityTeam(data: any): BigNumberModel[] {

    let config1: BigNumberConfig = new BigNumberConfig(true, false, true);
    let config2: BigNumberConfig = new BigNumberConfig(false, true, true);
    //let config3: BigNumberConfig = new BigNumberConfig(false, false, true);

    let bigNumberAvailabilityTeam: BigNumberModel[] = []


    //debugger
    let danger = this.semaphoreDisp!.ranges!.red
    let warning = this.semaphoreDisp!.ranges!.yellow
    let success = this.semaphoreDisp!.ranges.green

    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);

    let availabilityTeam: BigNumberModel = new BigNumberModel(data.today, 'Disponibilidade', '', '(tempo logado)', BigNumberIcon.circle, bigNumberAlert, config2)
    bigNumberAvailabilityTeam.push(availabilityTeam)

    let todayTeam = new BigNumberModel(data.today, '', '%', this.dayLabel, BigNumberIcon.circle, bigNumberAlert, config1)
    bigNumberAvailabilityTeam.push(todayTeam)

    let yesterdayTeam = new BigNumberModel(data.yesterday, '', '%', this.beforeDayLabel, BigNumberIcon.circle, bigNumberAlert, config1)
    bigNumberAvailabilityTeam.push(yesterdayTeam)

    let weekTeam = new BigNumberModel(data.week, '', '%', 'Sem', BigNumberIcon.circle, bigNumberAlert, config1)
    bigNumberAvailabilityTeam.push(weekTeam)

    let monthTeam = new BigNumberModel(data.month, '', '%', 'Mês', BigNumberIcon.circle, bigNumberAlert, config1)
    bigNumberAvailabilityTeam.push(monthTeam)

    return bigNumberAvailabilityTeam
  }
  private getEmployeeTitle(employee: EmployeeModel): string {
    let title = "";

    title += employee.enabled ? "Está ONLINE agora" : "Está OFFLINE agora";

    if (employee.badSignal) {

      title += "\nApresenta problemas de CONEXÃO COM A INTERNET";
    }

    if (employee.shouldBeCurrentlyWorking) {

      title += "\nDEVERIA estar ONLINE agora";
    }

    if (!employee.shouldBeCurrentlyWorking) {

      title += "\nNÃO deveria estar ONLINE agora";
    }

    return title;

  }
  bigNumberProductivityTeam!: BigNumberModel[];

  getProductivityTeam(data: any): BigNumberModel[] {

    let bigNumberProductivityTeam: BigNumberModel[] = []

    let config2: BigNumberConfig = new BigNumberConfig(false, true, true);



    let danger = this.semaphoreDisp!.ranges!.red
    let warning = this.semaphoreDisp!.ranges!.yellow
    let success = this.semaphoreDisp!.ranges.green

    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);


    let availabilityTeam: BigNumberModel = new BigNumberModel(data.today, 'Produtividade', '%', '(tempo logado)', BigNumberIcon.circle, bigNumberAlert, config2)
    bigNumberProductivityTeam.push(availabilityTeam)

    let todayTeam = new BigNumberModel(data.today, '', '%', this.dayLabel, BigNumberIcon.circle, bigNumberAlert, this.config1)
    let yesterdayTeam = new BigNumberModel(data.yesterday, '', '%', this.beforeDayLabel, BigNumberIcon.circle, bigNumberAlert, this.config1)
    let weekTeam = new BigNumberModel(data.week, '', '%', 'Sem', BigNumberIcon.circle, bigNumberAlert, this.config1)
    let monthTeam = new BigNumberModel(data.month, '', '%', 'Mês', BigNumberIcon.circle, bigNumberAlert, this.config1)

    bigNumberProductivityTeam.push(todayTeam)
    bigNumberProductivityTeam.push(yesterdayTeam)
    bigNumberProductivityTeam.push(weekTeam)
    bigNumberProductivityTeam.push(monthTeam)

    return bigNumberProductivityTeam
  }

  bigNumberConnectionTeam!: BigNumberModel[]

  getConnectionTeam(data: any): BigNumberModel[] {

    let bigNumberConnectionTeam: BigNumberModel[] = []

    let danger = 999999
    let warning = 99999999
    let success = 9999999999

    let networkAlert: AlertSetting = new AlertSetting(danger, warning, success);

    let bigNumberNetwork = new BigNumberModel(data, 'Conexão', '', 'Críticos', BigNumberIcon.signal, networkAlert, this.config3);
    bigNumberConnectionTeam.push(bigNumberNetwork)

    return bigNumberConnectionTeam
  }

  availabilityVerify(quantity: number, logged: number) {
    return (logged / quantity) * 100
  }

  makeRandom(lengthOfCode: number) {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]\=-)(*&^%$#@!~`";
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.round(Math.random() * possible.length));
    }
    return text;
  }



  private addItemsList(fator: any): EmployeeProductivityIndexModel[] | any {

    let list: EmployeeProductivityIndexModel[] = [];

    let danger = this.semaphoreDisp!.ranges!.red
    let warning = this.semaphoreDisp!.ranges!.yellow
    let success = this.semaphoreDisp!.ranges.green

    let alertSetting = new AlertSetting(danger, warning, success);


    let currentDailyValue = new EmployeeDataModel(fator.today, "%", alertSetting);
    let currentWeeklyValue = new EmployeeDataModel(fator.week, "%", alertSetting);
    let currentMonthlyValue = new EmployeeDataModel(fator.month, "%", alertSetting);

    // //hoje - onti

    // let dayDiference = fator.today - fator.dayPercent
    // let weekDiference = fator.week - fator.weekPercent
    // let monthDiference = fator.month - fator.monthPercent

    let employeeProductivityIndexModel = new EmployeeProductivityIndexModel(fator.dayPercent, "p.p", currentDailyValue)
    let employeeProductivityIndexModel1 = new EmployeeProductivityIndexModel(fator.weekPercent, "p.p", currentWeeklyValue)
    let employeeProductivityIndexModel2 = new EmployeeProductivityIndexModel(fator.monthPercent, "p.p", currentMonthlyValue)

    list.push(employeeProductivityIndexModel)
    list.push(employeeProductivityIndexModel1)
    list.push(employeeProductivityIndexModel2)






    return list;


  }
  public toggleRefresh(e: any) {
    if (e.target.checked) {
      this.setMyTeamReportSetInterval()
    }
    else {
      this.stopMyTeamReportSetInterval()

    }
  }
  public selectFilterEmployees(select: HTMLSelectElement) {

    let target = this.filterTableActions[select.selectedIndex];
    this.selectedFilter  = target.id;
    this.filterApply(this.selectedFilter);
    this.cdr.detectChanges();
  }



  filterApply(filterId: string){
    switch (filterId) {
      case "0":
        this.tableProdutividadeSubject.next(this.produtividadeList)
        break;

      case "1":
        let logged = this.produtividadeList.filter(p => p.employee.enabled == true)
        this.tableProdutividadeSubject.next(logged)
        break;

      case "2":
        let loggedOutShouldLogged = this.produtividadeList.filter(p => p.employee.enabled == false && p.employee.shouldBeCurrentlyWorking == true)
        this.tableProdutividadeSubject.next(loggedOutShouldLogged)
        break;

      case "3":
        let loggedOutShouldLogout = this.produtividadeList.filter(p => p.employee.enabled == false && p.employee.shouldBeCurrentlyWorking == false)
        this.tableProdutividadeSubject.next(loggedOutShouldLogout)
        break;

      case "4":
        let naoLogado24hs = this.filteredEmployees.filter(p => p.notLogged24hs).map(p => p.rowTable)
        this.tableProdutividadeSubject.next(naoLogado24hs)
        break;

      case "5":
        let notLogged16hsWorkingHours = this.filteredEmployees.filter(p => p.notLogged16hsWorkingHours).map(p => p.rowTable)
        this.tableProdutividadeSubject.next(notLogged16hsWorkingHours)
        break;

      case "6":
        let notConnection = this.produtividadeList.filter(p => p.employee.badSignal == true)
        this.tableProdutividadeSubject.next(notConnection)
        break;
    }
  }



  updateBadSignalHighlights(){

    switch (this.visualizationType) {
      case "gestao-direta":
        this.listaPessoasService.getTotalBadSignalByChiefId(this.authUser.id, this.dateFiltered).subscribe(data => this.bigNumberConnectionTeam = this.getConnectionTeam(data))
        break;
        case "todo-time":
          this.listaPessoasService.getTotalBadSignalByChiefIdManager(this.authUser.id, this.dateFiltered).subscribe(data => this.bigNumberConnectionTeam = this.getConnectionTeam(data))
          break;
      default:
        break;
    }
  }
  updateTodayTeamHighLights()
  {
    switch (this.visualizationType) {
      case "gestao-direta":
        this.dashboardService.getTeamLogged(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.bigNumberAvailabilityTodayTeams = this.setTodayTeam(data)
          })
        break;
        case "todo-time":
          this.dashboardService.getTeamLoggedManager(this.authUser.id, this.dateFiltered).subscribe(
            data => {
              this.bigNumberAvailabilityTodayTeams = this.setTodayTeam(data)
            })
          break;
      default:
        break;
    }
  }
  udpateAvailabilityTeamHighlights() {

    if (this.authUser.jobPosition != "Supervisor") {
      this.listaPessoasService.setAvailabilityTeam(this.authUser.id, this.dateFiltered).subscribe(data => {
        this.bigNumberAvailabilityTeam = this.getAvailabilityTeam(data)
      })
    }
    else {
      this.dashboardService.getTeamAvailability(this.authUser.id, this.dateFiltered).subscribe(data => {
        this.bigNumberAvailabilityTeam = this.getAvailabilityTeam(data)
      })
    }
  }
  udpateProductivityTeamHighlights() {

    if (this.authUser.jobPosition != "Supervisor") {
      this.listaPessoasService.setProductivityTeam(this.authUser.id, this.dateFiltered).subscribe(data => this.bigNumberProductivityTeam = this.getProductivityTeam(data))
    }
    else {
      this.dashboardService.getProductivityTeam(this.authUser.id, this.dateFiltered).subscribe(data => this.bigNumberProductivityTeam = this.getProductivityTeam(data))
    }
  }
  updateTeamReport(pageNumber: number, pageSize: number)
  {
    this.listaPessoasService.getMyTeamReport(this.authUser.id, this.dateFiltered, pageNumber, pageSize).subscribe(data => {
      this.setMyTeamReport(data.team)
      this.filterApply(this.selectedFilter);
      this.cdr.detectChanges();
    });
  }

  loadMoreEmployees() {
    const nextPageNumber = this.pageNumber + 1;

    this.requestLoading = true;
    this.listaPessoasService.getMyTeamReport(this.authUser.id, this.dateFiltered, nextPageNumber, this.pageSize)
      .subscribe({
        next: (result) => {
          this.requestLoading = false;

          if (!result.team) {
            this.employeeListComplete = true;
            return;
          }
          debugger
          const newTeam = result.team;

          // Verifica e remove duplicidades por ID
          const uniqueTeam = this.getUniqueEmployees([...this.myTeam, ...newTeam]);

          // Atualiza a lista de funcionários com os novos funcionários únicos
          this.myTeam = uniqueTeam;

          this.setMyTeamReport(this.myTeam);

          this.pageNumber = nextPageNumber;
        },
        error: (error) => {
          // Lidar com o erro, se necessário...
        }
      });
  }

  onHold()
  {
    this.alternativeEmployeesButton = true;
    this.loadAllTeam();
  }

  loadAllTeam() {

    this.listaPessoasService.getMyTeamReport(this.authUser.id, this.dateFiltered, 1, 999999).subscribe({
      next: (result) => {
        const employees = result.team; // Obter os dados dos funcionários
        this.totalPages = 1; // Obter o total de páginas
        this.pageNumber = 1;
        this.totalCount = result.totalCount; // Obter a contagem total

        this.setMyTeamReport(result.team);

      },
      error: (error) => {
        // Lidar com o erro, se necessário...
      }
    });
  }

  initialLoadTeam() {


    this.listaPessoasService.getMyTeamReport(this.authUser.id, this.dateFiltered, this.pageNumber, this.pageSize).subscribe({
      next: (result) => {
        const employees = result.team; // Obter os dados dos funcionários
        this.totalPages = result.totalPages; // Obter o total de páginas
        this.totalCount = result.totalCount; // Obter a contagem total
        this.myTeam = result.team;

        this.setMyTeamReport(result.team);


        // Usar totalPages e totalCount conforme necessário...
      },
      error: (error) => {
        // Lidar com o erro, se necessário...
      }
    });
  }

  getUniqueEmployees(employees: any[]): any[] {
    const uniqueEmployees: any[] = [];
    employees.forEach((employee) => {
        uniqueEmployees.push(employee);
    });

    return uniqueEmployees;
  }


  validateDateFiltered() {
    let today = new Date();
    let selectedDate = new Date(this.dateFiltered).addDays(1)

    this.toggleRefreshEnabled = false
    if (selectedDate > today.toFormattedString()) {
      this.toastrService.info("Não é possível informar uma data futura", "Atenção")
      this.dateFiltered = today.toFormattedString()
    }
    if (selectedDate == today.toFormattedString()) {

      this.toggleRefreshEnabled = true
    }
  }
  validateLastUpdate(): boolean {
    let today = new Date()

    let diff = today.diffInSeconds(this.lastUpdate);


    if (diff < 3) {
      this.toastrService.info("Aguarde pelo menos 3 segundos para informar uma nova data de referência", "Atenção")
      this.dateFiltered = today.toFormattedString()
      return false
    }

    this.lastUpdate = today
    return true
  }
  updateLabels()
  {
    this.dayLabel = this.dateFiltered == new Date().toFormattedString() ? "Hoje" : "Dia"
    this.beforeDayLabel = this.dateFiltered == new Date().toFormattedString() ? "Ontem" : "Dia anterior"
  }
  updatePanels() {

    if (!this.validateLastUpdate()) {
      return
    }
    var totalCurrentPageSize = this.pageNumber * this.pageSize
    this.validateDateFiltered()
    this.updateBadSignalHighlights()
    this.updateTodayTeamHighLights()
    this.udpateAvailabilityTeamHighlights()
    this.udpateProductivityTeamHighlights()
    this.updateTeamReport(1, totalCurrentPageSize)
    this.updateLabels()
  }
}
