import { RequestSemaphoreRanges } from "./request-semahore-ranges.model";

export class RequestSemaphore {

    id?: string;
    description: string; 
    enable: boolean;    
    ranges: RequestSemaphoreRanges;


    constructor(description: string, enable: boolean, ranges: RequestSemaphoreRanges, id?: string){

        this.id = id;
        this.description = description;
        this.enable = enable;
        this.ranges = ranges;
    }

    
}