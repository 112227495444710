import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChartColumnGroupModel, ChartItemModel, ChartLineModel } from '@station/charts';
import { Observable, Subject } from 'rxjs';
import { ConnectionComparisionModel } from 'src/app/shared/model/connection-comparesion';
import { environment } from 'src/environments/environment';
import { ChartDataModel } from '../models/charUnproductiveModel';
import { ScreenshotResponseModel } from '../models/screenshot.model';
import { UserNotification } from '../models/userNotification.model';

@Injectable({
  providedIn: 'root'
})
export class ColaboradorService {

  constructor(private http: HttpClient) { }

  private chartConnection$ = new Subject<ChartLineModel>()
  currentchartConnection$ = this.chartConnection$.asObservable()

  updateChartConnection(data: any) {

    this.chartConnection$.next(data)

  }

  private agentAnalysisTimeLogged = new Subject<ChartItemModel>()
  agentAnalysisTimeLogged$ = this.agentAnalysisTimeLogged.asObservable()

  updateAgentAnalysisTimeLogged(data: any){

    this.agentAnalysisTimeLogged.next(data)

  }

  private chartComparativoHistoricoJornada = new Subject<ChartColumnGroupModel>()
  chartComparativoHistoricoJornada$ = this.chartComparativoHistoricoJornada.asObservable()

  updateChartComparativoHistoricoJornada(data: any){

    this.chartComparativoHistoricoJornada.next(data)

  }
  getScreenshots(agentId: String): Observable<ScreenshotResponseModel[]> {

    let url = `${environment.agentApi}${environment.endpoints.getScrennshotByUserId}/${agentId}`

    return this.http.get<ScreenshotResponseModel[]>(url);
  }
  getNotificationByUserId(agentId: string | null, pageNumber:number = 1): Observable<UserNotification[]> {

    let url = `${environment.notificationApi}${environment.endpoints.getMessagesByUserId}/${agentId}?pageNumber=${pageNumber}&pageSize=10`

    return this.http.get<UserNotification[]>(url);
  }
  getCountUnreadNotificationByUserId(agentId: string | null): Observable<number> {

    let url = `${environment.notificationApi}${environment.endpoints.getCountUnreadMessagesByUserId}/${agentId}`

    return this.http.get<number>(url);
  }


  getConectionAgent(agentId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getConnectionAgent}/${agentId}?referenceDate=${dateFiltered}`
    return this.http.get(url);
  }


  getAvailability(userId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getAvailability}/${userId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);

  }


  getConnectionComparision(userId: String, dateFiltered:string): Observable<ConnectionComparisionModel> {

    let url = `${environment.dashboardApi}${environment.endpoints.getConnectionComparision}/${userId}?referenceDate=${dateFiltered}`
    return this.http.get<ConnectionComparisionModel>(url);
  }


  getChartConnectionDaily(userId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.chartConnectionDaily}/${userId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);

  }

  getChartConnectionWeekly(userId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.chartConnectionWeekly}/${userId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);

  }


  getChartConnectionMonthly(userId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.chartConnectionMonthly}/${userId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);

  }


  public getCardUnproductive(userId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getCardsUnproductive}/${userId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);
  }


  getAgentAvailabilityToday(userId: string, dateFiltered:string): Observable<any> {
    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getAgentAvailabilityToday}/${userId}?referenceDate=${dateFiltered}`)

  }




  getAgentAnalysisTimeLoggedDaily(userId: String, dateFiltered:string): Observable<any> {

    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getAgentAnalysisTimeLoggedDaily}/${userId}?referenceDate=${dateFiltered}`)


  }

  getAgentAnalysisTimeLoggedWeekly(userId: String, dateFiltered:string): Observable<any> {

    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getAgentAnalysisTimeLoggedWeekly}/${userId}?referenceDate=${dateFiltered}`)

  }

  getAgentAnalysisTimeLoggedMonthly(userId: String, dateFiltered:string): Observable<any> {

    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getAgentAnalysisTimeLoggedMonthly}/${userId}?referenceDate=${dateFiltered}`)

  }


  getAgentById(userId: String): Observable<any> {

    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getAgentByUserId}/${userId}?checkSleep=true`)

  }
  getChartUnproductiveDaily(userId: String, dateFiltered:string): Observable<ChartDataModel> {
    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getChartUnproductiveDaily}/${userId}?referenceDate=${dateFiltered}`)
  }
  getChartUnproductiveWeekly(userId: String, dateFiltered:string): Observable<ChartDataModel> {
    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getChartUnproductiveWeekly}/${userId}?referenceDate=${dateFiltered}`)
  }
  getChartUnproductiveMonthly(userId: String, dateFiltered:string): Observable<ChartDataModel> {
    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getChartUnproductiveMonthly}/${userId}?referenceDate=${dateFiltered}`)
  }

  getComparativeJourneyDaily(userId: string, dateFiltered:string): Observable<ChartDataModel> {


    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getComparativeJourneyDaily}/${userId}?referenceDate=${dateFiltered}`)
  }

  getComparativeJourneyWeekly(userId: string, dateFiltered:string): Observable<ChartDataModel> {


    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getComparativeJourneyWeekly}/${userId}?referenceDate=${dateFiltered}`)
  }

  getComparativeJourneyMonthly(userId: string, dateFiltered:string): Observable<ChartDataModel> {


    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getComparativeJourneyMonthly}/${userId}?referenceDate=${dateFiltered}`)
  }

  GetProductivityHighLightsByAgentId(userId: string, dateFiltered:string): Observable<any> {


    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getProductivityHighLightsByAgentId}/${userId}?referenceDate=${dateFiltered}`)
  }

}
