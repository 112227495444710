import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from '@station/charts';
import { EmployeesModule } from '@station/employees';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';
import { DashHeaderModule } from 'src/app/shared/modules/dash-header/dash-header.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PainelSupervisorRoutingModule } from './painel-supervisor-routing.module';
import { ViewComponent } from './view/view.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {MatTabsModule} from '@angular/material/tabs';
import { TableTeamReportModule } from 'src/app/shared/modules/table-team-report/table-team-report.module';
import { SpinnersModule } from '@station/spinners';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmployeeInfoModule } from 'src/app/shared/modules/employee-info/EmployeeInfo/employee-Info.module';
import { ModalOptionsModule } from 'src/app/shared/modules/modal-options/modal-options.module';

@NgModule({
  declarations: [ViewComponent],
  imports: [
    CommonModule,
    FormsModule,
    PainelSupervisorRoutingModule,
    HeaderModule,
    EmployeesModule,
    DashHeaderModule,
    ChartsModule,
    MenuModule,
    MatProgressSpinnerModule,
    TableTeamReportModule,
    EmployeeInfoModule,
    MatIconModule,
    NgbTooltipModule,
    MatProgressBarModule,
    MatTabsModule,
    SpinnersModule,
    MatExpansionModule,
    ModalOptionsModule,
  ],
})
export class PainelSupervisorModule {}
