import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SecurityContext,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { UserNotification } from 'src/app/pages/painel-colaborador/models/userNotification.model';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { UserNotificationService } from './services/user-notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'station-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponent implements OnInit {
  @Input() userNotifications: UserNotification[] = [];
  @Input() countUnread: number = 0;
  @Output() loadMore: EventEmitter<number> = new EventEmitter();
  @ViewChild('modalTitle') modalTitle!: TemplateRef<any>;
  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  @ViewChild('modalFooter') modalFooter!: TemplateRef<any>;
  selectedUserNotification!: UserNotification;
  notificationMenuShow: boolean = false;
  pageNumber: number = 1;
  modal!:NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private userNotificationService: UserNotificationService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {}


  sanitizeHtml(): void {

    this.userNotifications.forEach(x=>{

      let hasExternalLink = this.hasExternalLink(x.message)

      if(hasExternalLink){
        x.message = "Esta notificação contém conteúdo potencialmente inseguro."
      }
      else
      {
        x.message = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(x.message)) || '';
      }
    });

  }
  unsafeNotify(): void {

    let unsafeMessageCount = this.userNotifications.filter((notification) => {
      const hasUnsafeContent = this.hasExternalLink(notification.message);
      return hasUnsafeContent;
    }).length;

    if (unsafeMessageCount == 1) {

      this.toastr.warning("Existe uma notificação que contém um conteúdo potencialmente inseguro.", "Aviso de Segurança");
    }
    else if (unsafeMessageCount > 1)
    {
      this.toastr.warning(`Existem ${unsafeMessageCount} notificações que contém um conteúdo potencialmente inseguro.`, "Aviso de Segurança");
    }
  }


  hasExternalLink(htmlString: string): boolean {
    const regex = /<a\s+(?:[^>]*?\s+)?href=("|')([^"']*?)\1/gi;
    let match;

    while ((match = regex.exec(htmlString)) !== null) {
        const href = match[2];
        if (!/^\/($|[^\/])|station\.center($|\/)/.test(href)) {
            return true;
        }
    }
    return false;
}



  ngOnInit(): void {
    this.unsafeNotify();
    this.sanitizeHtml();
  }

  showNotificationMenu() {
    this.notificationMenuShow = !this.notificationMenuShow;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.notification')) {
      this.notificationMenuShow = false;
    }
  }

  calculateTimeAgo(dateString: string) {
    const now = new Date();
    const date = new Date(dateString);

    // Subtrai 3 horas da data 'date' (se necessário)
    now.setHours(now.getHours() - 3);

    const diffMilliseconds = now.getTime() - date.getTime();
    const diffSeconds = Math.floor(diffMilliseconds / 1000);

    if (diffSeconds < 60) {
      return 'agora';
    } else if (diffSeconds < 3600) {
      const minutes = Math.floor(diffSeconds / 60);
      return `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'} atrás`;
    } else if (diffSeconds < 86400) {
      const hours = Math.floor(diffSeconds / 3600);
      return `${hours} ${hours === 1 ? 'hora' : 'horas'} atrás`;
    } else if (diffSeconds < 604800) {
      const days = Math.floor(diffSeconds / 86400);
      return `${days} ${days === 1 ? 'dia' : 'dias'} atrás`;
    } else if (diffSeconds < 2419200) {
      const weeks = Math.floor(diffSeconds / 604800);
      return `${weeks} ${weeks === 1 ? 'semana' : 'semanas'} atrás`;
    } else if (diffSeconds < 29030400) {
      const months = Math.floor(diffSeconds / 2419200);
      return `${months} ${months === 1 ? 'mês' : 'meses'} atrás`;
    } else {
      const years = Math.floor(diffSeconds / 29030400);
      return `${years} ${years === 1 ? 'ano' : 'anos'} atrás`;
    }
  }


  openModal() {
    const options: NgbModalOptions = {
      size: 'lg' // Ou 'xl' para uma largura ainda maior
    };

    const modalRef = this.modalService.open(ModalContentComponent, options);
    this.modal = modalRef;

    modalRef.componentInstance.modalTitle = this.modalTitle;
    modalRef.componentInstance.modalContent = this.modalContent;
    modalRef.componentInstance.modalFooter = this.modalFooter;
  }

  showMessage(selectedItem: UserNotification) {

    if (selectedItem.readedAt == null) {

      const index = this.userNotifications.findIndex((notification) => notification.id === selectedItem.id);

      if (index !== -1) {
        this.userNotifications[index].readedAt = new Date().toISOString();
      }

      this.userNotificationService.markReadUserNotification(selectedItem.id).subscribe();
    }

    this.selectedUserNotification = selectedItem;
    this.notificationMenuShow = false;
    this.countUnread--;
    this.openModal();
  }
  markUnreadMessage(userNotificationAId:string) {


    const index = this.userNotifications.findIndex((notification) => notification.id === userNotificationAId);

    if (index !== -1) {
      this.userNotifications[index].readedAt = null;
      this.countUnread++;
    }

    this.userNotificationService.markUnreadUserNotification(userNotificationAId).subscribe(x=> this.modal.close());

  }
  deleteUserNotification(userNotificationAId:string)
  {
    const index = this.userNotifications.findIndex((notification) => notification.id === userNotificationAId);

    if (index !== -1) {
      this.userNotifications.splice(index, 1); // Remove o item do array
      this.countUnread--;
    }

    this.userNotificationService.deleteUserNotification(userNotificationAId).subscribe(x=> this.modal.close());

  }
  @HostListener('scroll', ['$event'])
  onScroll() {
    const element = this.elementRef.nativeElement.querySelector('.notification-menu');
    const scrollHeight = element.scrollHeight;
    const scrollTop = element.scrollTop;
    const clientHeight = element.clientHeight;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

    if (scrollPercentage >= 50) {
      this.pageNumber++;
      this.loadMore.emit(this.pageNumber);
    }
  }

}
