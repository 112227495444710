import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeesModule } from '@station/employees';
import { HeaderModule } from '@station/header';
import { DashHeaderModule } from 'src/app/shared/modules/dash-header/dash-header.module';

import { ListaDePessoasRoutingModule } from './lista-de-pessoas-routing.module';
import { ViewComponent } from './view/view.component';


@NgModule({
  declarations: [
    ViewComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ListaDePessoasRoutingModule,
    HeaderModule,
    DashHeaderModule,
    EmployeesModule,
    ReactiveFormsModule

  ]
})
export class ListaDePessoasModule { }
