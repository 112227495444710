import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeModel } from '@station/employees';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../../login/model/login-response';

import { ProfileEmployeeModel, SelectItemApplicationGroup, SelectItemHierarchy, SelectItemJourney } from '../models/profile-employee.model';
import { ProfileEmployeeMinimalModel } from '../models/profile-employee-minimal.model';
import { ProfileEmployeePatchModel } from '../models/profile-employee-patch.model';
import { ApplicationGroupMinimalModel } from '../models/application-group-minimal.model';
import { JourneyMinimalModel } from '../models/journey-minimal.model';
import { HierarchyMinimalModel } from '../models/hierarchy-minimal.model';
import { UpdateEmployeesRegistrationIds } from '../models/update-employees-registrationIds.model';
import { ProfileEmployeeLastCheckin } from '../models/profile-employee-last-checkin.model';
import { ProfileEmployeeLastLogin } from '../models/profile-employee-last-login.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileEmployeeService {


  constructor(private http: HttpClient) { }

  public removerEmployee(id: string): Observable<any> {
    return this.http.delete(`${environment.peopleApi}/api/v1/employees/${id}`)
  }

  public getEmployeeApplicationGroup(): Observable<SelectItemApplicationGroup[]> {
    return this.http.get<SelectItemApplicationGroup[]>(`${environment.applicationsApi}/api/v1/applicationGroups`)
      .pipe(map(resp => resp.map(i => this.factorySelectItemApplicationGroup(i))))
  }

  public getEmployeeJourney(): Observable<SelectItemJourney[]> {
    return this.http.get<SelectItemJourney[]>(`${environment.peopleApi}/api/v1/journey`)
      .pipe(map(resp => resp.map(i => this.factorySelectItemJourney(i))))
  }

  public getEmployee(id: string): Observable<ProfileEmployeeModel> {
    return this.http.get<ProfileEmployeeModel>(`${environment.peopleApi}/api/v1/employees/${id}`)
      .pipe(map(resp => this.factoryEmployee(resp)))
  }

  public getEmployees(): Observable<ProfileEmployeeModel[]> {
    return this.http.get<ProfileEmployeeModel[]>(`${environment.peopleApi}/api/v1/employees`)
      .pipe(map(resp => resp.map(i => this.factoryEmployee(i))))
  }

  public getEmployeesFullTime(id: string): Observable<ProfileEmployeeModel[]> {
    return this.http.get<ProfileEmployeeModel[]>(`${environment.peopleApi}/api/v1/employees/fulltime/${id}`)
      .pipe(map(resp => resp.map(i => this.factoryEmployee(i))))
  }

  public getEmployeesMinimal(): Observable<ProfileEmployeeMinimalModel[]> {
    return this.http.get<ProfileEmployeeMinimalModel[]>(`${environment.peopleApi}/api/v1/employees/GetAllMinimal`)
  }
  public getApplicationGroupMinimal(): Observable<ApplicationGroupMinimalModel[]> {
    return this.http.get<ApplicationGroupMinimalModel[]>(`${environment.applicationsApi}/api/v1/applicationGroups/GetAllMinimal`)
  }
  public getJourneyMinimal(): Observable<JourneyMinimalModel[]> {
    return this.http.get<JourneyMinimalModel[]>(`${environment.peopleApi}/api/v1/journey/GetAllMinimal`)
  }
  public getHierarchyMinimal(): Observable<HierarchyMinimalModel[]> {
    return this.http.get<HierarchyMinimalModel[]>(`${environment.peopleApi}/api/v1/hierarchy/GetAllMinimal`)
  }

  public postNewEmployee(newProfile: ProfileEmployeeModel): Observable<any> {
    return this.http.post(`${environment.peopleApi}/api/v1/employees`, newProfile)
  }

  public putEmployee(profile: ProfileEmployeeModel) {
    return this.http.put(`${environment.peopleApi}/api/v1/employees/${profile.id}`, profile)
  }
  public patchEmployeeByIds(profileEmployeePatchModel: ProfileEmployeePatchModel) {
    return this.http.patch(`${environment.peopleApi}${environment.endpoints.patchEmployeeByIds}`, profileEmployeePatchModel)
  }
  public patchEmployeeByOtherField(profileEmployeePatchModel: ProfileEmployeePatchModel) {
    return this.http.patch(`${environment.peopleApi}${environment.endpoints.patchEmployeeByOhterField}`, profileEmployeePatchModel)
  }
  public getHierarchy(): Observable<SelectItemHierarchy[]> {
    return this.http.get<SelectItemHierarchy[]>(`${environment.peopleApi}/api/v1/hierarchy`)
  }

  public postUpdateEmployeesRegistrationIds(updateEmployeesRegistrationIds: UpdateEmployeesRegistrationIds[]): Observable<any> {
    return this.http.post(`${environment.peopleApi}${environment.endpoints.postUpdateEmployeesRegistrationIds}`, updateEmployeesRegistrationIds)
  }

  public getEmployeeChiefs(order: number): Observable<ProfileEmployeeModel[]> {
    return this.http.get<ProfileEmployeeModel[]>(`${environment.peopleApi}/api/v1/employees/chiefs/${order}`)
  }

  public getEmployeesWithNonExistentCompanyId(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithNonExistentCompanyId}`)
  }

  public GetEmployeesWithNonExistentJobPosition(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithNonExistentJobPosition}`)
  }

  public getEmployeesWithNonExistentShiftPlainId(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithNonExistentShiftPlainId}`)
  }

  public GetEmployeesWithNonExistentApplicationGroupId(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithNonExistentApplicationGroupId}`)
  }

  public GetEmployeesWithNonExistentChiefId(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithNonExistentChiefId}`)
  }

  public GetEmployeesWithSameFullname(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithSameFullname}`)
  }

  public GetEmployeesWithSameNetworkUser(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithSameNetworkUser}`)
  }

  public GetEmployeesWithSameRegistrationId(): Observable<UpdateEmployeesRegistrationIds[]> {
    return this.http.get<UpdateEmployeesRegistrationIds[]>(`${environment.peopleApi}${environment.endpoints.getEmployeesWithSameRegistrationId}`)
  }
  public GetLastStationCheckinByUserId(userId:string): Observable<ProfileEmployeeLastCheckin> {
    return this.http.get<ProfileEmployeeLastCheckin>(`${environment.authApi}${environment.endpoints.getLastCheckinByUserId}/${userId}`)
  }
  public GetLastAgenntLoginByUserId(userId:string): Observable<ProfileEmployeeLastLogin> {
    return this.http.get<ProfileEmployeeLastLogin>(`${environment.agentApi}${environment.endpoints.getLastLoginByUserId}/${userId}`)
  }

  public getEmployeeTitle(employee: LoginResponse): string
  {
    let title = "";

    title += employee.enabled ? "Está ONLINE agora" : "Está OFFLINE agora";

    if (employee.badSignal) {

      title += "\nApresenta problemas de CONEXÃO COM A INTERNET";
    }

    return title;

  }


  private factorySelectItemApplicationGroup(application: any): SelectItemApplicationGroup {
    const item: SelectItemApplicationGroup = { id: application.id, groupName: application.groupName }
    return item;
  }

  private factorySelectItemJourney(Journey: any): SelectItemJourney {
    const item: SelectItemJourney = { id: Journey.id, description: Journey.description }
    return item;
  }


  private factoryEmployee(objeto: ProfileEmployeeModel): ProfileEmployeeModel {

    objeto.employee = new EmployeeModel(`${objeto.firstName} ${objeto.lastName}`, objeto.jobPosition, objeto.enabled, "", objeto.photoPath, false, false)
    return objeto;
  }

}
