<div class="dash-herader">
    <ng-container *ngIf="isProfile">
        <div class="dash-left" *ngIf="employee$ | async as employee">
            <div *ngIf="employee.sleep">
              <picture class="sleep">
                <source srcset="/assets/img/sleep.webp" type="image/webp"><img class="image media" id="image-zestycluelesseyas"
                  alt="tired sweet dreams sleepy time nighttime night time goodnight good night exhausted dreams counting sheep count sheep zzz sleepy sleeping sleep GIF"
                  src="https://thumbs.gfycat.com/ZestyCluelessEyas-size_restricted.gif" height="800" width="800"
                  style="/* max-width: 50px; */height: 50px;width: 50px;"></picture>
            </div>
              <img class="rounded-circle" src="{{ employee.picture }}" alt="">
              <div class="online-circle" *ngIf="employee.isOnline"></div>
              <div class="colaborador">
                  <span class="name"> {{ employee.name }} </span>
              </div>
              <span class="job-position">| {{ employee.role }} </span>
          </div>

    </ng-container>
    
    <div class="dash-right">
        <div *ngIf="enabledBadConnection" class="dash-label-content">
            <i class="material-icons">signal_wifi_off</i>
            <span><span class="strong">{{ employeesBadConnection }} </span>Pessoa(s) com problemas de conexão.</span>

        </div>
        <div class="dash-label-content">
            <div class="legenda">
                <div class="rounded-circle legenda-color bg-red"></div>
                <span>&#60;{{ danger }}% &#62;= {{ danger }}% </span>
            </div>

            <div class="legenda">
                <div class="rounded-circle legenda-color bg-yellow"></div>
                <span>&#60;= {{ warning }}%</span>
            </div>

            <div class="legenda">
                <div class="rounded-circle legenda-color bg-green"></div>
                <span>&#62;{{ success }}%</span>
            </div>
        </div>
    </div>
</div>
