import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListaPessoasService {

  constructor(private http: HttpClient) { }

  setAvailabilityTeam(chiefId: String, dateFiltered:string): Observable<any> {
    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAvailability}/${chiefId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);
  }

  setAvailabilityTeamManager(chiefId: String, dateFiltered:string): Observable<any> {
    let url = `${environment.dashboardApi}${environment.endpoints.getTeamLoggedInTodayManager}/${chiefId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);
  }

  setProductivityTeam(chiefId: String, dateFiltered:string): Observable<any> {
    let url = `${environment.dashboardApi}${environment.endpoints.getProductivityTeam}/${chiefId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);
  }
  setProductivityTeaMmanager(chiefId: String, dateFiltered:string): Observable<any> {
    let url = `${environment.dashboardApi}${environment.endpoints.getProductivityTeamManager}/${chiefId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);
  }

  getTotalBadSignalByChiefId(chiefId: String, dateFiltered:string): Observable<any>{

    let url = `${environment.dashboardApi}${environment.endpoints.getTotalBadSignalByChiefId}/${chiefId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);

  }
  getTotalBadSignalByChiefIdManager(chiefId: String, dateFiltered:string): Observable<any>{

    let url = `${environment.dashboardApi}${environment.endpoints.getTotalBadSignalByChiefIdManager}/${chiefId}?referenceDate=${dateFiltered}`
    return this.http.get<any>(url);

  }
  getMyTeamReport(chiefId: String, dateFiltered:string, pageNumber: number = 1, pageSize: number = 10): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getMyTeamReport}/${chiefId}?referenceDate=${dateFiltered}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    return this.http.get<any>(url);
  }
  getMyTeamReportManager(chiefId: String): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getMyTeamReportManager}/${chiefId}`
    return this.http.get<any>(url);
  }

}
