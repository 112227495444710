import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from '@station/charts';
import { EmployeesModule } from '@station/employees';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';
import { ChartModule } from 'angular-highcharts';
import { CountUpModule } from 'ngx-countup';

import { DashHeaderModule } from './../../shared/modules/dash-header/dash-header.module';
import { PainelColaboradorRoutingModule } from './painel-colaborador-routing.module';
import { ViewComponent } from './view/view.component';
import { MatIconModule } from '@angular/material/icon';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
  declarations: [
    ViewComponent,


  ],
  imports: [
    CommonModule,
    FormsModule,
    PainelColaboradorRoutingModule,
    HeaderModule,
    MenuModule,
    EmployeesModule,
    DashHeaderModule,
    ChartsModule,
    ChartModule,
    CountUpModule,
    MatIconModule,
    MatMenuModule,
    NgbCarouselModule,
    NgbTooltipModule,
    MatStepperModule,
  ]
})
export class PainelColaboradorModule { }
