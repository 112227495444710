<div class="content">
  <div class="info-container"
    [ngClass]="{'border-bottom-orange': showRegistrationData, 'border-bottom-none': !showRegistrationData}">
    <div class="info-header">
      <div class="info-header-all-vison">
        <div>
          <div class="info-hour">
            <span>Intervalo: </span>
            <span *ngIf="showFilter">
              <mat-chip class="mat-chip" *ngFor="let term of terms" [selectable]="selectable" [removable]="removable"
                (removed)="remove(term)">
                {{term}}
                <mat-icon (click)="resetReport()" matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
            </span>
            <span *ngIf="showSubtitle" class="subtitle">
              {{subtitle}}
            </span>
            <span> / Total de funcionários: <b>{{filteredTeam.length}}</b></span>
          </div>
        </div>
      </div>

      <div class="section-action-button">
        <div class="section-action-button-switch">
          <span>
            Dados cadastrais
          </span>
          <span>
            <label class="switch">
              <input type="checkbox" checked (click)="changeTable()">
              <span class="slider round"></span>
            </label>
          </span>
          <span>
            Produtividade
          </span>
        </div>
        <div *ngIf="showClearFilter">
          <button (click)="clearFilters()" class="btn btn-light" style="display: flex;"><mat-icon>close</mat-icon>Limpar
            Filtro
          </button>
        </div>
        <div>
          <button (click)="showModal()" class="btn m-1 btn-label btn-filter filter">
            Filtrar
            <mat-icon class="filter">filter_list_alt</mat-icon>
          </button>
        </div>

      </div>
    </div>

    <div id="modal-filter" class="info-filter" *ngIf="showModalFilter">
      <div class="filters">
        <div class="row-filter">
          <div *ngIf="showChiefs()">
            <label>Gestor:</label>
            <select [(ngModel)]="selectedChief" (change)="applyFilters()" class="form-select">
              <!-- <option value="">Selecione o Gestor</option> -->
              <option value="">Todos</option>
              <option *ngFor="let chief of uniqueChiefs" [value]="chief">{{ chief }}</option>
            </select>
          </div>

          <div [ngClass]="{'classe-80': showChiefs(), 'classe-100': !showChiefs()}">
            <label>Colaborador:</label>
            <select [(ngModel)]="selectedFullName" (change)="applyFilters()" class="form-select">
              <!-- <option value="">Selecione o Colaborador</option> -->
              <option value="">Todos</option>
              <option *ngFor="let fullName of uniqueFullNames" [value]="fullName">{{ fullName }}</option>
            </select>
          </div>
        </div>
        <div class="row-filter">
          <div>
            <label>Cargo:</label>
            <select [(ngModel)]="selectedJobPosition" (change)="applyFilters()" class="form-select">
              <!-- <option value="">Selecione o Cargo</option> -->
              <option value="">Todos</option>
              <option *ngFor="let jobPosition of uniqueJobPositions" [value]="jobPosition">{{ jobPosition }}</option>
            </select>
          </div>
          <div>
            <label>Jornada:</label>
            <select [(ngModel)]="selectedShiftPlan" (change)="applyFilters()" class="form-select">
              <!-- <option value="">Selecione a Jornada</option> -->
              <option value="">Todos</option>
              <option *ngFor="let shiftPlan of uniqueShiftPlans" [value]="shiftPlan">{{ shiftPlan }}</option>
            </select>
          </div>
        </div>
        <div class="row-filter">
          <div>
            <label>Grupo de ferramentas:</label>
            <select [(ngModel)]="selectedGroupName" (change)="applyFilters()" class="form-select">
              <!-- <option value="">Selecione o Grupo de ferramentas</option> -->
              <option value="">Todos</option>
              <option *ngFor="let groupName of uniqueGroupNames" [value]="groupName">{{ groupName }}</option>
            </select>
          </div>
          <div>
            <label>Status:</label>
            <select [(ngModel)]="selectedStatus" (change)="applyFilters()" class="form-select">
              <!-- <option value="">Selecione o Status</option> -->
              <option value="">Todos</option>
              <option *ngFor="let status of uniqueStatuses" [value]="status">{{ status }}</option>
            </select>
          </div>
        </div>
        <div class="row-filter">
          <div>
            <label>Versão do Agent:</label>
            <select [(ngModel)]="selectedAgentVersion" (change)="applyFilters()" class="form-select">
              <option value="">Todos</option>
              <option *ngFor="let agentVersion of uniqueAgentVersions" [value]="agentVersion">{{ agentVersion }}
              </option>
            </select>
          </div>
          <div>
            <label>Faixa horária:</label>
            <select [(ngModel)]="selectedHour" id="select-hour" class="form-select" (click)="selectTimeRange()">
              <option value="">Todos</option>
              <option *ngFor="let hour of hours" [selected]="selectedHour === hour">{{ hour }}</option>
            </select>
          </div>
        </div>
        <div class="row-filter">
          <div>
            <label>Hostname:</label>
            <select [(ngModel)]="selectedHostName" (change)="applyFilters()" class="form-select">
              <option value="">Todos</option>
              <option *ngFor="let hostName of uniqueHostName" [value]="hostName">{{ hostName }}
              </option>
            </select>
          </div>
          <div class="row-filter-end">
            <button (click)="clearFilters()" class="btn btn-light"
              style="display: flex;"><mat-icon>close</mat-icon>Limpar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="filteredTeam.length > 0; else loading">
    <div class="table-container">

      <table id="table-ProductivityData" class="employee-ranking-table" *ngIf="showProductivityData">
        <thead class="employee-ranking-table-header">

          <tr class="employee-ranking-table-summary">
            <th class="summary" colspan="3" *ngIf="!showChiefs()">
              <div>
                <p>Resumo</p>
              </div>
            </th>
            <th class="summary" colspan="4" *ngIf="showChiefs()">
              <div>
                <p>Resumo</p>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Tempo logado</p>
                  <p class="summary-subtitle"> sobre a previsão</p>
                </div>
                <div>
                  <p class="summary-value">{{calculateLoggedTimePercentage(summary.timeLoggedIn, summary.expectedTime)}}
                    %
                  </p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Produtividade</p>
                  <p class="summary-subtitle"> sobre o tempo previsto</p>
                </div>
                <div>
                  <p class="summary-value">{{calculateLoggedTimePercentage(summary.productiveTime,
                    summary.expectedTime)}}
                    %</p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Produtividade</p>
                  <p class="summary-subtitle"> sobre o tempo logado</p>
                </div>
                <div>
                  <p class="summary-value">{{calculateLoggedTimePercentage(summary.productiveTime,
                    summary.timeLoggedIn)}}
                    %</p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Tempo Previsto</p>
                </div>
                <div>
                  <p class="summary-value">{{getHours(summary.expectedTime)}}</p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Tempo logado</p>
                </div>
                <div>
                  <p class="summary-value">{{getHours(summary.timeLoggedIn)}}</p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Tempo produtivo</p>
                </div>
                <div>
                  <p class="summary-value">{{getHours(summary.productiveTime)}}</p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Tempo improdutivo</p>
                </div>
                <div>
                  <p class="summary-value">{{getHours(summary.unproductiveTime)}}</p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Tempo sem classificação</p>
                </div>
                <div>
                  <p class="summary-value">{{getHours(summary.timeWithoutClassification)}}</p>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div>
                  <p class="summary-title">Conexão</p>
                </div>
                <div>
                  <p class="summary-value">{{summary.connection}} <span class="summary-subtitle">Mbps</span></p>
                </div>
              </div>
            </th>
          </tr>
          <tr class="hidden">
            <th colspan="3" *ngIf="!showChiefs()"></th>
            <th colspan="4" *ngIf="showChiefs()"></th>
            <th>{{calculateLoggedTimePercentage(summary.timeLoggedIn, summary.expectedTime).toFixed(2)}} %</th>
            <th>{{calculateLoggedTimePercentage(summary.productiveTime, summary.expectedTime).toFixed(2)}} %</th>
            <th>{{calculateLoggedTimePercentage(summary.productiveTime, summary.timeLoggedIn) > 100 ? 100 : calculateLoggedTimePercentage(summary.productiveTime, summary.timeLoggedIn).toFixed(2)}} %</th>
            <th>{{getHours(summary.expectedTime)}}</th>
            <th>{{getHours(summary.timeLoggedIn)}}</th>
            <th>{{getHours(summary.productiveTime)}}</th>
            <th>{{getHours(summary.unproductiveTime)}}</th>
            <th>{{getHours(summary.timeWithoutClassification)}}</th>
            <th>{{summary.connection}} mbs</th>
          </tr>
          <tr class="employee-ranking-table-titles">
            <th>Status</th>
            <th *ngIf="showChiefs()">Gestor</th>
            <th colspan="2">Nome</th>
            <th>Tempo logado <p class="summary-subtitle"> sobre a previsão</p>
            </th>
            <th>Produtividade <p class="summary-subtitle"> sobre o tempo previsto</p>
            </th>
            <th>Produtividade <p class="summary-subtitle"> sobre o tempo logado</p>
            </th>
            <th>Tempo Previsto <p class="summary-subtitle"> horas</p>
            </th>
            <th>Tempo logado <p class="summary-subtitle"> horas</p>
            </th>
            <th>Tempo produtivo <p class="summary-subtitle"> horas</p>
            </th>
            <th>Tempo improdutivo</th>
            <th>Tempo sem classificação</th>
            <th>Conexão</th>
          </tr>
        </thead>
        <tbody class="employee-ranking-table-body">
          <tr *ngFor="let item of filteredTeam">
            <td class="info-status">
              <div [title]="item.status" class="status-circle bg-success"
                [ngClass]="{'bg-success': item.status == 'Logado agora', 'bg-primary': item.status == 'Logou durante o dia', 'bg-danger': item.status == 'Não logou'}">
                <span class="hidden" style="font-size: 1px;">{{item.status}}</span>
              </div>
            </td>
            <td *ngIf="showChiefs()">{{ item.chief }}</td>
            <td colspan="2" class="info-name"><a target="_blank" [routerLink]="['/painel-colaborador', item.employeeId]"
                [queryParams]="{ date_filter: dateFiltered }">{{item.fullName}}</a></td>
            <td><station-employee-data class="info-text"
                [employeeData]="setPecents(calculateLoggedTimePercentage(item.loggedTime, item.expectedTime))"></station-employee-data>
            </td>
            <td><station-employee-data class="info-text"
                [employeeData]="setPecents(calculateLoggedTimePercentage(item.productiveTime, item.expectedTime))"></station-employee-data>
            </td>
            <td><station-employee-data class="info-text"
                [employeeData]="setPecents(calculateLoggedTimePercentage(item.productiveTime, item.loggedTime) > 100 ? 100 : calculateLoggedTimePercentage(item.productiveTime, item.loggedTime))"></station-employee-data>
            </td>
            <td>{{ getHours(item.expectedTime) }}</td>
            <td>{{ getHours(item.loggedTime) }}</td>
            <td>{{ getHours(item.productiveTime) }}</td>
            <td>{{ getHours(item.unproductiveTime) }}</td>
            <td>{{ getHours(item.timeWithoutClassification) }}</td>
            <td>{{ item.connection }} <span class="summary-subtitle">Mbps</span></td>
          </tr>
        </tbody>
      </table>

      <table class="employee-ranking-table-data" *ngIf="showRegistrationData">
        <thead class="employee-ranking-table-header-data">
          <tr>
            <th>Status</th>
            <th *ngIf="showChiefs()">Gestor</th>
            <th>Nome</th>
            <th>Tempo logado (hr)</th>
            <th>Cargo</th>
            <th>Jornada</th>
            <th>Grupo de ferramentas</th>
            <th>Hostname</th>
            <th>Versão do Agent</th>
          </tr>
        </thead>
        <tbody class="employee-ranking_table_body">
          <tr *ngFor="let item of filteredTeam">
            <td class="info-status">
              <div [title]="item.status" class="status-circle bg-success"
                [ngClass]="{'bg-success': item.status == 'Logado agora', 'bg-primary': item.status == 'Logou durante o dia', 'bg-danger': item.status == 'Não logou'}">
              </div>
            </td>
            <td *ngIf="showChiefs()">{{ item.chief }}</td>
            <td class="info-name"><a target="_blank" [routerLink]="['/painel-colaborador', item.employeeId]"
                [queryParams]="{ date_filter: dateFiltered }">{{item.fullName}}</a></td>
            <td>{{item.loggedTime}}</td>
            <td>{{item.jobPosition}}</td>
            <td>{{item.shiftPlan}}</td>
            <td>{{item.groupName}}</td>
            <td [title]="item.hostName">{{ truncate(verifyValueNull(item.hostName), 20)}}</td>
            <td [title]="item.agentVersion">{{ truncate(verifyValueNull(item.agentVersion), 20)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
  <ng-template #loading>
    <station-spinners></station-spinners>
  </ng-template>
</div>
