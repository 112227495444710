import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const date = new Date(value);
    const hours = this.addZero(date.getUTCHours());
    const minutes = this.addZero(date.getUTCMinutes());
    const seconds = this.addZero(date.getUTCSeconds());

    return `${hours}:${minutes}:${seconds}`;
  }

  private addZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }
}
