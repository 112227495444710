<div class="modal-header">
  <h5 *ngIf="title != null || title != undefined" class="modal-title" id="exampleModalLabel">{{ title }}</h5>
  <ng-container *ngTemplateOutlet="modalTitle"></ng-container>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <ng-container *ngTemplateOutlet="modalContent"></ng-container>
</div>
<div class="modal-footer">
  <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
</div>
