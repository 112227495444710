import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TeamReportModel } from '../models/team-report-mode';

@Injectable({
  providedIn: 'root'
})
export class TeamReportService {

  constructor(private http: HttpClient) { }

  private report = new Subject<TeamReportModel>()
  getTeamReport$ = this.report.asObservable()

  updateGetTeamReport(data: any){
    this.report.next(data)
  }

  getTeamReport(userId: String, startDate: string, endDate: string, isInterval: boolean = false): Observable<TeamReportModel[]>{


    let url = `${environment.dashboardApi}${environment.endpoints.getTeamReport}/${userId}?startDate=${startDate}&endDate=${endDate}&isInterval=${isInterval}&enabled=true`;
    return this.http.get<TeamReportModel[]>(url)
  }
  getTeamReportManager(userId: String, startDate: string, endDate: string, isInterval: boolean = false): Observable<TeamReportModel[]>{


    let url = `${environment.dashboardApi}${environment.endpoints.getTeamReportManager}/${userId}?startDate=${startDate}&endDate=${endDate}&isInterval=${isInterval}&enabled=true`;
    return this.http.get<TeamReportModel[]>(url)
  }


}
