import { DatePipe } from '@angular/common';

declare global {
  interface Date {
    toFormattedString(): string;
    addDays(days:number): string;
    addDaysDate(days:number): Date;
    diffInSeconds(date: Date): number;
  }
}

Date.prototype.toFormattedString = function(): string {
  const datePipe = new DatePipe('en-US');
  const formattedDate = datePipe.transform(this, 'yyyy-MM-dd');
  return formattedDate || '';
};
Date.prototype.addDays = function(days: number): string {
  const newDate = new Date(this.getTime());
  newDate.setDate(this.getDate() + days);
  return newDate.toFormattedString();
};
Date.prototype.addDaysDate = function(days: number): Date {
  const newDate = new Date(this.getTime());
  newDate.setDate(this.getDate() + days);
  return newDate;
};
Date.prototype.diffInSeconds = function(date: Date): number {
  if (!this || !date) {
    console.error('Data indefinida ou nula.');
    return -1;
  }
  return Math.round(Math.abs(this.getTime() - date.getTime()) / 1000);
};
