import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeModel } from '@station/employees';
import { Menu } from '@station/menus';
import { Observable } from 'rxjs';
import { LoginResponse } from 'src/app/pages/login/model/login-response';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { EmployeeModelResponse } from 'src/app/shared/model/employee-model-response';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  constructor(private http: HttpClient, private authService: AuthService) { }



  getEmployees(): Observable<EmployeeModel[]> {


    return this.http.get<EmployeeModel[]>(`${environment.employees}`)

  }

  getMenu(): Observable<Menu[]> {

    return this.http.get<Menu[]>(`${environment.menu}`)
  }

  //TODO: Remover após criar todo fluxo de login
  getProfile(): EmployeeModel {

    let employeeProfile = {
      id: this.authService.authUser.id,
      name: this.authService.authUser.firstName + " " + this.authService.authUser.lastName,
      role: this.authService.authUser.jobPosition,
      roles: this.authService.getRoles(),
      picture: this.authService.authUser.photoPath.length > 0 ? this.authService.authUser.photoPath.length : "/assets/img/user-default.png",
      enabled: false,
      panel: true,
      badSignal: false
    } as EmployeeModel;

    return employeeProfile
  }

  getEmployeeByChiefId(chiefId: any, pageNumber: number = 1, pageSize: number = 10): Observable<EmployeeModelResponse> {

    // Faz a chamada HTTP com o cabeçalho configurado
    return this.http.get<EmployeeModelResponse>(
      `${environment.dashboardApi}${environment.endpoints.getEmployeeByChiefId}/${chiefId}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }
}
