<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Clonar Usuário</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body">
    <label for="quantity" class="form-check-label">Quantidade de cópias:</label>
    <input type="number" id="quantity" name="quantity" min="1" class="input-number" [(ngModel)]="userCloneCount" />
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" aria-label="Close" (click)="modalClose()" mat-button>
        <mat-icon>flash_on</mat-icon>
        <span>Clonar</span>
    </button>
</div>