export class SearchApplications
{
    chiefId!: string
    application!:  string
    referenceDate!: string

    constructor(
      chiefId: string,
      application:  string,
      referenceDate: string
    ) {

      this.chiefId = chiefId
      this.application = application
      this.referenceDate = referenceDate
    }
}
