<div class="jornada-de-trabaho">
  <station-header [headerModel]="header" class="station-header"></station-header>

  <div class="station-container">

    <div class="sidbar">
      <station-sidebar [isEmployees]="false"></station-sidebar>
  </div>


    <div class="station-content">

      <div class="sub-header">

        <p>Jornada de trabalho</p>
        <div class="new-journey">
          <button *ngIf="hasRole('jornada-de-trabalho-criacao')" (click)="newJourney()" class="button-new card-journey" >Novo</button>
        </div>

      </div>

      <main class="">
        <div class="list-card ">
          <input class="station-form-control search" type="text" placeholder="Buscar jornada" [(ngModel)]="filtroLista">

          <div class="list-container-journey">
            <div *ngFor="let journey of journeyFiltered">

              <div class="item-direction-journey" (click)="renderForm(journey)" (click)="verifyCheckedAll('markall', 'checkin')" (click)="verifyCheckedAll('markallweekend', 'checkinweekend')">
                <station-employee-card-journey  [CardJourney]="renderCardJourney(journey)">
                </station-employee-card-journey>
              </div>

            </div>
          </div>
        </div>

        <form [formGroup]="formJourney" [hidden]="!form" class="station-form-group " (ngSubmit)="saveChanges()">
          <div class="journey-description ">
            <label class="station-form-label">Id</label>
            <label class="station-form-label">{{journey.id}}</label>
          </div>
          <hr>
          <div class="journey-description ">
            <label class="station-form-label station-form-description" style="width: 130px;">Descrição</label>
            <input [(ngModel)]="journey.description" formControlName="description" style="font-size: 0.8em;" class="station-form-control" type="text"
            [ngClass]="{'is-invalid': formJourney.get('description')?.errors && formJourney.get('description')?.touched}">
          </div>
          <hr>

          <div class="day-week ">

            <label class="station-form-label station-title-week " for="">Dias da semana</label>

            <div class="week-content">
              <div class="">

                <div class="mark-day ">
                  <input id="checkweek" (click)="markAllDayWeek('markall', 'checkin')" class="markall" type="checkbox">
                  <label class="station-form-label-bold">Marcar todos</label>
                </div>

                <div class="mark-day ">
                  <input [(ngModel)]="journey.seg" class="checkin" (click)="verifyCheckedAll('markall', 'checkin')" type="checkbox" formControlName="seg">
                  <label class="station-form-label ">Segunda feira</label>
                </div>

                <div class="mark-day">
                  <input [(ngModel)]="journey.ter" class="checkin" (click)="verifyCheckedAll('markall', 'checkin')" type="checkbox" formControlName="ter">
                  <label class="station-form-label ">Terça feira</label>
                </div>

                <div class="mark-day ">
                  <input [(ngModel)]="journey.qua" class="checkin" (click)="verifyCheckedAll('markall', 'checkin')" type="checkbox" formControlName="qua">
                  <label class="station-form-label ">Quarta feira</label>
                </div>

                <div class="mark-day">
                  <input [(ngModel)]="journey.qui" class="checkin" (click)="verifyCheckedAll('markall', 'checkin')" type="checkbox" formControlName="qui">
                  <label class="station-form-label ">Quinta feira</label>
                </div>

                <div class="mark-day ">
                  <input [(ngModel)]="journey.sex" class="checkin" (click)="verifyCheckedAll('markall', 'checkin')" type="checkbox" formControlName="sex">
                  <label class="station-form-label ">Sexta feira</label>
                </div>

              </div>

              <div class="">

                <div class="mark-day ">
                  <input id="checkweekend" (click)="markAllDayWeek('markallweekend', 'checkinweekend')" class="markallweekend" [(ngModel)]="checkedAllDayWeekEnd" [ngModelOptions]="{standalone: true}" type="checkbox">
                  <label class="station-form-label-bold ">Marcar todos</label>
                </div>

                <div class="mark-day ">
                  <input [(ngModel)]="journey.dom" (click)="verifyCheckedAll('markallweekend', 'checkinweekend')" class="checkinweekend" type="checkbox" formControlName="dom">
                  <label class="station-form-label ">Domingo</label>
                </div>

                <div class="mark-day ">
                  <input [(ngModel)]="journey.sab" (click)="verifyCheckedAll('markallweekend', 'checkinweekend')" class="checkinweekend" type="checkbox" formControlName="sab">
                  <label class="station-form-label ">Sábado</label>
                </div>

                <div class="mark-day ">
                  <input [(ngModel)]="journey.fer" (click)="verifyCheckedAll('markallweekend', 'checkinweekend')" class="checkinweekend" type="checkbox" formControlName="fer">
                  <label class="station-form-label ">Feriado</label>
                </div>

              </div>
            </div>
          </div>

          <hr>

          <div class="week-hours ">

            <label class="station-form-label station-title-week " for="">Horário de trabalho</label>

            <div  class="hours-content">

              <div class="" formArrayName="hoursWork">

                <div class="hoursWork" *ngFor="let hours of getHoursWork.controls; index as i">


                  <div style="display: flex;" [formGroupName]="i">
                    <div class="mark-hours ">

                      <input formControlName="in" class="station-form-control station-form-hour" type="time">
                      <label class="station-form-label">Hora de entrada</label>
                    </div>

                    <div class="">
                      <div class="mark-hours-2 ">

                        <input formControlName="out" class="station-form-control station-form-hour" type="time">
                        <label class="station-form-label">Hora de saída <b (click)="confirm($event)">X</b><span (click)="removeWorkScheduleFromList(i)" class="confirm">Confirmar?</span></label>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="actions">
                  <label class="station-form-label mark-hours ">{{calculatedHours(journey)}}</label>
                  <div class="">
                    <button type="button" (click)="newHoursWork(journey)" class="button-new ">Novo intervalo</button>
                    <button *ngIf="hasRole('jornada-de-trabalho-edicao')" class="card-journey" type="submit">Salvar</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  </div>
</div>
