import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlterarSenhaRoutingModule } from './alterar-senha-routing.module';
import { ViewComponent } from './view/view.component';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashHeaderModule } from 'src/app/shared/modules/dash-header/dash-header.module';

@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    CommonModule,
    AlterarSenhaRoutingModule,
    HeaderModule,
    MenuModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    DashHeaderModule,
  ]
})
export class AlterarSenhaModule { }
