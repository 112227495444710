export const environment = {
  firebase: {
    apiKey: "AIzaSyA-Wki9SMnjwvvKK5U_xioX2gW0sY5UOds",
    authDomain: "station-intervalor-ee1e6.firebaseapp.com",
    projectId: "station-intervalor",
    storageBucket: "station-intervalor.appspot.com",
    messagingSenderId: "531214937593",
    appId: "1:531214937593:web:bd7131ed97fcb19bcf2128",
    measurementId: "G-CK7T7J8F9J"
  },
  appVersion: require('../../package.json').version,
  production: true,
  hostLocal: "./",
  menu: "/assets/mock/menu.json",
  employees: "assets/mock/employees.json",
  profile: "assets/mock/profile.json",
  bestEmployees: "assets/mock/best-employees.json",
  worstEmployees: "assets/mock/worst-employees.json",
  bigNumberAvailability: "assets/mock/bignumber-availability.json",
  bigNumberHighlightsAvailability: "assets/mock/bignumber-highlights-availability.json",
  bigNumberHighlightsProductivity: "assets/mock/bignumber-highlights-productivity.json",
  bigNumberProductivity: "assets/mock/bignumber-productivity.json",
  applications: "assets/mock/applications.json",
  applicationsUtility: "assets/mock/applications-utility.json",
  authApi: "https://api-auth-station-6j3mawfg6q-uc.a.run.app",
  companyApi: "https://api-company-station-6j3mawfg6q-uc.a.run.app",
  agentApi: "https://api-agent-station-6j3mawfg6q-uc.a.run.app",
  peopleApi: "https://api-people-station-6j3mawfg6q-uc.a.run.app",
  settingsApi: "https://api-settings-station-6j3mawfg6q-uc.a.run.app",
  applicationsApi: "https://api-applications-station-6j3mawfg6q-uc.a.run.app",
  dashboardApi: "https://api-dashboard-station-6j3mawfg6q-uc.a.run.app",
  notificationApi: "https://api-notification-station-6j3mawfg6q-uc.a.run.app",
  endpoints: {
    getConnectionAgent: "/api/v1/dashboard/getConnectionAgent",
    applications: "/api/v1/dashboard/dashApplicationTop",
    applicationFullTeam: "/api/v1/dashboard/manager/dashApplicationTop",
    produtiveApplicationsManager: "/api/v1/dashboard/manager/dashApplicationProdutiveTop",
    unprodutiveApplicationsManager: "/api/v1/dashboard/manager/dashApplicationUnprodutiveTop",
    whithoutClassificationApplicationsManager: "/api/v1/dashboard/manager/dashApplicationWithoutClassificationTop",
    getAvailability: "/api/v1/dashboard/GetAgentAvailabilityToday",
    getConnectionComparision: "/api/v1/dashboard/getConnectionComparision",
    dashProductivyApplicationUser: "/api/v1/dashboard/dashProductivyApplicationUser",
    chartConnectionDaily: "/api/v1/dashboard/GetConnectionAnalysisDaily",
    chartConnectionWeekly: "/api/v1/dashboard/GetConnectionAnalysisWeekly",
    chartConnectionMonthly: "/api/v1/dashboard/GetConnectionAnalysisMonthly",
    getRankingEmployees: "/api/v2/dashboard/GetProductivityRanking",
    getRankingEmployeesManager: "/api/v2/dashboard/manager/GetProductivityRanking",
    getTotalBadSignalByChiefId: "/api/v1/dashboard/GetCountTotalBadSignalByChiefId",
    getTotalBadSignalByChiefIdManager: "/api/v1/agent/manager/GetTotalBadSignalByChiefId",
    getMyTeamReport: "/api/v1/dashboard/GetMyTeamReport",
    getMyTeamReportManager: "/api/v1/dashboard/manager/GetMyTeamReport",
    getAgentAnalysisTimeLoggedDaily: "/api/v1/dashboard/getAgentAnalysisTimeLoggedDaily",
    getAgentAnalysisTimeLoggedWeekly: "/api/v2/dashboard/GetAvailabilityAgentChartWeek",
    getAgentAnalysisTimeLoggedMonthly: "/api/v2/dashboard/GetAvailabilityAgentChartMonth",
    getTeamAnalysisTimeLoggedDaily: "/api/v1/dashboard/getTeamAnalysisTimeLoggedDaily",
    getTeamAnalysisTimeLoggedDailyManager: "/api/v1/dashboard/manager/getTeamAnalysisTimeLoggedDaily",
    getTeamAnalysisTimeLoggedWeekly: "/api/v2/dashboard/GetAvailabilityChartWeek",
    getTeamAnalysisTimeLoggedWeeklyManager: "/api/v2/dashboard/manager/GetAvailabilityChartWeek",
    getTeamAnalysisTimeLoggedMonthly: "/api/v2/dashboard/GetAvailabilityChartMonth",
    getTeamAnalysisTimeLoggedMonthlyManager: "/api/v2/dashboard/manager/GetAvailabilityChartMonth",
    getAgentAvailabilityToday: "/api/v2/dashboard/GetAvailabilityAgentHighLights",
    getTeamAvailability: "/api/v2/dashboard/GetAvailabilityTeamHighLights",
    getTeamAvailabilityManager: "/api/v2/dashboard/manager/GetAvailabilityTeamHighLights",
    getTeamLoggedInTodayManager: "/api/v1/dashboard/manager/getTeamLoggedInToday",
    getAgentByUserId: "/api/v1/dashboard/GetEmployeeById",
    getApplicationTimeline: "/api/v1/agent/ApplicationLog/GetByUserId",
    getCardsUnproductive: "/api/v1/dashboard/dashApplicationUnproductive",
    twoFactorRegister: "/users/twoFactorRegister",
    authenticateWithTwoFactor: "/users/authenticateWithTwoFactor",
    getChartUnproductiveDaily: "/api/v1/dashboard/UnproductiveApplicationsDaily",
    getChartUnproductiveWeekly: "/api/v1/dashboard/UnproductiveApplicationsWeekly",
    getChartUnproductiveMonthly: "/api/v1/dashboard/UnproductiveApplicationsMonthly",
    getProductivityTeam: "/api/v2/dashboard/GetProductivityTeamHighLights",
    getProductivityTeamManager: "/api/v2/dashboard/manager/GetProductivityTeamHighLights",
    getTeamLogged: "/api/v1/dashboard/GetTeamLogged",
    getTeamLoggedManager: "/api/v1/dashboard/manager/GetTeamLogged",
    getSemaphore: "/api/v1/semaphore",
    getComparativeJourneyDailyByChiefId: "/api/v1/dashboard/GetComparativeHistoricalDailyByChiefId",
    getComparativeJourneyDailyByChiefIdManager: "/api/v1/dashboard/manager/GetComparativeHistoricalDailyByChiefId",
    getComparativeJourneyWeeklyByChiefId: "/api/v2/dashboard/GetProductivityChartWeek",
    getComparativeJourneyWeeklyByChiefIdManager: "/api/v2/dashboard/manager/GetProductivityChartWeek",
    getComparativeJourneyMonthlyByChiefId: "/api/v2/dashboard/GetProductivityChartMonth",
    getComparativeJourneyMonthlyByChiefIdManager: "/api/v2/dashboard/manager/GetProductivityChartMonth",
    getComparativeJourneyDaily: "/api/v1/dashboard/GetComparativeHistoricalDailyByUserId",
    getComparativeJourneyWeekly: "/api/v2/dashboard/GetProductivityAgentChartWeek",
    getComparativeJourneyMonthly: "/api/v2/dashboard/GetProductivityAgentChartMonth",
    dashTeamAvailabilityToday: "/api/v1/agent/dashTeamAvailabilityToday",
    getProductivityHighLightsByAgentId: "/api/v2/dashboard/GetProductivityAgentHighLights",
    putApplicationGroups: "/api/v1/applicationGroups",
    getApplicationGroups:"/api/v1/applicationGroups",
    postApplicationGroups:"/api/v1/applicationGroups",
    hierarchy: "/api/v1/hierarchy",
    getEmployeeByChiefId: "/api/v1/dashboard/Team",
    getScrennshotByUserId: "/api/v1/agent/Screenshot/GetByUserId",
    patchEmployeeByIds: "/api/v1/employees",
    patchEmployeeByOhterField: "/api/v1/employees/patchWithCustomFilter",
    getWordCloud: "/api/v1/agent/ApplicationWordCloud/GetCloudWords",
    getRoles: "/api/v1/roles",
    postUpdateEmployeesRegistrationIds: "/api/v1/employees/UpdateEmployeesRegistrationIds",
    getEmployeesWithNonExistentCompanyId: "/api/v1/GetEmployeesWithNonExistentCompanyId",
    getEmployeesWithNonExistentShiftPlainId: "/api/v1/GetEmployeesWithNonExistentShiftPlainId",
    getEmployeesWithNonExistentApplicationGroupId: "/api/v1/GetEmployeesWithNonExistentApplicationGroupId",
    getEmployeesWithNonExistentJobPositionId: "/api/v1/GetEmployeesWithNonExistentJobPositionId",
    getEmployeesWithNonExistentJobPosition: "/api/v1/GetEmployeesWithNonExistentJobPosition",
    getEmployeesWithNonExistentChiefId: "/api/v1/GetEmployeesWithNonExistentChiefId",
    getEmployeesWithSameFullname: "/api/v1/GetEmployeesWithSameFullname",
    getEmployeesWithSameNetworkUser: "/api/v1/GetEmployeesWithSameNetworkUser",
    getEmployeesWithSameRegistrationId: "/api/v1/GetEmployeesWithSameRegistrationId",
    getApplicationDetailsByUserId: "/api/v1/agent/ApplicationLog/GetApplicationLogDetailsByUserId",
    getMessagesByUserId: "/api/v1/Notification/UserNotification/GetAllByUserId",
    getCountUnreadMessagesByUserId: "/api/v1/Notification/UserNotification/CountUnreadByUserId",
    markReadUserNotification: "/api/v1/Notification/UserNotification/MarkAsRead",
    markUnreadUserNotification: "/api/v1/Notification/UserNotification/MarkAsUnread",
    deleteUserNotification: "/api/v1/Notification/UserNotification",
    userCheckin: "/api/v1/checkinCheckout",
    getLastCheckinByUserId: "/api/v1/checkinCheckout/GetLastByUserId",
    getLastLoginByUserId: "/api/v1/agent/LoginLogoutLog/GetLastActivity",
    getTeamReport: "/api/v1/dashboard/getTeamReport",
    getTeamReportManager: "/api/v1/dashboard/manager/getTeamReport",
    getComparisonLoggedInAndExpectedLogin: "/api/v1/dashboard/getComparisonLoggedInAndExpectedLogin",
    getComparisonLoggedInAndExpectedLoginManager: "/api/v1/dashboard/manager/getComparisonLoggedInAndExpectedLogin",
    getProductivityLoggedTimeHighlights: "/api/v2/dashboard/GetProductivityLoggedTimeHighlights",
    getProductivityLoggedTimeHighlightsManager: "/api/v2/dashboard/manager/GetProductivityLoggedTimeHighlights",
    getEmployeeListThatUseApplication: "/api/v1/dashboard/supervisor/ApplicationLog/GetEmployeeListThatUseApplication",
    getEmployeeListThatUseApplicationManager: "/api/v1/dashboard/manager/ApplicationLog/GetEmployeeListThatUseApplication",
    getApplicationTimeFrameDataCountByHour: "/api/v1/dashboard/GetApplicationTimeFrameDataCountByHour"
  }
}
