import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeMininalModel } from 'src/app/pages/painel-supervisor/models/employeeMininalModel';

@Component({
  selector: 'station-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.scss']
})
export class EmployeeInfoComponent implements OnInit {

  panelOpenState = false;


  @Input() employee!: EmployeeMininalModel
  @Input() visualization_type!: string
  @Output() goToSomePage: EventEmitter<EmployeeMininalModel> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.employee.openChiefPage = false
    this.getSortedDates()
  }

  getSortedDates() {
    return this.employee.dates?.slice().sort((a, b) => {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();
      return dateA - dateB;
    });
  }


  GotoPage(){
    this.goToSomePage.emit(this.employee)
  }

}

