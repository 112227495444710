import { Directive, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Directive({
    selector: '[login]'
})

export class ValidateLogin implements OnInit {


    constructor(private router: Router) { }
    ngOnInit(): void {

        const body = document.querySelector('body')
        const html = document.querySelector('html')

        this.router.events.subscribe((s) => {

            if (s instanceof NavigationEnd) {

                if (s.url == '/' || s.url == '/login' || s.url.includes("/login?")) {
                    body?.setAttribute('style', 'overflow: hidden !important')
                    body?.style.setProperty('overflow', 'hidden !important')
                    body?.style.setProperty('height', '100%')
                    html?.style.setProperty('height', '100%')
                }
                else if(s.url == '/' || s.url == '/forgotPassword' || s.url.includes("/forgotPassword?")){
                    body?.setAttribute('style', 'overflow: hidden !important')
                    body?.style.setProperty('overflow', 'hidden !important')
                    body?.style.setProperty('height', '100%')
                    html?.style.setProperty('height', '100%')
                }
                else {
                    body?.removeAttribute('style')
                    body?.removeAttribute('style')
                }
            }
        })
    }

}