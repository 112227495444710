
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';


import { ProfileEmployeeModel, SelectItemApplicationGroup, SelectItemHierarchy, SelectItemJourney } from '../models/profile-employee.model';
import { ProfileEmployeeService } from '../services/profile-employees.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUserCloneComponent } from 'src/app/shared/components/modal-user-clone/modal-user-clone.component';
import { CustomValidators } from '../validators/custom-validators';
import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-profile-employees-form',
  templateUrl: './profile-employees-form.component.html',
  styleUrls: ['./profile-employees-form.component.scss']
})
export class ProfileEmployeesFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private service: ProfileEmployeeService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
  ) { }

  @ViewChildren(FormControlName, { read: ElementRef }) private inputs!: QueryList<ElementRef>
  @Input() public employee!: ProfileEmployeeModel;
  @Input() roles: string[] = [];
  @Output() public refreshEmployeeList: EventEmitter<any> = new EventEmitter();
  defaultPasswordInfo?: string = "A senha inicial é formada pela <b>MATRÍCULA</b> seguida de <b>@</b> e o <b>PRIMEIRO NOME</b>. Ex.: 000@NOME"
  firstName?: string;
  registrationId?: string;
  buttonModalClosed: boolean = false;
  public employeeForm!: FormGroup;

  public profList: SelectItemHierarchy[] = [];
  public chiefList: ProfileEmployeeModel[] = [];
  public journeyList: SelectItemJourney[] = [];
  public applicGroupList: SelectItemApplicationGroup[] = [];


  private subscriberHierarchy!: Subscription;
  private subscriberChief!: Subscription;
  private subscriptionJourney!: Subscription;
  private subscriberApplicGroupList!: Subscription;

  public imageemployee!: string | undefined;
  public mensagensErro: Messages = {};
  private config: Partial<IndividualConfig> = { progressBar: true }



  public validations: Validacoes = {
    firstName: {
      minlength: "* Tamanho inválido minimo 4 caracteres",
      required: "* Nome é requerido"
    },
    lastName: {
      minlength: "* Tamanho inválido 4 caracteres",
      required: "* Sobrenome é requerido"
    },
    phone: {
      mask: "* Número de telefone inválido."
    },
    networkUser: {
      required: "* Usuário de rede é requerido."
    }
  };


  ngOnInit(): void {

    this.firstName = this.employee.firstName
    this.registrationId = this.employee.registrationId

    this.employeeForm = this.fb.group({
      firstName: [this.employee.firstName, [Validators.required, Validators.minLength(3), CustomValidators.whitespaceValidator]],
      lastName: [this.employee.lastName, [CustomValidators.whitespaceValidator]],
      email: [this.employee.email, [Validators.email]],
      registrationId: [this.employee.registrationId, [CustomValidators.whitespaceValidator]],
      networkUser: [this.employee.networkUser, [Validators.required, CustomValidators.whitespaceValidator]],
      phone: [this.employee.phone],
      photoPath: [this.employee.photoPath],
      twoFactorKey: [this.employee.twoFactorKey],
      twoFactorExpirateDate: null,
      jobPositionId: [this.employee.jobPositionId, [Validators.required]],
      chiefId: [this.employee.chiefId, [Validators.required]],
      shiftPlanId: [this.employee.shiftPlanId, [Validators.required, Validators.nullValidator]],
      applicationGroupId: [this.employee.applicationGroupId, [Validators.required]]
    })

    this.roles = { ...this.employee.roles }

  }


  sanitizeHtml(html: string): SafeHtml {

    let hasExternalLink = this.hasExternalLink(html)

    if(hasExternalLink){
      this.toastr.info("Por motivo de segurança, está notificação teve o seu conteúdo bloqueado, pois pode conter algo malicioso","Informativo")
      html = ""
    } 
          
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }


  
  hasExternalLink(htmlString: string): boolean {
    const regex = /<a\s+(?:[^>]*?\s+)?href=("|')([^"']*?)\1/gi; 
    let match;

    while ((match = regex.exec(htmlString)) !== null) {
        const href = match[2];
        if (!/^\/($|[^\/])|station\.center($|\/)/.test(href)) {
            return true; 
        }
    }   
    return false; 
}



  ngAfterViewInit(): void {

    this.initValidations();
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.setValues(this.employee);

    this.employee.photoPath = this.employee.photoPath == this.imageDefault() ? '' : this.employee.photoPath;

    this.loadJobPostionList(this.employee);
    this.loadJourney();
    this.loadApplicationGroup();
    this.defaultPasswordInfo = ""
  }

  //#region  load Dropdows

  public loadJobPostionList(employee: ProfileEmployeeModel) {

    if (employee.id === null && employee.id === '') {

      this.profList = [];
      this.chiefList = [];

    } else {
      this.subscriberHierarchy = this.service.getHierarchy()
        .subscribe({ next: (success) => this.successHierarchy(success), error: error => this.failRequest(error) })
    }
  }
  public updatePasswordInfo() {

    let registrationId = this.employeeForm.get('registrationId')?.value;
    let firstName = this.employeeForm.get('firstName')?.value;

    console.log(this.registrationId)
    console.log(this.firstName)

    this.defaultPasswordInfo = `A senha inicial é formada pela <b>MATRÍCULA</b> seguida de <b>@</b> e o <b>PRIMEIRO NOME</b>. Ex.: ${registrationId}@${firstName}`;
  }
  private loadChiefList(employee: ProfileEmployeeModel) {
    let perfil = this.profList.find(p => p.id === employee.jobPositionId)
    const order = perfil != null ? perfil.order : 0;
    if (order == 1 || perfil === undefined) {

      this.chiefList = [];
      return;

    }

    this.subscriberChief = this.service.getEmployeeChiefs(order)
      .subscribe({ next: (success) => this.successChief(success), error: error => this.failRequest(error) })
  }

  private loadJourney() {
    this.subscriptionJourney = this.service.getEmployeeJourney()
      .subscribe({ next: (success) => this.successJourney(success), error: error => this.failRequest(error) })
  }

  private loadApplicationGroup() {

    this.subscriberApplicGroupList = this.service.getEmployeeApplicationGroup()
      .subscribe({ next: (success) => this.successApplicationGroup(success), error: error => this.failRequest(error) })
  }


  //#endregion  load Dropdows

  //#region   response Subscribers


  private successHierarchy(lista: SelectItemHierarchy[]) {

    this.profList = lista.sort((a: SelectItemHierarchy, b: SelectItemHierarchy) => a.order - b.order);
    this.loadChiefList(this.employee)
  }

  private successChief(lista: ProfileEmployeeModel[]) {

    let profile = this.profList.find(p => p.id === this.employee.jobPositionId)
    if (profile == null || profile.order < 2) {
      this.chiefList = [];
    } else {
      this.chiefList = lista.filter(p => p.id != this.employee.id);
    }
  }

  private successApplicationGroup(lista: SelectItemApplicationGroup[]): void {
    this.applicGroupList = lista;
  }

  private successJourney(lista: SelectItemJourney[]): void {
    this.journeyList = lista.sort((a, b) => a.description.localeCompare(b.description));
  }


  private failRequest(error: HttpErrorResponse) {
    let message = error.error
    switch (error.status) {
      case 404:
        message = "Alguns items não foram encontrados. codigo 101"
        this.toastr.info(message, "Op's houve um erro", this.config)
        break
      case 500:     
        message = "Op's Ocorreu uma falha comunicação com Servidor entre contato com administrador ou tente mais tarde. codigo 102"
        this.toastr.error(message, "Op's houve um erro", this.config)
    }

   
  }


  //#endregion response Subscribers

  //#region  Events

  public onChange(select: HTMLSelectElement) {


    this.employeeForm.markAllAsTouched();
    let novoPerfilSelecionado = select.value;
    if (novoPerfilSelecionado.indexOf(":") > 0) {
      const initCut = novoPerfilSelecionado.indexOf(":") + 1
      novoPerfilSelecionado = novoPerfilSelecionado.substring(initCut, novoPerfilSelecionado.length).trim()
    }
    let newJobPosition = this.profList.find(p => p.id === novoPerfilSelecionado);

    //chiefId
    if (newJobPosition === undefined) return

    this.employeeForm.get("chiefId")?.setValue(null)

    this.employee.jobPositionId = newJobPosition.id

    this.subscriberChief = this.service.getEmployeeChiefs(newJobPosition?.order)
      .subscribe({
        next: lista => this.successChief(lista),
        error: error => this.failRequest(error)
      })

  }

  //#endregion Events

  //#region  Notify e Validations

  private initValidations() {
    let inputs: Observable<any>[] = this.inputs
      .map((control: ElementRef<HTMLElement>) => fromEvent(control.nativeElement, "blur"))
    merge(...inputs).subscribe(() => this.VerifyFieldValid())
  }

  private VerifyFieldValid() {
    for (let control in this.employeeForm.controls) {
      let controle = this.employeeForm.controls[control];
      this.mensagensErro[control] = '';

      if (controle.errors != null && (controle.dirty || controle.touched)) {
        if (this.validations[control]) { // Verifica se a chave existe no objeto validations
          for (let key in controle.errors) {
            let errorMsg = this.validations[control][key];
            if (errorMsg == null) continue;
            this.mensagensErro[control] = errorMsg;
          }
        }
      }
    }
  }

  //#endregion  Notify e Validations

  //#region  Submits

  validateChiefId() {
    const selectElement = document.querySelector('select[formControlName="chiefId"]');

    return (selectElement && selectElement.attributes.getNamedItem('disabled') == null)
  }

  public submit() {

    if(!this.employeeForm.valid)
    {
      this.ErrorMessage("Preencha os campos corretanemnte.");
      return
    }

    let jobPositionId = this.employee.jobPositionId;

    if (this.profList.findIndex(x => x.id == jobPositionId) == -1) {
      this.ErrorMessage("O campo PERFIL DE ACESSO é obrigatório.")
      return
    }

    if (this.validateChiefId() && this.employeeForm.get("chiefId")?.value == '') {
      this.ErrorMessage("O campo GESTOR IMEDIATO é obrigatório.")
      return

    }


    if (this.employeeForm.get("email")?.value == '' && this.employeeForm.get("registrationId")?.value == '') {
      this.ErrorMessage("O campo E-MAIL ou MATRÍCULA é obrigatório.")
      return
    }

    if (this.employeeForm.get("jobPositionId")?.value == 'Selecione...') {
      this.ErrorMessage("Alguns campos são obrigatórios.")
      return
    }

    const clone = Object.assign({}, this.employee)
    const employeeChange = { ...clone, ...this.employeeForm.value }

    employeeChange.roles = this.roles.length > 0 ? this.roles : []
    employeeChange.twoFactorKey = null

    let role = this.profList.find(p => p.id == jobPositionId)

    employeeChange.jobPosition = role?.role ?? this.employee.jobPosition


    // Remova a propriedade "employee" antes de enviar o POST
    delete employeeChange.employee;

    if (this.employee.id === "") {
    // Remova a propriedade "id" antes de enviar o POST
    delete employeeChange.id;

      this.service.postNewEmployee(employeeChange).subscribe({
        next: result => {
          this.refreshEmployeeList.emit({});
          this.imageemployee = employeeChange.photoPath == this.imageDefault() ? "" : employeeChange.photoPath;
          this.toastr.success("Funcionario adicionado com sucesso.", "Sucesso !", this.config)
        },
        error: error => this.ErrorMessage(error.error)
      })

    } else {

      this.service.putEmployee(employeeChange).subscribe({
        next: resul => {
          this.employeeForm.reset()
          this.imageemployee = employeeChange.photoPath == this.imageDefault() ? "" : employeeChange.photoPath;
          this.refreshEmployeeList.emit({})
          this.toastr.success("Funcionario alterado com sucesso.", "Sucesso !", this.config)
        },
        error: error => console.log(error)
      })

    }

    this.imageemployee = this.imageDefault();

  }

  public ErrorMessage(msg: string) {
    this.toastr.error(
      msg,
      "Erro ao cadastrar",
      { closeButton: true, progressBar: true },
    )
  }
  public inactivateEmployee() {


    this.service.removerEmployee(this.employee.id).subscribe(res => {
      this.refreshEmployeeList.emit({})
      const msg = this.employee.enabled ? "Inativado" : "Ativado"

      this.toastr.success(`usuario ${msg} com sucesso.`, "Sucesso !", this.config)
    })
  }
  public sendLinkIntaller() {

    this.employee.stationAgentInstalled = false;



    this.service.putEmployee(this.employee).subscribe(respo => {
      this.refreshEmployeeList.emit({})
      this.toastr.success(`E-mail enviado com instruções com sucesso aguarde alguns instantes.`, "Sucesso !", this.config)
    })
  }


  //#endregion Submits


  public setValues(employee: ProfileEmployeeModel) {

    if (!this.employeeForm) return

    this.mensagensErro = {};
    this.employeeForm.reset();


    this.imageemployee = employee.photoPath?.trim() ?? this.imageDefault();
    this.employeeForm.get("firstName")?.setValue(employee.firstName.trim())
    this.employeeForm.get("lastName")?.setValue(employee.lastName.trim())
    this.employeeForm.get("registrationId")?.setValue(employee.registrationId)
    this.employeeForm.get("networkUser")?.setValue(employee.networkUser)
    this.employeeForm.get("email")?.setValue(employee.email.trim())
    this.employeeForm.get("photoPath")?.setValue(this.imageemployee)
    this.employeeForm.get("phone")?.setValue(employee.phone == undefined ? "" : employee.phone.trim())
    this.employeeForm.get("jobPositionId")?.setValue(employee.jobPositionId)
    this.employeeForm.get("chiefId")?.setValue(employee.chiefId)
    this.employeeForm.get("shiftPlanId")?.setValue(employee.shiftPlanId)
    this.employeeForm.get("applicationGroupId")?.setValue(employee.applicationGroupId)
    this.employeeForm.get("twoFactorKey")?.setValue(employee.twoFactorKey?.trim())
    this.employeeForm.get("twoFactorExpirateDate")?.setValue(null)
  }

  public alterImage(inputfile: HTMLInputElement) {

    let files = inputfile?.files as FileList;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.employee.photoPath = reader.result?.toString() ?? this.imageDefault();
      this.employeeForm.get("photoPath")?.setValue(reader.result)
      this.employeeForm.markAsDirty()
    }
  }





  private imageDefault(): string {

    return "/assets/img/user-default.png"
  }



  ngOnDestroy(): void {

    this.subscriberHierarchy?.unsubscribe();
    this.subscriptionJourney?.unsubscribe();
    this.subscriberApplicGroupList?.unsubscribe();
    this.subscriberChief?.unsubscribe();
  }

  modalOpen() {
    const modalRef = this.modalService.open(ModalUserCloneComponent);

    modalRef.result.then((result) => {
      if (result) {
        const numberOfCopies = parseInt(result, 10); // Converte o número fornecido para um valor inteiro


        if (isNaN(numberOfCopies) || numberOfCopies <= 0 || this.employee.id == "") {
          // Verifica se o número fornecido é válido
          this.ErrorMessage("O valor fornecido para a quantidade de cópias é inválido.");
          return;
        }

        const originalEmployee = { ...this.employee }; // Cria uma cópia do funcionário original

         // Loop para criar cópias e alterar os dados
        for (let i = 1; i <= numberOfCopies; i++) {
          const copyEmployee = { ...originalEmployee} // Remo }; // Cria uma cópia do funcionário original para cada cópia

          // Altera os dados do funcionário copiado (por exemplo, adicionando o sufixo "Copia(2)", "Copia(3)", etc.)
          copyEmployee.lastName = originalEmployee.lastName + " Cópia(" + i + ")";
          copyEmployee.registrationId = originalEmployee.registrationId + " Cópia(" + i + ")";
          copyEmployee.email = originalEmployee.email + " Cópia(" + i + ")";
          copyEmployee.networkUser = '';
          copyEmployee.id = '';

          // Aqui, você pode enviar a requisição POST ou PUT para criar o funcionário clonado no servidor
          // this.service.postNewEmployee(copyEmployee) ou this.service.putEmployee(copyEmployee)
          // Lembre-se de ajustar o serviço e o endpoint de acordo com sua API.

          this.service.postNewEmployee(copyEmployee).subscribe({
            next: result => {
              this.toastr.clear();
              this.toastr.success(`${numberOfCopies} funcionário(s) adicionado(s) com sucesso.`, "Sucesso !", this.config)
            },
            error: error => this.ErrorMessage(error.error)
          })
        }
      }
    });
  }
  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

}

interface Validacoes {
  [key: string]: { [key: string]: string }
}

interface Messages {
  [key: string]: string
}


