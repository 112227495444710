import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  static readonly TIMEOUT_LIMIT:number = 60 * 60 * 1000; // 15 minutos em milissegundos
  timeoutId!: ReturnType<typeof setTimeout>;

  constructor(public authService: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Defina a URL de retorno antes de redirecionar para a página de login
    this.authService.setReturnUrl(state.url);

    return this.authService.checkToken().pipe(
      map(isAuthenticated => {

        if (!isAuthenticated) {
          this.logout();
          return false;
        }

        document.addEventListener('mousemove', this.resetTimeout.bind(this));
        document.addEventListener('mousedown', this.resetTimeout.bind(this));
        document.addEventListener('keypress', this.resetTimeout.bind(this));

        const expectedRole = route.data['expectedRole'];

        if (expectedRole && !this.authService.hasRole(expectedRole)) {
          this.authService.defaultUserPage();
          return false;
        }

        if (!this.authService.isAuthenticated()) {
          this.logout();
          return false;
        }

        return true;


      })

    );
  }

  private resetTimeout = () => {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(this.logout.bind(this), AuthGuardService.TIMEOUT_LIMIT);
  }

  private logout() {
    this.router.navigate(['logout']);
  }
}
