<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{employee.fullName}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="border-top: 1px solid rgb(166, 166, 166);">
      <div style="margin: 21px 0;">
        <ul>
          <li class="section-information">
            <span>
              <mat-icon>business_center</mat-icon>
            </span>
            <span>
              {{employee.jobPosition}}
            </span>
          </li>
          <li *ngIf="visualization_type == 'todo-time'" class="section-information">
            <span>
              <mat-icon>supervisor_account</mat-icon>
            </span>
            <span>
              {{employee.chiefFullName}}
            </span>
          </li>
          <li *ngIf="visualization_type == 'todo-time'" class="section-option">
            <label class="container">
              Abrir visão do chefe ?
              <input [(ngModel)]="employee.openChiefPage" type="checkbox">
              <span class="checkmark"></span>
            </label>
          </li>
        </ul>
      </div>
      <div>
        <select (change)="GotoPage()" [(ngModel)]="employee.selectDate" class="form-select">
          <option value="" selected>Selecione uma data</option>
          <option *ngFor="let date of getSortedDates()" [value]="date | date: 'yyyy-MM-dd'">{{ date | date: 'dd-MM-yyyy'}}</option>
        </select>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
