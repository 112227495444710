import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationsData } from '../models/application.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient) { }



  getApplications(userId: String, dateFiltered: string): Observable<ApplicationsData>{


    let url = `${environment.dashboardApi}${environment.endpoints.applications}/${userId}?referenceDate=${dateFiltered}&enabled=${true}`

    return this.http.get<ApplicationsData>(url)
  }

  getApplicationsFullTeam(userId: String, dateFiltered: string): Observable<ApplicationsData>{


    let url = `${environment.dashboardApi}${environment.endpoints.applicationFullTeam}/${userId}?referenceDate=${dateFiltered}&enabled=${true}`

    return this.http.get<ApplicationsData>(url)
  }

  getProdutiveApplicationsManager(userId: String, dateFiltered: string): Observable<ApplicationsData>{


    let url = `${environment.dashboardApi}${environment.endpoints.produtiveApplicationsManager}/${userId}?referenceDate=${dateFiltered}`

    return this.http.get<ApplicationsData>(url)
  }
  getUnprodutiveApplicationsManager(userId: String, dateFiltered: string): Observable<ApplicationsData>{


    let url = `${environment.dashboardApi}${environment.endpoints.unprodutiveApplicationsManager}/${userId}?referenceDate=${dateFiltered}`

    return this.http.get<ApplicationsData>(url)
  }
  getWhithoutClassificationApplicationsManager(userId: String, dateFiltered: string): Observable<ApplicationsData>{


    let url = `${environment.dashboardApi}${environment.endpoints.whithoutClassificationApplicationsManager}/${userId}?referenceDate=${dateFiltered}`

    return this.http.get<ApplicationsData>(url)
  }


}
