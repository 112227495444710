<div class="lista-de-pessoas">
  <station-header [headerModel]="header"></station-header>
  <station-user-notification [countUnread]="countUnreadUserNotification" [userNotifications]="userNotifications" (loadMore)="loadMoreUserNotification($event)"></station-user-notification>

  <div class="lista-de-pessoas_conteudo">
    <station-sidebar></station-sidebar>

    <div class="content">
      <div class="dash-header_content">
        <station_dash-header [enabledBadConnection]="false"></station_dash-header>
      </div>
      <hr>

        <div class="select_content">
          <select class="form-control text-capitalize filter" #selectElement (change)="selectFilterEmployees(selectElement)">
            <option *ngFor="let item of filterTableActions" [value]="item.id">
              {{item.title}}
            </option>
          </select>
          <div class="dateFilter">
            <select *ngIf="visualizationTypeEnabled" class="visualization-filter" name="" id="" [(ngModel)]="visualizationType" (change)="updatePanels()">
              <option value="gestao-direta">Gestão direta</option>
              <option value="todo-time">Todo o time</option>
            </select>
            <input type="date" [max]="maxDate" (change)="updatePanels()" [(ngModel)]="dateFiltered" />
          </div>
        </div>

      <div class="big-number-content">

        <div class="big-number-info">
          <station-employee-big-number [bigNumbers]="bigNumberAvailabilityTodayTeams"></station-employee-big-number>
          <div class="divisor"></div>
          <station-employee-big-number [bigNumbers]="bigNumberAvailabilityTeam"></station-employee-big-number>
          <div class="divisor"></div>
          <station-employee-big-number [bigNumbers]="bigNumberProductivityTeam"></station-employee-big-number>
          <div class="divisor"></div>
          <div class="big-number-connection">
          <station-employee-big-number [bigNumbers]="bigNumberConnectionTeam"></station-employee-big-number>
          </div>
        </div>

      </div>

      <div class="table-productivity">

        <div class="toggle-switch-container" *ngIf="toggleRefreshEnabled">
            <input type="checkbox" id="toggle-switch" class="toggle-switch" (change)="toggleRefresh($event)" >
            <label for="toggle-switch" class="switch"></label>
            <label for="toggle-switch" class="switch-label">Atualizar?</label>

            <input type="number" id="input-time" value="1"class="input-time" [(ngModel)]="intervalValue" >
            <label for="toggle-switch" class="switch-label">min.</label>
          </div>


          <station-employees-table-productivity [headerTable]="headerTable" [employeeModel]="produtividadeList$">
          </station-employees-table-productivity>
          <div appHoldable class="plus-button" *ngIf="pageNumber < totalPages" (click)="loadMoreEmployees()" (hold)="onHold()" [class.bg-success]="alternativeEmployeesButton">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
            </svg>
            <span class="page-info">{{pageNumber}}/{{totalPages}}</span>
          </div>

      </div>


    </div>
  </div>
</div>
