<div class="container-prod">
  <div class="improdutivas">
    <div class="table-title-content">
      <div class="table-title">
        <h2>Aplicações Utilizadas <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal" data-bs-target="#modalAplicacoes">info</mat-icon></h2>
        <p>Lista de Aplicações acessadas ({{total}} aplicações(s) / {{totalMin}})</p>
      </div>
      <div class="input-search">
        <input type="text" (input)="listFilterKeyUp($event)" placeholder="Buscar">
        <span class="span-icon"></span>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th class="p-employee"></th>
          <th>Termo</th>
          <th>Descritivo</th>
          <th>Tempo total</th>
          <th>Categoria</th>
          <th>Classificação</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of productiveApplicationsFiltered">
          <td>
            <mat-icon (click)="showDetails(item)">visibility</mat-icon>
            <!-- <div *ngIf="mostrarIcone;else mostrarOutroIcone">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
                <path
                  d="m10.4 20.7-.35-2.8q-.525-.15-1.163-.5-.637-.35-1.062-.75l-2.575 1.1-1.6-2.8 2.225-1.675q-.05-.3-.088-.625-.037-.325-.037-.625 0-.275.037-.587l.088-.713L3.65 9.05l1.6-2.75L7.8 7.375q.5-.425 1.087-.763.588-.337 1.138-.512l.375-2.8h3.2l.35 2.825q.65.225 1.138.512.487.288 1.012.738L18.75 6.3l1.6 2.75-2.325 1.75q.1.35.113.638.012.287.012.562 0 .25-.025.537-.025.288-.1.713l2.275 1.7-1.6 2.8-2.6-1.125q-.525.45-1.05.762-.525.313-1.1.488L13.6 20.7Zm1.55-6.5q.925 0 1.562-.638.638-.637.638-1.562t-.638-1.562Q12.875 9.8 11.95 9.8t-1.563.638Q9.75 11.075 9.75 12t.637 1.562q.638.638 1.563.638Zm0-.7q-.625 0-1.062-.438-.438-.437-.438-1.062t.438-1.062q.437-.438 1.062-.438t1.063.438q.437.437.437 1.062t-.437 1.062q-.438.438-1.063.438ZM12 12Zm-1 8h1.95l.375-2.725q.75-.2 1.338-.538.587-.337 1.237-.962l2.5 1.075 1-1.7-2.2-1.65q.125-.45.163-.8.037-.35.037-.7 0-.375-.037-.7-.038-.325-.163-.75l2.25-1.7-1-1.7-2.575 1.075q-.425-.475-1.187-.925-.763-.45-1.388-.575L13 4h-2l-.3 2.7q-.75.15-1.375.5t-1.275 1l-2.5-1.05-1 1.7 2.175 1.625q-.125.325-.175.725-.05.4-.05.825 0 .375.05.75t.15.725l-2.15 1.65 1 1.7 2.475-1.05q.6.6 1.225.95.625.35 1.425.55Z" />
              </svg>
            </div>
            <ng-template #mostrarOutroIcone>
              <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
                <path
                  d="M12 21q-1.875 0-3.512-.712-1.638-.713-2.85-1.926-1.213-1.212-1.926-2.85Q3 13.875 3 12t.712-3.513q.713-1.637 1.926-2.85 1.212-1.212 2.85-1.925Q10.125 3 12 3q3.5 0 6.038 2.312Q20.575 7.625 20.925 11h-1q-.275-2.175-1.575-3.9-1.3-1.725-3.35-2.55V5q0 .825-.587 1.412Q13.825 7 13 7h-2v2q0 .425-.287.712Q10.425 10 10 10H8v2h1.85v3H9l-4.8-4.8q-.075.45-.138.9Q4 11.55 4 12q0 3.275 2.3 5.625T12 20Zm9.025-.275-3.45-3.4q-.45.325-.975.5-.525.175-1.1.175-1.475 0-2.488-1.012Q12 15.975 12 14.5t1.012-2.488Q14.025 11 15.5 11t2.488 1.012Q19 13.025 19 14.5q0 .6-.188 1.137-.187.538-.537.963l3.45 3.425ZM15.5 17q1.05 0 1.775-.725Q18 15.55 18 14.5q0-1.05-.725-1.775Q16.55 12 15.5 12q-1.05 0-1.775.725Q13 13.45 13 14.5q0 1.05.725 1.775Q14.45 17 15.5 17Z" />
              </svg>
            </ng-template> -->
          </td>
          <td>{{ item.title }}</td>
          <td>{{ item.description }}</td>
          <td *ngIf="item.totalTime > 0">{{ formatTime(item.totalTime) }}</td>
          <td *ngIf="item.totalTime == 0"> < 1 min</td>

          <td>{{ item.category }}</td>
          <td>{{ item.classification }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="footer-buttons" *ngIf="productiveApplicationsFiltered.length > 0">
    <button class="btn btn-light btn-download" title="Baixar CSV" (click)="downloadCSV('table')">description</button>
  </div>
</div>
<!-- Conteudo do body do modal -->
<ng-template #modalContent>
  <table class="table table-striped" #excelTable id="excelTableId">
    <thead>
      <tr>
        <th scope="col" *ngFor="let item of displayedColumns">{{item}}</th>
      </tr>
    </thead>
    <tbody *ngIf="productiveApplicationsDetails.length > 0">
      <tr *ngFor="let item of productiveApplicationsDetails">
        <td>{{ item.startDate | timeFormat }}</td>
        <td>{{ item.endDate | timeFormat}}</td>
        <td title="Tempo em segundos">{{ item.duration }}</td>
        <td class="table-content-cell" [title]=" item.windowTitle">{{ item.windowTitle }}</td>
        <td class="table-content-cell" [title]="item.url">{{ item.url }}</td>
        <td title="Termo que deu match do Grupo de Ferramentas">{{ item.term }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>
<ng-template #modalFooter >
<button *ngIf="productiveApplicationsDetails.length > 0" class="btn btn-light btn-download" title="Baixar CSV" (click)="downloadCSV('modal')">description</button>
<button *ngIf="productiveApplicationsDetails.length > 0" class="btn btn-light btn-download text-success" title="Baixar Planilha (xls)" (click)="exportAsExcel()">table_chart</button>
</ng-template>


 <!-- Modal Aplicações utilizadas -->
 <div class="modal fade" id="modalAplicacoes" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Saiba mais</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p><b>Aplicações Utilizadas</b></p>
        <p><span class="font-weight-normal">Este registro fornece informações abrangentes sobre todas as aplicações acessadas durante o dia, incluindo aplicações produtivas, improdutivas e aquelas que ainda não foram classificadas.</span></p>
        <p></p>
        <p><span class="font-weight-normal">No registro, você encontrará detalhes como o termo de classificação usado, a descrição da aplicação, o tempo total gasto nela e sua classificação correspondente.</span></p>
        <p></p>
        <p><span class="font-weight-normal">Para obter informações mais detalhadas sobre uma aplicação específica, basta clicar no ícone do "Olho", o que abrirá um novo modal contendo informações como data de início e fim, duração, título, URL registrada e o termo de classificação usado.</span></p>
        <p></p>
        <p></p>
        <p><span class="font-weight-normal">Além disso, você tem a opção de baixar esses registros em formatos CSV e XLSX para análise posterior.<b> 5</b></span></p>
      </div>
    </div>
  </div>
</div>
