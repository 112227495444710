<div class="painel-colaborador">
  <station-header [headerModel]="header"></station-header>
  <station-user-notification [countUnread]="countUnreadUserNotification" [userNotifications]="userNotifications"
    (loadMore)="loadMoreUserNotification($event)"></station-user-notification>
  <div class="painel-colaborador_conteudo">
    <station-sidebar [isEmployees]="true" [employeeId]="authUser.id" [showEmployeeList]="showEmployeeList">
    </station-sidebar>

    <div class="content">
      <div class="dash-header_content">
        <station_dash-header [employee$]="profile$" [enabledBadConnection]="false"></station_dash-header>
      </div>
      <div class="dateFilter">
        <small><i><b>Última atualização:</b>
            {{ lastUpdate | date : "dd/MM/yyyy HH:mm:ss" }}</i></small>
        <div class="d-flex btnSelector">
          <div class="d-flex flex-row-reverse me-2 mb-2">
            <input type="date" [max]="maxDate" (change)="updatePanels()" [(ngModel)]="dateFiltered" class="inputDate" />
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('month')">
              Mês
            </button>
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('week')">
              Quinzena
            </button>
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('day')">
              {{ dayLabel }}
            </button>
          </div>
        </div>
      </div>

      <div class="employee-chart-content">
        <div class="big-number-content">
          <div class="big-number-info">
            <p class="highlights-title">
              Application logs
            </p>

          </div>

          <div class="chartComparativoTempoLogado">
            <div>
              <station-chart-column-group [chartColumnGroupOptions$]="chartItemOptionsStacked" placement="bottom"
                (newItemEvent)="dateFilterClick($event, 'station-chart-column-group')"
                (nenuItemEvent)="chartColumnGroupMenuItemClick($event)">
              </station-chart-column-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>