<div class="perfil-empresa">
  <station-header [headerModel]="header"></station-header>

  <div class="perfil-empresa_content">

    <div class="sidbar">
      <station-sidebar></station-sidebar>
    </div>

    <div class="content">
      <div class="title">Alterar Senha </div>

      <div class="form_content">
        <form action="" (ngSubmit)="changePassword()" [formGroup]="form">
          <div class="station-painel-form">
            <div class="station-painel-form-company">
              <div class="station-form-group">
                <div class="station-form-flex">
                  <div>
                    <label class="station-form-label" for="oldPassword">Senha Atual:</label>
                    <input type="hidden" formControlName="userId" />
                  </div>
                  <div>
                    <input formControlName="oldPassword" id="oldPassword" class="station-form-control" type="password"
                      placeholder="Digite a senha atual" required [ngClass]="form.controls['oldPassword'].invalid ? 'is-invalid' : ''"/>
                    <label class="text-danger" *ngIf="!form.controls['oldPassword'].valid">Informe a senha atual</label>
                  </div>
                </div>
              </div>
              <div class="station-form-group">
                <div class="station-form-flex">
                  <div>
                    <label class="station-form-label" for="password">Nova Senha:</label>
                  </div>
                  <div>
                    <input formControlName="password" id="password" class="station-form-control" type="password"
                      placeholder="Digite a nova senha" required pattern=".{8,12}" required [ngClass]="form.controls['password'].invalid ? 'is-invalid' : ''"
                      title="Entre 8 e 12 caracteres" />
                      <label class="text-danger" *ngIf="form.controls['password'].hasError('hasNumber')">A senha precisa conter número</label>
                      <label class="text-danger" *ngIf="form.controls['password'].hasError('hasCapitalCase')">A senha precisa conter letra maiúscula</label>
                      <label class="text-danger" *ngIf="form.controls['password'].hasError('hasSmallCase')">A senha precisa conter letra minúscula</label>
                      <label class="text-danger" *ngIf="form.controls['password'].hasError('hasLenght')">A senha precisa conter de 8 a 12 caracteres</label>
                      <label class="text-danger" *ngIf="form.controls['password'].hasError('PasswordUsed')">A senha não pode ser igual a senha antiga</label>
                      <label class="text-danger" *ngIf="form.controls['password'].hasError('WhitespaceFound')">A senha não pode conter espaços em branco no início ou no final</label>
                  </div>
                </div>
              </div>
              <div class="station-form-group">
                <div class="station-form-flex">
                  <div>
                    <label class="station-form-label" for="confirmPassword">Confirme a Senha:</label>
                  </div>
                  <div>
                    <input formControlName="confirmPassword" id="confirmPassword" class="station-form-control"
                      type="password" placeholder="Digite a nova senha" required  [ngClass]="form.controls['confirmPassword'].invalid ? 'is-invalid' : ''" />
                    <label class="text-danger"
                      *ngIf="form.controls['confirmPassword'].hasError('NoPassswordMatch')">
                      A senha precisa ser igual a nova senha
                    </label>
                  </div>
                </div>
              </div>
              <div class="station-submit">
                <button type="submit" [disabled]="form.invalid" [ngClass]="form.invalid ? 'submit-button-disabled' : 'submit-button'" >Salvar</button>
                <!-- <input type="submit" value="Salvar" [disabled]="form.invalid" [ngClass]="form.invalid ? 'is-invalid' : ''"> -->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
