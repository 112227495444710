import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'station-card-hierarchy',
  templateUrl: './card-hierarchy.component.html',
  styleUrls: ['./card-hierarchy.component.scss']
})
export class CardHierarchyComponent {

  @Input() cardsHierarchy!: any;
  @ViewChild('clickForm') elementView!: ElementRef;
  isClicked: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  @HostListener('document:click', ['$event'])
  onClick(event: any) {

    let currentElement = (event.target as Element).className

    let parentElement = (event.target as Element)?.parentElement?.className

    const cardElement = (parentElement?.includes("card-hierarchy") || currentElement.includes("card-hierarchy"));

    if (!cardElement) {
      return;
    }

    this.isClicked = (this.elementView.nativeElement.contains(event.target));
  }
}

