import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmployeeModel } from '@station/employees';
import { Menu } from '@station/menus';
import { Observable, tap } from 'rxjs';
import { LoginResponse } from 'src/app/pages/login/model/login-response';
import { SidebarService } from './services/sidebar.service';
import { ActivatedRoute } from '@angular/router';
import { Hierarchy } from 'src/app/pages/painel-hierarquia/models/hierarchy';
import { PanelHierarchyService } from 'src/app/pages/painel-hierarquia/services/panelHierarchyService.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'station-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges {
  isEmployeesHidden: boolean = false;

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private panelHierarchyService: PanelHierarchyService
  ) { }

  @Input() isEmployees: boolean = false;
  @Input() showEmployeeList: any = null;
  @Input() employeeId!: string;
  @Input() employeeId$!: Observable<string>;
  @Input() employee$!: Observable<EmployeeModel>;
  @Input() refreshInterval:number = 0;
  @Output() isOpened: EventEmitter<boolean> = new EventEmitter();

  dataMenu: Menu[] = []

  pageNumber: number = 1
  pageSize: number = 30
  totalPages:number = 1;
  totalCount:number = 0;
  requestLoading:boolean = false
  employeeListComplete = false
  alternativeEmployeesButton = false;
  intervalId: any;
  intervalValue = 5;

  profile: EmployeeModel = {
    name: '',
    role: '',
    roles: [],
    enabled: false,
    panel: false,
    badSignal: false,
    isOnline: false,
    linkProfile: '',
    shouldBeCurrentlyWorking: false,
    linkDetail: '',
    hasFirstLogin: false,
    teamIsOnline: 0
  }

  ngOnInit(): void {


    if (this.showEmployeeList === null) {
      this.showEmployeeList = this.isEmployees;
    }


    let uri = this.route.parent?.snapshot.routeConfig?.path



    switch (uri) {
      case 'painel-supervisor':
      case 'painel-supervisor/:id':
        case 'painel-colaborador':
          case 'painel-colaborador/:id':

            this.employeeId$?.pipe(
              tap(result => this.employeeId = result)
              ).subscribe(() => this.initialLoad())

              break;

              default:

              this.initialLoad()
              break;
    }

    this.renderMenu()

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["refreshInterval"] && !changes["refreshInterval"].firstChange && this.intervalValue > 0) {
      this.setMyTeamReportSetInterval();
    }
  }
  private renderMenu() {
    this.sidebarService.getMenu().subscribe(menu => {

      this.adjustMenu(menu, this.profile).then(data => {
        const filteredMenu = this.filterMenuByRole(data);
        this.dataMenu = filteredMenu;
      });
    });
  }

  private filterMenuByRole(menu: any[]): any[] {
    this.profile.roles = this.authService.getRoles()
    const userRoles = this.profile.roles;
    const filteredMenu = menu.filter(item => {
      const role = item.menuLink.role;
      return !role || userRoles?.find(x => x == role);
    });
    return filteredMenu;
  }

  MapEmployeeModel(response: LoginResponse[]): EmployeeModel[] {
    let result = response.map(mapEmployee => {

      return {
        id: mapEmployee.id,
        name: mapEmployee.firstName + " " + mapEmployee.lastName,
        role: mapEmployee.jobPosition,
        picture: mapEmployee.photoPath,
        enabled: mapEmployee.enabled,
        panel: true,
        isOnline: mapEmployee.isOnline,
        linkProfile: this.getPageRedirect(mapEmployee),
        linkDetail: this.getPageDetailRedirect(mapEmployee),
        title: this.getEmployeeTitle(mapEmployee),
        shouldBeCurrentlyWorking: mapEmployee.shouldBeCurrentlyWorking,
        sleep: mapEmployee.sleep,
        hasFirstLogin: mapEmployee.hasFirstLogin,
        teamIsOnline: mapEmployee.teamIsOnline,
        badSignal: mapEmployee.badSignal
      } as EmployeeModel;

    })

    return result
  }
  loadMoreEmployees() {
    const nextPageNumber = this.pageNumber + 1;

    this.requestLoading = true;
    this.sidebarService.getEmployeeByChiefId(this.employeeId, nextPageNumber, this.pageSize)
      .subscribe({
        next: (result) => {
          this.requestLoading = false;

          if (!result.team) {
            this.employeeListComplete = true;
            return;
          }

          const newEmployees = this.MapEmployeeModel(result.team);

          // Verifica e remove duplicidades por ID
          const uniqueEmployees = this.getUniqueEmployees([...this.employees, ...newEmployees]);

          // Atualiza a lista de funcionários com os novos funcionários únicos
          this.employees = uniqueEmployees;

          this.employees = this.setEmployees(this.employees);

          this.pageNumber = nextPageNumber;
        },
        error: (error) => {
          // Lidar com o erro, se necessário...
        }
      });
  }

  getUniqueEmployees(employees: EmployeeModel[]): EmployeeModel[] {
    const uniqueIds = new Set();
    const uniqueEmployees: EmployeeModel[] = [];

    employees.forEach((employee) => {
      if (!uniqueIds.has(employee.id)) {
        uniqueIds.add(employee.id);
        uniqueEmployees.push(employee);
      }
    });

    return uniqueEmployees;
  }
  initialLoad() {
    this.profile = this.sidebarService.getProfile();
    this.employeeId = this.employeeId ?? this.profile.id;

    this.sidebarService.getEmployeeByChiefId(this.employeeId, 1, this.pageSize).subscribe({
      next: (result) => {
        const employees = result.team; // Get employee data
        this.totalPages = result.totalPages; // Get total pages
        this.totalCount = result.totalCount; // Get total count

        const previousData = this.employees.filter(x => x.isOnline);
        const previousDataIds = previousData.map(x => x.id); // Extract IDs from previousData

        if (this.employee$) {
          this.getEmployeSelected(this.employee$).then((data) => {
            this.profile = data == null ? this.setProfile(this.profile) : this.setProfile(data);
          });
        }

        this.employees = this.setEmployees(this.MapEmployeeModel(employees));

        const currentData = this.employees.filter(x => x.isOnline);
        const newOnlineUsers = currentData.filter(user => !previousDataIds.includes(user.id));

        if (newOnlineUsers.length > 0) {
          const newUsersNames = newOnlineUsers.map(user => `🙋🏻‍♀️${user.name}`).join('\n');
          const message = `👧🏽👩🏻‍🦰👨🏾‍🦲🧔🏻👱🏻 Novo(s) usuário(s) conectado(s):\n\n${newUsersNames}`;
          this.notificationService.sendSimpleNotification(message, "Station - Integration Monitor");
        }

        // Use totalPages and totalCount as needed...
      },
      error: (error) => {
        // Handle the error, if necessary...
      }
    });
  }

  loadAllEmployees() {

    this.sidebarService.getEmployeeByChiefId(this.employeeId, 1, 99999).subscribe({
      next: (result) => {
        const employees = result.team; // Obter os dados dos funcionários
        this.totalPages = 1; // Obter o total de páginas
        this.pageNumber = 1;
        this.totalCount = result.totalCount; // Obter a contagem total

        if (this.employee$) {
          this.getEmployeSelected(this.employee$).then((data) => {
            this.profile = data == null ? this.setProfile(this.profile) : this.setProfile(data);
          });
        }

        this.employees = this.setEmployees(this.MapEmployeeModel(employees));

      },
      error: (error) => {
        // Lidar com o erro, se necessário...
      }
    });
  }
  private async adjustMenu(menu: Menu[], employee: EmployeeModel) {

    let jobPosition: Hierarchy = await new Promise((resolve) => this.panelHierarchyService.getAllCardHierarchys().subscribe(sub => resolve(sub[sub.length - 1])))

    switch (employee.role) {

      case jobPosition.role:

        menu.map(m => { if (m.menuLink.title == "Painel") m.menuLink.link = 'painel-colaborador' });
        break;

      default:
        menu.map(m => { if (m.menuLink.title == "Painel") m.menuLink.link = 'painel-supervisor' });
        break;
    }

    return menu as Menu[]


  }

  private getPageRedirect(employee: LoginResponse) {
    return employee.jobPosition.toUpperCase() == "COLABORADOR" ? `/painel-colaborador/${employee.id}` : `/painel-supervisor/${employee.id}`
  }
  private getPageDetailRedirect(employee: LoginResponse) {
    return employee.jobPosition.toUpperCase() == "COLABORADOR" ? '' : `/painel-colaborador/${employee.id}`
  }

  private getEmployeeTitle(employee: LoginResponse): string {
    let title = employee.firstName + " " + employee.lastName + "\n";

    title += employee.isOnline ? "\n-Está ONLINE agora" : "\n-Está OFFLINE agora";

    if (!employee.hasFirstLogin) {

      title += "\n-Nunca realizou LOGIN no AGENT";
    }

    if (employee.teamIsOnline > 0) {

      title += `\n-${employee.teamIsOnline} colaborador(es) ONLINE`;
    }

    if (employee.badSignal) {

      title += "\n-Apresenta problemas de CONEXÃO COM A INTERNET";
    }

    if (employee.shouldBeCurrentlyWorking) {

      title += "\n-DEVERIA estar ONLINE agora";
    }

    if (!employee.shouldBeCurrentlyWorking) {

      title += "\n-NÃO deveria estar ONLINE agora";
    }

    if (employee.agentVersion) {

      title += employee.agentVersion.length < 10 ? `\n-Versão do Agent: ${employee.agentVersion}`: `\n-${employee.agentVersion}`;
    }
    return title;

  }
  private setProfile(profile: any): EmployeeModel {

    return new EmployeeModel(profile.name, profile.role, profile.enabled, '', profile.picture, profile.panel, false)

  }

  employees: EmployeeModel[] = []

  private sortEmployees(employees: EmployeeModel[]): EmployeeModel[] {
    const sortedEmployees = employees.sort((a, b) => {
      // Verificar se está destacado
      const aHighlighted = a.teamIsOnline > 0;
      const bHighlighted = b.teamIsOnline > 0;

      // Verificar se está ativo
      const aActive = a.isOnline && a.enabled;
      const bActive = b.isOnline && b.enabled;

      // Ordenar por destaque
      if (aHighlighted && !bHighlighted) {
        return -1; // a vem antes de b
      }
      if (!aHighlighted && bHighlighted) {
        return 1; // b vem antes de a
      }

      // Ordenar por ativo
      if (aActive && !bActive) {
        return -1; // a vem antes de b
      }
      if (!aActive && bActive) {
        return 1; // b vem antes de a
      }

      // Ordenar por nome
      return a.name.localeCompare(b.name);
    });

    return sortedEmployees;
  }

  private updateEmployeeNames(employees: EmployeeModel[]): void {
    employees.forEach((employee: EmployeeModel) => {
      employee.panel = true;
      const names = employee.name.split(' ');
      if (names.length > 1) {
        employee.name = `${names[0]} ${names[names.length - 1]}`;
      }
    });
  }

  private setEmployees(employees: EmployeeModel[]): EmployeeModel[] {
    const sortedEmployees = this.sortEmployees(employees);
    this.updateEmployeeNames(sortedEmployees);
    return sortedEmployees;
  }

  private async getEmployeSelected(employees: Observable<EmployeeModel>) {

    let resolveEMployee = await new Promise((resolve) => employees.subscribe(em => resolve(em)))

    return resolveEMployee

  }
  setMyTeamReportSetInterval() {
    this.intervalId = setInterval(() => {
      this.initialLoad()
    }, this.intervalValue * 60000)
  }
  stopMyTeamReportSetInterval() {
    clearInterval(this.intervalId);
  }
  onHold()
  {
    this.alternativeEmployeesButton = true;
    this.loadAllEmployees();
  }
  hideEmployees() {
    this.showEmployeeList = false;
    this.isEmployeesHidden = true; // Adicione esta variável
    this.isOpened.emit(false);
  }
  showEmployees() {
    this.showEmployeeList = true;
    this.isEmployeesHidden = false; // Adicione esta variável
    this.isOpened.emit(true);
  }
}
