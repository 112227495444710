<div>
    <form action="" [formGroup]="employeeForm" (ngSubmit)="submit()" style="font-size: 20px;">
        <div class="station-form-group">
            <div class="station-form-flex">
                <div>
                    <label class="station-form-label" for="">ID</label>
                </div>
                <div>
                    <label>{{employee.id}}</label>
                </div>
            </div>
        </div>
        <div *ngIf="employee.lastStationCheckin != null" class="station-form-group">
            <div class="station-form-flex">
                <div>
                    <label class="station-form-label" for="">Último acesso Station (Web)</label>
                </div>
                <div>
                    <label>{{(employee.lastStationCheckin | date:'dd/MM/yyyy HH:mm:ss')}}</label>
                </div>
            </div>
        </div>
        <div *ngIf="employee.lastAgentLogin != null" class="station-form-group">
            <div class="station-form-flex">
                <div>
                    <label class="station-form-label" for="">Último acesso Agent (Desktop)</label>
                </div>
                <div>
                    <label>{{(employee.lastAgentLogin | date:'dd/MM/yyyy HH:mm:ss')}}</label>
                </div>
            </div>
        </div>
        <div class="station-form-group">
            <div class="row">
                <div class="col-6">
                    <div class="form-floating mb-3">
                        <input type="text" formControlName="firstName" class="form-control" id="floatingInput" placeholder="name@example.com" (keyup)="updatePasswordInfo()">
                        <label for="floatingInput">Nome</label>
                        <label class="text-danger" *ngIf="employeeForm.controls['firstName'].hasError('WhitespaceFound')"> Não pode conter espaços em branco no início ou no final</label>
                        <span *ngIf="mensagensErro['firstName']" class="station-alert-danger">{{ mensagensErro['firstName'] }}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input type="text" formControlName="lastName" class="form-control" id="floatingPassword" placeholder="Sobrenome">
                        <label for="floatingPassword">Sobrenome</label>
                        <label class="text-danger" *ngIf="employeeForm.controls['lastName'].hasError('WhitespaceFound')">Não pode conter espaços em branco no início ou no final</label>
                        <span *ngIf="mensagensErro['lastName']" class="station-alert-danger">{{ mensagensErro['lastName'] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="station-form-group">
            <div class="row">
                <div class="col-6">
                    <div class="form-floating mb-3">
                        <input type="text" formControlName="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Email</label>
                        <span *ngIf="mensagensErro['email']" class="station-alert-danger">{{ mensagensErro['email'] }}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input type="text" formControlName="registrationId" class="form-control" id="floatingPassword" placeholder="Sobrenome" (keyup)="updatePasswordInfo()">
                        <label for="floatingPassword">Matrícula</label>
                        <label class="text-danger" *ngIf="employeeForm.controls['registrationId'].hasError('WhitespaceFound')">Não pode conterespaços em branco no início ou no final</label>
                        <span *ngIf="mensagensErro['registrationId']" class="station-alert-danger">{{mensagensErro['registrationId'] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="station-form-group">
            <div class="row">
                <div class="col-6">
                    <div class="form-floating mb-3">
                        <input type="text" formControlName="networkUser" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Usuário de rede*</label>
                        <label class="text-danger" *ngIf="employeeForm.controls['networkUser'].hasError('WhitespaceFound')">Não pode conter espaços em branco no início ou no final</label>
                        <span *ngIf="mensagensErro['networkUser']" class="station-alert-danger">{{mensagensErro['networkUser'] }}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-floating">
                        <input type="text" formControlName="phone" prefix="+55 " mask="(00) 00000-0000" class="form-control" id="floatingPassword" placeholder="Sobrenome">
                        <label for="floatingPassword">Telefone</label>
                        <!-- <input type="text" class="station-form-control"  > -->
                        <span *ngIf="mensagensErro['phone']" class="station-alert-danger">{{ mensagensErro['phone']}}</span>
                    </div>
                </div>
            </div>
        </div>


        <div class="station-form-group">
            <div class="row">
                <div class="col-6">
                    <div>
                        <label class="station-form-label" for="">Foto</label>
                    </div>
                    <div class="input-group mb-3">
                        <input type="file" class="form-control" id="inputGroupFile02" style="
                        padding: 12px !important;">
                        <label class="input-group-text" for="inputGroupFile02">Upload</label>
                    </div>
                </div>
                <div class="col-6">
                    <figure class="box d-flex flex-nowrap align-items-center">
                        <img [src]="employee.photoPath" alt="" class="me-2">&nbsp;
                        <label for="input-image" class="input-image">
                            <input type="file" id="input-image" class="img-fluid input-file" #inputimage
                                (change)="alterImage(inputimage)">
                            <span id="imageName">Inserir foto</span>
                        </label>
                    </figure>
                </div>
            </div>
        </div>

        <div class="station-form-group">
            <div class="row">
                <div class="col-6">
                    <div>
                        <label class="station-form-label" for="">Perfil de acesso*</label>
                    </div>
                    <div>
                        <select  class="form-select" #select formControlName="jobPositionId"
                            [attr.disabled]="profList.length === 0 ? '': null" (change)="onChange(select)">
                            <option>Selecione...</option>
                            <option *ngFor="let item of profList" [ngValue]="item.id"
                                [selected]="item.id === employee.jobPositionId">
                                {{item.order}} - {{item.role}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div>
                        <label class="station-form-label" for="">Gestor imediato*</label>
                    </div>
                    <div>
                        <select class="form-select text-capitalize" formControlName="chiefId"
                            [attr.disabled]="chiefList.length === 0 ? '': null">
                            <option value="">Selecione...</option>
                            <option *ngFor="let item of chiefList" [ngValue]="item.id"
                                [selected]="item.id === employee.chiefId">
                                {{item.firstName}} {{ item.lastName }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="station-form-group">
            <div class="row">
                <div class="col-6">
                    <div>
                        <label class="station-form-label" for="">Jornada de trabalho*</label>
                    </div>
                    <div>
                        <select class="form-select" formControlName="shiftPlanId"
                            [attr.disabled]="journeyList.length === 0 ? '': null ">
                            <option>Selecione...</option>
                            <option *ngFor="let item of journeyList" [ngValue]="item.id"
                                [selected]="item.id === employee.shiftPlanId">
                                {{item.description}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div>
                        <label class="station-form-label" for="">Grupo de ferramentas*</label>
                    </div>
                    <div>
                        <select class="form-select" formControlName="applicationGroupId"
                            [attr.disabled]="applicGroupList.length === 0 ? '': null ">
                            <option>Selecione...</option>
                            <option *ngFor="let item of applicGroupList" [ngValue]="item.id"
                                [selected]="item.id === employee.applicationGroupId">
                                {{item.groupName}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    
        
        <div class="row defaultPasswordInfo" *ngIf="defaultPasswordInfo">
            <div class="col-12">
                <small [innerHTML]="sanitizeHtml(defaultPasswordInfo)"></small>
            </div>
        </div>
        <div class="buttons mt-5">
            <button type="button" class="btn btn-clonar" [ngClass]="employee.enabled ? 'btn-danger': ' btn-success'"
                *ngIf="employee.id  !== '' && hasRole('perfil-do-usuario-inativar')" (click)="inactivateEmployee()"
                mat-button>
                <mat-icon>{{ employee.enabled ? 'block': 'how_to_reg' }}</mat-icon>
                <span> {{ employee.enabled ? 'Inativar': 'Ativar' }}</span>
            </button>
            <div class="btn btn-primary btn-clonar" (click)="modalOpen()"
                *ngIf="employee.id  !== '' && hasRole('perfil-do-usuario-clonar')">
                <mat-icon>flash_on</mat-icon>
                Clonar
            </div>
            <button type="button" *ngIf="hasRole('perfil-do-usuario-permissoes')" class="btn btn-secondary btn-clonar"
                aria-label="Close" data-bs-toggle="modal" data-bs-target="#station-employee-checklist-modal" mat-button>
                <mat-icon>admin_panel_settings</mat-icon>
                <span>Permissões</span>
            </button>
            <button type="submit" *ngIf="hasRole('perfil-do-usuario-edicao')" class="btn btn-save btn-clonar btn-clonar"
                aria-label="Close" mat-button>
                <mat-icon>save</mat-icon>
                <span>Salvar</span>
            </button>
        </div>
    </form>
</div>