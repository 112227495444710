import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appHoldable]'
})
export class HoldableDirective {
  @Output() hold: EventEmitter<void> = new EventEmitter();
  private isPressed: boolean = false;
  private holdInterval: any;
  private holdCounter: number = 0;
  private maxHoldCount: number = 3;

  @HostListener('mousedown')
  onMouseDown(): void {
    this.isPressed = true;
    this.holdCounter = 0;
    this.startHoldEvent();
  }

  @HostListener('mouseup')
  onMouseUp(): void {
    this.isPressed = false;
    this.clearHoldEvent();
  }

  private startHoldEvent(): void {
    this.holdInterval = setInterval(() => {
      if (this.isPressed) {
        this.holdCounter++;
        if (this.holdCounter === this.maxHoldCount) {
          this.hold.emit();
          this.playNotificationSound();
          this.clearHoldEvent();
        }
      }
    }, 1000); // 1 segundo, intervalo entre incrementos do contador
  }

  private clearHoldEvent(): void {
    clearInterval(this.holdInterval);
  }
  private playNotificationSound(): void {
    // Obter a referência do elemento de áudio
    const audio = new Audio();
    audio.src = 'assets/audio/retro-game-notification.wav';

    // Reproduzir o som
    audio.play().catch(error => {
      console.error('Erro ao reproduzir o som:', error);
    });
  }
}
