import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PainelAdministrativoComponent } from './view/view.component';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';
import { PainelAdministrativoRoutingModule } from './painel-administrativo-routing.module';
import { DashHeaderModule } from "../../shared/modules/dash-header/dash-header.module";
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ChartsModule } from '@station/charts';


@NgModule({
    declarations: [
        PainelAdministrativoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ChartsModule,
        HeaderModule,
        MenuModule,
        MatIconModule,
        PainelAdministrativoRoutingModule,
        DashHeaderModule
    ]
})
export class PainelAdministrativoModule { }
