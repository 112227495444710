import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Hierarchy } from '../models/hierarchy';
import { HierarchyRequest } from '../models/hierarchyRequest';

@Injectable({
  providedIn: 'root'
})
export class PanelHierarchyService {
  listaHierarquia$: any = new BehaviorSubject<Hierarchy[]>([])

  constructor(private http: HttpClient) { }

  getAllCardHierarchys(): Observable<Hierarchy[]>{
    return this.http.get<Hierarchy[]>(`${environment.peopleApi}${environment.endpoints.hierarchy}`);
  }

  post(hierarchy: HierarchyRequest): Observable<Hierarchy>{
    return this.http.post<Hierarchy>(`${environment.peopleApi}${environment.endpoints.hierarchy}`, hierarchy);
  }

  put(hierarchy: Hierarchy): Observable<Hierarchy>{
    return this.http.put<Hierarchy>(`${environment.peopleApi}${environment.endpoints.hierarchy}/${hierarchy.id}`, hierarchy);
  }

  getListHierarchy(): Observable<Hierarchy> {
    return this.listaHierarquia$.asObservable();
  }

  setListHierarchy(hierarchy: Hierarchy): void {
    this.listaHierarquia$.next(hierarchy);
  }
}
