<station-employee-checklist [elements]="checklistItems" [modalId]="'station-employee-checklist-modal'" (checkedItems)="checkedItems($event)"></station-employee-checklist>
<div login class="panel-hierarchy">
  <station-header [headerModel]="header"></station-header>

  <div class="panel-hierarchy_content">
    <div class="sidbar">
      <station-sidebar></station-sidebar>
  </div>

    <div class="hierarchy-content">

      <div class="panel-title">
        <b>Perfil de acesso</b>
        <button type="button" *ngIf="hasRole('perfil-de-acesso-criacao')" name="clearForm" class="btn btn-light m-1 button-form" (click)="newForm()">Nova</button>
      </div>

      <div class="form-content">
        <div class="card-hierarchy">
          <div class="input-search">
            <input type="text" placeholder="Busca de Perfil" [(ngModel)]="filterList">
            <span class="span-icon">X</span>
          </div>
          <div #drag cdkDropList class="hierarchy-drag-drop" (cdkDropListDropped)="drop($event)">
            <div [ngClass]="{'deactive': card.deactive}" class="hierarchy-content-drag-drop" cdkDrag *ngFor="let card of hierarchyFiltered">
              <station-card-hierarchy [cardsHierarchy]="card" (click)="findHierarchy(card.order)">
              </station-card-hierarchy>
            </div>
          </div>
        </div>

        <div class="hierarchy-description">
          <form [formGroup]="hierarchyForm" (ngSubmit)="saveHierarchy()">
            <input type="hidden" formControlName="roles" [(ngModel)]="roles">
            <div class="profile-description">
              <label class="">Descritivo do perfil</label>
              <input type="number" [hidden]="true" formControlName="order">
              <input type="text" formControlName="role">
            </div>
            <hr>
            <div class="access-description ">
              <label class="">Acessa o station:</label>

              <div class="checkbox">
                <input formControlName="access" type="checkbox">
                <label>Sim</label>
              </div>
            </div>
            <div *ngIf="hasRole('perfil-de-acesso-permissoes')" class="deactive-description">
              <label class="deactive-label">Desativar:</label>
              <div class="checkbox">
                <input formControlName="deactive" type="checkbox">
                <label class="deactive-label-2">Sim</label>
              </div>
            </div>
            <div class="text-end station-btn">
              <!-- <button type="button" class="btn btn-light m-1 button-form">Desativar</button> -->
              <button type="button" *ngIf="hasRole('perfil-de-acesso-permissoes')" data-bs-toggle="modal" data-bs-target="#station-employee-checklist-modal" class="btn btn-danger">Permissões</button>
              <button type="submit" *ngIf="hasRole('perfil-de-acesso-edicao')" class="btn btn-light m-1 button-form">Salvar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
