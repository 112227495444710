<div class="content height-100 forgot-password" [style.background]="currentAnimation.BackgroundColor">

    <div class="row height-100">
        <div class="box-left col-lg-4">
            <div class="row back-logo">
                <div class="col-lg-6 d-flex align-items-start">
                    <picture>
                        <img src="./assets/img/logo-mutant.webp">
                    </picture>
                </div>
                <div class="col-lg-6">
                    <img src="./assets/img/station-logo-transparente.png">
                </div>
            </div>
            <div class="align-items-center height-100 d-flex">
                <img [src]="'./assets/img/' + currentAnimation.Filename" />
                <div class="word-container" [@typedAnimation]="typedWord">
                    {{ typedWord }}
                </div>
            </div>
        </div>

        <div class="box-right col-lg-8">
            <div class="form-section d-flex align-items-center"
                *ngIf="!modalMessage"
                [ngStyle]="{'background-image': 'url(assets/img/' + backgroundImage + ')'}">

                <div [ngClass]="{'form-content-forgout':changeForForgout, 'form-content':!changeForForgout}">

                    <div class="form-logo">
                        <img src="./assets/img/myra-icon.png">
                    </div>
                    <div class="form-forms" >
                        <form [formGroup]="form" (ngSubmit)="changePassword()">
                            <div>
                                <label for="password">Nova senha:</label>
                                <div class="input-form-password">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-shield-lock" viewBox="0 0 16 16">
                                        <path
                                            d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                                        <path
                                            d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
                                    </svg>
                                    <input 
                                            *ngIf="isPasswordField; else textInputPassword" 
                                            class="username" 
                                            id="password" 
                                            (keyup)="verifiyFildPassword()"
                                            formControlName="password"
                                            minlength="8"
                                            maxlength="12"
                                            type="password" required
                                            [ngClass]="form.controls['password'].invalid ? 'is-invalid' : ''"
                                            name="senha" 
                                            placeholder="Nova senha"
                                            [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}"
                                        />
                                    <ng-template #textInputPassword>
                                        <input
                                            class="username" 
                                            id="password" 
                                            (keyup)="verifiyFildPasswordText()"
                                            formControlName="password"
                                            minlength="8"
                                            maxlength="12"
                                            type="text" required
                                            [ngClass]="form.controls['password'].invalid ? 'is-invalid' : ''"
                                            name="senha" placeholder="Nova senha"
                                            [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}"
                                        />
                                    </ng-template>
                                    <mat-icon (click)="togglePasswordVisibility()" *ngIf="visibilityPassword">visibility</mat-icon>
                                    <mat-icon (click)="togglePasswordVisibility()" *ngIf="visibilityOffPassword">visibility_off</mat-icon>
                                </div>
                            </div>

                            <div>
                                <label for="confirmPassword">Confirmar senha:</label>
                                <div class="input-form-password">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-shield-lock" viewBox="0 0 16 16">
                                        <path
                                            d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                                        <path
                                            d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415z" />
                                    </svg>
                                    <input *ngIf="isConfirmPasswordFild; else textInpuConfirmPassword" 
                                            class="username" 
                                            id="confirmPassword" 
                                            (keyup)="verifiyFildConfirmPassword()"
                                            formControlName="confirmPassword"
                                            type="password" required
                                            minlength="8"
                                            maxlength="12"
                                            [ngClass]="form.controls['confirmPassword'].invalid ? 'is-invalid' : ''"
                                            name="senha" placeholder="Confirmar senha"
                                            [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}"
                                        />
                                    <ng-template #textInpuConfirmPassword>
                                        <input
                                            class="username" 
                                            id="confirmPassword" 
                                            (keyup)="verifiyFildConfirmPasswordText()"
                                            formControlName="confirmPassword"
                                            type="text" required
                                            minlength="8"
                                            maxlength="12"
                                            [ngClass]="form.controls['confirmPassword'].invalid ? 'is-invalid' : ''"
                                            name="senha" placeholder="Confirmar senha"
                                            [ngClass]="{'focused': isPasswordFocused, 'unfocused': !isPasswordFocused}"
                                        />
                                    </ng-template>
                                    <mat-icon (click)="toggleConfirmPasswordVisibility()" *ngIf="visibilityConfirmPassword">visibility</mat-icon>
                                    <mat-icon (click)="toggleConfirmPasswordVisibility()" *ngIf="visibilityOffConfirmPassword">visibility_off</mat-icon>
                                </div>
                            </div>


                            <div class="form-validation-message mt-1">

                                <ul>
                                    <li class="text-white mt-4">
                                        <h5> Sua senha precisa conter:</h5>
                                    </li>
                                    <li>
                                        <label class="text-secondary d-flex align-items-center"
                                            *ngIf="form.controls['password'].invalid && !hasNumber()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>block</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Um número
                                            </span>
                                        </label>

                                        <label class="text-success d-flex align-items-center" *ngIf="hasNumber()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>done</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Um número
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label class="text-secondary d-flex align-items-center"
                                            *ngIf="form.controls['password'].invalid && !hasCapitalCase()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>block</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Uma letra maiúscula
                                            </span>
                                        </label>

                                        <label class="text-success d-flex align-items-center" *ngIf="hasCapitalCase()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>done</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Uma letra maiúscula
                                            </span>
                                        </label>
                                    </li>

                                    <li>
                                        <label class="text-secondary d-flex align-items-center"
                                            *ngIf="form.controls['password'].invalid && !hasSmallCase()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>block</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Uma letra minúscula
                                            </span>
                                        </label>

                                        <label class="text-success d-flex align-items-center" *ngIf="hasSmallCase()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>done</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Uma letra minúscula
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="text-secondary d-flex align-items-center"
                                            *ngIf="form.controls['password'].invalid && !hasLenght()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>block</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Entre 8 a 12 caracteres
                                            </span>
                                        </label>

                                        <label class="text-success d-flex align-items-center" *ngIf="hasLenght()">
                                            <span class="d-flex align-items-center">
                                                <mat-icon>done</mat-icon>
                                            </span>
                                            <span class="d-flex align-items-center">
                                                Entre 8 a 12 caracteres
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="text-danger d-flex align-items-center"
                                            *ngIf="form.controls['password'].hasError('WhitespaceFound')">
                                            <span class="">
                                                <mat-icon>block</mat-icon>
                                            </span>
                                            <span class="">
                                                Senha com espaços em branco
                                            </span>
                                        </label>
                                    </li>
                                    <li>
                                        <label class="text-danger d-flex align-items-center" *ngIf="comparePasswords()">
                                            <span class="">
                                                <mat-icon>block</mat-icon>
                                            </span>
                                            <span class="">
                                                Senhas divergentes
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                            </div>

                            <div class="mt-4">
                                <button type="submit">Redefinir</button>
                            </div>
                        </form>
                    </div>


                </div>
            </div>

            <div class="form-section d-flex align-items-center"
                *ngIf="modalMessage"
                [ngStyle]="{'background-image': 'url(assets/img/' + backgroundImage + ')'}">

                <div [ngClass]="{'form-content-forgout':changeForForgout, 'form-content':!changeForForgout}">

                    <div class="form-logo">
                        <img src="./assets/img/myra-icon.png">
                    </div>

                    <div class="d-flex justify-content-center align-items-center p-3 text-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                        </svg>
                    </div>

                    <div class="form-forms">
                        <div>
                            <h2 class="text-white text-center">Sua senha foi alterada</h2>
                            <p class="text-secondary text-center">Deseja efetuar o login com essa nova senha?</p>
                            <button (click)="GoToLogin()">Fazer login</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>