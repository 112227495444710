import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import { ImplantacaoComponent } from './view/implantacao.component';
import { ImplantacaoRoutingModule } from './implantacao-routing.module';
import { MatIconModule } from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ImplantacaoComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatBadgeModule,
    FormsModule,
    MatCardModule,
    MatDividerModule,
    MatProgressBarModule,
    ImplantacaoRoutingModule
  ]
})
export class ImplantacaoModule { }
