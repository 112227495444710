import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HeaderModel } from '@station/header';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

import { Hierarchy } from '../models/hierarchy';
import { HierarchyRequest } from '../models/hierarchyRequest';
import { PanelHierarchyService } from '../services/panelHierarchyService.service';
import { EmployeeChecklistAccordionItem } from '@station/employees';
import { Role, RolesDefinition } from 'src/app/shared/model/role.model';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})


export class ViewComponent implements OnInit {

  hierarchyForm: FormGroup;
  arrayCardHierarchy: Hierarchy[] = [];
  newHierarchy: Hierarchy = new Hierarchy()
  editHierarchy?: Hierarchy = new Hierarchy()
  roles: string[] = [];
  rolesResponse: Role[] = [];
  checklistItems: EmployeeChecklistAccordionItem[] = [];

  constructor(
    private panelHierarchyService: PanelHierarchyService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService) {
    this.hierarchyForm = this.formBuilder.group({
      id: '',
      order: '',
      role: '',
      roles: [],
      access: false,
      deactive: false,
    });
  }

  header: HeaderModel = new HeaderModel(this.authService.authUser.registrationId, this.authService.authUser.firstName + " " + this.authService.authUser.lastName, this.authService.authUser.jobPosition, false, this.authService.authUser.photoPath, true, "Buscar Funcionários");




  ngOnInit(): void {
    this.getAllHierarchys()
    this.getRoles()


  }
  getRoles() {
    this.authService.getAllRoles().subscribe(data => {

      this.rolesResponse = data
      this.checklistItems = this.generateChecklistItems(this.rolesResponse);
    });
  }
  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }
  generateChecklistItems(rolesResponse: Role[]): EmployeeChecklistAccordionItem[] {

    return rolesResponse.map((role: Role) => ({
      title: role.groupTitle,
      checkItems: role.rolesDefinition.map((roleDefinition: RolesDefinition) => ({
        id: roleDefinition.slug,
        description: roleDefinition.description,
        checked: this.editHierarchy?.roles != null ? this.editHierarchy?.roles.includes(roleDefinition.slug) : false
      })),
    }));
  }
  //#region Filter
  hierarchyFiltered: any = [];
  _filterList: string = "";

  get filterList(): string {
    return this._filterList;
  }
  set filterList(value: string) {
    this._filterList = value;
    this.hierarchyFiltered = this.filterList ? this.hierarchyFilter(this.filterList) : this.arrayCardHierarchy;
  }

  hierarchyFilter(filterFor: any): Hierarchy[] {
    filterFor = filterFor.toLocaleLowerCase();
    return this.arrayCardHierarchy.filter(
      (hierarchy: any) => hierarchy.role.toLocaleLowerCase().indexOf(filterFor) !== -1
    )
  }
  // #endregion Filter


  //#region Post and Upadate CardHierarchys
  saveHierarchy() {

    if(this.hierarchyForm.value.role == ""){
      this.toastr.warning("O descritivo é obrigatório")
      return;
    }

    if(this.hierarchyForm.pristine && this.roles.length == 0){
      this.toastr.info("Nada foi alterado")
      return;
    }


    if (this.hierarchyForm.value.id == '') {
      let hierarchyRequest = new HierarchyRequest(this.createHierarchy(), this.hierarchyForm.value.role, this.hierarchyForm.value.access, this.hierarchyForm.value.deactive, this.roles)
      this.panelHierarchyService['post'](hierarchyRequest)
        .subscribe({
          next: (newHierarchy: Hierarchy) => {
            this.getAllHierarchys()
            this.hierarchyForm.value
            this.toastr.success('Dados salvos com sucesso')
          },
          error: (err) => { console.log(err) },

        })
    } else {
      this.panelHierarchyService['put'](this.hierarchyForm.value)
        .subscribe({
          next: (newHierarchy: Hierarchy) => {
            this.getAllHierarchys()
            this.hierarchyForm.value
            this.toastr.success('Dados atualizado com sucesso')
          },
          error: (err) => { console.log(err) },

        }
        )
    }
  }
  // #endregion

  // #region Get all the Hierarchys
  public getAllHierarchys() {
    this.panelHierarchyService.getAllCardHierarchys().subscribe(
      (resp: Hierarchy[]) => {
        this.arrayCardHierarchy = resp;
        this.hierarchyFiltered = this.arrayCardHierarchy;
        this.arrayCardHierarchy.sort(this.ordenizer);
      }
    )
  }

  ordenizer(a: Hierarchy, b: Hierarchy) {
    return a.order - b.order
  }

  // #endregion Get all the Hierarchys

  // #region Create a new Hierarchy
  private createHierarchy() {
    if(this.hierarchyFiltered.length) {
    let lastArray:Hierarchy = this.hierarchyFiltered[this.hierarchyFiltered.length - 1];
    return lastArray?.order + 1;
  }else{
    return 1
  }
}
  // #endregion Create a new Hierarchy


  //#region find card-hierarchy
  public findHierarchy(value: number) {
    let card = this.arrayCardHierarchy.find((a) => a.order === value)
    this.editHierarchy = card;
    this.hierarchyForm.patchValue({ ...card })

    this.checklistItems = this.generateChecklistItems(this.rolesResponse);
  }
  //#endregion find card-hierarchy

  //#region clear form
  newForm() { }
  //#endregion clear form

  //#region replaceOrder
  replaceOrder() {
    this.arrayCardHierarchy.forEach((element, index) => {
      element.order = index + 1
    });
  }

  //#endregion replaceOrder
  //#region DragDropFunction
  hierarchyCompare: Hierarchy[] = []

  popularCompare() {
    for (let i = 0; i < this.arrayCardHierarchy.length; i++) {
      this.hierarchyCompare.push(this.arrayCardHierarchy[i])
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    this.popularCompare();
    moveItemInArray(this.hierarchyFiltered, event.previousIndex, event.currentIndex)
    this.replaceOrder()

    this.arrayCardHierarchy.map(((hierarchy, i) => {
      if (hierarchy.order != this.hierarchyCompare[i].order) {
        this.panelHierarchyService.put(hierarchy).subscribe({
          next: () => {}
        })
      }
    }))
    this.hierarchyCompare = [];
    this.toastr.success('Dados salvos no bancos', 'Atualizado com sucesso')
  }

  makeRandom(lengthOfCode: number) {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890,./;'[]=-)(*&^%$#@!~`";
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.round(Math.random() * possible.length));
    }
    return text;
  }
  //#endregion makeRandom

  checkedItems(event:any)
  {
    this.roles = event;
    this.toastr.info(`${this.roles.length} permissões atribuídas`)
  }
}
