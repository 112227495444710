<div class="content height-100 login">

        <div class="row height-100">
          <div class="box-left col-lg-4">
            <div class="row back-logo">
              <div class="col-lg-6 d-flex align-items-start">
                <picture>
                  <img src="./assets/img/myra-branco-fix-2.png">
                </picture>
              </div>
              <div class="col-lg-6">
                <span class="station">Station</span>
                <span class="perfomace-anywhere">Performance Anywhere</span>
              </div>
            </div>
            <div class="align-items-center height-100 d-flex">
              <h1>Performance Anywhere</h1>
            </div>
          </div>
      
          <div class="box-right col-lg-8">
            <div class="form-section d-flex align-items-center"
              [ngStyle]="{'background-image': 'url(assets/img/' + backgroundImage + ')'}">             
      
              <div class='form-content'>
      
                <div class="form-logo">
                  <img src="./assets/img/myra-icon.png">
                  <!-- <app-loading-animation></app-loading-animation> -->
                </div>
      
                <!-- <div *ngIf="showMensageError" class="d-flex justify-content-center">
                              <p>Usuario ou senha estão incorretas!</p>
                          </div> -->
      
                <div class="form-forms">
                  <form (ngSubmit)="submit()">
                    <div>
                      <label for="password">Instruções para realizar o cadastro 2FA</label>
                        <ol>
                          <li>
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Baixe o aplicativo <b>"Google Authenticator"</b> na loja de aplicativos do seu aparelho</a>
                            
                          
                          <li>
                            Abre o aplicativo <b>"Google Authenticator"</b> e clique no ícone de <b>"+"</b> na parte inferior
                          </li>
                          <li>
                            Selecione a opção <b>"Ler código QR"</b>
                          </li>
                          <li>
                            Aponte a câmera do seu aparelho para o QR code
                          </li>
                        </ol>
      
                    </div>
                    <div class="qr">
                      <img class="qr" src="{{qrCode}}" alt="">
                    </div>
                    <div>
                        <button type="submit">Voltar</button>                        
                    </div>
                  </form>
                </div>
      
              </div>
      
            </div>
          </div>
      
        </div>
</div>