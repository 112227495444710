import { NotificationService } from 'src/app/services/notification.service';
import { ImplementationModel } from './../model/implementation.model';
import { ImplementationService } from './../service/implementation.service';
import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

@Component({
  selector: 'station-implantacao',
  templateUrl: './implantacao.component.html',
  styleUrls: ['./implantacao.component.scss']
})
export class ImplantacaoComponent implements OnInit {
  implementationData!: ImplementationModel[];
  latestAgentVersion: string = '';
  countLatestAgentVersion: number = 0;
  latestPythonVersion: string = '';
  countLatestPythonVersion: number = 0;
  latestPlatform: string = '';
  globalVersionCounts: Record<string, number> = {};
  countLatestPlatform: number = 0;

  constructor(private implementationService: ImplementationService,
    private notificationService: NotificationService) { }


    private _filteredimplementationData: ImplementationModel[] = [];


    uniqueNetworkUser: string[] = [];
    uniquePlatform: string[] = [];
    uniqueFullNames: string[] = [];
    uniqueChiefs: string[] = [];
    uniqueGroupNames: string[] = [];
    uniqueAgentVersion: string[] = [];
    uniqueHostName: string[] = [];
    uniquePythonVersion: string[] = [];
    hours: string[] = [];
    showModalFilter!: boolean;
    showClearFilter!: boolean;

  
    selectedFullName: string = '';
    selectedChief: string = '';
    selectedPlatform: string = '';
    selectedGroupName: string = '';
    selectedAgentVersion: string = '';
    selectedPythonVersion: string = '';
    selectedNetworkUser: string = '';
    selectedHostName: string = '';
    searchText: string = '';

  ngOnInit(): void {

    this.getImplementationData();
    this.addEventClick()

    setInterval(()=>{
      this.getImplementationData();
    }, 30000);

  }
  objectEntries(obj: Record<string, number>): [string, number][] {
    return Object.entries(obj);
  }
  getImplementationData(): void {

    this.implementationService.getLoginLogoutLogs().subscribe(data => {

      const previousDataLength = this.implementationData ? this.implementationData.length : 0;

      this.implementationData = data.map(item => ({
        ...item,
        formattedStartDate: this.formatDate(item.startDate),
        formattedEndDate: item.endDate ? this.formatDate(item.endDate) : null
      })
      
      );

      this.applyFilters();
      this.generateUniqueArrays();

      const currentDataLength = this.implementationData.length;

      if (currentDataLength > previousDataLength) {

        const diff = currentDataLength - previousDataLength;

        const newUsers = this.implementationData.slice(0, diff).map(user => `🙋🏻‍♀️${user.fullname}`).join('\n');

        const message = `👧🏽👩🏻‍🦰👨🏾‍🦲🧔🏻👱🏻Novos usuários conectados:\n\n${newUsers}`;
        this.notificationService.sendSimpleNotification(message, "Station - Integration Monitor");
      }


      this.calculateLatestVersions();
    });
  }

  private formatDate(date: string): Date {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(adjustedDate.getHours() + 3);  // Add 3 hours
    return adjustedDate;
  }
  isDateToday(date: string): boolean {
    const today = new Date().setHours(0, 0, 0, 0);
    const inputDate = new Date(date).setHours(0, 0, 0, 0);
    return today === inputDate;
  }
  getLoggedUsersCount(): number {
    return this.implementationData != null ? this.implementationData.filter(item => !item.endDate).length : 0;
  }

  getOfflineUsersCount(): number {
    return this.implementationData != null ? this.implementationData.filter(item => item.endDate).length : 0;
  }

  calculateLatestVersions(): void {
    const agentVersions: string[] = this.implementationData.map(item => item.agentVersion);

    // Contar a quantidade de cada versão
    const versionCounts: Record<string, number> = {};
    agentVersions.forEach(version => {
      versionCounts[version] = (versionCounts[version] || 0) + 1;
    });

    // Ordenar as versões do menor para o maior
    const sortedVersions = Object.keys(versionCounts).sort((a, b) => versionCounts[a] - versionCounts[b]);

    if (sortedVersions.length > 0) {
      this.latestAgentVersion = sortedVersions[0];
      this.countLatestAgentVersion = versionCounts[this.latestAgentVersion];

      // Atribuir os resultados à variável global
      this.globalVersionCounts = versionCounts;
    }

    const pythonVersions: string[] = this.implementationData.map(item => item.pythonVersion);
    const uniquePythonVersions: string[] = [...new Set(pythonVersions)];

    if (uniquePythonVersions.length > 0) {
      this.latestPythonVersion = uniquePythonVersions[0];
      this.countLatestPythonVersion = pythonVersions.filter(version => version === this.latestPythonVersion).length;
    }

    const platformVersions: string[] = this.implementationData.map(item => item.platform);
    const uniquePlatformVersions: string[] = [...new Set(platformVersions)];

    if (uniquePlatformVersions.length > 0) {
      this.latestPlatform = uniquePlatformVersions[0];
      this.countLatestPlatform = platformVersions.filter(version => version === this.latestPlatform).length;
    }
  }
  truncate(value: string, maxLength: number): string {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    }
    return value;
  }


  addEventClick() {

    document.addEventListener('click', (event) => {

      const meuElemento = document.getElementById('modal-filter') as HTMLElement
      const currentEvent = event.target as HTMLElement

      if(meuElemento == null) return 
      if(currentEvent.classList.contains('filter')) return

      if (!meuElemento.contains(event.target as Node)) {

        this.showModalFilter = false
      }

    });
  }

  exportToExcel(): void {
    const colNames = [
      'Nome Completo',
      'Versão do agent',
      'Versão do Python',
      'Plataforma',
      'Data Inicial',
      'Data Final',
      'Duração',
      'Network',
      'Gestor direto',
      'Hostname',
      'Grupo de aplicação',
    ];

    const data = this.filteredimplementationData.map((detail) => [
      detail.fullname || '',
      detail.agentVersion || '',
      detail.pythonVersion || '',
      detail.platform || '',
      detail.startDate || '',
      detail.endDate || '',
      detail.duration || '',
      detail.networkUser || '',
      detail.chief || '',
      detail.hostname || '',
      detail.applicationGroup || ''
    ]);

    // Adicionar o cabeçalho aos dados
    const wsData = [colNames, ...data];

    // Criar uma planilha
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(wsData);

    // Criar um livro de trabalho
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Funcionários');

    // Exportar a planilha para um arquivo Excel
    XLSX.writeFile(wb, 'StationRelatórioFuncionario.xlsx');
  }


  generateUniqueArrays(): void {
  
    this.uniqueFullNames = Array.from(
      new Set(this.implementationData.map((item) => item.fullname))
    );
    this.uniqueChiefs = Array.from(
      new Set(this.implementationData.map((item) => item.chief))
    );
    this.uniquePlatform = Array.from(
      new Set(this.implementationData.map((item) => item.platform))
    );
    this.uniqueNetworkUser = Array.from(
      new Set(this.implementationData.map((item) => this.verifyValueNull(item.networkUser)))
    );
    this.uniqueGroupNames = Array.from(
      new Set(this.implementationData.map((item) => item.applicationGroup))
    );
    this.uniqueAgentVersion = Array.from(
      new Set(this.implementationData.map((item) => this.verifyValueNull(item.agentVersion)))
    );
    this.uniqueHostName = Array.from(
      new Set(this.implementationData.map((item) => this.verifyValueNull(item.hostname)))
    );
    this.uniquePythonVersion = Array.from(
      new Set(this.implementationData.map((item) => this.verifyValueNull(item.pythonVersion)))
    );
  }

  get filteredimplementationData(): ImplementationModel[] {
    return this._filteredimplementationData;
  }

  set filteredimplementationData(value: ImplementationModel[]) {
    this._filteredimplementationData = value;
  }

  clearFilters(): void {
    this.selectedFullName = '';
    this.selectedChief = '';
    this.selectedPlatform = '';
    this.selectedGroupName = '';
    this.selectedAgentVersion = '';
    this.selectedPythonVersion = '';
    this.selectedNetworkUser = '';
    this.selectedHostName = '';
    this.searchText = '';
    this.showClearFilter = false;
    this.applyFilters();
  }


  showModal() {
    this.showModalFilter = !this.showModalFilter;
  }

  verifyValueNull(value: string) {
    return value == null ? "-" : value;
  }

  applyFilters(): void {
    this.filteredimplementationData = this.implementationData.filter((item) => {
      return (
        this.filterByProperty(item.fullname, this.selectedFullName) &&
        this.filterByProperty(item.chief, this.selectedChief) &&
        this.filterByProperty(item.platform, this.selectedPlatform) &&
        this.filterByProperty(item.hostname, this.selectedHostName) &&
        this.filterByProperty(item.pythonVersion, this.selectedPythonVersion) &&
        this.filterByProperty(item.agentVersion, this.selectedAgentVersion) &&
        this.filterByProperty(item.applicationGroup, this.selectedGroupName) &&
        this.filterByProperty(item.networkUser, this.selectedNetworkUser) &&
        this.containsSearchText(item)
      );
    });
  }

  filterByProperty(itemProperty: string, selectedValue: string): boolean {
    if (!selectedValue) {
      return true;
    }
    return itemProperty === selectedValue;
  }

  containsSearchText(item: ImplementationModel): boolean {
    return Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

}
