export class ProfileEmployeeMinimalModel {

    public id: string
    public fullName: string

    constructor() {
        this.id = ""
        this.fullName = ""
    }
}

