import { Component, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import {
  ChartColumnGroupModel,
  ChartItemModel,
  ChartLineModel,
  ChartStackedModel,
  ChartType,
  DashStyleOption,
  SerieColumnGroupModel,
  SerieLineModel,
  SerieModel,
} from '@station/charts';
import {
  AlertSetting,
  BigNumberConfig,
  BigNumberIcon,
  BigNumberItemsModel,
  BigNumberModel,
  EmployeeBigCardModel,
  EmployeeModel,
  Semaphore,
} from '@station/employees';
import { HeaderModel } from '@station/header';

import { LoginResponse } from '../../login/model/login-response';
import { AuthService } from 'src/app/services/auth.service';
import { ColaboradorService } from '../services/colaborador.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { EmployeeBigCardModelSelector } from '../models/bigNumberItems.model';

import { Chart } from 'angular-highcharts';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  ChartDataModel,
  ChartItemDataModel,
} from '../models/charUnproductiveModel';
import { Periods } from '../../painel-supervisor/models/teamAvailabilityToday.model';
import { ApplicationService } from 'src/app/shared/components/table-aplications-utility/services/application.service';
import { Application } from 'src/app/shared/components/table-aplications-utility/models/application.model';
import { HubService } from 'src/app/services/hub.service';
import { ScreenshotResponseModel } from '../models/screenshot.model';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { UserNotification } from '../models/userNotification.model';
import { ApplicationTimeline } from 'src/app/shared/model/application-timeline';
import * as moment from 'moment';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  employeeProfile!: EmployeeModel;
  employeeId!: string | null;
  semaphore!: Semaphore;
  internetLastUpdate!: string;
  latencyLastUpdate!: string;
  showEmployeeList: boolean = false;
  Periods = Periods;
  availabilityChartsSelectOption: Periods = Periods.Day;
  productivityChartsSelectOption: Periods = Periods.Day;
  unproductivityChartsSelectOption: Periods = Periods.Day;
  connectionChartsSelectOption: Periods = Periods.Day;
  dateFiltered!: string;
  maxDate!: string;
  lastUpdate!: Date;
  dayLabel: string = 'Hoje';
  beforeDayLabel: string = 'Ontem';
  unproductiveArray: EmployeeBigCardModelSelector[] = [];
  screenshots: ScreenshotResponseModel[] = [];
  subjectUnproductive!: Subscription;
  productiveApplications: Application[] = [];
  public closeResult = '';
  period!: string;

  private unsubscribe$ = new Subject();
  private chartUnproductiveObservables = new Subject<ChartLineModel>();
  private chartUnproductiveArray: EmployeeBigCardModelSelector[] = [];
  public chartImprodutividade$!: Observable<ChartLineModel>;

  isEmployees = false;
  header: HeaderModel = new HeaderModel(
    '300062',
    'Heverton pablo',
    'Colaborador',
    false,
    'https://picsum.photos/100',
    true,
    'Buscar Funcionários'
  );
  bigNumberAvailabilitys1: BigNumberModel[] = [];
  bigNumberAvailabilitys2: BigNumberModel[] = [];
  bigNumberAvailabilitysStacked: BigNumberModel[] = [];
  bigNumberAvailabilitysConexion1: BigNumberModel[] = [];
  bigNumberAvailabilitysConexion2: BigNumberModel[] = [];
  bigNumberItems: BigNumberItemsModel[] = [];
  bigNumberHighLightTodayTeam!: BigNumberModel[];
  bigNumberHighLightConnection!: BigNumberModel[];
  employeeBigNumberHighlightsProductivity!: BigNumberModel[];

  //#region employeeCard
  employeeCard: EmployeeBigCardModel[] = [];
  employeeCardBigNumberA!: Observable<EmployeeBigCardModel>;
  employeeCardBigNumberB!: Observable<EmployeeBigCardModel>;
  employeeCardBigNumberC!: Observable<EmployeeBigCardModel>;

  userNotifications: UserNotification[] = [];
  countUnreadUserNotification: number = 0;

  //#endregion employeeCard
  authUser!: LoginResponse;
  authUser$!: BehaviorSubject<LoginResponse>;
  profile$!: Observable<EmployeeModel>;
  public chart!: Chart;

  applicationTimeline: ApplicationTimeline[] = [];
  filteredApplicationTimeline: ApplicationTimeline[] = [];
  minStartDateTimeline: Date | null = null;
  maxEndDateTimeline: Date | null = null;
  duracaoLogs: number = 0;
  showModalFilter!: boolean
  duracaoImprodutiva: number = 0;
  duracaoSemClassificacao: number = 0;
  duracaoProdutiva: number = 0;
  qtdLogs: number = 0;
  qtdImprodutiva: number = 0;
  qtdSemClassificacao: number = 0;
  qtdProdutiva: number = 0;


  uniqueCategory: string[] = [];
  uniqueClassification: string[] = [];
  uniqueDescription: string[] = [];
  uniqueTerm: string[] = [];

  selectedCategory: string = '';
  selectedClassification: string = '';
  selectedDescription: string = '';
  selectedTerm: string = '';
  searchText: string = '';



  constructor(
    private authService: AuthService,
    private colaboradorService: ColaboradorService,
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: ApplicationService,
    private hubService: HubService,
    private modalService: NgbModal
  ) { }


  verifyValueNull(value: string) {
    return value == null ? "-" : value;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.subjectUnproductive?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['filteredApplicationTimeline'] && changes['filteredApplicationTimeline'].currentValue) {
      this.applyFilters();
    }
  }

  ngOnInit(): void {
    moment.locale('pt-br');

    this.maxDate = new Date().toFormattedString();

    this.lastUpdate = new Date();

    this.getUrlParameters();

    this.getEmployee();

    this.addEventClick();

    this.chartImprodutividade$! =
      this.chartUnproductiveObservables.asObservable();
  }

  initLoadCharts() {
    this.getScreenshots();
    this.getCountUnreadUserNotifications();
    this.getUserNotifications();
    this.hubService.addToGroup(this.authUser.id);
    this.updateAvailabilityHighlights();
    this.updateProductivityHighlights();
    this.updateAvailabilityCharts();
    this.updateProductivityCharts();
    // this.updateUnproductivityCards();
    // this.updateUnproductivityCharts();
    this.updateUserApplicationList();
    this.updateApplicationTimeline();
    this.udpateConnectionHighlights();
    this.updateConnectionComparativeCards();
    this.updateConnectionCharts();
  }
  getGetQueryParam(term: string) {
    let dateFilter = null;

    this.route.queryParams.subscribe((params: any) => {
      dateFilter = params[term];
    });

    return dateFilter;
  }
  validarFormatoData(data: string | null | undefined): string | null {
    if (data == null || data == undefined) {
      return null;
    }

    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(data)) {
      return data;
    } else {
      return null;
    }
  }
  getUserNotifications(pageNumber: number = 1) {
    let loggedUserId = this.authService.authUser.id;

    this.colaboradorService
      .getNotificationByUserId(loggedUserId, pageNumber)
      .subscribe((response: any) => {
        this.userNotifications.length == 0
          ? (this.userNotifications = response)
          : (this.userNotifications = this.userNotifications.concat(response));
      });
  }
  getCountUnreadUserNotifications() {
    let loggedUserId = this.authService.authUser.id;

    this.colaboradorService
      .getCountUnreadNotificationByUserId(loggedUserId)
      .subscribe((response: any) => {
        this.countUnreadUserNotification = response;
      });
  }
  formatTimeOnly(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    let hours = this.padZero(date.getHours() + 3);
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());

    hours = hours == "24" ? "00" : hours;

    return `${hours}:${minutes}:${seconds}`;
  }

  formatDateTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    let hours = this.padZero(date.getHours() + 3);
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());

    hours = hours == "24" ? "00" : hours;

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  padZero(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
  timelineSummary(): void {
    // Inicializa minStartDate e maxEndDate como null
    this.minStartDateTimeline = null;
    this.maxEndDateTimeline = null;

    // Calcula minStartDate e maxEndDate usando reduce
    this.applicationTimeline.forEach((timeline) => {
      if (this.minStartDateTimeline === null || timeline.startDate < this.minStartDateTimeline) {
        this.minStartDateTimeline = timeline.startDate;
      }

      if (this.maxEndDateTimeline === null || timeline.endDate > this.maxEndDateTimeline) {
        this.maxEndDateTimeline = timeline.endDate;
      }
    });
  }
  calcularDuracaoPorClassificacao(): void {
    // Resetar variáveis globais para evitar acumulação de valores
    this.duracaoImprodutiva = 0;
    this.duracaoSemClassificacao = 0;
    this.duracaoProdutiva = 0;

    // Calcular a soma da duração para cada classificação específica
    this.applicationTimeline.forEach(item => {

      this.duracaoLogs += item.duration;
      this.qtdLogs += 1;

      if (item.classification === 'Improdutivo') {
        this.duracaoImprodutiva += item.duration;
        this.qtdImprodutiva += 1;
      } else if (item.classification === 'Sem classificação') {
        this.duracaoSemClassificacao += item.duration;
        this.qtdSemClassificacao += 1;
      } else if (item.classification === 'Produtivo') {
        this.duracaoProdutiva += item.duration;
        this.qtdProdutiva += 1;
      }
    });

  }

  showModal() {
    this.showModalFilter = !this.showModalFilter;
  }

  generateUniqueArrays(): void {
    this.uniqueCategory = Array.from(
      new Set(this.applicationTimeline.map((item) => item.category))
    );
    this.uniqueClassification = Array.from(
      new Set(this.applicationTimeline.map((item) => item.classification))
    );
    this.uniqueDescription = Array.from(
      new Set(this.applicationTimeline.map((item) => item.description))
    );
    this.uniqueTerm = Array.from(
      new Set(this.applicationTimeline.map((item) => this.verifyValueNull(item.term)))
    );
  }

  addEventClick() {

    document.addEventListener('click', (event) => {

      const meuElemento = document.getElementById('modal-filter') as HTMLElement
      const currentEvent = event.target as HTMLElement

      if (meuElemento == null) return
      if (currentEvent.classList.contains('filter')) return

      if (!meuElemento.contains(event.target as Node)) {

        this.showModalFilter = false
      }

    });
  }


  clearFilters(): void {
    this.selectedCategory = '';
    this.selectedClassification = '';
    this.selectedDescription = '';
    this.selectedTerm = '';
    this.searchText = ''
    this.applyFilters();
  }

  applyFilters(): void {
    this.filteredApplicationTimeline = this.applicationTimeline.filter((item) => {
      // Verificar se o item passa por todos os filtros selecionados
      return (
        this.filterByProperty(item.category, this.selectedCategory) &&
        this.filterByProperty(item.description, this.selectedDescription) &&
        this.filterByProperty(item.classification, this.selectedClassification) &&
        this.filterByProperty(item.term, this.selectedTerm) &&
        this.containsSearchText(item)
      );
    });
  }

  filterByProperty(itemProperty: string, selectedValue: string): boolean {
    // Se nenhum valor for selecionado, o item passa pelo filtro
    if (!selectedValue) {
      return true;
    }
    // Caso contrário, verifica se a propriedade do item é igual ao valor selecionado
    return itemProperty === selectedValue;
  }

  containsSearchText(item: ApplicationTimeline): boolean {
    // Retorna verdadeiro se o texto de pesquisa estiver presente em qualquer propriedade do item
    return Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  loadMoreUserNotification(pageNumber: number) {
    this.getUserNotifications(pageNumber);
  }
  getEmployee() {
    if (this.employeeId != null) {
      this.colaboradorService
        .getAgentById(this.employeeId)
        .subscribe((data: any) => {
          this.authUser = data;
          this.profile$ = this.setProfile(data);
          this.initLoadCharts();
        });
    } else {
      this.authUser = this.authService.authUser;
      this.setProfile(this.authService.authUser);
      this.initLoadCharts();
    }
  }

  setProfile(data: any): Observable<EmployeeModel> {
    let profile: EmployeeModel = {
      name: `${data.firstName} ${data.lastName}`,
      picture:
        data.photoPath.length > 0
          ? data.photoPath.length
          : '/assets/img/user-default.png',
      role: data.jobPosition,
      roles: this.authService.getRoles(),
      enabled: data.enabled,
      panel: false,
      badSignal: false,
      linkProfile: '',
      isOnline: data.isOnline,
      sleep: data.sleep,
      shouldBeCurrentlyWorking: false,
      linkDetail: '',
      hasFirstLogin: false,
      teamIsOnline: 0,
    };

    return new Observable<EmployeeModel>((sub: any) => sub.next(profile));
  }
  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
      .result.then(
        (result) => { },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }
  updateChartConnection(data: ChartLineModel) {
    this.colaboradorService.updateChartConnection(data);
  }

  employeeBigNumberProductivitys: BigNumberItemsModel[] = [];
  employeeBigNumberAvailabilitys: BigNumberItemsModel[] = [];

  setHighLightTodayTeam(data: any): BigNumberModel[] {
    let semaphore = this.authService.geSemaphoreByDescription(
      'Disponibilidade e Produtividade'
    );
    let danger = semaphore!.ranges.red;
    let warning = semaphore!.ranges.yellow;
    let success = semaphore!.ranges.green;

    let bigNumberAlert: AlertSetting = new AlertSetting(
      danger,
      warning,
      success
    );
    let config3: BigNumberConfig = new BigNumberConfig(false, false, true);

    let teamAvailability: BigNumberModel[] = [];

    let today = new BigNumberModel(
      data.today,
      '',
      '%',
      this.dayLabel,
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamAvailability.push(today);

    let yesterday = new BigNumberModel(
      data.yesterday,
      '',
      '%',
      this.beforeDayLabel,
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamAvailability.push(yesterday);

    let week = new BigNumberModel(
      data.week,
      '',
      '%',
      'Sem',
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamAvailability.push(week);

    let month = new BigNumberModel(
      data.month,
      '',
      '%',
      'Mês',
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamAvailability.push(month);

    return teamAvailability;
  }

  setHighlightsProductivityTeam(data: any): BigNumberModel[] {
    let semaphore = this.authService.geSemaphoreByDescription(
      'Disponibilidade e Produtividade'
    );
    let danger = semaphore!.ranges.red;
    let warning = semaphore!.ranges.yellow;
    let success = semaphore!.ranges.green;

    let bigNumberAlert: AlertSetting = new AlertSetting(
      danger,
      warning,
      success
    );
    let config3: BigNumberConfig = new BigNumberConfig(false, false, true);

    let teamHighlightsProductivity: BigNumberModel[] = [];

    let today = new BigNumberModel(
      data.today,
      'Produtividade Total',
      '%',
      this.dayLabel,
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamHighlightsProductivity.push(today);

    let yesterday = new BigNumberModel(
      data.yesterday,
      '',
      '%',
      this.beforeDayLabel,
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamHighlightsProductivity.push(yesterday);

    let week = new BigNumberModel(
      data.week,
      '',
      '%',
      'Sem',
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamHighlightsProductivity.push(week);

    let month = new BigNumberModel(
      data.month,
      '',
      '%',
      'Mês',
      BigNumberIcon.circle,
      bigNumberAlert,
      config3
    );
    teamHighlightsProductivity.push(month);

    return teamHighlightsProductivity;
  }
  private getUrlParameters(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.employeeId = params.get('id');
      let dateFilter = this.validarFormatoData(
        this.getGetQueryParam('date_filter')
      );
      let period = this.getGetQueryParam('period');

      this.dateFiltered = dateFilter ?? new Date().toFormattedString();

      this.changeDateFilter();
      this.changePeriodParam('day');

      if (period) {
        this.selectedPeriod(period);
      }
    });
  }

  //######################### BIG NUMBERS ################################################

  agentAnalysisTimeLoggedWeekly!: any;
  agentAnalysisTimeLoggedMonthly!: any;

  changeAgentAnalysisTimeLoggedWeekly(period: string) {
    switch (period) {
      case 'day':
        this.setAgentAnalysisTimeLoggedDaily().then((data) =>
          this.updateAgentAnalysisTimeLogged(data)
        );
        this.availabilityChartsSelectOption = Periods.Day;
        break;

      case 'week':
        this.setAgentAnalysisTimeLoggedWeekly().then((data) =>
          this.updateAgentAnalysisTimeLogged(data)
        );
        this.availabilityChartsSelectOption = Periods.Week;
        break;

      case 'month':
        this.setAgentAnalysisTimeLoggedMonthly().then((data) =>
          this.updateAgentAnalysisTimeLogged(data)
        );
        this.availabilityChartsSelectOption = Periods.Month;
        break;
    }
  }

  updateAgentAnalysisTimeLogged(data: ChartItemModel) {
    this.colaboradorService.updateAgentAnalysisTimeLogged(data);
  }

  private async setAgentAnalysisTimeLoggedDaily() {
    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getAgentAnalysisTimeLoggedDaily(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartComparativoTempoLogado(data);
  }

  private async setAgentAnalysisTimeLoggedWeekly() {
    if (this.agentAnalysisTimeLoggedWeekly) {
      return this.agentAnalysisTimeLoggedWeekly;
    }

    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getAgentAnalysisTimeLoggedWeekly(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartComparativoTempoLogado(data);
  }

  private async setAgentAnalysisTimeLoggedMonthly() {
    if (this.agentAnalysisTimeLoggedMonthly) {
      return this.agentAnalysisTimeLoggedMonthly;
    }

    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getAgentAnalysisTimeLoggedMonthly(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartComparativoTempoLogado(data);
  }
  formatTime(timeInSeconds: number): string {
    const timeInMinutes = Math.ceil(timeInSeconds / 60); // Arredondar para cima

    if (timeInSeconds < 60) {
      return '< 1min';
    } else if (timeInMinutes < 60) {
      return timeInMinutes + 'min';
    } else if (timeInMinutes < 1440) {
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = timeInMinutes % 60;
      return hours + 'h' + minutes + 'min';
    } else if (timeInMinutes < 10080) {
      const days = Math.floor(timeInMinutes / 1440);
      const hours = Math.floor((timeInMinutes % 1440) / 60);
      return days + 'd' + hours + 'h';
    } else if (timeInMinutes < 40320) {
      const weeks = Math.floor(timeInMinutes / 10080);
      const days = Math.floor((timeInMinutes % 10080) / 1440);
      return weeks + 'sem' + days + 'd';
    } else {
      const months = Math.floor(timeInMinutes / 40320);
      const weeks = Math.floor((timeInMinutes % 40320) / 10080);
      return months + 'mês' + weeks + 'sem';
    }
  }
  setConecctionAgentToModel(data: any): BigNumberModel[] {
    let semaphore =
      this.authService.geSemaphoreByDescription('Conexão (Banda)');
    let danger = semaphore!.ranges.red;
    let warning = semaphore!.ranges.yellow;
    let success = semaphore!.ranges.green;

    let bigNumberAlert: AlertSetting = new AlertSetting(
      danger,
      warning,
      success
    );
    let config3: BigNumberConfig = new BigNumberConfig(false, false, true);

    let connection: BigNumberModel[] = [];

    let today = new BigNumberModel(
      data.today,
      '',
      'mbps',
      'Hoje',
      BigNumberIcon.signal,
      bigNumberAlert,
      config3
    );
    connection.push(today);

    let yesterday = new BigNumberModel(
      data.yesterday,
      '',
      'mbps',
      'Ontem',
      BigNumberIcon.signal,
      bigNumberAlert,
      config3
    );
    connection.push(yesterday);

    let week = new BigNumberModel(
      data.week,
      '',
      'mbps',
      'Sem',
      BigNumberIcon.signal,
      bigNumberAlert,
      config3
    );
    connection.push(week);

    let month = new BigNumberModel(
      data.month,
      '',
      'mbps',
      'Mês',
      BigNumberIcon.signal,
      bigNumberAlert,
      config3
    );
    connection.push(month);

    return connection;
  }

  conexaoLatencia!: Observable<EmployeeBigCardModel>;
  conexaoInternet!: Observable<EmployeeBigCardModel>;

  setConnectionComparisionToModel(
    title: string,
    subtitle: string,
    iconInfo: boolean,
    today: number,
    percent: number,
    sufixCharacterValue: string,
    sufixCharacterValueArrow: string,
    backgroundColor: string,
    borderColor: string,
    isLatency: boolean
  ) {
    return new Observable<EmployeeBigCardModel>((sub: any) =>
      sub.next(
        new EmployeeBigCardModel(
          title,
          subtitle,
          iconInfo,
          today,
          percent,
          sufixCharacterValue,
          sufixCharacterValueArrow,
          backgroundColor,
          borderColor,
          isLatency
        )
      )
    );
  }

  changePeriod(period: String) {
    switch (period) {
      case 'day':
        this.setChartConnectionDaily().then((res) =>
          this.updateChartConnection(res)
        );
        this.connectionChartsSelectOption = Periods.Day;
        break;

      case 'week':
        this.setChartConnectionWeek().then((res) =>
          this.updateChartConnection(res)
        );
        this.connectionChartsSelectOption = Periods.Week;
        break;

      case 'month':
        this.setChartConnectionMonthly().then((res) =>
          this.updateChartConnection(res)
        );
        this.connectionChartsSelectOption = Periods.Month;
        break;
    }
  }

  chartConnectionDaily!: ChartLineModel;
  chartConnectionWeek!: ChartLineModel;
  chartConnectionMonthly!: ChartLineModel;

  private async setChartConnectionDaily() {
    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getChartConnectionDaily(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartConexao(data);
  }

  private async setChartConnectionWeek() {
    if (this.chartConnectionWeek) {
      return this.chartConnectionWeek;
    }

    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getChartConnectionWeekly(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartConexao(data);
  }

  private async setChartConnectionMonthly() {
    if (this.chartConnectionMonthly) {
      return this.chartConnectionMonthly;
    }

    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getChartConnectionMonthly(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartConexao(data);
  }

  chartConexao: Observable<ChartLineModel> =
    this.colaboradorService.currentchartConnection$!!;

  setChartConexao(data: any): ChartLineModel {
    let bandaData = this.getDataChart(data.internet);
    let latenciaData = this.getDataChart(data.latency);

    let serieHistoricoTempoLogadoLine4 = new SerieLineModel(
      bandaData.listData,
      'Banda',
      ChartType.line,
      DashStyleOption.Solid,
      '#39DBC9',
      3
    ); //#AF372F
    let serieHistoricoTempoLogadoLine5 = new SerieLineModel(
      latenciaData.listData,
      'Latência',
      ChartType.line,
      DashStyleOption.Solid,
      '#8C54FF',
      3
    ); //#E08976
    let seriesLine2: SerieLineModel[] = [
      serieHistoricoTempoLogadoLine4,
      serieHistoricoTempoLogadoLine5,
    ];

    let firstLabel =
      bandaData.firstLabel.length > latenciaData.firstLabel.length
        ? bandaData.firstLabel
        : latenciaData.firstLabel;
    let secondLabel =
      bandaData.secondLabel.length > latenciaData.secondLabel.length
        ? bandaData.secondLabel
        : latenciaData.secondLabel;

    return new ChartLineModel(
      seriesLine2,
      '',
      '',
      true,
      false,
      true,
      'mbps',
      'ms',
      '',
      '',
      firstLabel,
      secondLabel
    );
  }

  //#region ######################### CHARTS TEMPO LOGADO ################################################

  chartComparativoTempoLogado: Observable<ChartItemModel> =
    this.colaboradorService.agentAnalysisTimeLogged$!!;
  setChartComparativoTempoLogado(data: any): ChartItemModel {
    let ListData: any[] = [];
    let ListDataIdleness: any[] = [];
    let firstLabel: any[] = [];
    let secondLabel: any[] = [];

    for (let i = 0; i < data.length; i++) {
      ListData.push(data[i].availability);
      ListDataIdleness.push(data[i].idleness);
      firstLabel.push(data[i].label);

      if (data[i].referenceDate != null)
        secondLabel.push(data[i].referenceDate);
    }

    let serieHistoricoAvailability = new SerieModel(
      ListData,
      'Disponibilidade',
      ChartType.column,
      DashStyleOption.Solid,
      '#C4CACF',
      1
    );
    let serieHistoricoIdleness = new SerieModel(
      ListDataIdleness,
      'Ociosidade',
      ChartType.column,
      DashStyleOption.Solid,
      '#F77733',
      1
    );

    let series: SerieModel[] = [];

    if (serieHistoricoIdleness.data.find((x) => x != null) != undefined) {
      series.push(serieHistoricoIdleness);
    }

    if (serieHistoricoAvailability.data.find((x) => x != null) != undefined) {
      series.push(serieHistoricoAvailability);
    }

    let secundLabelData = secondLabel.length > 0 ? secondLabel : [];

    let model = new ChartItemModel(
      series,
      'Comparativo Histórico do Tempo Logado',
      '',
      true,
      '',
      '',
      'Hora(s)',
      firstLabel,
      secundLabelData
    );

    return model;
  }

  //#endregion

  //#region ######################### CHART JORNADA ################################################

  chartComparativoHistoricoJornada: Observable<ChartColumnGroupModel> =
    this.colaboradorService.chartComparativoHistoricoJornada$!!;
  GetComparativeJourneyWeekly!: any;
  GetComparativeJourneyMonthly!: any;

  changechartComparativoHistoricoJornada(period: String) {
    switch (period) {
      case 'day':
        this.setChangechartComparativoHistoricoJornadaDaily().then((res) =>
          this.updateChartComparativoHistoricoJornada(res)
        );
        this.productivityChartsSelectOption = Periods.Day;
        break;

      case 'week':
        this.setChangechartComparativoHistoricoJornadaWeekly().then((res) =>
          this.updateChartComparativoHistoricoJornada(res)
        );
        this.productivityChartsSelectOption = Periods.Week;
        break;

      case 'month':
        this.setChangechartComparativoHistoricoJornadaMonthly().then((res) =>
          this.updateChartComparativoHistoricoJornada(res)
        );
        this.productivityChartsSelectOption = Periods.Month;
        break;
    }
  }
  dateFilterClick(event: string[]) {

    if (event.length < 2) return

    let clickedData = event[1];


    if (!clickedData.includes('/')) {
      return;
    }
  }
  private async setChangechartComparativoHistoricoJornadaDaily() {
    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getComparativeJourneyDaily(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartComparativoHistoricoJornada(data);
  }

  private async setChangechartComparativoHistoricoJornadaWeekly() {
    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getComparativeJourneyWeekly(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data: any) => resolve(data));
    });

    return this.setChartComparativoHistoricoJornada(data);
  }

  private async setChangechartComparativoHistoricoJornadaMonthly() {
    const data = await new Promise((resolve) => {
      this.colaboradorService
        .getComparativeJourneyMonthly(this.authUser.id, this.dateFiltered)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => resolve(data));
    });

    return this.setChartComparativoHistoricoJornada(data);
  }

  setChartComparativoHistoricoJornada(data: any): ChartStackedModel {
    let predictedProductivityData: number[] = [];
    let availabilityData: number[] = [];
    let totalProductivityData: number[] = [];
    let categoriesLabel: string[] = [];
    let categoriesDate: string[] = [];
    let loggedProductivity: string[] = [];

    for (let i = 0; i < data.length; i++) {
      predictedProductivityData.push(data[i].estimatedJourney);
      availabilityData.push(data[i].availability);
      totalProductivityData.push(data[i].totalProductivity);
      categoriesLabel.push(data[i].label);
      if (data[i].referenceDate != null)
        categoriesDate.push(data[i].referenceDate);
      loggedProductivity.push(data[i].loggedProductivity == null ? "-" : `${data[i].loggedProductivity}%`);
    }

    let predictedProductivity = new SerieColumnGroupModel(
      predictedProductivityData,
      'Produtividade prevista na jornada',
      ChartType.column,
      DashStyleOption.Solid,
      '#E3E9EF',
      0,
      '',
      -0.2,
      false
    );
    let availability = new SerieColumnGroupModel(
      availabilityData,
      'Disponibilidade',
      ChartType.column,
      DashStyleOption.Solid,
      '#FF9300',
      0
    );
    let totalProductivity = new SerieColumnGroupModel(
      totalProductivityData,
      'Produtividade realizada na jornada',
      ChartType.column,
      DashStyleOption.Solid,
      '#F37021',
      0
    );
    let seriesStacked1: SerieColumnGroupModel[] = [
      predictedProductivity,
      availability,
      totalProductivity,
    ];

    let categoriesDateLabel = categoriesDate.length > 0 ? categoriesDate : [];

    let model = new ChartColumnGroupModel(
      seriesStacked1,
      'Comparativo Histórico na Jornada',
      '',
      true,
      '',
      '',
      'Hora(s)',
      'Prod.',
      '(no tempo logado)',
      categoriesLabel,
      categoriesDateLabel,
      loggedProductivity,
      '500px'
    );

    return model;
  }

  updateChartComparativoHistoricoJornada(data: ChartStackedModel) {
    this.colaboradorService.updateChartComparativoHistoricoJornada(data);
  }

  //#endregion

  setChartUnproductive(data: ChartDataModel) {
    this.chartUnproductiveArray = [];
    // day
    let dataDay = [
      data.productiveChangeUnproductive.day,
      data.unproductive.day,
      data.withoutClassification.day,
    ];
    this.chartUnproductiveArray.push({ Identifier: 'day', data: dataDay });

    // week
    let dataWeek = [
      data.productiveChangeUnproductive.week,
      data.unproductive.week,
      data.withoutClassification.week,
    ];
    this.chartUnproductiveArray.push({ Identifier: 'week', data: dataWeek });

    // month
    let dataMonth = [
      data.productiveChangeUnproductive.month,
      data.unproductive.month,
      data.withoutClassification.month,
    ];
    this.chartUnproductiveArray.push({ Identifier: 'month', data: dataMonth });
  }

  private getDataChart(data: any) {
    let listData: any = [];
    let firstLabel: any = [];
    let secondLabel: any = [];

    for (let i = 0; i < data.length; i++) {
      listData.push(data[i].value);
      firstLabel.push(data[i].label);
      if (data[i].referenceDate != null)
        secondLabel.push(data[i].referenceDate);
    }

    return { listData, firstLabel, secondLabel };
  }

  //#region  Unproductive

  private setCardsUnproductive(data: any) {
    // day
    //Ausência de mouse e teclado para + de 15 minutos
    let prodUnprodDay = this.generateEmployeeCard(
      'Produtiva com ociosidade',
      true,
      '#f1f1f1',
      '#da9227',
      data.productiveChangeUnproductive.day,
      data.productiveChangeUnproductive.beforeDay,
      'Total de tempo com teclado e mouse ocioso'
    );
    let unprodDay = this.generateEmployeeCard(
      'Improdutivas',
      false,
      '#f9e7e4',
      '#af372f',
      data.unproductive.day,
      data.unproductive.beforeDay,
      'Total de tempo em aplicações classificadas como improdutivas.'
    );
    let withioutDay = this.generateEmployeeCard(
      'Sem classificação',
      false,
      '#f1f1f1',
      '#b8b8b8',
      data.withoutClassification.day,
      data.withoutClassification.beforeDay,
      'Total de tempo em aplicações sem classificação.'
    );
    this.unproductiveArray = [];
    this.unproductiveArray.push({
      Identifier: 'day',
      data: [unprodDay, prodUnprodDay, withioutDay],
    });
  }

  public changePeriodCardsUnproductive(target: string) {
    switch (target) {
      case 'day':
        this.unproductivityChartsSelectOption = Periods.Day;
        break;
      case 'week':
        this.unproductivityChartsSelectOption = Periods.Week;
        break;
      case 'month':
        this.unproductivityChartsSelectOption = Periods.Month;
        break;
      default:
        break;
    }

    this.updateUnproductivityCharts();
  }
  changePeriodChartsUnproductive() {
    let item: EmployeeBigCardModelSelector | undefined;
    let target = 'day';
    item = this.unproductiveArray.find((i) => i.Identifier == target);
    this.employeeCardBigNumberA = item?.data[0];
    this.employeeCardBigNumberB = item?.data[1];
    this.employeeCardBigNumberC = item?.data[2];

    switch (this.unproductivityChartsSelectOption) {
      case Periods.Day:
        target = 'day';
        break;
      case Periods.Week:
        target = 'week';
        break;
      case Periods.Month:
        target = 'month';
        break;
      default:
        break;
    }

    this.generateChartdata(target);
  }

  private generateEmployeeCard(
    title: string,
    iconInfo: boolean,
    bgColor: string,
    borderColor: string,
    value: number,
    before: number,
    iconTitle: string = ''
  ) {
    let today = Math.round(value);
    let percent = today - Math.round(before);

    let model = new Observable<EmployeeBigCardModel>((sub: any) =>
      sub.next(
        new EmployeeBigCardModel(
          title,
          '',
          iconInfo,
          today,
          percent,
          '%',
          'p.p',
          bgColor,
          borderColor,
          true,
          iconTitle
        )
      )
    );

    return model;
  }

  //#endregion

  private generateChartdata(target: string) {
    let chart = this.chartUnproductiveArray.find((p) => p.Identifier == target);

    if (chart == null) return;
    this.generateChartLineModel(chart?.data[0], chart?.data[1], chart?.data[2]);
  }

  private generateChartLineModel(
    line1: ChartItemDataModel,
    line2: ChartItemDataModel,
    line3: ChartItemDataModel
  ) {
    let serieHistoricoTempoLogadoLine1 = new SerieLineModel(
      line2.values,
      'Improdutivas',
      ChartType.line,
      DashStyleOption.Solid,
      '#Af372f',
      3
    ); //#E08976
    let serieHistoricoTempoLogadoLine2 = new SerieLineModel(
      line1.values,
      'Produtivas com ociosidade',
      ChartType.line,
      DashStyleOption.Solid,
      '#DA9227',
      3
    ); //#AF372F
    let serieHistoricoTempoLogadoLine3 = new SerieLineModel(
      line3.values,
      'Sem classificação',
      ChartType.line,
      DashStyleOption.Solid,
      '#B8B8B8',
      3
    ); //#DA9227
    let categoriesLine1 = line1.keys;
    let seriesLine1: SerieLineModel[] = [
      serieHistoricoTempoLogadoLine1,
      serieHistoricoTempoLogadoLine2,
      serieHistoricoTempoLogadoLine3,
    ];

    let xAxis = line1.keysTwo != null ? line1.keysTwo : [];

    let model = new ChartLineModel(
      seriesLine1,
      '',
      '',
      true,
      false,
      false,
      '%',
      '',
      '',
      '',
      categoriesLine1,
      xAxis
    );

    this.chartUnproductiveObservables.next(model);
  }
  updateLabels() {
    this.dayLabel =
      this.dateFiltered == new Date().toFormattedString() ? 'Hoje' : 'Dia';
    this.beforeDayLabel =
      this.dateFiltered == new Date().toFormattedString()
        ? 'Ontem'
        : 'Dia anterior';
  }
  updateAvailabilityHighlights() {
    this.colaboradorService
      .getAgentAvailabilityToday(this.authUser.id, this.dateFiltered)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.bigNumberHighLightTodayTeam = this.setHighLightTodayTeam(data);
      });
  }
  updateProductivityHighlights() {
    this.colaboradorService
      .GetProductivityHighLightsByAgentId(this.authUser.id, this.dateFiltered)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: any) =>
        (this.employeeBigNumberHighlightsProductivity =
          this.setHighlightsProductivityTeam(data))
      );
  }
  updateAvailabilityCharts() {
    //Limpa o dados para ativar a animacao do loading
    this.colaboradorService.updateAgentAnalysisTimeLogged(null);

    switch (this.availabilityChartsSelectOption) {
      case Periods.Day:
        {
          this.setAgentAnalysisTimeLoggedDaily().then((data) =>
            this.updateAgentAnalysisTimeLogged(data)
          );
        }
        break;
      case Periods.Week:
        this.setAgentAnalysisTimeLoggedWeekly().then((data) =>
          this.updateAgentAnalysisTimeLogged(data)
        );
        break;
      case Periods.Month:
        this.setAgentAnalysisTimeLoggedMonthly().then((data) =>
          this.updateAgentAnalysisTimeLogged(data)
        );
        break;
      default:
        break;
    }
  }
  updateProductivityCharts() {
    //Limpa o dados para ativar a animacao do loading
    this.colaboradorService.updateChartComparativoHistoricoJornada(null);

    switch (this.availabilityChartsSelectOption) {
      case Periods.Day:
        {
          this.colaboradorService
            .getComparativeJourneyDaily(this.authUser.id, this.dateFiltered)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data: any) =>
              this.updateChartComparativoHistoricoJornada(
                this.setChartComparativoHistoricoJornada(data)
              )
            );
        }
        break;
      case Periods.Week:
        this.colaboradorService
          .getComparativeJourneyWeekly(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) =>
            this.updateChartComparativoHistoricoJornada(
              this.setChartComparativoHistoricoJornada(data)
            )
          );
        break;
      case Periods.Month:
        this.colaboradorService
          .getComparativeJourneyMonthly(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) =>
            this.updateChartComparativoHistoricoJornada(
              this.setChartComparativoHistoricoJornada(data)
            )
          );
        break;
      default:
        break;
    }
  }
  updateUnproductivityCards() {
    this.subjectUnproductive = this.colaboradorService
      .getCardUnproductive(this.authUser.id, this.dateFiltered)
      .subscribe({
        next: (data: any) => this.setCardsUnproductive(data),
      });
  }
  updateUnproductivityCharts() {
    switch (this.availabilityChartsSelectOption) {
      case Periods.Day:
        this.colaboradorService
          .getChartUnproductiveDaily(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) => {
            this.setChartUnproductive(data);
            this.changePeriodChartsUnproductive();
          });
        break;
      case Periods.Week:
        this.colaboradorService
          .getChartUnproductiveWeekly(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) => {
            this.setChartUnproductive(data);
            this.changePeriodChartsUnproductive();
          });
        break;
      case Periods.Month:
        this.colaboradorService
          .getChartUnproductiveMonthly(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) => {
            this.setChartUnproductive(data);
            this.changePeriodChartsUnproductive();
          });
        break;
      default:
        break;
    }
  }
  updateUserApplicationList() {
    this.applicationService
      .getApplicationsUser(this.authUser.id, this.dateFiltered)
      .subscribe((data: any) => {
        this.productiveApplications = data;
      });
  }
  updateApplicationTimeline(period: String = 'day') {
    let startDate = new Date();
    let startDateFormatted = this.dateFiltered;

    switch (period) {
      case 'day':
        startDateFormatted = this.dateFiltered;
        break;
      case 'week':
        startDate = new Date(this.dateFiltered);
        startDate.setDate(startDate.getDate() - 13);
        break;
      case 'month':
        startDate = new Date(this.dateFiltered);
        startDate.setDate(1);
        startDateFormatted = moment(new Date(startDate)).format('YYYY-MM-DD');
        break;
      default:
        // Defina um comportamento padrão se necessário
        break;
    }
    this.applicationService
      .getApplicationTimeline(
        this.authUser.id,
        startDateFormatted,
        this.dateFiltered
      )
      .subscribe((data: any) => {
        this.applicationTimeline = data;
        this.filteredApplicationTimeline = data
        this.generateUniqueArrays();
        this.timelineSummary();
        this.calcularDuracaoPorClassificacao();
      });
  }
  updateConnectionCharts() {
    //Limpa o dados para ativar a animacao do loading
    this.colaboradorService.updateChartConnection(null);

    switch (this.availabilityChartsSelectOption) {
      case Periods.Day:
        this.colaboradorService
          .getChartConnectionDaily(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) =>
            this.updateChartConnection(this.setChartConexao(data))
          );
        break;
      case Periods.Week:
        this.colaboradorService
          .getChartConnectionWeekly(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) =>
            this.updateChartConnection(this.setChartConexao(data))
          );
        break;
      case Periods.Month:
        this.colaboradorService
          .getChartConnectionMonthly(this.authUser.id, this.dateFiltered)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data: any) =>
            this.updateChartConnection(this.setChartConexao(data))
          );
        break;
      default:
        break;
    }
  }
  udpateConnectionHighlights() {
    this.colaboradorService
      .getConectionAgent(this.authUser.id, this.dateFiltered)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: any) =>
        (this.bigNumberHighLightConnection =
          this.setConecctionAgentToModel(data))
      );
  }
  updateConnectionComparativeCards() {
    this.colaboradorService
      .getConnectionComparision(this.authUser.id, this.dateFiltered)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.conexaoInternet = this.setConnectionComparisionToModel(
          'Banda',
          'agora',
          false,
          data?.internet?.today ?? 0,
          data?.internet?.percent ?? 0,
          ' mbps',
          'p.p',
          '#D7F7F4',
          '#39dbc9',
          false
        );
        this.conexaoLatencia = this.setConnectionComparisionToModel(
          'Latência',
          'agora',
          false,
          data?.latency?.today ?? 0,
          data?.latency?.percent ?? 0,
          ' ms',
          'p.p',
          '#e8ddff',
          '#8C54FF',
          true
        );

        this.internetLastUpdate = `Última captura: ${data?.internet?.lastUpdate}`;
        this.latencyLastUpdate = `Última captura: ${data?.latency?.lastUpdate}`;
      });
  }
  changeDateFilter() {
    const queryParams = { ...this.route.snapshot.queryParams };

    queryParams['date_filter'] = this.dateFiltered;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
  changePeriodParam(period: string) {
    const queryParams = { ...this.route.snapshot.queryParams };

    queryParams['period'] = period;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  updatePanels() {
    this.changeDateFilter();
    this.updateAvailabilityHighlights();
    this.updateProductivityHighlights();
    this.updateAvailabilityCharts();
    this.updateProductivityCharts();
    this.updateUnproductivityCards();
    this.updateUnproductivityCharts();
    this.updateUserApplicationList();
    this.updateApplicationTimeline();
    this.udpateConnectionHighlights();
    this.updateConnectionComparativeCards();
    this.updateConnectionCharts();
    this.updateLabels();
  }
  toMinutes(applications: Application[]): Application[] {
    const applicationsInMinutes = applications.map((app) => ({
      ...app,
      totalTime: app.totalTime / 60, // Convert seconds to minutes
    }));

    return applicationsInMinutes;
  }
  selectedPeriod(period: string) {
    this.changeAgentAnalysisTimeLoggedWeekly(period);
    this.changechartComparativoHistoricoJornada(period);
    this.changePeriodCardsUnproductive(period);
    this.changePeriod(period);
    this.changePeriodParam(period);
    this.updateApplicationTimeline(period);
  }
  getScreenshots() {
    this.colaboradorService
      .getScreenshots(this.authUser.id)
      .subscribe((data: any) => {
        this.screenshots = data;
      });
  }
  takeScreetshot() {
    this.hubService.takeScreenshot(this.authUser.id);

    // Adicione um atraso de 3 segundos antes de chamar o getScreenshots()
    setTimeout(() => {
      this.getScreenshots();
    }, 3000);
  }
  takeWebcamPicture() {
    this.hubService.takeWebcamPicture(this.authUser.id);

    // Adicione um atraso de 3 segundos antes de chamar o getScreenshots()
    setTimeout(() => {
      this.getScreenshots();
    }, 45000);
  }
  remoteShutdown() {
    this.hubService.remoteShutdown(this.authUser.id);
  }
  remoteRestart() {
    this.hubService.remoteRestart(this.authUser.id);
  }
}
