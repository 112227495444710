import { EmployeeModel } from "@station/employees";

export class ProfileEmployeeModel {

    public id: string
    public registrationId: string
    public networkUser: string
    public firstName: string
    public lastName: string
    public phone: string
    public photoPath?: string
    public chiefId: string
    public chief?: string
    public shiftPlanId: string
    public shiftPlan?: string
    public applicationGroupId: string
    public applicationGroup?: string
    public jobPositionId: string
    public jobPosition: string
    public companyId: string
    public email: string
    public roles: string[]
    public stationAgentInstalled: boolean;
    public stationAgentURLDownload: string;
    public stationAgentURLDownloadExpirationDate!: Date;
    public stationAgentInstallationDate!: Date
    public enabled: boolean
    public visible: boolean = true
    public newemployee: boolean = false;
    public employee!: EmployeeModel;
    public twoFactorKey: string;
    public twoFactorExpirateDate!: Date;
    public linkDetail!: string;
    public lastStationCheckin!: Date;
    public lastAgentLogin!: Date;
    

    public lastStationCheckinDate?: string;
    public lastAgentLoginDate?: string;
 

    constructor() {
        this.id = ""
        this.registrationId = ""
        this.networkUser = ""
        this.firstName = ""
        this.lastName = ""
        this.phone = ""
        this.photoPath = ""
        this.chiefId = ""
        this.shiftPlanId = ""
        this.applicationGroupId = ""
        this.jobPositionId = ""
        this.jobPosition = ""
        this.companyId = ""
        this.email = ""
        this.roles = []
        this.stationAgentInstalled = false;
        this.stationAgentURLDownload = "";
        this.twoFactorKey = "";
        this.enabled = true
        this.linkDetail = ""
    }


}

export class InformationDate {
    id!: string
    lastStationCheckinDate!: string;
    lastAgentLoginDate!: string


    constructor(
        id: string,
        lastStationCheckinDate: string,
        lastAgentLoginDate: string) {
            this.id = id,
            this.lastStationCheckinDate = lastStationCheckinDate,
            this.lastAgentLoginDate = lastAgentLoginDate
        }
}


export class SelectItemHierarchy {

    constructor(
        public id: string,
        public role: string,
        public order: number) { }
}

export interface SelectItem {
    value: string;
    label: string
}

export interface SelectItemApplicationGroup {

    groupName: string,
    id: string
}

export interface SelectItemJourney {
    id: string;
    description: string
}

