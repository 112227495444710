export class Summary {

    timeLoggedWithinTheExpectedTime!:  number;
    productivityWithinTheExpectedTime!:  number;
    productivityWithinAvailability!:  number;
    connection!: number

    expectedTime!: string;
    timeLoggedIn!: string;
    productiveTime!: string;
    unproductiveTime!: string;
    timeWithoutClassification!: string
}
