import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../models/company.models';


@Injectable({
  providedIn: 'root'
})

export class CompanyService {

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  public SendCompany(company: Company): Observable<Company> {
    return this.http.post<Company>(`${environment.companyApi}/api/v1/companies`, company);
  }
  public UpdateCompany(company: Company): Observable<Company> {
    return this.http.put<Company>(`${environment.companyApi}/api/v1/companies/${company.id}`, company);
  }
  public GetCompany(): Observable<Company[]> {
    return this.http.get<Company[]>(`${environment.companyApi}/api/v1/companies`);
  }
  public CheckTwoFactor(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.companyApi}/api/v1/companies/checkTwoFactor`);
  }
  public GetByIdCompany(id:string): Observable<Company> {
    return this.http.get<Company>(`${environment.companyApi}/api/v1/companies/${id}`);
  }
  public SuccessMessage(){
    this.toastr.success(
      "Sua empresa foi cadastrada com sucesso",
      "Empresa cadastrada",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMessage(){
    this.toastr.error(
      "Ops! parece que um erro ocorreu ao cadastrar essa empresa",
      "Erro ao cadastrar",
      {closeButton: true, progressBar: true},
      )
  }

}
