import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Semaphore } from '@station/employees';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AnimationModel } from '../models/animation-model';
import { CustomValidators } from '../validators/custom-validators';
import { ForgotPasswordRequest } from '../models/forgot-password-request.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  animations: [
    trigger('inputFocus', [
      state(
        'focused',
        style({
          fontSize: '18px', // Defina o tamanho desejado ao focar
        })
      ),
      state(
        'unfocused',
        style({
          fontSize: '14px', // Defina o tamanho desejado ao perder o foco
        })
      ),
      transition('unfocused => focused', [animate('0.3s ease-in-out')]),
      transition('focused => unfocused', [animate('0.3s ease-in-out')]),
    ]),
    trigger('typedAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ]),
      transition('* => *', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ]),
    ]),
    // ... outras animações
  ],
})
export class ViewComponent implements OnInit {
  changeForForgout: boolean = false;
  twoFactorEnabled: boolean = false;

  visibilityPassword: boolean = false
  visibilityOffPassword: boolean = false
  modalMessage: boolean = false

  visibilityConfirmPassword: boolean = false
  visibilityOffConfirmPassword: boolean = false

  images: string[] = ['bg1.jpg', 'bg2.jpg', 'bg3.jpg', 'bg4.jpg', 'bg5.jpg'];
  backgroundImage: string = 'bg2.jpg';
  semaphore!: Observable<Semaphore[]>;
  returnUrl!: string | null;
  isPasswordField: boolean = true;
  isConfirmPasswordFild: boolean = true
  words: string[] = [
    'performance anywhere.',
    'performance em qualquer lugar.',
    'どこでもパフォーマンス。',
    'rendimiento en cualquier lugar.',
    'performance partout.',
    'Leistung überall.',
    'boost your productivity anywhere you go.',
    'aumente sua produtividade onde quer que você vá.',
    'どこに行っても生産性向上。',
    'aumenta tu productividad donde sea que vayas.',
    'augmentez votre productivité où que vous soyez.',
    'Steigern Sie Ihre Produktivität, egal wo Sie sind.',
    'unleash performance without boundaries, anywhere.',
    'libere o desempenho sem limites, em qualquer lugar.',
    'どこでも制限なしのパフォーマンスを解放。',
    'libera el rendimiento sin límites, en cualquier lugar.',
    'libérez la performance sans limites, partout.',
    'Leistung ohne Grenzen freisetzen, überall.',
    'elevate your performance everywhere.',
    'eleve seu desempenho em todos os lugares.',
    'いたるところであなたのパフォーマンスを向上させる。',
    'desempenho que se adapta a você, onde quer que esteja.',
    'élevez votre performance partout.',
    'Ihre Leistung überall verbessern.',
    'performance that adapts to you, wherever you are.',
    'seu desempenho, nossa prioridade, em qualquer lugar.',
    'あなたがいる場所に適応するパフォーマンス。',
    'tu rendimiento, nuestra prioridad, en cualquier lugar.',
    "performance qui s'adapte à vous, où que vous soyez.",
    'Leistung, die sich Ihnen anpasst, egal wo Sie sind.',
    'votre performance, notre priorité, partout.',
    'your performance, our priority, anywhere.',
    'あなたのパフォーマンス、私たちの優先事項、どこでも。',
    'performance anywhere.',
    'performance em qualquer lugar.',
    'どこでもパフォーマンス。',
    'rendimiento en cualquier lugar.',
    'performance partout.',
    'Leistung überall.',
  ];

  animations: AnimationModel[] = [
    {
      BackgroundColor: '#000',
      Filename: 'connection.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'global.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'face.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'blocks.gif',
    },
    {
      BackgroundColor: '#030303',
      Filename: 'woman.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'web.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'spectrum.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'water.gif',
    },
  ];
  currentWord: string = this.words[0];
  currentAnimation: AnimationModel = this.animations[0];
  typedWord: string = '';
  isUsernameFocused: boolean = false;
  isPasswordFocused: boolean = false;


  form!: FormGroup
  enabledValidation!: boolean;
  forgotPasswordRequest!: ForgotPasswordRequest;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private toastr: ToastrService, private router: Router) { }


  ngOnInit() {

    this.InitForm()
    let ran = Math.round((Math.random() * 100) % 5);

    this.backgroundImage = this.images[ran] == undefined ? 'bg2.jpg' : this.images[ran];

    this.getReturnUrl();

  }

  nextWord() {
    const currentIndex = this.words.indexOf(this.currentWord);
    const nextIndex = (currentIndex + 1) % this.words.length;
    this.currentWord = this.words[nextIndex];
    this.animateTyping();
  }
  nextAnimation() {
    const currentIndex = this.animations.indexOf(this.currentAnimation);
    const nextIndex = (currentIndex + 1) % this.words.length;
    this.currentAnimation = this.animations[nextIndex];
  }
  animateTyping() {
    const fullWord = this.currentWord;

    const typeNextLetter = (index: number) => {
      if (index <= fullWord.length) {
        this.typedWord = fullWord.substring(0, index);
        setTimeout(() => typeNextLetter(index + 1), 100);
      }
    };

    typeNextLetter(0);
  }


  public InitForm() {

    this.form = this.formBuilder.group({
      password: [null, Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 6. Has a minimum length of 8 and 12 characters
        CustomValidators.patternValidator(/.{8,12}/, { hasLenght: true }),
        CustomValidators.whitespaceValidator
      ])
      ],
      confirmPassword: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(12)])]
    },

      {
        validator: [CustomValidators.passwordMatchValidator, CustomValidators.usedPasswordValidator]
      })


  }


  hasNumber() {
    const password = this.form.controls['password'].value
    return /\d/.test(password);
  }

  hasCapitalCase() {
    const password = this.form.controls['password'].value
    return /[A-Z]/.test(password);
  }

  hasSmallCase() {
    const password = this.form.controls['password'].value;
    return password == null ? false : /[a-z]/.test(password);
  }

  hasLenght() {
    const password = this.form.controls['password'].value;
    return /.{8,12}/.test(password);
  }

  hasNoWhitespace() {
    const password = this.form.controls['password'].value;
    return !/\s/.test(password);
  }


  comparePasswords() {
    const password = this.form.controls['password'].value;
    const confirmPassword = this.form.controls['confirmPassword'].value;
    return password == null || confirmPassword == null ? false : password != confirmPassword
  }


  changePassword(): void {

    if (this.form.valid) {
      this.forgotPasswordRequest = Object.assign(this.form.value)

      let forgotPasswordRequest: ForgotPasswordRequest = {
        confirmationLink: this.getCurrentUrl(),
        password: this.forgotPasswordRequest.password
      }

      this.authService.forgotPassword(forgotPasswordRequest).subscribe({
        next: () => {
          this.modalMessage = true
        },
        error: error => {
          let errorMsg = error?.error?.message ?? "Ops! Parece que um erro ocorreu ao alterar a senha"
          this.errorMessage(errorMsg)
        }
      })

    }
    else {
      this.toastr.error("Siga corretamente os requisitos da senha.", "Formato de senha incorreta!")
    }


  }
  successMessage() {
    this.toastr.success(
      "Sua Senha foi alterada com sucesso",
      "Alteração de senha",
      { closeButton: true, progressBar: true },
    )
  }

  errorMessage(errorMessage: string) {
    this.toastr.error(
      errorMessage,
      "Erro ao cadastrar",
      { closeButton: true, progressBar: true },
    )
  }

  GoToLogin(){
    this.router.navigate(['/login'])
  }


  getReturnUrl() {
    this.returnUrl = this.authService.getReturnUrl();

    this.returnUrl = this.returnUrl == '/' ? null : this.returnUrl;

    if (this.returnUrl) {
      this.toastr.info(
        `Primeiro é necessário fazer o login para depois acessar [${this.returnUrl}]`
      );
    }
  }


  getCurrentUrl() {
    return window.location.href;
  }

  togglePasswordVisibility() {
    this.isPasswordField = !this.isPasswordField;
    this.visibilityOffPassword = !this.visibilityOffPassword
    this.visibilityPassword = !this.visibilityPassword
  }

  toggleConfirmPasswordVisibility(){
    this.isConfirmPasswordFild = !this.isConfirmPasswordFild;
    this.visibilityOffConfirmPassword = !this.visibilityOffConfirmPassword
    this.visibilityConfirmPassword = !this.visibilityConfirmPassword
  }

  verifiyFildPassword(){
    const password = this.form.controls['password'].value;
    this.visibilityOffPassword = password != "" ? true : false
  }


  verifiyFildPasswordText(){
    const password = this.form.controls['password'].value;
    this.visibilityPassword = password != "" ? true : false
  }


  verifiyFildConfirmPassword(){
    const confirmPassword = this.form.controls['confirmPassword'].value;
    this.visibilityOffConfirmPassword = confirmPassword != "" ? true : false
  }

  verifiyFildConfirmPasswordText(){
    const confirmPassword = this.form.controls['confirmPassword'].value;
    this.visibilityConfirmPassword = confirmPassword != "" ? true : false
  }

}
