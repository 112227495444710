import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() { }

  requestPermission(): Promise<NotificationPermission> {
    return Notification.requestPermission();
  }
  sendSimpleNotification(message: string, title:string = '[Station] Nova mensagem') {
    // Solicitar permissão de notificação (opcional, pode ser feito em outro lugar)
    this.requestPermission()
      .then(permission => {
        if (permission === 'granted') {
          // Enviar notificação
          this.sendNotification(title, {
            body: message,
            icon: './assets/img/myra-icon.png', // Opcional: caminho para um ícone da notificação
          });
        }
      });
  }
  sendNotification(title: string, options?: NotificationOptions): void {
    if (Notification.permission === 'granted' && document.hidden) {
      new Notification(title, options);
    }
  }
}
