<div class="perfil-empresa">
    <station-header [headerModel]="header"></station-header>

    <div class="perfil-empresa_content">

        <div class="sidbar">
            <station-sidebar></station-sidebar>
        </div>

        <div class="content">
            <div class="title">Perfil da empresa </div>

            <div class="form_content">
                <form action="" (ngSubmit)="SendCompany()" [formGroup]="form">
                    <div class="station-painel-form">
                        <div class="station-painel-form-company">
                            <div class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="nome">Nome:</label>
                                        <input type="hidden" formControlName="id"/>
                                    </div>
                                    <div>
                                        <input formControlName="name" class="station-form-control" type="text" id="nome"
                                            placeholder="Nome" required />
                                    </div>
                                </div>
                            </div>
                            <div class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="cnpj">CNPJ:</label>
                                    </div>
                                    <div>
                                        <input formControlName="cnpj" class="station-form-control" type="text" id="cnpj"
                                            required maxlength="20" mask="00.000.000/0000-00"
                                            [dropSpecialCharacters]="false" [showMaskTyped]="true"
                                            placeHolderCharacter="0" />
                                    </div>
                                </div>
                            </div>
                            <div class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="contato">Contato:</label>
                                    </div>
                                    <div>
                                        <input formControlName="contact" class="station-form-control" type="text"
                                            id="contato" required placeholder="Contato" />
                                    </div>
                                </div>
                            </div>
                            <div class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="telefone">Telefone:</label>
                                    </div>
                                    <div>
                                        <input formControlName="phone" class="station-form-control" type="text"
                                            id="telefone" required mask="(00) 00000-0000" [dropSpecialCharacters]="false"
                                            [showMaskTyped]="true" placeHolderCharacter="0" />
                                    </div>
                                </div>
                            </div>
                            <div class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="email">Email:</label>
                                    </div>
                                    <div>
                                        <input formControlName="email" class="station-form-control" type="email" id="email"
                                            placeholder="email@gmail.com" required />
                                    </div>
                                </div>
                            </div>
                            <div class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="seguimento">Seguimento:</label>
                                    </div>
                                    <div>
                                        <select formControlName="segment" class="station-form-control" id="seguimento"
                                            required>
                                            <option value="">Seguimento</option>
                                            <option value="publicidade">Publicidade</option>
                                            <option value="Marketing">Marketing</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="descricao">Descrição:</label>
                                    </div>
                                    <div>
                                        <textarea formControlName="description" class="station-form-control" id="descricao"
                                            cols="40" rows="5" required></textarea>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="hasRole('perfil-da-empresa-2fa')" class="station-form-group">
                                <div class="station-form-flex">
                                    <div>
                                        <label class="station-form-label" for="descricao">Duplo fator de autenticação (2FA):</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" formControlName="twoFactorEnabled" class="station-form-control" id="twoFactorEnabled" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="station-painel-file">
                            <label for="images" class="drop-container" title="Enviar logo tipo">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#dee2e5"
                                        class="bi bi-cloud-upload" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                                        <path fill-rule="evenodd"
                                            d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                                    </svg>
                                </span>
                                <span class="drop-title">
                                    Enviar logotipo
                                </span>
                                <span class="input-title">
                                    <input formControlName="logoPath" type="file" id="images" accept="image/*" required>
                                </span>
                            </label>

                            <div class="station-painel-info">
                                <p>Cliente desde 12/09/2022 as 23:45</p>
                                <p>Total de usuários: 567</p>
                            </div>

                            <div class="station-painel-link">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-file-earmark-post" viewBox="0 0 16 16">
                                        <path
                                            d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                                        <path
                                            d="M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                    <a href="#">Contratar novos produtos</a>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-file-earmark-post" viewBox="0 0 16 16">
                                        <path
                                            d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                                        <path
                                            d="M4 6.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-7zm0-3a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 0 1H4.5a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                    <a href="#">Cancelar assinatura</a>
                                </div>
                            </div>
                            <div class="station-subimit">
                                <input type="submit" value="Salvar">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div>
