import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { TeamReportModel } from './models/team-report-mode';

import { AlertSetting, EmployeeDataModel } from '@station/employees';
import { Summary } from './models/Summary';

import { TeamReportService } from './services/table-team-report.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';


@Component({
  selector: 'station-table-team-report',
  templateUrl: './table-team-report.component.html',
  styleUrls: ['./table-team-report.component.scss'],
})
export class TableTeamReportComponent implements OnInit, OnChanges {


  verifyValueNull(value: string) {
    return value == null ? "-" : value;
  }

  @Input() team: TeamReportModel[] = [];
  @Input() dateFiltered!: string;
  @Input() subtitle!: string;
  @Input() isExport!: boolean;
  @Input() alertSetting: AlertSetting = new AlertSetting(0, 0, 0);
  @Output() resetReportMyTeam = new EventEmitter<string>();
  @Output() changePropety = new EventEmitter<boolean>();
  @Output() getMyTeamReportByRangeTime = new EventEmitter<string>();

  private _filteredTeam: TeamReportModel[] = [];

  uniqueEmployeeIds: string[] = [];
  uniqueFullNames: string[] = [];
  uniqueChiefs: string[] = [];
  uniqueShiftPlans: string[] = [];
  uniqueJobPositions: string[] = [];
  uniqueGroupNames: string[] = [];
  uniqueAgentVersions: string[] = [];
  uniqueHostName: string[] = [];
  uniqueStatuses: string[] = [];
  hours: string[] = [];
  showModalFilter!: boolean;
  showClearFilter!: boolean;

  summary: Summary = new Summary();

  showProductivityData: boolean = true;
  showRegistrationData: boolean = false;

  currentSubtitleHour: string = '(00:00 até 23:59)';

  selectable = true;
  removable = true;
  addOnBlur = true;
  terms: any[] = [];
  showFilter!: boolean;
  showSubtitle!: boolean;

  selectedEmployeeId: string = '';
  selectedFullName: string = '';
  selectedChief: string = '';
  selectedShiftPlan: string = '';
  selectedJobPosition: string = '';
  selectedHour: string = ''
  selectedGroupName: string = '';
  selectedAgentVersion: string = '';
  selectedHostName: string = '';
  selectedStatus: string = '';
  searchText: string = '';


  constructor(private teamReportService: TeamReportService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['team'] && changes['team'].currentValue) {
      this.applyFilters();

      this.setSummary();
      this.generateUniqueArrays();
    }


    if(this.isExport){
      this.exportToExcel()
      this.changePropety.emit(false)
    }

    this.setFilterHour();

    if (this.terms.length > 1) {
      this.terms = [];
      this.terms.push(this.subtitle);
    }

  }

  resetReport() {
    this.setConfigurationDefaultFilterTime();
    this.setSubtitle("00:00", "23:59")
    this.resetReportMyTeam.emit('reset');
  }

  ngOnInit(): void {
    this.setHour()
    this.setFilterHour()
    this.addEventClick()
  }


  calculateLoggedTimePercentage(firstValue: string, secondValue: string): number {
    // Convert expectedTime and loggedTime to minutes
    const firstValueMinutes = this.convertTimeToMinutes(firstValue);
    const secondValueMinutes = this.convertTimeToMinutes(secondValue);

    // Check if secondValueMinutes is zero to avoid division by zero
    if (secondValueMinutes === 0) {
      return 0; // Return 0 if secondValueMinutes is zero
    }

    // Calculate the percentage
    const percentage = Math.round((firstValueMinutes / secondValueMinutes) * 100);

    return percentage;
  }

  addEventClick() {

    document.addEventListener('click', (event) => {

      const meuElemento = document.getElementById('modal-filter') as HTMLElement
      const currentEvent = event.target as HTMLElement

      if (meuElemento == null) return
      if (currentEvent.classList.contains('filter')) return

      if (!meuElemento.contains(event.target as Node)) {

        this.showModalFilter = false
        this.showClearFilter = !this.checkNoFilter()
      }

    });

  }


  checkNoFilter(): boolean {
    if (
      this.selectedEmployeeId === '' &&
      this.selectedFullName === '' &&
      this.selectedChief === '' &&
      this.selectedShiftPlan === '' &&
      this.selectedJobPosition === '' &&
      this.selectedGroupName === '' &&
      this.selectedStatus === '' &&
      this.searchText === '' &&
      this.selectedHour === '' &&
      this.selectedAgentVersion === '' &&
      this.selectedHostName === ''
    ) {
      return true
    } else {
      return false
    }
  }

  changeValueSelectHour(hour: string) {
    let currentHour = this.hours.find((item) => item.includes(hour)) || '';
    this.selectedHour = currentHour;
  }

  convertTimeToMinutes(time: string): number {

    time = this.getHours(time);

    // Split the time string by ":" to get hours and minutes
    const [hoursStr, minutesStr] = time.split(':');

    // Convert hours to minutes and add to the minutes
    const totalMinutes = Math.round(parseInt(hoursStr) * 60 + parseInt(minutesStr));

    return totalMinutes;
  }

  getHours(hour: string) {
    return hour ?? "00:00";
  }
  setPecents(value: number): EmployeeDataModel {

    return new EmployeeDataModel(
      value,
      "%",
      this.alertSetting
    );
  }

  exportToExcel(): void {
    this.showProductivityData ?
      this.generateExcelToTableProductivityData() :
      this.generateExcelToTableRegistrationData()
  }



  generateExcelToTableRegistrationData() {
    const colNames = [
      'Status',
      'Gestor',
      'Nome Completo',
      'Tempo logado',
      'Cargo',
      'Jornada',
      'Grupo de ferramentas',
      'Hostname',
      'Versão do Agent',
    ];


    const data = this.filteredTeam.map((detail) => [
      detail.status || '',
      detail.chief || '',
      detail.fullName || '',
      detail.loggedTime || '',
      detail.jobPosition || '',
      detail.shiftPlan || '',
      detail.groupName || '',
      detail.hostName || '',
      detail.agentVersion || '',
    ]);

    // Adicionar o cabeçalho aos dados
    const wsData = [colNames, ...data];

    // Criar uma planilha
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(wsData);

    // Criar um livro de trabalho
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Dados Cadastrais');

    // Exportar a planilha para um arquivo Excel
    XLSX.writeFile(wb, 'Visão-Geral.xlsx');
  }

  showOrHiddenSummaryValue(table: HTMLElement, value: string) {

    var element = table.getElementsByClassName("summary-value")

    for (var i = 0; i < element.length; i++) {
      var item = element.item(i) as HTMLElement
      item.style.display = value
    }
  }

  generateExcelToTableProductivityData() {


    const table = document.getElementById('table-ProductivityData') as HTMLElement

    //this.showOrHiddenSummaryValue(table, 'none')

    const ws = XLSX.utils.table_to_sheet(table);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Dados Cadastrais');

    XLSX.writeFile(wb, 'Visão-Geral.xlsx');

    //this.showOrHiddenSummaryValue(table, 'block')


  }


  setFilterHour() {
    if (!this.subtitle.includes('(00:00 até 23:59)')) {
      this.terms.push(this.subtitle);
      this.showFilter = true;
      this.showSubtitle = false;

      const regex = /\((\d{2}:\d{2})/;
      const match = this.subtitle.match(regex);

      if (match && match[1]) {
        const hour = match[1];
        this.changeValueSelectHour(hour)
      }

    } else {
      this.setConfigurationDefaultFilterTime();
    }
  }

  remove(term: any): void {
    const index = this.terms.indexOf(term);

    if (index >= 0) {
      this.terms.splice(index, 1);
    }
    this.setConfigurationDefaultFilterTime();
    this.replaceSubtitle();
    this.selectedHour = ''
  }

  replaceSubtitle() {
    const regex = /\([^)]+\)/;
    let formatedSubtile = this.subtitle.replace(
      regex,
      this.currentSubtitleHour
    );
    this.subtitle = formatedSubtile;
  }

  setConfigurationDefaultFilterTime() {
    this.showSubtitle = true;
    this.showFilter = false;
  }

  generateUniqueArrays(): void {
    this.uniqueEmployeeIds = Array.from(
      new Set(this.team.map((item) => item.employeeId))
    );
    this.uniqueFullNames = Array.from(
      new Set(this.team.map((item) => item.fullName))
    );
    this.uniqueChiefs = Array.from(
      new Set(this.team.map((item) => item.chief))
    );
    this.uniqueShiftPlans = Array.from(
      new Set(this.team.map((item) => item.shiftPlan))
    );
    this.uniqueJobPositions = Array.from(
      new Set(this.team.map((item) => item.jobPosition))
    );
    this.uniqueGroupNames = Array.from(
      new Set(this.team.map((item) => item.groupName))
    );
    this.uniqueAgentVersions = Array.from(
      new Set(this.team.map((item) => this.verifyValueNull(item.agentVersion)))
    );
    this.uniqueHostName = Array.from(
      new Set(this.team.map((item) => this.verifyValueNull(item.hostName)))
    );
    this.uniqueStatuses = Array.from(
      new Set(this.team.map((item) => item.status))
    );
  }

  get filteredTeam(): TeamReportModel[] {
    return this._filteredTeam;
  }

  set filteredTeam(value: TeamReportModel[]) {
    this._filteredTeam = value;
  }

  clearFilters(): void {
    this.selectedEmployeeId = '';
    this.selectedFullName = '';
    this.selectedChief = '';
    this.selectedShiftPlan = '';
    this.selectedJobPosition = '';
    this.selectedGroupName = '';
    this.selectedAgentVersion = '';
    this.selectedHostName = '';
    this.selectedStatus = '';
    this.searchText = '';
    this.selectedHour = ''
    this.showClearFilter = false;
    this.resetReport();
    this.applyFilters();
  }


  showModal() {
    this.showModalFilter = !this.showModalFilter;

    this.showModalFilter ? this.showClearFilter = false :
      this.showClearFilter = !this.checkNoFilter()
  }

  applyFilters(): void {
    this.filteredTeam = this.team.filter((item) => {
      // Verificar se o item passa por todos os filtros selecionados
      return (
        this.filterByProperty(item.employeeId, this.selectedEmployeeId) &&
        this.filterByProperty(item.fullName, this.selectedFullName) &&
        this.filterByProperty(item.chief, this.selectedChief) &&
        this.filterByProperty(item.shiftPlan, this.selectedShiftPlan) &&
        this.filterByProperty(item.jobPosition, this.selectedJobPosition) &&
        this.filterByProperty(item.groupName, this.selectedGroupName) &&
        this.filterByProperty(this.verifyValueNull(item.agentVersion), this.selectedAgentVersion) &&
        this.filterByProperty(this.verifyValueNull(item.hostName), this.selectedHostName) &&
        this.filterByProperty(item.status, this.selectedStatus) &&
        this.containsSearchText(item)
      );
    });
    this.setSummary();
  }

  changeTable() {
    if (this.showProductivityData) {
      this.showProductivityData = !this.showProductivityData;
      this.showRegistrationData = !this.showRegistrationData;
    } else {
      this.showRegistrationData = !this.showRegistrationData;
      this.showProductivityData = !this.showProductivityData;
    }
  }

  filterByProperty(itemProperty: string, selectedValue: string): boolean {
    // Se nenhum valor for selecionado, o item passa pelo filtro
    if (!selectedValue) {
      return true;
    }
    // Caso contrário, verifica se a propriedade do item é igual ao valor selecionado
    return itemProperty === selectedValue;
  }

  containsSearchText(item: TeamReportModel): boolean {
    // Retorna verdadeiro se o texto de pesquisa estiver presente em qualquer propriedade do item
    return Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(this.searchText.toLowerCase())
    );
  }


  setHour() {
    this.hours = [
      '00:00 - 00:59',
      '01:00 - 01:59',
      '02:00 - 02:59',
      '03:00 - 03:59',
      '04:00 - 04:59',
      '05:00 - 05:59',
      '06:00 - 06:59',
      '07:00 - 07:59',
      '08:00 - 08:59',
      '09:00 - 09:59',
      '10:00 - 10:59',
      '11:00 - 11:59',
      '12:00 - 12:59',
      '13:00 - 13:59',
      '14:00 - 14:59',
      '15:00 - 15:59',
      '16:00 - 16:59',
      '17:00 - 17:59',
      '18:00 - 18:59',
      '19:00 - 19:59',
      '20:00 - 20:59',
      '21:00 - 21:59',
      '22:00 - 22:59',
      '23:00 - 23:59',
    ];
  }


  selectTimeRange() {
    this.selectedHour != ""
      ? this.getTeamReportByTimeRage()
      : this.resetReport()
  }

  getTeamReportByTimeRage() {

    const hours = this.selectedHour.split(/[\s-]+/);

    let initial = hours[0]
    let final = initial.replace(":00", ":59")

    let now = new Date()
    let initialHour = moment(initial, 'HH:mm', true);
    let initalDate: Date = new Date(`${this.dateFiltered}T${initialHour.minute(0).second(0).format("HH:mm:ss")}`);


    let isToday = initalDate.getDate() == now.getDate()
    let ishourFuture = now.getHours() < initalDate.getHours()


    if (!this.showFilter) {
      if (isToday && !ishourFuture || !isToday) {
        this.setSubtitle(initial, final)
        this.terms = [];
        this.terms.push(this.subtitle);
        this.showFilter = true;
        this.showSubtitle = false;
      }
    }


    this.getMyTeamReportByRangeTime.emit(initial)
  }


  setSubtitle(initialHour: string, finalHour: string) {
    this.subtitle = `${this.dateFiltered} (${initialHour} até ${finalHour})`
  }

  showChiefs(): boolean {
    return this.uniqueChiefs.length > 1;
  }

  setSummary() {
    this.summary.expectedTime = this.sumTimes(
      this.filteredTeam.map((x) => x.expectedTime)
    );
    this.summary.timeLoggedIn = this.sumTimes(
      this.filteredTeam.map((x) => x.loggedTime)
    );
    this.summary.productiveTime = this.sumTimes(
      this.filteredTeam.map((x) => x.productiveTime)
    );
    this.summary.unproductiveTime = this.sumTimes(
      this.filteredTeam.map((x) => x.unproductiveTime)
    );
    this.summary.timeWithoutClassification = this.sumTimes(
      this.filteredTeam.map((x) => x.timeWithoutClassification)
    );

    this.summary.timeLoggedWithinTheExpectedTime = this.calculateAverage(
      this.filteredTeam.map((x) => x.timeLoggedWithinTheExpectedTime)
    );

    this.summary.productivityWithinTheExpectedTime = this.calculateAverage(
      this.filteredTeam.map((x) => x.productivityWithinTheExpectedTime)
    );

    this.summary.productivityWithinAvailability = this.calculateAverage(
      this.filteredTeam.map((x) => x.productivityWithinAvailability)
    );

    this.summary.connection = this.calculateAverage(
      this.filteredTeam.map((x) => x.connection)
    );
  }


  calculateAverage(list: number[]): number {
    if (list.length === 0) {
      return 0
    }
    const sum: number = list.reduce((accumulator, element) => accumulator + element, 0);
    return isNaN(sum) ? 0 : Math.round(sum / list.length)
  }

  truncate(value: string, maxLength: number): string {
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    }
    return value;
  }
  sumTimes(timeArray: string[]): string {
    let totalMinutes = 0;

    // Itera sobre o array e converte cada tempo para minutos
    for (let time of timeArray) {
      time = this.getHours(time);
      const [hours, minutes] = time.split(':').map(Number);
      totalMinutes += hours * 60 + minutes;
    }

    // Converte o total de minutos de volta para o formato hh:mm
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;

    // Formata os componentes de horas e minutos para garantir dois dígitos
    const formattedHours = totalHours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

}
