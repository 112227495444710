import { CheckinRequest } from './../model/checkin-request';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil, forkJoin, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from '../../perfil-da-empresa/services/company.service';
import { LoginService } from '../service/login.service';
import { LoginRequest } from '../model/login-request';
import { LoginTwoFactorRequest } from '../model/login-two-factor-request';
import { Semaphore } from '@station/employees';
import { AnimationModel } from '../model/animation-model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  animations: [
    trigger('inputFocus', [
      state(
        'focused',
        style({
          fontSize: '18px', // Defina o tamanho desejado ao focar
        })
      ),
      state(
        'unfocused',
        style({
          fontSize: '14px', // Defina o tamanho desejado ao perder o foco
        })
      ),
      transition('unfocused => focused', [animate('0.3s ease-in-out')]),
      transition('focused => unfocused', [animate('0.3s ease-in-out')]),
    ]),
    trigger('typedAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ]),
      transition('* => *', [
        style({ opacity: 0 }),
        animate('0.5s', style({ opacity: 1 })),
      ]),
    ]),
    // ... outras animações
  ],
})
export class ViewComponent implements OnInit {
  changeForForgout: boolean = false;
  twoFactorEnabled: boolean = false;
  loginForm!: FormGroup;
  images: string[] = ['bg1.jpg', 'bg2.jpg', 'bg3.jpg', 'bg4.jpg', 'bg5.jpg'];
  backgroundImage: string = 'bg2.jpg';
  semaphore!: Observable<Semaphore[]>;
  private unsubscribe$ = new Subject();
  returnUrl!: string | null;
  isPasswordField: boolean = true;
  words: string[] = [
    'performance anywhere.',
    'performance em qualquer lugar.',
    'どこでもパフォーマンス。',
    'rendimiento en cualquier lugar.',
    'performance partout.',
    'Leistung überall.',
    'boost your productivity anywhere you go.',
    'aumente sua produtividade onde quer que você vá.',
    'どこに行っても生産性向上。',
    'aumenta tu productividad donde sea que vayas.',
    'augmentez votre productivité où que vous soyez.',
    'Steigern Sie Ihre Produktivität, egal wo Sie sind.',
    'unleash performance without boundaries, anywhere.',
    'libere o desempenho sem limites, em qualquer lugar.',
    'どこでも制限なしのパフォーマンスを解放。',
    'libera el rendimiento sin límites, en cualquier lugar.',
    'libérez la performance sans limites, partout.',
    'Leistung ohne Grenzen freisetzen, überall.',
    'elevate your performance everywhere.',
    'eleve seu desempenho em todos os lugares.',
    'いたるところであなたのパフォーマンスを向上させる。',
    'desempenho que se adapta a você, onde quer que esteja.',
    'élevez votre performance partout.',
    'Ihre Leistung überall verbessern.',
    'performance that adapts to you, wherever you are.',
    'seu desempenho, nossa prioridade, em qualquer lugar.',
    'あなたがいる場所に適応するパフォーマンス。',
    'tu rendimiento, nuestra prioridad, en cualquier lugar.',
    "performance qui s'adapte à vous, où que vous soyez.",
    'Leistung, die sich Ihnen anpasst, egal wo Sie sind.',
    'votre performance, notre priorité, partout.',
    'your performance, our priority, anywhere.',
    'あなたのパフォーマンス、私たちの優先事項、どこでも。',
    'performance anywhere.',
    'performance em qualquer lugar.',
    'どこでもパフォーマンス。',
    'rendimiento en cualquier lugar.',
    'performance partout.',
    'Leistung überall.',
  ];

  animations: AnimationModel[] = [
    {
      BackgroundColor: '#000',
      Filename: 'connection.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'global.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'face.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'blocks.gif',
    },
    {
      BackgroundColor: '#030303',
      Filename: 'woman.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'web.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'spectrum.gif',
    },
    {
      BackgroundColor: '#000',
      Filename: 'water.gif',
    },
  ];
  currentWord: string = this.words[0];
  currentAnimation: AnimationModel = this.animations[0];
  typedWord: string = '';
  isUsernameFocused: boolean = false;
  isPasswordFocused: boolean = false;

  constructor(
    private loginService: LoginService,
    private companyService: CompanyService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    let ran = Math.round((Math.random() * 100) % 5);
    this.backgroundImage =
      this.images[ran] == undefined ? 'bg2.jpg' : this.images[ran];

    await this.companyService.CheckTwoFactor().subscribe((data) => {
      this.twoFactorEnabled = data;

      this.loginForm = this.formBuilder.group({
        login: [null, [Validators.required, Validators.email]],
        senha: [null, Validators.required],
        twoFactorCode: [],
      });

      this.getReturnUrl();
    });

    setInterval(() => {
      this.nextWord();
      this.nextAnimation();
    }, 6000);
  }
  nextWord() {
    const currentIndex = this.words.indexOf(this.currentWord);
    const nextIndex = (currentIndex + 1) % this.words.length;
    this.currentWord = this.words[nextIndex];
    this.animateTyping();
  }
  nextAnimation() {
    const currentIndex = this.animations.indexOf(this.currentAnimation);
    const nextIndex = (currentIndex + 1) % this.words.length;
    this.currentAnimation = this.animations[nextIndex];
  }
  animateTyping() {
    const fullWord = this.currentWord;

    const typeNextLetter = (index: number) => {
      if (index <= fullWord.length) {
        this.typedWord = fullWord.substring(0, index);
        setTimeout(() => typeNextLetter(index + 1), 100);
      }
    };

    typeNextLetter(0);
  }

  onUsernameFocus() {
    this.isUsernameFocused = true;
  }

  onUsernameBlur() {
    this.isUsernameFocused = false;
  }

  onPasswordFocus() {
    this.isPasswordFocused = true;
  }

  onPasswordBlur() {
    this.isPasswordFocused = false;
  }
  getReturnUrl() {
    this.returnUrl = this.authService.getReturnUrl();

    this.returnUrl = this.returnUrl == '/' ? null : this.returnUrl;

    if (this.returnUrl) {
      this.toastr.info(
        `Primeiro é necessário fazer o login para depois acessar [${this.returnUrl}]`
      );
    }
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  submit() {
    let login = this.loginFormControl['login'].value;
    let senha = this.loginFormControl['senha'].value;
    let twoFactorCode = this.loginFormControl['twoFactorCode'].value;

    if (!this.twoFactorEnabled || twoFactorCode == null) {
      let loginRequest = new LoginRequest(login, senha);

      let auth = this.loginService.login(loginRequest);

      const observables = {
        auth,
      };

      const join = forkJoin(observables);

      join.pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (data) => {
          this.authService.setAuthUser(data.auth);
          let checkinRequest: CheckinRequest = {
            userId: data.auth.id,
            chiefId: data.auth.chiefId,
          };

          this.loginService.checkin(checkinRequest).subscribe();

          this.semaphore = this.loginService.getSemaphore();

          this.semaphore.subscribe((dataSemaphore) => {
            this.authService.setSemaphore(dataSemaphore);

            let userHomepage = !this.twoFactorEnabled
              ? `${this.loginService.defaultUserPage(
                  this.authService.authUser
                )}/${this.authService.authUser.id}`
              : 'cadastro-duplo-fator';

            const goUrl = this.returnUrl || userHomepage;

            this.router.navigateByUrl(goUrl);

            this.authService.clearReturnUrl();
          });
        },
        error: (error: any) => {
          var errorMessage =
            error?.error?.message ??
            'Ops! Parece que um erro ocorreu ao tentar efetuar o login';
          this.toastr.error(errorMessage);
        },
      });
    } else {
      let loginTwoFactorRequest = new LoginTwoFactorRequest(
        login,
        senha,
        twoFactorCode
      );

      this.loginService.loginWithTwoFactor(loginTwoFactorRequest).subscribe(
        (data) => {
          this.authService.setAuthUser(data);

          this.semaphore = this.loginService.getSemaphore();

          this.semaphore.subscribe((dataSemaphore) => {
            this.authService.setSemaphore(dataSemaphore);
          });

          let userHomepage = `${this.loginService.defaultUserPage(
            this.authService.authUser
          )}/${this.authService.authUser.id}`;

          this.router.navigate([userHomepage]);
        },
        (error: any) => {
          var errorMessage =
            error?.error?.message ??
            'Ops! Parece que um erro ocorreu ao tentar efetuar o login';
          this.toastr.error(errorMessage);
        }
      );
    }
  }

  sendForgoutPassword() {
    if (this.loginForm.get('login')?.hasError('required')) {
      this.toastr.warning('Preencha o campo email', 'O email é obrigatório', {
        closeButton: true,
        progressBar: true,
      });
      return;
    }

    if (this.loginForm.get('login')?.hasError('email')) {
      this.toastr.warning(
        'O formato do email deve ser example@example.com',
        'Email preenchido incorretamente',
        { closeButton: true, progressBar: true }
      );
      return;
    }

    let forgoutPassword = this.loginForm.get('login')?.value;

    return this.loginService.emailForgout(forgoutPassword).subscribe(
      () => this.toastr.success('Se você não receber o e-mail de recuperação de senha em sua caixa de entrada, verifique sua pasta de spam. Alguns provedores de e-mail podem direcionar nossas mensagens para lá.', "Email enviado com sucesso.", {
        timeOut: 6000 // Tempo em milissegundos (6 segundos)
      }),
      (error) => {
        let errorMsg =
          error?.error?.message ??
          'Ops! Parece que um erro ocorreu ao alterar a senha';
        this.toastr.error(errorMsg);
      }
    );
  }

  changeToForgoutPassword() {
    return (this.changeForForgout = true);
  }

  changeToLogin() {
    return (this.changeForForgout = false);
  }

  togglePasswordVisibility() {
    this.isPasswordField = !this.isPasswordField;
  }
}
