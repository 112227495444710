import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CardJourneyModel, SubtitleCardJourneyModel } from '@station/employees';
import { HeaderModel } from '@station/header';
import { ToastrService } from 'ngx-toastr';

import { HoursWork, Journey } from '../models/journey';
import { JourneyService } from '../service/journey.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private journeyService: JourneyService,
    private router: Router,
    private toastr: ToastrService
    ) {
    this.createForm();
  }

  header: HeaderModel = new HeaderModel("300062", "Heverton pablo", "Colaborador", false, "https://picsum.photos/100", true, "Buscar Funcionários");
  ngOnInit(): void {
    this.form = false;
    this.getJourney();
  }

  journeys!: Journey[];
  journey: Journey = new Journey();

  // #region Gets do back end
  journeyFiltered?: Journey[];
  _filtroLista: string = "";

  get filtroLista(): string {
    return this._filtroLista;
  }

  set filtroLista(value: string) {
    this._filtroLista = value;
    this.journeyFiltered = this.filtroLista ? this.journeyFilter(this.filtroLista) : this.journeys;
  }

  journeyFilter(filtrarPor: string): Journey[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();

    return this.journeys.filter(
      (journey: Journey) => journey.description.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  arrayCardJourney: CardJourneyModel[] = []
  daysCardJourney: SubtitleCardJourneyModel[] = []
  arrayDaysCardJourney: SubtitleCardJourneyModel[] = []

  renderCardJourney(journey: Journey) {
    let daysWeek: string[] = ["Dom", "Seg", "ter", "Qua", "Qui", "Sex", "Sab", "fer"];

    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[0], false, !journey.dom));
    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[1], false, !journey.seg));
    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[2], false, !journey.ter));
    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[3], false, !journey.qua));
    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[4], false, !journey.qui));
    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[5], false, !journey.sex));
    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[6], false, !journey.sab));
    this.daysCardJourney.push(new SubtitleCardJourneyModel(daysWeek[7], true, !journey.fer));

    this.arrayCardJourney.push(new CardJourneyModel(journey.description, this.daysCardJourney, journey.hoursWork[0].in as unknown as string, journey.hoursWork[journey.hoursWork.length - 1].out as unknown as string));

    this.daysCardJourney = [];

    return this.arrayCardJourney[this.arrayCardJourney.length - 1];
  }

  getJourney() {
    this.journeyService.getAllJourneys().subscribe(
      (res: Journey[]) => {
        this.journeys = res;
        this.journeyFiltered = this.journeys;
      });
  }

  // #endregion





  // #region formularios
  formJourney!: FormGroup;
  createForm() {
    this.formJourney = this.fb.group({
      id: [''],
      description: ['', [Validators.required, Validators.minLength(3)]],
      dom: [false],
      seg: [false],
      ter: [false],
      qua: [false],
      qui: [false],
      sex: [false],
      sab: [false],
      fer: [false],
      hoursWork: this.fb.array([], Validators.required)
    })
  }

  get getHoursWork(): FormArray {
    return this.formJourney.get('hoursWork') as FormArray;
  }

  action: string = "Post";
  form!: boolean;

  resetChecBox() {
    let checkWeek = document.getElementById('checkweek') as HTMLInputElement;
    checkWeek.checked = false;

    let checkWeekEnd = document.getElementById('checkweekend') as HTMLInputElement;
    checkWeekEnd.checked = false;
    this.form = true;
  }

  newJourney() {
    this.getJourney()
    this.action = "Post";
    this.createForm();
    this.resetChecBox();
  }

  renderForm(journey: Journey) {

    this.action = "Put";
    this.form = true;

    this.formJourney = this.fb.group({
      id: [journey.id],
      description: [journey.description, [Validators.required, Validators.minLength(3)]],
      dom: [journey.dom],
      seg: [journey.seg],
      ter: [journey.ter],
      qua: [journey.qua],
      qui: [journey.qui],
      sex: [journey.sex],
      sab: [journey.sab],
      fer: [journey.fer],

      hoursWork: this.fb.array(journey.hoursWork.map(hoursWork => this.renderHoursWork(hoursWork)))
    });

    this.calculatedHours(journey);

    this.journey = journey;
  };

  labelHours!: string;

  calculatedHours(journey: Journey) {

    let hoursAcumulated = new Date('2022-02-20 00:00:00')

    journey.hoursWork?.forEach(hours => {

      let ini = hours.in.toString().toDate();
      let out = hours.out.toString().toDate();

      let hour = new Date(out.getTime() - ini.getTime());

      hoursAcumulated.setHours(hoursAcumulated.getHours() + hour.getUTCHours());
      hoursAcumulated.setMinutes(hoursAcumulated.getMinutes() + hour.getUTCMinutes());
    });

    return `Jornada de ${hoursAcumulated.getHours()} horas e ${hoursAcumulated.getMinutes()} minutos`;
  }

  newHoursWork(journey: Journey) {

    if (journey.hoursWork?.length) {
      journey.hoursWork[(journey.hoursWork?.length - 1) + 1] = {
        in: journey.hoursWork[journey.hoursWork?.length - 1].in,
        out: journey.hoursWork[journey.hoursWork?.length - 1].out
      }
    }

    this.getHoursWork.push(
      this.fb.group({
        in: ['', Validators.required],
        out: ['', Validators.required]
      })
    );
  }

  verifyCheckedAll(markAllClassName: string, chekinClassName: string) {
    let checkbox = document.getElementsByClassName(chekinClassName);
    let markall = document.getElementsByClassName(markAllClassName)[0] as HTMLInputElement;
    for (let i = 0; i < checkbox.length; i++) {
      let input = checkbox[i] as HTMLInputElement;

      if (!input.checked) {
        markall.checked = false;
        return;
      }
      markall.checked = true;
    }
  }
  week: string[] = ['seg', 'ter', 'qua', 'qui', 'sex'];
  weekend: string[] = ['dom', 'sab', 'fer'];

  markAllDayWeek(markAllClassName: string, chekinClassName: string) {
    let checkbox = document.getElementsByClassName(chekinClassName);
    let markall = document.getElementsByClassName(markAllClassName)[0] as HTMLInputElement;


    let array = markAllClassName == 'markall' ? this.week : this.weekend;

    if (markall.checked) {
      this.setCheckWeek(true, array);
    } else {
      this.setCheckWeek(false, array);
    }

  }

  setCheckWeek(checked: boolean, array: any) {

    for (var i = 0; i < array.length; i++) {
      this.formJourney.get(array[i])?.setValue(checked)
    }
  }

  private setCheckbox(htmlcollection: HTMLCollectionOf<Element>, checked: boolean) {

    for (let i = 0; i < htmlcollection.length; i++) {
      let input = htmlcollection[i] as HTMLInputElement
      input.checked = checked
    }

  }

  checkedAllDayWeekEnd!: boolean;

  markAllDayWeekEnd() {
    let checkbox = document.getElementsByClassName('checkinweekend');
    let markall = document.getElementsByClassName('markallweekend')[0] as HTMLInputElement;

    if (markall.checked) {
      this.setCheckbox(checkbox, true)
    } else {
      this.setCheckbox(checkbox, false)
    }
  }

  renderHoursWork(hoursWork?: any): FormGroup {
    return this.fb.group({
      in: [hoursWork.in],
      out: [hoursWork.out]
    });
  }

  get formControl() { return this.formJourney.controls }
  // #endregion

  // #region metodos de edição e salvar

  hoursWorkDulyFilled(journey: any) {
    var dulyCompleted: boolean = true
    journey.hoursWork.map((hourWork: any) => {
      if (hourWork.in.toString() == "" || hourWork.out.toString() == "") {
        dulyCompleted = false
      }
    })
    return dulyCompleted
  }

  saveChanges() {
    this.journey = Object.assign(this.formJourney.value);



    if (this.formJourney.get('description')?.hasError('required')) {
      this.toastr.warning("O campo descrição deve ser preenchido.")
      return
    }

    if (this.formJourney.get('description')?.hasError('minlength')) {
      this.toastr.warning("O campo descrição deve ter no mínimo 3 caracteres.")
      return
    }

    if (!this.hoursWorkDulyFilled(this.journey)) {
      this.toastr.warning("Todos os intervalos devem conter entrada e saída.")
      return
    }

    if (!this.journey.hoursWork.length) {
      this.toastr.warning("Pelo menos um intervalo deve ser definido.")
      return
    }

    if (this.action === "Post") {
      this.journeyService.postJourney(this.journey).subscribe({
        next: () => {
          this.getJourney();
          this.createForm();
          this.resetChecBox();
        },
        error: error => {
          console.log('Erro ao submeter', error.message);
        }
      });
    } else {
      this.journeyService.putJourney(this.journey).subscribe({
        next: (newJourney: Journey) => {
          this.getJourney();
          this.createForm();
          this.resetChecBox();
          this.toastr.success("Jornada alterado com sucesso.", "Sucesso !")
        }
      });
    }
  }

  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  confirm(event: Event){



    const elementoClicado = event.target as HTMLElement;
    elementoClicado.style.display = "none";

    const divMostrar = elementoClicado.nextElementSibling as HTMLElement;
    divMostrar.style.display = 'inline-block';





  }

  removeWorkScheduleFromList(index: number) {

    this.formJourney.value.hoursWork.splice(index, 1)
    this.getHoursWork.controls.splice(index, 1)

    this.saveChanges()

  }

  // #endregion

}
