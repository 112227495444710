import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {


    static whitespaceValidator(control: AbstractControl): { [key: string]: any } | null {
        if (!control?.value) {
            // if control is empty return no error
            return null;
        }

        // Remove leading and trailing whitespaces from the password
        const trimmedPassword = control.value.trim();

        if (control.value !== trimmedPassword) {
            // if the password has leading or trailing whitespaces, return an error
            return { WhitespaceFound: true };
        }

        return null; // no error, password is valid
    }
}
