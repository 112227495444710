<div class="painel-colaborador">
  <station-header [headerModel]="header"></station-header>
  <station-user-notification [countUnread]="countUnreadUserNotification" [userNotifications]="userNotifications"
    (loadMore)="loadMoreUserNotification($event)"></station-user-notification>
  <div class="painel-colaborador_conteudo">
    <station-sidebar [isEmployees]="true" [employeeId]="authUser.id" [showEmployeeList]="showEmployeeList">
    </station-sidebar>

    <div class="content">
      <div class="dash-header_content">
        <station_dash-header [employee$]="profile$" [enabledBadConnection]="false"></station_dash-header>
      </div>
      <div class="dateFilter">
        <div>
          <div *ngIf="hasRole('colaborador-shutdown')" class="screenshot-group">
            <mat-icon (click)="remoteShutdown()" class="screenshot-icon"
              title="Desligar a máquina do colaborador">power_settings_new</mat-icon>
          </div>
          <div *ngIf="hasRole('colaborador-restart')" class="screenshot-group">
            <mat-icon (click)="remoteRestart()" class="screenshot-icon"
              title="Reiniciar a máquina do colaborador">sync</mat-icon>
          </div>
          <mat-icon *ngIf="hasRole('colaborador-screenshot')" (click)="takeScreetshot()" class="screenshot-icon"
            title="Fazer captura de tela agora">computer</mat-icon>
          <mat-icon *ngIf="hasRole('colaborador-webcam')" (click)="takeWebcamPicture()" class="screenshot-icon"
            title="Fazer captura de webcam agora">photo_camera</mat-icon>
          <div class="screenshot-group">
            <mat-icon class="screenshot-icon" *ngIf="screenshots.length > 0" data-bs-toggle="modal"
              data-bs-target="#myModal" title="Visualizar capturas de tela">visibility</mat-icon>
            <span class="badge bg-secondary badge-screenshot" *ngIf="screenshots.length > 0">{{ screenshots.length
              }}</span>
          </div>
        </div>
        <small><i><b>Última atualização:</b>
            {{ lastUpdate | date : "dd/MM/yyyy HH:mm:ss" }}</i></small>
        <div class="d-flex btnSelector">
          <div class="d-flex flex-row-reverse me-2 mb-2">
            <input type="date" [max]="maxDate" (change)="updatePanels()" [(ngModel)]="dateFiltered" class="inputDate" />
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('month')" [ngClass]="
                availabilityChartsSelectOption === Periods.Month
                  ? 'periodActive'
                  : ''
              ">
              Mês
            </button>
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('week')" [ngClass]="
                availabilityChartsSelectOption === Periods.Week
                  ? 'periodActive'
                  : ''
              ">
              Quinzena
            </button>
            <button type="button" class="btn m-1 btn-label" (click)="selectedPeriod('day')" [ngClass]="
                availabilityChartsSelectOption === Periods.Day
                  ? 'periodActive'
                  : ''
              ">
              {{ dayLabel }}
            </button>
          </div>
        </div>
      </div>

      <div class="employee-chart-content">
        <div class="big-number-content">
          <div class="big-number-info">
            <p class="highlights-title">
              Disponibilidade
              <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                data-bs-target="#modalDisponibilidade">info</mat-icon>
            </p>

            <station-employee-big-number [ngbTooltip]="tipAvailabilityHighlights"
              [bigNumbers]="bigNumberHighLightTodayTeam"></station-employee-big-number>
          </div>

          <div class="chartComparativoTempoLogado">
            <station-chart-column-percentage-area [ngbTooltip]="tipAvailabilityChart" placement="bottom"
              *ngIf="chartComparativoTempoLogado != null"
              [chartOptions$]="chartComparativoTempoLogado"></station-chart-column-percentage-area>
          </div>
        </div>
      </div>

      <div class="employee-chart-content">
        <div class="big-number-content">
          <div class="big-number-info">
            <p class="highlights-title">
              Produtividade
              <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                data-bs-target="#modalProdutividade">info</mat-icon>
            </p>
            <station-employee-big-number [ngbTooltip]="tipProductivityHighlights" placement="bottom"
              [bigNumbers]="employeeBigNumberHighlightsProductivity"></station-employee-big-number>
          </div>

          <div>
            <station-chart-column-group [ngbTooltip]="tipProductivityChart" placement="bottom"
              [chartColumnGroupOptions$]="chartComparativoHistoricoJornada"
              (newItemEvent)="dateFilterClick($event)"></station-chart-column-group>
          </div>
        </div>
      </div>

      <!-- <div class="employee-chart-content">
        <div class="employee-chart-title">
          <p class="highlights-title">Improdutividade <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal" data-bs-target="#modalImprodutividade">info</mat-icon></p>
          <br />
          <a> Tempo contabilizado como não produtivo</a>
        </div>
        <div>
          <div class="employee-card">
            <station-employee-card-big-number
              [employeeBigCard$]="employeeCardBigNumberA"
            >
            </station-employee-card-big-number>
            <station-employee-card-big-number
              [employeeBigCard$]="employeeCardBigNumberB"
            >
            </station-employee-card-big-number>
            <station-employee-card-big-number
              [employeeBigCard$]="employeeCardBigNumberC"
            >
            </station-employee-card-big-number>
          </div>
        </div>

        <div class="employee-card-subtitle">
          Distribuição da improdutividade - jornada prevista
        </div>

        <div class="employee-chart">
          <station-chart-line-percentage-area
            [ngbTooltip]="tipAgent"
            placement="bottom"
            [chartLineOptions$]="chartImprodutividade$"
          >
          </station-chart-line-percentage-area>
        </div>
      </div> -->

      <div class="indicadores"></div>
      <div class="employee-chart-content">
        <div class="table">
          <station-table-aplications-utility [productiveApplications]="productiveApplications"
            [productiveApplicationsFiltered]="productiveApplications" [userId]="authUser.id"
            [dateFilter]="dateFiltered">
          </station-table-aplications-utility>
        </div>
      </div>
      <div class="employee-chart-content">
        <div class="big-number-content">
          <div class="big-number-info">
            <p class="highlights-title">
              Linha do tempo
              <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                data-bs-target="#modalProdutividade">info</mat-icon>
            </p>
            <button (click)="showModal()" class="btn m-1 btn-label btn-filter filter">
              Filtrar
              <mat-icon class="filter">filter_list_alt</mat-icon>
            </button>
            <div id="modal-filter" class="info-filter" *ngIf="showModalFilter">
              <div class="filters">
                <div class="row-filter">
                  <div>
                    <label>Classificação:</label>
                    <select [(ngModel)]="selectedClassification" (change)="applyFilters()" class="form-select">
                      <option value="">Todos</option>
                      <option *ngFor="let classification of uniqueClassification" [value]="classification">{{
                        classification }}</option>
                    </select>
                  </div>
                  <div>
                    <label>Descrição</label>
                    <select [(ngModel)]="selectedDescription" (change)="applyFilters()" class="form-select">
                      <option value="">Todos</option>
                      <option *ngFor="let description of uniqueDescription" [value]="description">{{ description }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row-filter">
                  <div>
                    <label>Categoria</label>
                    <select [(ngModel)]="selectedCategory" (change)="applyFilters()" class="form-select">
                      <option value="">Todos</option>
                      <option *ngFor="let category of uniqueCategory" [value]="category">{{ category }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <label>Termo:</label>
                    <select [(ngModel)]="selectedTerm" (change)="applyFilters()" class="form-select">
                      <option value="">Todos</option>
                      <option *ngFor="let term of uniqueTerm" [value]="term">{{ term }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row-filter">
                  <div>
                    <button (click)="clearFilters()" class="btn btn-light" style="display: flex;">
                      <mat-icon>close</mat-icon>
                      Limpar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="timeline-summary">
            <span *ngIf="minStartDateTimeline"><mat-icon class="timeline-icon">schedule</mat-icon><span>Início:
                {{minStartDateTimeline | date:'dd/MM/yyyy HH:mm:ss':'UTC'}}</span></span>
            <span *ngIf="maxEndDateTimeline"><mat-icon class="timeline-icon">schedule</mat-icon><span>Fim:
                {{maxEndDateTimeline | date:'dd/MM/yyyy HH:mm:ss':'UTC'}}</span></span>
            <span *ngIf="maxEndDateTimeline"><mat-icon class="timeline-icon">format_list_numbered</mat-icon><span>Total
                de logs: {{qtdLogs}} / {{formatTime(duracaoLogs)}}</span></span>
            <span *ngIf="maxEndDateTimeline" class="ng-star-inserted"><span
                class="badge bg-success">Produtivo:</span><span class="ng-star-inserted"> {{qtdProdutiva}} /
                {{formatTime(duracaoProdutiva)}}</span></span>
            <span *ngIf="maxEndDateTimeline" class="ng-star-inserted"><span
                class="badge bg-danger">Improdutivo:</span><span class="ng-star-inserted"> {{qtdImprodutiva}} /
                {{formatTime(duracaoImprodutiva)}}</span></span>
            <span *ngIf="maxEndDateTimeline" class="ng-star-inserted"><span class="badge bg-secondary">Sem
                classificação:</span><span class="ng-star-inserted"> {{qtdSemClassificacao}} /
                {{formatTime(duracaoSemClassificacao)}}</span></span>
          </div>
          <div class="timeline-content">
            <mat-stepper orientation="vertical" [linear]="false" #stepper>
              <mat-step *ngFor="let app of filteredApplicationTimeline" completed="false"
                [hasError]="app.classification == 'Improdutivo'">
                <ng-template matStepLabel>
                  <span class="step-label-details">[{{
                    app.startDate | date:'dd/MM/yyyy HH:mm:ss':'UTC'
                    }}]</span> - {{ app.processName }} - {{ app.windowTitle }}
                </ng-template>
                <ul>
                  <li>
                    <span>Classificação:</span> 
                    <label class="badge" [ngClass]="{
                        'bg-danger': app.classification === 'Improdutivo',
                        'bg-secondary':
                          app.classification === 'Sem classificação',
                        'bg-success': app.classification === 'Produtivo'
                      }">
                      {{ app.classification }}
                    </label>
                  </li>
                  <li>
                    <span>Início:</span>
                    <label class="step-label-details">{{
                      app.startDate | date:'dd/MM/yyyy HH:mm:ss':'UTC'
                      }}</label>
                  </li>
                  <li>
                    <span>Fim:</span>
                    <label class="step-label-details">{{
                      app.endDate | date:'dd/MM/yyyy HH:mm:ss':'UTC'
                      }}</label>
                  </li>
                  <li>
                    <span>Duração:</span><label class="step-label-details">{{
                      formatTime(app.duration)
                      }}</label>
                  </li>
                  <li>
                    <span>Termo:</span><label class="step-label-details">{{ app.term }}</label>
                  </li>
                  <li>
                    <span>Descrição:</span><label class="step-label-details">{{
                      app.description
                      }}</label>
                  </li>
                  <li>
                    <span>Categoria:</span><label class="step-label-details">{{
                      app.category
                      }}</label>
                  </li>
                  <li>
                    <span>Nome do processo:</span><label class="step-label-details">{{
                      app.processName
                      }}</label>
                  </li>
                  <li>
                    <span>Hostname:</span><label class="step-label-details">{{
                      app.hostName
                      }}</label>
                  </li>
                  <li>
                    <span>Título da janela:</span><label class="step-label-details">{{
                      app.windowTitle
                      }}</label>
                  </li>
                  <li>
                    <span>URL:</span><label class="step-label-details">{{ app.url }}</label>
                  </li>
                  <li>
                    <span>Sistema operacional:</span><label class="step-label-details">{{
                      app.platform
                      }}</label>
                  </li>
                </ul>
              </mat-step>
            </mat-stepper>
          </div>
        </div>
      </div>

      <div class="employee-chart-content">
        <div class="big-number-content">
          <div class="big-number-info">
            <div>
              <p class="highlights-title">
                Conexão
                <mat-icon class="info-icon" title="Saiba mais" data-bs-toggle="modal"
                  data-bs-target="#modalConexao">info</mat-icon>
              </p>
              <br />
              <p>Média histórica de desempenho de Conexão e Latência</p>
            </div>
            <station-employee-big-number [ngbTooltip]="tipConectionHighlights"
              [bigNumbers]="bigNumberHighLightConnection">
            </station-employee-big-number>
          </div>
        </div>
        <div class="employee-card-conexion">
          <station-employee-card-big-number class="card-connection" [employeeBigCard$]="conexaoInternet"
            [title]="internetLastUpdate"></station-employee-card-big-number>
          <station-employee-card-big-number class="card-connection" [employeeBigCard$]="conexaoLatencia"
            [title]="latencyLastUpdate"></station-employee-card-big-number>
        </div>

        <div class="employee-chart">
          <station-chart-line-percentage-area [chartLineOptions$]="chartConexao">
          </station-chart-line-percentage-area>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Capturas de tela</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ngb-carousel *ngIf="screenshots">
          <ng-template ngbSlide *ngFor="let screenshot of screenshots">
            <div class="picsum-img-wrapper">
              <img class="scr-img" src="data:image/png;base64,{{ screenshot.fileData }}" alt="Random first slide" />
            </div>
            <div class="carousel-caption">
              <h3>{{ screenshot.description }}</h3>
              <p>{{ screenshot.createdAt }}</p>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>

<!-- Modal Disponibilidade -->
<div class="modal fade" id="modalDisponibilidade" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Saiba mais</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p><b>Disponibilidade</b></p>
        <p>
          <span class="font-weight-normal">Refere-se ao tempo que um usuário passa logado e disponível para
            trabalhar.</span>
        </p>
        <p>
          <span class="font-weight-normal">É calculado o tempo disponível em relação à jornada de trabalho,
            descontando o tempo ocioso.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Para calcular a disponibilidade de toda a equipe, somamos o tempo
            de trabalho planejado para cada colaborador (Jornada) e depois
            somamos quanto tempo cada um deles ficou realmente online durante o
            dia.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">(Tempo total logado <b>/</b> Tempo da jornada)</span>
        </p>
        <p></p>
        <p><b>Exemplo:</b></p>
        <p></p>
        <p>
          <span class="font-weight-normal">Total na equipe:<b> 5</b></span>
        </p>
        <p>
          <span class="font-weight-normal">Cada colaborador com 10 horas de jornada no dia: 10 * 5 =
            <b>50</b> horas</span>
        </p>
        <p>
          <span class="font-weight-normal">Cada colaborador com 8 horas de tempo logado no dia: 8 * 5 =
            <b>40</b> horas</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Resultando em um total de <b>80%</b> de Disponibilidade no dia para
            a equipe</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">A disponibilidade é uma métrica importante para medir o tempo em
            que os colaboradores estão online e disponíveis para trabalhar,
            permitindo avaliar a utilização eficiente do tempo de trabalho da
            equipe.</span>
        </p>
        <p></p>
        <p><b>Ociosidade</b></p>
        <p>
          <span class="font-weight-normal">Refere-se ao tempo em que o usuário não utiliza o mouse ou
            teclado.</span>
        </p>
        <p>
          <span class="font-weight-normal">É calculado o tempo ocioso dividido pelo tempo da jornada.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Para calcular a Ociosidade de toda a equipe, somamos o tempo de
            trabalho planejado para cada colaborador (Jornada) e depois somamos
            quanto tempo cada um deles ficou realmente ocioso durante o
            dia.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">(Tempo ocioso <b>/</b> Tempo da jornada)</span>
        </p>
        <p></p>
        <p><b>Exemplo:</b></p>
        <p></p>
        <p>
          <span class="font-weight-normal">Total na equipe:<b> 5</b></span>
        </p>
        <p>
          <span class="font-weight-normal">Cada colaborador com 10 horas de jornada no dia: 10 * 5 =
            <b>50</b> horas</span>
        </p>
        <p>
          <span class="font-weight-normal">Cada colaborador com 2 horas de tempo ocioso no dia: 2 * 5 =
            <b>10</b> horas</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Resultando em um total de <b>20%</b> de Ociosidade no dia para a
            equipe</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">A ociosidade é uma métrica importante para medir o tempo em que os
            colaboradores estão "inativos" durante suas jornadas de trabalho,
            auxiliando na análise da eficiência e utilização do tempo.</span>
        </p>
        <p></p>
      </div>
    </div>
  </div>
</div>

<!-- Modal Produtividade -->
<div class="modal fade" id="modalProdutividade" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Saiba mais</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p><b>Produtividade</b></p>
        <p>
          <span class="font-weight-normal">Refere-se ao tempo que um usuário passa acessando aplicações
            classificadas como Produtivas.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Para calcular a Produtividade de toda a equipe, somamos o tempo de
            trabalho planejado para cada colaborador (Jornada) e depois somamos
            quanto tempo cada um deles ficou realmente em aplicações
            produtivas.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">(Tempo em aplicações produtivas <b>/</b> Tempo na
            Jornada)</span><b>&nbsp;</b>
        </p>
        <p></p>
        <p><b>Exemplo:</b></p>
        <p></p>
        <p>
          <span class="font-weight-normal">Total na equipe:<b> 5</b></span>
        </p>
        <p>
          <span class="font-weight-normal">Cada colaborador com 10 horas de jornada no dia: 10 * 5 =
            <b>50</b> horas</span>
        </p>
        <p>
          <span class="font-weight-normal">Cada colaborador com 6 horas de tempo em aplicações produtivas no
            dia: 6 * 5 = <b>30</b> horas</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Resultando em um total de <b>60%</b> de Produtividade no dia para a
            equipe</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">A produtividade é uma métrica importante para medir o tempo que a
            equipe gasta em atividades produtivas, auxiliando na avaliação da
            eficiência do trabalho realizado.</span>
        </p>
        <p></p>
      </div>
    </div>
  </div>
</div>

<!-- Modal Improdutividade -->
<div class="modal fade" id="modalImprodutividade" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Saiba mais</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p><b>Improdutividade</b></p>
        <p>
          <span class="font-weight-normal">Refere-se ao tempo que um usuário passa acessando aplicações
            classificadas como <b>Improdutivas</b>.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Para calcular a <b>Improdutividade</b> do colaborador, somamos o
            tempo de trabalho planejado (Jornada) e depois somamos quanto tempo
            cada um deles ficou realmente em aplicações improdutivas.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">(Tempo em aplicações <b>Improdutivas</b> / Tempo na
            Jornada)</span><b>&nbsp;</b>
        </p>
        <p></p>
        <p><b>Exemplo:</b></p>
        <p></p>
        <p>
          <span class="font-weight-normal">Colaborador com 10 horas de jornada no dia: 10 horas</span>
        </p>
        <p>
          <span class="font-weight-normal">E teve 2 horas de aplicações improdutivas acessadas: 02 horas</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">Resultando em um total de <b>20%</b> de <b>Improdutividade</b> no
            dia para esse colaborador.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">A <b>improdutividade</b> é uma métrica importante para medir o
            tempo que a equipe gasta em atividades improdutivas, auxiliando na
            avaliação da eficiência do trabalho realizado.</span>
        </p>
        <p></p>
      </div>
    </div>
  </div>
</div>

<!-- Modal Conexão -->
<div class="modal fade" id="modalConexao" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Saiba mais</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2>Conexão</h2>
        <p><b>Card de Banda: </b></p>
        <p>
          <span class="font-weight-normal">Este card fornece informações sobre a média de banda de internet ao
            longo do dia. A média é calculada com base em leituras realizadas em
            diferentes horários, e, em seguida, é obtida uma média geral do
            dia.</span>
        </p>
        <p></p>
        <p><span class="font-weight-normal">Por exemplo:</span></p>
        <p></p>
        <p><span class="font-weight-normal">08:00 - 10 Mbps</span></p>
        <p><span class="font-weight-normal">09:00 - 15 Mbps</span></p>
        <p><span class="font-weight-normal">10:00 - 10 Mbps</span></p>
        <p><span class="font-weight-normal">11:00 - 10 Mbps</span></p>
        <p><span class="font-weight-normal">12:00 - 16 Mbps</span></p>
        <p></p>
        <p>
          <span class="font-weight-normal">No exemplo acima, a média de banda até as 12:00 foi calculada em 12
            Mbps. Esse valor representa a média da velocidade de internet
            durante esse período específico.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">O card de banda fornece uma visão geral da qualidade da conexão ao
            longo do dia, ajudando a identificar variações e tendências na
            velocidade da internet.</span>
        </p>
        <p></p>
        <br />
        <br />
        <p><b>Card de Latência</b></p>
        <p>
          <span class="font-weight-normal">Este card oferece informações sobre a média de latência da rede ao
            longo do dia. A latência é calculada com base em leituras realizadas
            em diferentes horários e, em seguida, é obtida uma média geral do
            dia.</span>
        </p>
        <p></p>
        <p><span class="font-weight-normal">Por exemplo:</span></p>
        <p></p>
        <p><span class="font-weight-normal">08:00 - 20 ms</span></p>
        <p><span class="font-weight-normal">09:00 - 18 ms</span></p>
        <p><span class="font-weight-normal">10:00 - 22 ms</span></p>
        <p><span class="font-weight-normal">11:00 - 19 ms</span></p>
        <p><span class="font-weight-normal">12:00 - 25 ms</span></p>
        <p></p>
        <p>
          <span class="font-weight-normal">No exemplo acima, a média de latência até as 12:00 foi calculada em
            20 ms. Esse valor representa a média do tempo de resposta da rede
            durante esse período específico.</span>
        </p>
        <p></p>
        <p>
          <span class="font-weight-normal">O card de latência fornece uma visão geral da qualidade da conexão
            ao longo do dia, ajudando a identificar variações e tendências na
            latência da rede, o que é fundamental para garantir uma experiência
            de usuário suave e eficiente.</span>
        </p>
        <p></p>
      </div>
    </div>
  </div>
</div>

<!-- Tooltip Agent -->
<ng-template #tipAgent><b>colaborador</b> Total de pessoas da equipe diretamente ligadas<br /><br /><b>Logado:</b>
  Total de pessoas logadas no momento<br /><br /><b>% Logado:</b> Percentual de
  pessoas logadas sobre o total da equipe</ng-template>
<!-- Tooltip Availability Highlights Agent -->
<ng-template #tipAvailabilityHighlights><b>Hoje:</b> Percentual de Disponibilidade do colaborador no dia
  atual<br /><br /><b>Ontem:</b>
  Percentual de Disponibilidade do colaborador no dia de ontem<br /><br /><b>Sem:</b>
  Percentual de Disponibilidade do colaborador na semana atual<br /><br /><b>Mês:</b>
  Percentual de Disponibilidade do colaborador no mês atual</ng-template>
<!-- Tooltip Availability Chart -->
<ng-template #tipAvailabilityChart><b>Gráfico:</b> Exibe dados de Disponibilidade e Ociosidade do colaborador
  nas visões de Hoje, Semana e Mês<br /><br /><b>Ociosidade:</b> Oculta ou exibe
  somente os dados de Ociosidade<br /><br /><b>Disponibilidade:</b> Oculta ou
  exibe somente os dados de Disponibilidade</ng-template>
<!-- Tooltip Productivity Highlights Agent -->
<ng-template #tipProductivityHighlights><b>Hoje:</b> Percentual de Produtividade do colaborador no dia
  atual<br /><br /><b>Ontem:</b>
  Percentual de Produtividade do colaborador no dia de ontem<br /><br /><b>Sem:</b>
  Percentual de Produtividade do colaborador na semana atual<br /><br /><b>Mês:</b>
  Percentual de Produtividade do colaborador no mês atual</ng-template>
<!-- Tooltip Productivity Chart -->
<ng-template #tipProductivityChart><b>Gráfico:</b> Exibe dados de Produtividade Prevista, Disponibilidade e
  Produtividade Realizada na Jornada do colaborador nas visões de Hoje, Semana e
  Mês<br /><br /><b>Produtividade prevista:</b> Oculta ou exibe somente os dados
  de Produtividade Prevista<br /><br /><b>Disponibilidade:</b> Oculta ou exibe
  somente os dados de Disponibilidade<br /><br /><b>Produtividade realizada na jornada:</b>
  Oculta ou exibe somente os dados de Produtividade Realizada na
  Jornada</ng-template>
<!-- Tooltip Conection Chart -->
<ng-template #tipConectionHighlights><b>Hoje:</b> Medição de Banda do colaborador no dia atual<br /><br /><b>Ontem:</b>
  Medição de Banda do colaborador no dia de ontem<br /><br /><b>Sem:</b> Medição
  de Banda do colaborador na semana atual<br /><br /><b>Mês:</b> Medição de
  Banda do colaborador no mês atual</ng-template>
<!-- Icon overrides. -->
<ng-template matStepperIcon="phone">
  <mat-icon>call_end</mat-icon>
</ng-template>
<ng-template matStepperIcon="chat">
  <mat-icon>forum</mat-icon>
</ng-template>