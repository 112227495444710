<div class="content">
  <p>{{ subTitle }}</p>
  <table class="table" >
    <tr>
      <th>Categoria</th>
      <th>Título</th>
      <th>Tempo total</th>
      <th>Detalhe</th>
    </tr>
    <tr *ngFor="let item of applications">
      <td>{{ item.category }}</td>
      <td title="{{ item.title }}">{{ item.title.length > 70 ? (item.title | slice:0:65) + '...' : item.title }}</td>
      <td>{{ formatTime(item.time) }}</td>
      <td>
        <span (click)="searchEmployeeApplication(item.title)" *ngIf="modalIdPesquisaColaborador" class="info-icon"
          title="Saiba mais" data-bs-toggle="modal" [attr.data-bs-target]="'#' + modalIdPesquisaColaborador">
          <mat-icon>remove_red_eye</mat-icon>
        </span>
      </td>
    </tr>
  </table>

  <table class="table" id="{{IdTable}}" style="display: none;">
    <tr>
      <th>Categoria</th>
      <th>Título</th>
      <th>Tempo total</th>
    </tr>
    <tr *ngFor="let item of applications">
      <td>{{ item.category }}</td>
      <td title="{{ item.title }}">{{ item.title }}</td>
      <td>{{ formatTime(item.time) }}</td>
    </tr>
  </table>

</div>