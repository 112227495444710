import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeaderModel } from '@station/header';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

import { ChangePasswordRequest } from '../models/change-password-request.model';
import { ChangePassword } from '../models/change-password.model';
import { CustomValidators } from '../validators/custom-validators';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  form!: FormGroup
  enabledValidation!: boolean;
  changePasswordModel!: ChangePassword;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private toastr: ToastrService) { }

  header: HeaderModel = new HeaderModel(this.authService.authUser.registrationId, this.authService.authUser.firstName + " " + this.authService.authUser.lastName, this.authService.authUser.jobPosition, false, this.authService.authUser.photoPath, true, "Buscar Funcionários");



  public InitForm() {

    this.form = this.formBuilder.group({
      userId: [this.authService.authUser.id, Validators.required],
      oldPassword: [null, Validators.required],
      password: [null, Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // 3. check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // 4. check whether the entered password has a lower-case letter
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // 6. Has a minimum length of 8 and 12 characters
        CustomValidators.patternValidator(/.{8,12}/, { hasLenght: true }),
        CustomValidators.whitespaceValidator
      ])
      ],
      confirmPassword: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(12)])]
    },
      {
        validator: [CustomValidators.passwordMatchValidator, CustomValidators.usedPasswordValidator]
      })
  }

  ngOnInit(): void {
    this.InitForm()
  }

  changePassword(): void {

    this.changePasswordModel = Object.assign(this.form.value)

    let changePasswordRequest: ChangePasswordRequest = {
      userId: this.changePasswordModel.userId,
      password: this.changePasswordModel.oldPassword,
      newPassword: this.changePasswordModel.password
    }

    this.authService.changePassword(changePasswordRequest).subscribe(
      () => this.successMessage(),
      (error) => {

        let errorMsg = error?.error?.message  ?? "Ops! Parece que um erro ocorreu ao alterar a senha"
        this.errorMessage(errorMsg)
      }
    )

    
  }
  successMessage() {
    this.toastr.success(
      "Senha alterada cadastrado com sucesso",
      "Alteração de senha",
      { closeButton: true, progressBar: true },
    )
  }

  errorMessage(errorMessage: string) {
    this.toastr.error(
      errorMessage,
      "Erro ao cadastrar",
      { closeButton: true, progressBar: true },
    )
  }
}
