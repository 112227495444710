<station-employee-checklist
  [elements]="checklistItems"
  [modalId]="'station-employee-checklist-modal'"
  (checkedItems)="checkedItems($event)"
></station-employee-checklist>
<div login class="profile-user">
  <station-header [headerModel]="header"></station-header>
  <div class="profile-employees_content">
    <div class="sidbar">
      <station-sidebar></station-sidebar>
    </div>

    <main class="content">
      <div class="container-profile-employees">
        <div class="title-form">
          <div>
            <div class="title-form-b-span">
              <b>Usuários</b>
              <span
                >{{ this.profilesEmployeeModel.length }} usuários
                cadastrados.</span
              >
            </div>
            <button
              type="button"
              class="title-button-form"
              (click)="changeVision()"
              [class.button-actived]="showActive"
            >
              Ativos
            </button>
          </div>
          <div>
            <button
              *ngIf="hasRole('perfil-do-usuario-inconsistencias-leitura') && EmployeesWithDuplicateNameRegisterErrorCount() > 0 && EmployeesWithAnyRegisterErrorCount() == 0"
              type="button"
              class="btn btn-warning btn-data-danger m-1"
              (click)="dataIntegraty()"
            >
              <mat-icon>warning</mat-icon>Atenção
            </button>
            <button
              *ngIf="hasRole('perfil-do-usuario-exportar-dados')"
              type="button"
              class="btn btn-light m-1 button-form"
              (click)="exportToExcel()"
            >
              Exportar Dados
            </button>
            <button
              *ngIf="hasRole('perfil-do-usuario-importar-dados')"
              type="button"
              class="btn btn-light m-1 button-form"
              (click)="import()"
            >
              Importar
            </button>
            <button
              *ngIf="hasRole('perfil-do-usuario-criacao')"
              type="button"
              class="btn btn-light m-1 button-form"
              (click)="novoEmployee()"
            >
              Nova
            </button>
            <button
            *ngIf="hasRole('perfil-do-usuario-inconsistencias-leitura') && EmployeesWithAnyRegisterErrorCount() > 0"
              type="button"
              class="btn btn-danger btn-data-danger m-1"
              (click)="dataIntegraty()"
            >
              <mat-icon>warning</mat-icon>Inconsistências
            </button>
          </div>
        </div>

        <div class="container-employee">
          <div *ngIf="!userId" class="container-list-funcionarios">
            <div class="scroll-list">
              <div class="group-input-search">
                <input
                  (keyup)="listFilterKeyUp()"
                  class="input-search"
                  type="text"
                  [(ngModel)]="search"
                  placeholder="Buscar funcionário"
                  title="Buscar por nome, matrícula, usuário de rede, email, cargo, grupo de ferramentas ou ID"
                />
                <button
                  title="Limpar"
                  class="close-search"
                  type="button"
                  (click)="empty()"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.574922 0.575044C0.856213 0.293837 1.23768 0.135864 1.63542 0.135864C2.03317 0.135864 2.41463 0.293837 2.69592 0.575044L7.99992 5.87904L13.3039 0.575044C13.5868 0.301807 13.9657 0.150615 14.359 0.154033C14.7523 0.15745 15.1285 0.315204 15.4066 0.593316C15.6848 0.871428 15.8425 1.24765 15.8459 1.64094C15.8493 2.03424 15.6982 2.41314 15.4249 2.69604L10.1209 8.00004L15.4249 13.304C15.6982 13.5869 15.8493 13.9658 15.8459 14.3591C15.8425 14.7524 15.6848 15.1287 15.4066 15.4068C15.1285 15.6849 14.7523 15.8426 14.359 15.8461C13.9657 15.8495 13.5868 15.6983 13.3039 15.425L7.99992 10.121L2.69592 15.425C2.41302 15.6983 2.03412 15.8495 1.64082 15.8461C1.24752 15.8426 0.871306 15.6849 0.593194 15.4068C0.315082 15.1287 0.157328 14.7524 0.153911 14.3591C0.150493 13.9658 0.301685 13.5869 0.574922 13.304L5.87892 8.00004L0.574922 2.69604C0.293715 2.41475 0.135742 2.03329 0.135742 1.63554C0.135742 1.2378 0.293715 0.856335 0.574922 0.575044Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div
                #cardItem
                [attr.data-id]="profile.id"
                [attr.data-index]="i"
                class="card-item"
                *ngFor="let profile of employeesList$ | async; let i = index"
                (click)="selectEmployee(profile, cardItem)"
              >
                <station-employee-card removeLink [employee]="profile.employee">
                </station-employee-card>
              </div>
            </div>
          </div>
          <div [class.container-form-padding]="!userId" class="container-form">
            <app-profile-employees-form
              *ngIf="showForm"
              [employee]="profileSelected"
              [roles]="profileSelected.roles"
              (refreshEmployeeList)="refreshEmployeeList()"
            >
            </app-profile-employees-form>
          </div>
        </div>
      </div>
      <ng-template #contentDataIntegraty let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Inconsistências</h4>
          <button
            type="button"
            class="btn button-form"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body">
          <mat-accordion class="mat-accordion-result">
            <mat-expansion-panel
              *ngIf="companyIntegratyData.length > 0"
              (opened)="(true)"
              (closed)="(false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="color-danger">work</mat-icon>
                  Empresa inexistente</mat-panel-title
                >
                <mat-panel-description>
                  ({{ companyIntegratyData.length }}) registro(s)
                </mat-panel-description>
              </mat-expansion-panel-header>
              <table
                mat-table
                [dataSource]="companyIntegratyData"
                class="mat-elevation-z8"
              >
                <!-- FullName Column -->
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                  </td>
                </ng-container>

                <!-- RegistrationId Column -->
                <ng-container matColumnDef="registrationId">
                  <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.registrationId }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedCoulmns"
                ></tr>
              </table>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="shiftPlanIntegratyData.length > 0"
              (opened)="(true)"
              (closed)="(false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="color-danger">today</mat-icon>
                  Jornada de trabalho inexistente</mat-panel-title
                >
                <mat-panel-description>
                  ({{ shiftPlanIntegratyData.length }}) registro(s)
                </mat-panel-description>
              </mat-expansion-panel-header>
              <table
                mat-table
                [dataSource]="shiftPlanIntegratyData"
                class="mat-elevation-z8"
              >
                <!-- FullName Column -->
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                  </td>
                </ng-container>

                <!-- RegistrationId Column -->
                <ng-container matColumnDef="registrationId">
                  <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.registrationId }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedCoulmns"
                ></tr>
              </table>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="applicationGroupIntegratyData.length > 0"
              (opened)="(true)"
              (closed)="(false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="color-danger">tune</mat-icon>
                  Grupo de aplicações inexistente</mat-panel-title
                >
                <mat-panel-description>
                  ({{ applicationGroupIntegratyData.length }}) registro(s)
                </mat-panel-description>
              </mat-expansion-panel-header>
              <table
                mat-table
                [dataSource]="applicationGroupIntegratyData"
                class="mat-elevation-z8"
              >
                <!-- FullName Column -->
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                  </td>
                </ng-container>

                <!-- RegistrationId Column -->
                <ng-container matColumnDef="registrationId">
                  <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.registrationId }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedCoulmns"
                ></tr>
              </table>
            </mat-expansion-panel>
            <mat-expansion-panel
            *ngIf="jobPositionIntegratyData.length > 0"
            (opened)="(true)"
            (closed)="(false)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="color-danger">lock_opened</mat-icon>
                Perfil de acesso inexistente</mat-panel-title
              >
              <mat-panel-description>
                ({{ jobPositionIntegratyData.length }}) registro(s)
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table
              mat-table
              [dataSource]="jobPositionIntegratyData"
              class="mat-elevation-z8"
            >
              <!-- FullName Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fullName }}
                </td>
              </ng-container>

              <!-- RegistrationId Column -->
              <ng-container matColumnDef="registrationId">
                <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.registrationId }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCoulmns"
              ></tr>
            </table>
            </mat-expansion-panel>
            <mat-expansion-panel
            *ngIf="chiefIntegratyData.length > 0"
            (opened)="(true)"
            (closed)="(false)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="color-danger">person</mat-icon>
                Gestor imediato inexistente</mat-panel-title
              >
              <mat-panel-description>
                ({{ chiefIntegratyData.length }}) registro(s)
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table
              mat-table
              [dataSource]="chiefIntegratyData"
              class="mat-elevation-z8"
            >
              <!-- FullName Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fullName }}
                </td>
              </ng-container>

              <!-- RegistrationId Column -->
              <ng-container matColumnDef="registrationId">
                <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.registrationId }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCoulmns"
              ></tr>
            </table>
            </mat-expansion-panel>
            <mat-expansion-panel
            *ngIf="networkUserIntegratyData.length > 0"
            (opened)="(true)"
            (closed)="(false)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="color-danger">vpn_key</mat-icon>
                Usuário de rede em duplicidade</mat-panel-title
              >
              <mat-panel-description>
                ({{ networkUserIntegratyData.length }}) registro(s)
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table
              mat-table
              [dataSource]="networkUserIntegratyData"
              class="mat-elevation-z8"
            >
              <!-- FullName Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fullName }}
                </td>
              </ng-container>

              <!-- RegistrationId Column -->
              <ng-container matColumnDef="registrationId">
                <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.registrationId }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCoulmns"
              ></tr>
            </table>
            </mat-expansion-panel>
            <mat-expansion-panel
            *ngIf="registrationIdIntegratyData.length > 0"
            (opened)="(true)"
            (closed)="(false)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="color-danger">money</mat-icon>
                Matrícula em duplicidade</mat-panel-title
              >
              <mat-panel-description>
                ({{ registrationIdIntegratyData.length }}) registro(s)
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table
              mat-table
              [dataSource]="registrationIdIntegratyData"
              class="mat-elevation-z8"
            >
              <!-- FullName Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fullName }}
                </td>
              </ng-container>

              <!-- RegistrationId Column -->
              <ng-container matColumnDef="registrationId">
                <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.registrationId }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCoulmns"
              ></tr>
            </table>
            </mat-expansion-panel>
            <mat-expansion-panel
            *ngIf="fullNameIntegratyData.length > 0"
            (opened)="(true)"
            (closed)="(false)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="color-warning">group</mat-icon>
                Nome possivelmente em duplicidade</mat-panel-title
              >
              <mat-panel-description>
                ({{ fullNameIntegratyData.length }}) registro(s)
              </mat-panel-description>
            </mat-expansion-panel-header>
            <table
              mat-table
              [dataSource]="fullNameIntegratyData"
              class="mat-elevation-z8"
            >
              <!-- FullName Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.fullName }}
                </td>
              </ng-container>

              <!-- RegistrationId Column -->
              <ng-container matColumnDef="registrationId">
                <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.registrationId }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedCoulmns"
              ></tr>
            </table>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-template>
      <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">
            Edição em lote de usuários
          </h4>
          <button
            type="button"
            class="btn button-form"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-check mb-2">
            <input
              type="radio"
              class="form-check-input"
              id="radio1"
              [(ngModel)]="massiveEditOption"
              value="geral"
              checked
            />
            <label class="form-check-label" for="radio1">Edições Gerais</label>
          </div>
          <div class="form-check mb-2 ml">
            <input
              type="radio"
              class="form-check-input"
              id="radio2"
              [(ngModel)]="massiveEditOption"
              value="matricula"
            />
            <label class="form-check-label" for="radio2"
              >Cadastro de Matrículas</label
            >
          </div>
          <textarea
            *ngIf="massiveEditOption === 'geral'"
            #modalContent
            cols="55"
            rows="8"
            placeholder="matricula01&#10;matricula02&#10;matricula03"
            [(ngModel)]="userIds"
          ></textarea>

          <textarea
            *ngIf="massiveEditOption === 'matricula'"
            #modalContent
            cols="55"
            rows="8"
            placeholder="matricula01, nome01&#10;matricula02, nome02&#10;matricula03, nome03"
            [(ngModel)]="registrationIds"
          ></textarea>
        </div>
        <div *ngIf="massiveEditOption === 'geral'" class="modal-footer">
          <div class="station-form-group">
            <div class="station-form-flex">
              <div>
                <label class="station-form-label" for=""
                  >Perfil de acesso</label
                >
              </div>
              <div>
                <select
                  class="station-form-control"
                  [(ngModel)]="jobPositionId"
                  #select
                  (change)="onChangeJobPosition()"
                >
                  <option value="" selected>Manter o atual...</option>
                  <option *ngFor="let item of hierarchies" [ngValue]="item.id">
                    {{ item.order }} - {{ item.role }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="station-form-group">
            <div class="station-form-flex">
              <div>
                <label class="station-form-label" for="">Gestor imediato</label>
              </div>
              <div>
                <select
                  class="station-form-control"
                  [(ngModel)]="selectedChiefId"
                >
                  <option value="" selected>Manter o atual...</option>
                  <option *ngFor="let item of employees" [ngValue]="item.id">
                    {{ item.fullName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="station-form-group">
            <div class="station-form-flex">
              <div>
                <label class="station-form-label" for=""
                  >Jornada de trabalho</label
                >
              </div>
              <div>
                <select class="station-form-control" [(ngModel)]="shiftPlanId">
                  <option value="" selected>Manter o atual...</option>
                  <option *ngFor="let item of journeys" [ngValue]="item.id">
                    {{ item.description }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="station-form-group">
            <div class="station-form-flex">
              <div>
                <label class="station-form-label" for=""
                  >Grupo de ferramentas</label
                >
              </div>
              <div>
                <select
                  class="station-form-control"
                  [(ngModel)]="applicationGroupId"
                >
                  <option value="" selected>Manter o atual...</option>
                  <option
                    *ngFor="let item of applicationGroups"
                    [ngValue]="item.id"
                  >
                    {{ item.groupName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="station-form-group">
            <div class="station-form-flex">
              <div>
                <label class="station-form-label">Status</label>
              </div>
              <div>
                <label class="modal-label">
                  <input type="radio" [(ngModel)]="resultEmployeeCheckbox" checked [value]="null"><span class="modal-span">Nenhum</span>
                </label>
                <label class="modal-label">
                  <input type="radio" [(ngModel)]="resultEmployeeCheckbox" [value]="true"><span class="modal-span">Ativo</span>
                </label>
                <label class="modal-label">
                  <input type="radio" [(ngModel)]="resultEmployeeCheckbox" [value]="false"><span class="modal-span">Inativo</span>
                </label>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-light button-form btn-save"
            (click)="updateAll()"
          >
            Atualizar
          </button>
        </div>
        <div *ngIf="massiveEditOption === 'matricula'" class="modal-footer">
          <button
            type="button"
            class="btn btn-light button-form btn-save"
            (click)="updateRegistrationIds()"
          >
            Atualizar
          </button>
        </div>
        <div
          *ngIf="getRegistrationIdUpdateCount() > 0"
          class="modal-footer modal-result"
        >
          <h5 class="result-title">Resultado</h5>
          <mat-accordion class="mat-accordion-result">
            <mat-expansion-panel
              *ngIf="registrationIdSuccess.length > 0"
              (opened)="(true)"
              (closed)="(false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="color-success">done</mat-icon>
                  Sucesso</mat-panel-title
                >
                <mat-panel-description>
                  ({{ registrationIdSuccess.length }}) matrículas atualizadas
                </mat-panel-description>
              </mat-expansion-panel-header>
              <table
                mat-table
                [dataSource]="registrationIdSuccess"
                class="mat-elevation-z8"
              >
                <!-- FullName Column -->
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                  </td>
                </ng-container>

                <!-- RegistrationId Column -->
                <ng-container matColumnDef="registrationId">
                  <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.registrationId }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedCoulmns"
                ></tr>
              </table>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="registrationIdNotFound.length > 0"
              (opened)="(true)"
              (closed)="(false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="color-danger">error</mat-icon>
                  Não encotrando
                </mat-panel-title>
                <mat-panel-description>
                  ({{ registrationIdNotFound.length }}) nomes não encontrados
                </mat-panel-description>
              </mat-expansion-panel-header>
              <table
                mat-table
                [dataSource]="registrationIdNotFound"
                class="mat-elevation-z8"
              >
                <!-- FullName Column -->
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                  </td>
                </ng-container>

                <!-- RegistrationId Column -->
                <ng-container matColumnDef="registrationId">
                  <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.registrationId }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedCoulmns"
                ></tr>
              </table>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="registrationIdDuplicate.length > 0"
              (opened)="(true)"
              (closed)="(false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="color-warning">warning</mat-icon>
                  Nomes duplicados
                </mat-panel-title>
                <mat-panel-description>
                  ({{ registrationIdDuplicate.length }}) nomes em duplicidade
                </mat-panel-description>
              </mat-expansion-panel-header>
              <table
                mat-table
                [dataSource]="registrationIdDuplicate"
                class="mat-elevation-z8"
              >
                <!-- FullName Column -->
                <ng-container matColumnDef="fullName">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                  </td>
                </ng-container>

                <!-- RegistrationId Column -->
                <ng-container matColumnDef="registrationId">
                  <th mat-header-cell *matHeaderCellDef>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.registrationId }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedCoulmns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedCoulmns"
                ></tr>
              </table>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-template>
    </main>
  </div>
</div>
