import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChartColumnGroupModel, ChartItemModel, ChartLineModel } from '@station/charts';
import { Observable, Subject, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TeamAvailabilityToday } from '../models/teamAvailabilityToday.model';
import { SearchApplications } from '../models/SearchApplications.model';
import { EmployeeMininalModel } from '../models/employeeMininalModel';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  private teamAnalysisTimeLogged = new Subject<ChartItemModel>()
  teamAnalysisTimeLogged$ = this.teamAnalysisTimeLogged.asObservable()

  updateTeamAnalysisTimeLogged(data: any){

    this.teamAnalysisTimeLogged.next(data)

  }

  private chartConnection = new Subject<ChartLineModel>()
  currentchartConnection$ = this.chartConnection.asObservable()

  updateChartConnection(data: any) {
    this.chartConnection.next(data)
  }

  private comparativeJourney = new Subject<ChartColumnGroupModel>()
  comparativeJourney$ = this.comparativeJourney.asObservable()

  updatecomparativeJourney(data: any){
    this.comparativeJourney.next(data)
  }





  constructor(private http: HttpClient) { }


  getTeamLoggedInToday(chiefId: string): Observable<TeamAvailabilityToday> {
    return this.http.get<TeamAvailabilityToday>(`${environment.dashboardApi}${environment.endpoints.dashTeamAvailabilityToday}/${chiefId}`)
  }


  getRankingEmployees(userId: String): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getRankingEmployees}/${userId}`
    return this.http.get<any>(url);




  }


  getTotalBadSignalByChiefId(chiefId: String, dateFiltered:string): Observable<any>{

    let url = `${environment.dashboardApi}${environment.endpoints.getTotalBadSignalByChiefId}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);

  }


  getComparisonLoggedInAndExpectedLogin(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparisonLoggedInAndExpectedLogin}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);
  }

  getComparisonLoggedInAndExpectedLoginManager(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparisonLoggedInAndExpectedLoginManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);
  }



  getRankingEmployeeByChiefId(chiefId: String, startDate:string, endDate:string): Observable<any>{

    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getRankingEmployees}/${chiefId}?startDate=${startDate}&endDate=${endDate}&enabled=true`)

  }
  getRankingEmployeeByChiefIdManager(chiefId: String, startDate:string, endDate:string): Observable<any>{

    return this.http.get<any>(`${environment.dashboardApi}${environment.endpoints.getRankingEmployeesManager}/${chiefId}?startDate=${startDate}&endDate=${endDate}&enabled=true`);

  }
  getTeamAvailability(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAvailability}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);

  }
  getTeamAvailabilityManager(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAvailabilityManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);

  }

  getProductivityTeam(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getProductivityTeam}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);
  }
  getProductivityTeamManager(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getProductivityTeamManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);
  }
  getProductivityLoggedTeam(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getProductivityLoggedTimeHighlights}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);
  }
  getProductivityLoggedTeamManager(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getProductivityLoggedTimeHighlightsManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);
  }
  getTeamLogged(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamLogged}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url);
  }
  getTeamLoggedManager(chiefId: String, dateFiltered:any): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamLoggedManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`
    return this.http.get<any>(url).pipe(
      timeout(90000)
    );

  }
  getTeamAnalysisTimeLoggedDaily(chiefId: String, dateFiltered:string): Observable<any> {


    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAnalysisTimeLoggedDaily}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)


  }
  getTeamAnalysisTimeLoggedDailyManager(chiefId: String, dateFiltered:string): Observable<any> {


    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAnalysisTimeLoggedDailyManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)


  }
  getTeamAnalysisTimeLoggedWeekly(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAnalysisTimeLoggedWeekly}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)

  }
  getTeamAnalysisTimeLoggedWeeklyManager(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAnalysisTimeLoggedWeeklyManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)
  }

  getTeamAnalysisTimeLoggedMonthly(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAnalysisTimeLoggedMonthly}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)

  }
  getTeamAnalysisTimeLoggedMonthlyManager(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getTeamAnalysisTimeLoggedMonthlyManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url);

  }
  getComparativeJourneyDailyByChiefId(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparativeJourneyDailyByChiefId}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)

  }
  getComparativeJourneyDailyByChiefIdManager(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparativeJourneyDailyByChiefIdManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url);

  }
  getComparativeJourneyWeeklyByChiefId(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparativeJourneyWeeklyByChiefId}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)

  }
  getComparativeJourneyWeeklyByChiefIdManager(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparativeJourneyWeeklyByChiefIdManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)

  }
  getComparativeJourneyMonthlyByChiefId(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparativeJourneyMonthlyByChiefId}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)

  }
  getComparativeJourneyMonthlyByChiefIdManager(chiefId: String, dateFiltered:string): Observable<any> {

    let url = `${environment.dashboardApi}${environment.endpoints.getComparativeJourneyMonthlyByChiefIdManager}/${chiefId}?referenceDate=${dateFiltered}&enabled=true`

    return this.http.get<any>(url)

  }


  getEmployeeListThatUseApplication(searchApplication: SearchApplications): Observable<EmployeeMininalModel[]> {

    let url = `${environment.dashboardApi}${environment.endpoints.getEmployeeListThatUseApplication}`

    return this.http.post<EmployeeMininalModel[]>(url, searchApplication)

  }
  
  getEmployeeListThatUseApplicationManager(searchApplication: SearchApplications): Observable<EmployeeMininalModel[]> {

    let url = `${environment.dashboardApi}${environment.endpoints.getEmployeeListThatUseApplicationManager}`

    return this.http.post<EmployeeMininalModel[]>(url,searchApplication)

  }
}
