import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationGroup } from '../models/ApplicationGroup.model';
import { WordcloudData } from '@station/charts';

@Injectable({
  providedIn: 'root'
})
export class ApplicationGroupService {

  applicationGroup = new BehaviorSubject<ApplicationGroup[]>([])
  applicationGroup$: Observable<ApplicationGroup[]> = this.applicationGroup.asObservable()

  constructor(private http: HttpClient, private toastr: ToastrService) { }

  updateApplicationGroup(applicationGroup: ApplicationGroup[]){
    this.applicationGroup.next(applicationGroup)
  }
 
  public SendGroup(applicationGroup: ApplicationGroup): Observable<ApplicationGroup> {
   
    return this.http.post<ApplicationGroup>(`${environment.applicationsApi}${environment.endpoints.postApplicationGroups}`, applicationGroup);
  }

  public GetAllGroup(): Observable<ApplicationGroup[]> {
    return this.http.get<ApplicationGroup[]>(`${environment.applicationsApi}${environment.endpoints.getApplicationGroups}`);
  }
  public GetAllWordCloud(): Observable<WordcloudData[]> {
    return this.http.get<WordcloudData[]>(`${environment.agentApi}${environment.endpoints.getWordCloud}`);
  }
  public UpdateGroup(application: ApplicationGroup): Observable<any>{
    return this.http.put(`${environment.applicationsApi}${environment.endpoints.putApplicationGroups}/${application.id}`, application);
  }


  public SuccessMessage(){
    this.toastr.success( 
      "Seu grupo foi cadastrado com sucesso",
      "Grupo cadastrado",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMessage(){
    this.toastr.error(
      "Ops! parece que um erro ocorreu ao cadastrar esse grupo",
      "Erro ao cadastrar",
      {closeButton: true, progressBar: true},
      )
  }

  public SuccessUpdate(){
    this.toastr.success( 
      "Seu grupo foi atualizado com sucesso",
      "Grupo atualizado",
      {closeButton: true, progressBar: true},
      )
  }


  public SuccessNewClassification(description: string, group: string){
    this.toastr.success( 
      `A classificação ${description} foi adicionada com sucesso no grupo ${group}`,
      "Grupo atualizado",
      {closeButton: true, progressBar: true},
      )
  }

  public Updateilter(){
    this.toastr.success( 
      `filtro atualizado com sucesso`,
      "Classificação atualizada",
      {closeButton: true, progressBar: true},
      )
  }


  public SuccessNewFilter(description: string){
    this.toastr.success( 
      `Um novo filtro foi adicionado com sucesso na classificação ${description}`,
      "Classificação atualizada",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMensageFilterNull(){
    this.toastr.error( 
      "É necessario cadastrar pelo menos uma classificação",
      "Erro",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMensageTermNull(){
    this.toastr.error( 
      "É necessario cadastrar pelo menos um termo",
      "Erro",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMensageOriginNull(){
    this.toastr.error( 
      "É necessario selecionar pelo menos uma origem",
      "Erro",
      {closeButton: true, progressBar: true},
      )
  }


  public ErrorMensageDescritionNull(){
    this.toastr.error( 
      "O descritivo  é obrigatória",
      "Erro",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMensageCategoryNull(){
    this.toastr.error( 
      "A Categoria é obrigatória",
      "Erro",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMensageClassificationNull(){
    this.toastr.error( 
      "A Classificação é obrigatória",
      "Erro",
      {closeButton: true, progressBar: true},
      )
  }

  public ErrorMensageGroupNameNull(){
    this.toastr.error( 
      "O Nome do grupo é obrigatório é obrigatória",
      "Erro",
      {closeButton: true, progressBar: true},
      )
  }


  public ErrorUpdate(){
    this.toastr.error(
      "Ops! parece que um erro ocorreu ao atualizar esse grupo",
      "Erro ao atualizar",
      {closeButton: true, progressBar: true},
      )
  }
}
