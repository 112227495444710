import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';

import {
  EmployeeChecklistAccordionItem,
  EmployeeModel,
} from '@station/employees';
import { ProfileEmployeeService } from '../services/profile-employees.service';
import { TitleForms } from '@station/titles';

import {
  InformationDate,
  ProfileEmployeeModel,
  SelectItemHierarchy,
} from '../models/profile-employee.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { HeaderModel } from '@station/header';
import { AuthService } from 'src/app/services/auth.service';
import * as Papa from 'papaparse';
import { ProfileEmployeeMinimalModel } from '../models/profile-employee-minimal.model';
import { ProfileEmployeePatchModel } from '../models/profile-employee-patch.model';
import { ApplicationGroupMinimalModel } from '../models/application-group-minimal.model';
import { JourneyMinimalModel } from '../models/journey-minimal.model';
import { HierarchyMinimalModel } from '../models/hierarchy-minimal.model';
import { Role, RolesDefinition } from 'src/app/shared/model/role.model';
import { UpdateEmployeesRegistrationIds } from '../models/update-employees-registrationIds.model';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as XLSX from 'xlsx';
//import { faker } from '@faker-js/faker/locale/pt_BR';

@Component({
  selector: 'app-profile-employees-page',
  templateUrl: './profile-employees-page.component.html',
  styleUrls: ['./profile-employees-page.component.scss'],
})
export class ProfileEmployeesPageComponent
  implements OnInit, OnDestroy, OnChanges
{
  employees: ProfileEmployeeMinimalModel[] = [];
  applicationGroups: ApplicationGroupMinimalModel[] = [];
  journeys: JourneyMinimalModel[] = [];
  hierarchies: HierarchyMinimalModel[] = [];
  profList: SelectItemHierarchy[] = [];
  subscriberHierarchy!: Subscription;
  listInformactionDate: InformationDate[] = [];
  userIds!: string;
  userId!: string | null;
  registrationIds!: string;
  selectedChiefId = '';
  showActive: boolean = true;
  shiftPlanId = '';
  applicationGroupId = '';
  jobPositionId = '';
  roles: string[] = [];
  rolesResponse: Role[] = [];
  checklistItems: EmployeeChecklistAccordionItem[] = [];
  massiveEditOption: string = 'geral'; // Define a opção padrão
  displayedCoulmns = ['fullName', 'registrationId'];
  resultEmployeeCheckbox: boolean | null = null;

  // Array com registrationId de sucesso
  registrationIdSuccess: UpdateEmployeesRegistrationIds[] = [];

  // Array com registrationId não encontrados
  registrationIdNotFound: UpdateEmployeesRegistrationIds[] = [];

  // Array com registrationId duplicados
  registrationIdDuplicate: UpdateEmployeesRegistrationIds[] = [];

  companyIntegratyData: UpdateEmployeesRegistrationIds[] = [];
  shiftPlanIntegratyData: UpdateEmployeesRegistrationIds[] = [];
  applicationGroupIntegratyData: UpdateEmployeesRegistrationIds[] = [];
  jobPositionIntegratyData: UpdateEmployeesRegistrationIds[] = [];
  chiefIntegratyData: UpdateEmployeesRegistrationIds[] = [];
  networkUserIntegratyData: UpdateEmployeesRegistrationIds[] = [];
  registrationIdIntegratyData: UpdateEmployeesRegistrationIds[] = [];
  fullNameIntegratyData: UpdateEmployeesRegistrationIds[] = [];

  constructor(
    private service: ProfileEmployeeService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  @ViewChildren('cardItem') private listCard!: QueryList<
    ElementRef<HTMLElement>
  >;
  @ViewChild('content', { read: TemplateRef })
  private modalExample!: TemplateRef<any>;
  @ViewChild('contentDataIntegraty', { read: TemplateRef })
  private modalDataIntegraty!: TemplateRef<any>;

  header: HeaderModel = new HeaderModel(
    this.authService.authUser.registrationId,
    this.authService.authUser.firstName +
      ' ' +
      this.authService.authUser.lastName,
    this.authService.authUser.jobPosition,
    false,
    this.authService.authUser.photoPath,
    true,
    'Buscar Funcionários'
  );

  private subscriptionEmployee!: Subscription;
  private subscription!: Subscription;

  public employeesList$!: Observable<ProfileEmployeeModel[]>;
  public employeesFiltred: ProfileEmployeeModel[] = [];
  public employeesNoFiltred: ProfileEmployeeModel[] = [];
  public employeesListOrign$!: Observable<ProfileEmployeeModel[]>;

  public chiefList: ProfileEmployeeModel[] = [];
  public employee!: ProfileEmployeeModel;

  public profilesEmployeeModel: ProfileEmployeeModel[] = [];
  public profileSelected: ProfileEmployeeModel = new ProfileEmployeeModel();

  public lastAgentLogin!: Date;
  public lastSationLogin!: Date;

  public showForm: boolean = false;
  public search: string = '';
  public titleForm: TitleForms = new TitleForms(
    'Usuários ',
    'sem usuários para exibir',
    false,
    true,
    false,
    false,
    true
  );
  public closeResult = '';
  public pending: number = 0;

  //#endregion Properties

  ngOnInit(): void {
    this.getHierarchies();
    this.getJourneys();
    this.getApplicationGroups();
    this.getUrlParameters();
    this.EmployeesWithAnyRegisterError();
    this.getListUpdate();
    this.getRoles();
    this.showActives();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.removeBgWarningClass();
  }
  getUrlParameters(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = params.get('id');

      if (this.userId) {
        this.subscription = this.service.getEmployee(this.userId).subscribe({
          next: (resp) => {
            this.profileSelected = resp;

            this.sendForm(this.profileSelected);
          },
          error: (e) => this.failReponse(e),
        });
      }
    });
  }
  getRoles() {
    this.authService.getAllRoles().subscribe((data) => {
      this.rolesResponse = data;
      this.checklistItems = this.generateChecklistItems(this.rolesResponse);
    });
  }

  generateChecklistItems(
    rolesResponse: Role[]
  ): EmployeeChecklistAccordionItem[] {
    return rolesResponse.map((role: Role) => ({
      title: role.groupTitle,
      checkItems: role.rolesDefinition.map(
        (roleDefinition: RolesDefinition) => ({
          id: roleDefinition.slug,
          description: roleDefinition.description,
          checked:
            this.profileSelected?.roles.includes(roleDefinition.slug) ?? false,
        })
      ),
    }));
  }
  private getJourneys() {
    this.service.getJourneyMinimal().subscribe((data) => {
      this.journeys = data.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
    });
  }
  private getApplicationGroups() {
    this.service.getApplicationGroupMinimal().subscribe((data) => {
      this.applicationGroups = data.sort((a, b) =>
        a.groupName.localeCompare(b.groupName)
      );
    });
  }
  private getHierarchies() {
    this.service.getHierarchyMinimal().subscribe((data) => {
      this.hierarchies = data.sort((a, b) => a.order - b.order);
    });
  }

  private getListUpdate() {
    // Caso tenha recebido um user Id nao carrega a lista de usuarios
    if (this.userId) {
      return;
    }

    this.service.getEmployees()
      .subscribe((data) => {
        console.log("EMPLOYEES",data)
        this.successLoad(data);
        this.getUrlParameters();
      }).unsubscribe;
  }
  private successLoad(lista: ProfileEmployeeModel[]) {
    this.profilesEmployeeModel = lista.map((i) => {
      let item = { ...i, fullName: `${i.firstName} ${i.lastName}` };
      item.newemployee = false;
      return item;
    });

    this.employeesFiltred = this.profilesEmployeeModel;
    this.employeesNoFiltred = this.profilesEmployeeModel;

    this.employeesList$ = new Observable<ProfileEmployeeModel[]>(
      (subcriber) => {
        subcriber.next(
          lista.map((i) => {
            let name = `${i.firstName} ${i.lastName}`;
            let photo = i.photoPath ?? '/assets/img/user-default.png';
            let employee = new EmployeeModel(
              name,
              i.jobPosition,
              i.enabled,
              '',
              photo,
              false,
              false,
              '',
              false,
              false,
              false,
              `/painel-colaborador/${i.id}`
            );
            employee.teamIsOnline = 1;
            i.employee = employee;
            return i;
          })
        );
        subcriber.unsubscribe();
      }
    );

    this.employeesListOrign$ = this.employeesList$;
    this.filterEmployeesEnable().then(
      (result) => (this.employeesList$ = result)
    );
  }

  public selectEmployee(
    profileEmployee: ProfileEmployeeModel,
    div: HTMLDivElement
  ) {
    if (profileEmployee.id === '') {
      this.sendForm(profileEmployee, div);
    } else {
      this.subscription = this.service
        .getEmployee(profileEmployee.id)
        .subscribe({
          next: (resp) => this.sendForm(resp, div),
          error: (e) => this.failReponse(e),
        });
    }
  }

  //#region Modal

  open(content: any) {
    this.removerClass();

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
      .result.then(
        (result) => {},
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  updateAll() {
    if (this.userIds == undefined) {
      this.toastr.info('Informe pelo menos uma matrícula', 'Atenção');
    }

    const userIdsArray = this.userIds
      .split('\n')
      .map((id) => id.trim()) // Remove espaços em branco no início e no final de cada ID
      .filter((id) => id !== ''); // Filtra IDs vazios após a remoção de espaços

    const hierarchyResult = this.updateHierarchy(userIdsArray);
    const chiefIdResult = this.updateChiefId(userIdsArray);
    const journeyResult = this.updateJourney(userIdsArray);
    const applicationGroupResult = this.updateApplicationGroup(userIdsArray);
    const statusResult = this.updateStatus(userIdsArray);

    if (
      !hierarchyResult &&
      !chiefIdResult &&
      !journeyResult &&
      !applicationGroupResult &&
      !statusResult
    ) {
      this.toastr.info(
        'É necessário escolher pelo menos uma opção de atualização em lote',
        'Opção não selecionada'
      );
    }
  }

  updateRegistrationIds() {
    const registrationLines = this.registrationIds
      .split('\n')
      .map((id) => id.trim()) // Remove espaços em branco no início e no final de cada ID
      .filter((id) => id !== ''); // Filtra IDs vazios após a remoção de espaços;

    const registrationArray: UpdateEmployeesRegistrationIds[] = [];

    for (const line of registrationLines) {
      const [matricula, nome] = line.split(',');
      const employee: UpdateEmployeesRegistrationIds = {
        registrationId: matricula.trim(),
        fullName: nome.trim(),
      };
      registrationArray.push(employee);
    }

    this.service
      .postUpdateEmployeesRegistrationIds(registrationArray)
      .subscribe((res) => {
        if (res.employeesNotFound.length > 0) {
          res.employeesNotFound.forEach((element: any) => {
            var employee: UpdateEmployeesRegistrationIds = {
              fullName: element.fullName,
              registrationId: element.registrationId,
            };
            this.registrationIdNotFound.push(employee);
          });
        }

        if (res.duplicateEmployees.length > 0) {
          res.duplicateEmployees.forEach((element: any) => {
            var employee: UpdateEmployeesRegistrationIds = {
              fullName: element.fullName,
              registrationId: element.registrationId,
            };
            this.registrationIdDuplicate.push(employee);
          });
        }

        res.employeeTotalSuccefulUpdate.forEach((element: any) => {
          var employee: UpdateEmployeesRegistrationIds = {
            fullName: element.fullName,
            registrationId: element.registrationId,
          };
          this.registrationIdSuccess.push(employee);
        });
      });
  }

  EmployeesWithAnyRegisterError() {
    this.service.getEmployeesWithNonExistentCompanyId().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((employee: UpdateEmployeesRegistrationIds) => {
          this.companyIntegratyData.push(employee);
        });
      }
    });

    this.service.getEmployeesWithNonExistentShiftPlainId().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((employee: UpdateEmployeesRegistrationIds) => {
          this.shiftPlanIntegratyData.push(employee);
        });
      }
    });

    this.service.GetEmployeesWithNonExistentJobPosition().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((employee: UpdateEmployeesRegistrationIds) => {
          this.jobPositionIntegratyData.push(employee);
        });
      }
    });

    this.service
      .GetEmployeesWithNonExistentApplicationGroupId()
      .subscribe((res) => {
        if (res.length > 0) {
          res.forEach((employee: UpdateEmployeesRegistrationIds) => {
            this.applicationGroupIntegratyData.push(employee);
          });
        }
      });

    this.service.GetEmployeesWithNonExistentChiefId().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((employee: UpdateEmployeesRegistrationIds) => {
          this.chiefIntegratyData.push(employee);
        });
      }
    });

    this.service.GetEmployeesWithSameFullname().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((employee: UpdateEmployeesRegistrationIds) => {
          this.fullNameIntegratyData.push(employee);
        });
      }
    });

    this.service.GetEmployeesWithSameNetworkUser().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((employee: UpdateEmployeesRegistrationIds) => {
          this.networkUserIntegratyData.push(employee);
        });
      }
    });

    this.service.GetEmployeesWithSameRegistrationId().subscribe((res) => {
      if (res.length > 0) {
        res.forEach((employee: UpdateEmployeesRegistrationIds) => {
          this.registrationIdIntegratyData.push(employee);
        });
      }
    });
  }

  updateChiefId(userIdsArray: string[]) {
    const selectedChiefId = this.selectedChiefId;

    if (userIdsArray.length > 0 && selectedChiefId) {
      const profileEmployeePatchModel: ProfileEmployeePatchModel = {
        filterValues: userIdsArray,
        employee: {
          chiefId: selectedChiefId,
        },
      };

      this.service
        .patchEmployeeByOtherField(profileEmployeePatchModel)
        .subscribe(
          (data) => {
            const affectedCount = data;

            if (affectedCount == 0) {
              this.toastr.error(
                'Ocorreu um erro ao tentar realizar as modificações.'
              );
              return false;
            }
            this.toastr.success(
              `Gestor Imediato atualizado com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
            );
            return true;
          },
          () => {
            this.toastr.error(
              'Ocorreu um erro ao tentar realizar as modificações.'
            );
            return false;
          }
        );
    }
    return false;
  }

  updateHierarchy(userIdsArray: string[]) {
    if (userIdsArray.length > 0 && this.jobPositionId) {
      const jobPosition = this.hierarchies.find(
        (x) => x.id == this.jobPositionId
      )?.role;

      const profileEmployeePatchModel: ProfileEmployeePatchModel = {
        filterValues: userIdsArray,
        employee: {
          jobPositionId: this.jobPositionId,
          jobPosition: jobPosition,
        },
      };

      this.service
        .patchEmployeeByOtherField(profileEmployeePatchModel)
        .subscribe(
          (data) => {
            const affectedCount = data;

            if (affectedCount == 0) {
              this.toastr.error(
                'Ocorreu um erro ao tentar realizar as modificações.'
              );
              return false;
            }
            if(affectedCount !=userIdsArray.length)
            {
              this.toastr.warning(
                `Perfil de acesso atualizado com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
              );
              return true;
            }
            this.toastr.success(
              `Perfil de acesso atualizado com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
            );
            return true;
          },
          () => {
            this.toastr.error(
              'Ocorreu um erro ao tentar realizar as modificações.'
            );
            return false;
          }
        );
    }

    return false;
  }

  updateJourney(userIdsArray: string[]) {
    if (userIdsArray.length > 0 && this.shiftPlanId) {
      const profileEmployeePatchModel: ProfileEmployeePatchModel = {
        filterValues: userIdsArray,
        employee: {
          shiftPlanId: this.shiftPlanId,
        },
      };

      this.service
        .patchEmployeeByOtherField(profileEmployeePatchModel)
        .subscribe(
          (data) => {
            const affectedCount = data;

            if (affectedCount == 0) {
              this.toastr.error(
                'Ocorreu um erro ao tentar realizar as modificações.'
              );
              return false;
            }
            if(affectedCount !=userIdsArray.length)
            {
              this.toastr.warning(
                `Jornada de trabalho atualizada com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
              );
              return true;
            }
            this.toastr.success(
              `Jornada de trabalho atualizada com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
            );
            return true;
          },
          () => {
            this.toastr.error(
              'Ocorreu um erro ao tentar realizar as modificações.'
            );
            return false;
          }
        );
    }
    return false;
  }

  updateApplicationGroup(userIdsArray: string[]) {
    if (userIdsArray.length > 0 && this.applicationGroupId) {
      const profileEmployeePatchModel: ProfileEmployeePatchModel = {
        filterValues: userIdsArray,
        employee: {
          applicationGroupId: this.applicationGroupId,
        },
      };

      this.service
        .patchEmployeeByOtherField(profileEmployeePatchModel)
        .subscribe(
          (data) => {
            const affectedCount = data;

            if (affectedCount == 0) {
              this.toastr.error(
                'Ocorreu um erro ao tentar realizar as modificações.'
              );
              return false;
            }
            if(affectedCount !=userIdsArray.length)
            {
              this.toastr.warning(
                `Grupo de ferramentas atualizado com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
              );
              return true;
            }
            this.toastr.success(
              `Grupo de ferramentas atualizado com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
            );
            return true;
          },
          () => {
            this.toastr.error(
              'Ocorreu um erro ao tentar realizar as modificações.'
            );
            return false;
          }
        );
    }
    return false;
  }
  updateStatus(userIdsArray: string[]) {
    if (userIdsArray.length > 0 && this.resultEmployeeCheckbox != null) {
      const profileEmployeePatchModel: ProfileEmployeePatchModel = {
        filterValues: userIdsArray,
        employee: {
          enabled: this.resultEmployeeCheckbox,
        },
      };

      this.service
        .patchEmployeeByOtherField(profileEmployeePatchModel)
        .subscribe(
          (data) => {
            const affectedCount = data;

            if (affectedCount == 0) {
              this.toastr.error(
                'Ocorreu um erro ao tentar realizar as modificações.'
              );
              return false;
            }
            if(affectedCount !=userIdsArray.length)
            {
              this.toastr.warning(
                `Status atualizado com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
              );
              return true;
            }
            this.toastr.success(
              `Status atualizado com sucesso para ${affectedCount} de ${userIdsArray.length} funcionário(s).`
            );
            return true;
          },
          () => {
            this.toastr.error(
              'Ocorreu um erro ao tentar realizar as modificações.'
            );
            return false;
          }
        );
    }
    return false;
  }

  //#endregion Modal

  public refreshEmployeeList() {
    this.getListUpdate();
    this.showForm = false;
  }

  public sendForm(
    response: ProfileEmployeeModel,
    elem: HTMLDivElement | null = null
  ) {
    this.removerClass();

    if (elem) elem.classList.add('ativo');

    this.showForm = true;

    this.service
      .GetLastStationCheckinByUserId(response.id)
      .subscribe((data) => {
        this.profileSelected.lastStationCheckin = data?.checkinDate;
      });

    this.service.GetLastAgenntLoginByUserId(response.id).subscribe((data) => {
      this.profileSelected.lastAgentLogin = data?.startDate;
    });

    this.profileSelected = response;
    this.checklistItems = this.generateChecklistItems(this.rolesResponse); //Refresh Checked items
  }

  public failReponse(error: HttpErrorResponse) {
    let message = 'Erro de conexão com servidor. ';

    if (error.status == 401) {
      message = message + ' 401 Não autorizado';
    }

    const config: Partial<IndividualConfig> = {
      progressBar: true,
    };

    this.toastr.error(message, "Op's houve um erro", config);
    this.showForm = false;
  }

  public listFilterKeyUp() {
    this.removerClass();
    this.stationTools(StationStates.ALL);
  }

  searchEmployee(items: any, valor: string): ProfileEmployeeModel[] {
    console.log('EMPLOYEE', items);

    if (!valor || valor.length == 0) {
      if (!this.showActive) {
        return items.filter((item: any) => item.enabled === false);
      } else {
        return items.filter((item: any) => item.enabled === true);
      }
    }

    return items.filter(
      (item: any) =>
        this.searchIsMatch(item, valor) && item.enabled === this.showActive
    );
  }

  searchIsMatch(item: any, valor: string): boolean {
    const lowerCaseValor = valor.toLocaleLowerCase().trim();

    const fullNameMatch = item.fullName
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const registrationIdMatch = item.registrationId
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const networkUserMatch = item.networkUser
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const emailMatch = item.email
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const jobPositionMatch = item.jobPosition
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const shiftPlanIdMatch = item.shiftPlanId
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const chiefIdMatch = item.chiefId
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const idMatch = item.id
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);
    const applicationGroupMatch = item.applicationGroup
      ?.toLocaleLowerCase()
      .trim()
      .includes(lowerCaseValor);

    return (
      fullNameMatch ||
      registrationIdMatch ||
      networkUserMatch ||
      emailMatch ||
      jobPositionMatch ||
      shiftPlanIdMatch ||
      chiefIdMatch ||
      idMatch ||
      applicationGroupMatch
    );
  }
  public stationTools(status: StationStates) {
    let copyList = this.profilesEmployeeModel.map((p) => p);
    let items = this.searchEmployee(copyList, this.search);

    console.log('ITENS', items);

    items.forEach((i) => {
      let name = `${i.firstName} ${i.lastName}`;

      let employee = new EmployeeModel(
        name,
        i.jobPosition,
        i.enabled,
        '',
        i.photoPath,
        false,
        false,
        '',
        false,
        false,
        false,
        `/painel-colaborador/${i.id}`
      );
      employee.teamIsOnline = 1;

      i.employee = new EmployeeModel(
        i.firstName + ' ' + i.lastName,
        i.jobPosition,
        i.enabled,
        '',
        i.photoPath,
        false,
        false,
        '',
        false,
        false,
        false,
        `/painel-colaborador/${i.id}`,
        false,
        1
      );
    });

    this.employeesList$ = new Observable<ProfileEmployeeModel[]>(
      (subscriber) => {
        subscriber.next(items);
        this.employeesFiltred = items;
        subscriber.unsubscribe();
      }
    );

    this.showForm = false;
    this.removerClass();
    this.removeBgWarningClass();
  }
  exportToExcel(): void {
    const colNames = [
      'Id',
      'Nome',
      'Sobrenome',
      'Email',
      'Matricula',
      'Usuário de rede',
      'Telefone',
      'Perfil de acesso',
      'Gestor imediato',
      'Joranada de trabalho',
      'Grupo de ferramenta',
    ];

    const data = this.employeesFiltred.map((detail) => [
      detail.id,
      detail.firstName || '',
      detail.lastName || '',
      detail.email || '',
      detail.registrationId || '',
      detail.networkUser || '',
      detail.phone || '',
      detail.employee.role || '',
      this.getchief(detail.chiefId) || '', // Certifique-se de que a função getchief retorna um valor adequado
      (this.journeys.find((x) => x.id === detail.shiftPlanId) || {})
        .description || '',
      (
        this.applicationGroups.find(
          (x) => x.id === detail.applicationGroupId
        ) || {}
      ).groupName || '',
    ]);

    // Adicionar o cabeçalho aos dados
    const wsData = [colNames, ...data];

    // Criar uma planilha
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(wsData);

    // Criar um livro de trabalho
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Funcionários');

    // Exportar a planilha para um arquivo Excel
    XLSX.writeFile(wb, 'StationRelatórioFuncionario.xlsx');
  }

  downloadCSV() {
    const csvContent = this.generateCSV();
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContent], {
      type: 'text/csv; charset=utf-8',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'StationRelatórioFuncionario.xls';
    a.click();

    window.URL.revokeObjectURL(url);
  }

  generateCSV() {
    const csvRows = [];
    const headers = [
      'Id',
      'Nome',
      'Sobrenome',
      'Email',
      'Matricula',
      'Usuário de rede',
      'Telefone',
      'Perfil de acesso',
      'Gestor imediato',
      'Joranada de trabalho',
      'Grupo de ferramenta',
    ];

    const rows = this.employeesFiltred.map((detail) => [
      detail.id,
      detail.firstName == null ? '' : detail.firstName,
      detail.lastName == null ? '' : detail.lastName,
      detail.email == null ? '' : detail.email,
      detail.registrationId == null ? '' : detail.registrationId,
      detail.networkUser == null ? '' : detail.networkUser,
      detail.phone == null ? '' : detail.phone,
      detail.employee.role == null ? '' : detail.employee.role,
      (detail.chief = this.getchief(detail.chiefId)),
      (detail.shiftPlan = this.journeys.find(
        (x) => x.id === detail.shiftPlanId
      )?.description),
      (detail.applicationGroup = this.applicationGroups.find(
        (x) => x.id === detail.applicationGroupId
      )?.groupName),
    ]);

    csvRows.push(headers.join(','));
    for (const row of rows) {
      csvRows.push(row.map((item) => `"${item}"`).join(','));
    }

    return csvRows.join('\n');
  }

  private getchief(chiefId: string): string {
    let chief = this.employeesNoFiltred.find((x) => x.id === chiefId)?.employee
      .name;
    return chief == undefined ? '' : chief;
  }

  private removerClass() {
    this.listCard.forEach((cardItem) =>
      cardItem.nativeElement.classList.remove('ativo')
    );
  }
  removeBgWarningClass() {
    const statusCircles = this.el.nativeElement.querySelectorAll(
      '.status-circle.bg-warning'
    );

    statusCircles.forEach((statusCircle: any) => {
      this.renderer.removeClass(statusCircle, 'bg-warning');
    });
  }
  public empty() {
    this.search = '';
    this.stationTools(StationStates.ALL);
  }

  public import() {
    this.showForm = false;
    this.removerClass();

    this.modalService.open(this.modalExample, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  public dataIntegraty() {
    this.showForm = false;
    this.removerClass();

    this.modalService
      .open(this.modalDataIntegraty, { centered: true })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  showActives() {
    this.filterEmployeesEnable().then((result) => {
      this.employeesList$ = result;
    });
  }

  changeVision() {
    this.showActive = !this.showActive;
    this.filterEmployeesEnable(this.showActive).then((result) => {
      this.employeesList$ = result;
    });
  }

  async filterEmployeesEnable(
    enabled: boolean = true
  ): Promise<Observable<ProfileEmployeeModel[]>> {
    return await new Promise((resolve) => {
      this.employeesListOrign$?.subscribe((list: ProfileEmployeeModel[]) => {
        let lista: ProfileEmployeeModel[] = list.filter(
          (x) => x.enabled === enabled
        );
        resolve(
          new Observable<ProfileEmployeeModel[]>((sub) => sub.next(lista))
        );
      });
    });
  }

  public novoEmployee() {
    this.empty();
    this.profileSelected = new ProfileEmployeeModel();
    this.profileSelected.employee = new EmployeeModel(
      '',
      'Colaborador',
      true,
      '',
      '/assets/img/user-default.png',
      true,
      false
    );
    this.profileSelected.newemployee = true;
    this.showForm = true;
    this.removeBgWarningClass();
  }
  public onChange(select: HTMLSelectElement) {
    let novoPerfilSelecionado = select.value;
    if (novoPerfilSelecionado.indexOf(':') > 0) {
      const initCut = novoPerfilSelecionado.indexOf(':') + 1;
      novoPerfilSelecionado = novoPerfilSelecionado
        .substring(initCut, novoPerfilSelecionado.length)
        .trim();
    }

    let order = Number(novoPerfilSelecionado.substring(0, 1));

    this.service.getEmployeeChiefs(order).subscribe({
      next: (lista) => {
        // Converta os objetos ProfileEmployeeModel para ProfileEmployeeMinimalModel
        const minimalEmployees = this.convertToMinimalModels(lista);
        this.employees = minimalEmployees;
      },
      error: (error) => console.log(error),
    });
  }
  public onChangeJobPosition() {
    let novoPerfilSelecionado = this.hierarchies.find(
      (x) => x.id == this.jobPositionId
    );

    if (!novoPerfilSelecionado) {
      return;
    }

    let order = Number(novoPerfilSelecionado.order);

    this.service.getEmployeeChiefs(order).subscribe({
      next: (lista) => {
        // Converta os objetos ProfileEmployeeModel para ProfileEmployeeMinimalModel
        const minimalEmployees = this.convertToMinimalModels(lista);
        this.employees = minimalEmployees;
      },
      error: (error) => console.log(error),
    });
  }
  private convertToMinimalModels(
    employees: ProfileEmployeeModel[]
  ): ProfileEmployeeMinimalModel[] {
    return employees.map((employee) => {
      const minimalModel = new ProfileEmployeeMinimalModel();
      minimalModel.id = employee.id;
      minimalModel.fullName = employee.firstName + ' ' + employee.lastName;
      return minimalModel;
    });
  }
  ngOnDestroy(): void {
    this.subscriptionEmployee?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  public importCSV(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input) return;

    const file = input.files?.[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (result) => {},
    });
  }
  checkedItems(event: any) {
    this.profileSelected.roles = event;
    this.toastr.info(
      `${this.profileSelected.roles.length} permissões atribuídas`
    );
  }
  getRegistrationIdUpdateCount(): number {
    return (
      this.registrationIdSuccess.length +
      this.registrationIdNotFound.length +
      this.registrationIdDuplicate.length
    );
  }
  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }
  EmployeesWithAnyRegisterErrorCount(): number {
    return (
      this.companyIntegratyData.length +
      this.shiftPlanIntegratyData.length +
      this.applicationGroupIntegratyData.length +
      this.jobPositionIntegratyData.length +
      this.chiefIntegratyData.length +
      this.networkUserIntegratyData.length +
      this.registrationIdIntegratyData.length
    );
  }
  EmployeesWithDuplicateNameRegisterErrorCount(): number {
    return this.fullNameIntegratyData.length;
  }
}

enum StationStates {
  ATIVO = 0,
  PENDING = 1,
  ALL = 2,
  INACTIVE = 3,
}
