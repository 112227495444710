import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmployeesModule } from '@station/employees';
import { MenuModule } from '@station/menus';

import { DashHeaderComponent } from '../../components/dash-header/dash-header.component';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { TableAplicacoesComponent } from '../../components/table-aplicacoes/table-aplicacoes.component';
import { TableAplicationUtilityComponent } from '../../components/table-aplications-utility/table-aplications-utility.component';
import { HoldableDirective } from '../../directive/holdable.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import { DatetimeFormatPipe } from '../../pipes/datetime-format.pipe';
import { TimeFormatPipe } from '../../pipes/time-format.pipe';
import { UserNotificationComponent } from '../../components/user-notification/user-notification.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DashHeaderComponent,
    TableAplicacoesComponent,
    TableAplicationUtilityComponent,
    UserNotificationComponent,
    SidebarComponent,
    HoldableDirective,
    DatetimeFormatPipe,
    TimeFormatPipe
  ],
  exports: [
    DashHeaderComponent,
    TableAplicationUtilityComponent,
    TableAplicacoesComponent,
    SidebarComponent,
    UserNotificationComponent
  ],
  imports: [
    EmployeesModule,
    MenuModule,
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatTableModule,
    RouterModule,
    FormsModule
  ]
})
export class DashHeaderModule { }
