import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardJourneyModel, SubtitleCardJourneyModel } from '@station/employees';
import { HeaderModel } from '@station/header';

import { Observable, retry, take, tap, map } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { ApplicationFilters } from '../models/ApplicationFilter.model';
import { ApplicationFilterGroups } from '../models/ApplicationFilterGroup.model';
import { ApplicationGroup } from '../models/ApplicationGroup.model';
import { ApplicationRequest } from '../models/ApplicationRequest.model';
import { ApplicationGroupService } from '../services/application-group.service';
import { WordcloudData } from '@station/charts';
import { ToastrService } from 'ngx-toastr';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  terms: any[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our term
    if ((value || '').trim()) {
      this.terms.push({ name: value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }

    let termsString = this.toStringWithDelimiter(this.terms);

    this.form.controls['term'].setValue(termsString);
  }
  remove(term: any): void {
    const index = this.terms.indexOf(term);

    if (index >= 0) {
      this.terms.splice(index, 1);
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private applicationGroupService: ApplicationGroupService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) { }
  header: HeaderModel = new HeaderModel(
    this.authService.authUser.registrationId,
    this.authService.authUser.firstName +
    ' ' +
    this.authService.authUser.lastName,
    this.authService.authUser.jobPosition,
    false,
    this.authService.authUser.photoPath,
    true,
    'Buscar Funcionários'
  );

  //### formBuild ###
  form!: FormGroup;
  group: ApplicationRequest = {
    action: '',
    id: '',
    groupName: '',
    description: '',
    category: '',
    classification: '',
    ignoreIdleInputDevice: false,
    term: '',
    title: false,
    url: false,
    processName: false,
    indexClassification: null,
    indexFilter: null,
  };

  @ViewChild('inputTerms') inputTerms!: ElementRef;
  indexClassification!: number;
  indexCurrentClassification!: number;
  currentDescription!: string;
  currentAction!: string;
  action: string = 'create';
  showApplicationArea!: boolean;
  showNewGroup!: boolean;
  showButtonAddFilter!: boolean;
  showButton!: boolean;
  applicationGroup!: ApplicationGroup;
  applicationFilterGroups!: ApplicationFilterGroups;
  applicationGroupRequest!: ApplicationRequest;
  applicationFilters!: ApplicationFilters;
  currentGroupApplication: ApplicationGroup = new ApplicationGroup("", false, [], "");
  applicationFilterGroup: ApplicationFilterGroups[] = [];
  applicationFilterGroupsFiltered: ApplicationFilterGroups[] = [];
  arrayCardJourney: CardJourneyModel[] = [];
  subtitle: SubtitleCardJourneyModel[] = [];
  cardJourney!: CardJourneyModel;
  journeyFiltered?: CardJourneyModel[];
  journeyFiltered$?: Observable<CardJourneyModel[]>;
  _filtroLista: string = '';

  wordCloudData: WordcloudData[] = [];

  get filtroLista(): string {
    return this._filtroLista;
  }
  set filtroLista(value: string) {
    this._filtroLista = value;
    this.journeyFiltered = this.filtroLista
      ? this.journeyFilter(this.filtroLista)
      : this.arrayCardJourney;
    this.journeyFiltered$ = new Observable((sub) =>
      sub.next(this.journeyFiltered)
    );
  }

  ngOnInit(): void {
    this.showNewGroup = false;
    this.showButton = false;
    this.currentAction = 'Adicionar Nova Classificação';
    this.showButtonAddFilter = true;
    this.currentGroupApplication.id = ''
    this.initForm();
    this.GetAllGroup();
    this.GetWordCloud();
  }
  public openWordCloud() {
    this.GetWordCloud();
  }

  public GetAllGroup() {
    this.applicationGroupService.GetAllGroup().subscribe({
      next: (result) => {
        this.setAplicationGroupSubject(result);

        this.generateCards()
          .then((data) => (this.arrayCardJourney = data))
          .finally(() => this.getJourney());
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
  public GetWordCloud() {
    this.applicationGroupService.GetAllWordCloud().subscribe({
      next: (result) => {
        this.wordCloudData = result.map((item: any) => {
          return new WordcloudData(item.word, item.count);
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  setAplicationGroupSubject(applicationGroup: ApplicationGroup[]) {
    this.applicationGroupService.updateApplicationGroup(applicationGroup);
  }

  private async generateCards() {
    const data: CardJourneyModel[] = await new Promise((resolve) => {
      this.applicationGroupService.applicationGroup$.subscribe({
        next: (applications) => {
          let cardJourney: CardJourneyModel[] = [];

          for (let app of applications) {
            let subtitles = [];
            subtitles.push(
              new SubtitleCardJourneyModel(
                `${app.applicationFilterGroup.length} Classificações`,
                true,
                false
              )
            );
            cardJourney.push(
              new CardJourneyModel(app.groupName, subtitles, '', '')
            );
          }

          this.getJourney();
          this.arrayCardJourney = cardJourney;
          resolve(cardJourney);
        },
      });
    });

    return data;
  }

  getJourney() {
    this.journeyFiltered$ = new Observable((sub) =>
      sub.next(this.arrayCardJourney)
    );
  }

  journeyFilter(filtrarPor: string): CardJourneyModel[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();

    return this.arrayCardJourney.filter(
      (journey: any) =>
        journey.title.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  setIndexCurrentClassification(index: number, description: string) {
    this.indexCurrentClassification = index;
    this.currentDescription = description;
  }

  newGroup() {
    this.action = 'create';
    this.showNewGroup = true;
    this.showButtonAddFilter = true;
    this.showApplicationArea = false;
    this.showButton = false;
    this.applicationFilterGroup.splice(0, this.applicationFilterGroup.length);
    this.applicationFilterGroupsFiltered = [];
    this.resetAllForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      action: 'create',
      id: [this.group.id],
      groupName: [this.group.groupName, Validators.required],
      description: [this.group.description],
      category: [this.group.category],
      classification: [this.group.classification],
      ignoreIdleInputDevice: [this.group.ignoreIdleInputDevice],
      term: [this.group.term],
      title: [this.group.title],
      url: [this.group.url],
      processName: [this.group.processName],
    });
  }

  async addFilter() {
    this.applicationGroupRequest = Object.assign(this.form.value);

    let isValid = this.validatorAddFilter(this.applicationGroupRequest);
    if (!isValid) return;

    if (this.isAnyFieldNull(this.applicationGroupRequest)) {
      this.toastrService.warning(
        'Preenche os dados do grupo de classificação antes de adicionar um novo filtro',
        'Atenção'
      );
      return;
    }

    switch (this.action) {
      case 'create':
        this.createApplicationGroup();
        break;
      case 'update':
        await this.update();
        this.resetDefaultAction();
        break;
      case 'addFilter':
        this.addFilterInClassification();
        this.resetDefaultAction();
        this.applicationGroupService.SuccessNewFilter(
          this.applicationGroupRequest.description
        );
        break;
      case 'addNewClassification':
        this.addNewClassificationInGroup();
        this.resetDefaultAction();
        this.applicationGroupService.SuccessNewClassification(
          this.applicationGroupRequest.description,
          this.applicationGroupRequest.groupName
        );
        await this.update();
        break;
      default:
        break;
    }

    this.terms = [];
  }

  async update() {
    const data: ApplicationGroup[] = await new Promise((resolve) => {
      this.applicationGroupService.applicationGroup$.subscribe({
        next: (result) => resolve(result),
      });
    });
    this.updateApplicationGroup(data);
    this.resetForm();
  }

  resetDefaultAction() {
    this.action = 'addNewClassification';
  }

  addNewClassificationInGroup() {
    let uniqueTerms = this.termsDistinct(this.terms);
    let terms = this.toStringWithDelimiter(uniqueTerms);
    let origns = this.verifyCheckBox();
    let applicationFilters: ApplicationFilters = new ApplicationFilters(
      terms,
      origns
    );
    let applicationFilterGroup: ApplicationFilterGroups;
    let ignoreIdleInputDevice =
      this.applicationGroupRequest.ignoreIdleInputDevice == null ? false : true;

    applicationFilterGroup = new ApplicationFilterGroups(
      this.applicationGroupRequest.description,
      this.applicationGroupRequest.category,
      this.applicationGroupRequest.classification,
      ignoreIdleInputDevice,
      applicationFilters
    );
    this.currentGroupApplication.applicationFilterGroup.push(
      applicationFilterGroup
    );
  }

  addFilterInClassification() {
    let indexFilter = this.form.get('indexFilter')!.value;
    let uniqueTerms = this.termsDistinct(this.terms);

    let terms = this.toStringWithDelimiter(uniqueTerms);
    let origns = this.verifyCheckBox();
    let applicationFilters: ApplicationFilters = new ApplicationFilters(
      terms,
      origns
    );
    this.applicationFilterGroupsFiltered[indexFilter].applicationFilter.push(
      applicationFilters
    );
    this.resetForm();
    this.currentAction = 'Adicionar Nova Classificação';
  }
  isAnyFieldNull(applicationGroupRequest: ApplicationRequest): boolean {
    return (
      !applicationGroupRequest.description ||
      !applicationGroupRequest.category ||
      !applicationGroupRequest.classification
    );
  }
  addNewFilter(indexFilter: number, el: HTMLElement) {
    this.currentAction = 'Atualizar Filtro';
    this.terms = [];
    this.scrollToTop(el);

    this.showButtonAddFilter = true;
    this.action = 'addFilter';

    let group = this.applicationFilterGroupsFiltered[indexFilter];

    this.form = this.formBuilder.group({
      action: 'create',
      indexFilter: indexFilter,
      groupName: [this.group.groupName, Validators.required],
      description: [group.description],
      category: [group.category],
      classification: [group.classification],
      ignoreIdleInputDevice: [group.ignoreIdleInputDevice],
      term: [],
      title: false,
      url: false,
      processName: false,
    });
  }

  private createApplicationGroup() {
    let uniqueTerms = this.termsDistinct(this.terms);
    let terms = this.toStringWithDelimiter(uniqueTerms);
    let origns = this.verifyCheckBox();

    let applicationFilters: ApplicationFilters = new ApplicationFilters(
      terms,
      origns
    );
    let applicationFilterGroups: ApplicationFilterGroups[] = [];
    let ignoreIdleInputDevice =
      this.applicationGroupRequest.ignoreIdleInputDevice == null ? false : true;

    applicationFilterGroups.push(
      new ApplicationFilterGroups(
        this.applicationGroupRequest.description,
        this.applicationGroupRequest.category,
        this.applicationGroupRequest.classification,
        ignoreIdleInputDevice,
        applicationFilters
      )
    );

    let applicationGroup = new ApplicationGroup(
      this.applicationGroupRequest.groupName,
      true,
      applicationFilterGroups
    );

    this.action = 'create';

    this.applicationGroupService.applicationGroup$
      .pipe(
        take(1),
        tap((array) => {
          array.push(applicationGroup);
          this.applicationGroupService.applicationGroup.next(array);
          this.applicationGroup = applicationGroup;
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          var card = document.querySelectorAll('.card-journey-container');
          card[card.length - 1].classList.add('isClicked');
          this.resetForm();
        }, 300);

        this.applicationFilterGroupsFiltered = applicationFilterGroups;
      });
  }

  private updateApplicationGroup(applicationGroup: ApplicationGroup[]) {
    applicationGroup.forEach((application: ApplicationGroup) => {
      if (application.groupName == this.applicationGroupRequest.groupName) {
        application.applicationFilterGroup.map(
          (filter: ApplicationFilterGroups, index: number) => {
            if (index == this.applicationGroupRequest.indexClassification) {
              filter.description = this.applicationGroupRequest.description;
              filter.category = this.applicationGroupRequest.category;
              filter.classification =
                this.applicationGroupRequest.classification;
              filter.ignoreIdleInputDevice =
                this.applicationGroupRequest.ignoreIdleInputDevice;

              let uniqueTerms = this.termsDistinct(this.terms);

              let terms = this.toStringWithDelimiter(uniqueTerms);

              let origns = this.verifyCheckBox();
              this.applicationFilters = new ApplicationFilters(terms, origns);

              filter.applicationFilter[
                this.applicationGroupRequest.indexFilter!
              ] = this.applicationFilters;
            }
          }
        );

        this.currentGroupApplication = application;
      }
    });

    this.applicationGroupService.updateApplicationGroup(applicationGroup);
    this.currentAction = 'Adicionar Nova Classificação';
  }
  termsDistinct(terms: any[]) {
    // Filtrar termos duplicados independentemente de maiúsculas e minúsculas
    let uniqueTerms = terms.filter((term, index, self) => {
      const lowercaseTrimmedName = term.name.trim().toLowerCase(); // Aplica trim e converte para minúsculas

      return (
        self.findIndex(
          (t) => t.name.trim().toLowerCase() === lowercaseTrimmedName
        ) === index
      );
    });

    return uniqueTerms.map((x: any) => {
      return { name: x.name.trim() };
    });
  }

  scrollToTop(el: HTMLElement): void {
    el.scrollIntoView();
    this.inputTerms.nativeElement.focus();
  }

  private verifyCheckBox(): string[] {
    this.applicationGroupRequest = Object.assign(this.form.value);

    var origin = [];

    if (this.applicationGroupRequest.title == true) {
      origin.push('Title');
    }

    if (this.applicationGroupRequest.url == true) {
      origin.push('Url');
    }

    if (this.applicationGroupRequest.processName == true) {
      origin.push('ProcessName');
    }

    return origin;
  }

  public saveGroup() {
    if (this.action == 'create') {
      this.createGroup();
    } else {
      this.updateGroup();
    }
  }

  createGroup() {
    if (this.applicationGroup == null) {
      this.applicationGroupService.ErrorMensageFilterNull();
    } else {
      this.applicationGroupService.SendGroup(this.applicationGroup).subscribe(
        () => {
          this.applicationGroupService.SuccessMessage();
          this.resetForm();
        },
        (error) => {
          this.applicationGroupService.ErrorMessage();
        }
      );
    }
  }

  updateGroup() {
    this.applicationGroupRequest = Object.assign(this.form.value);

    if (
      this.currentGroupApplication.groupName !==
      this.applicationGroupRequest.groupName
    ) {
      this.currentGroupApplication.groupName =
        this.applicationGroupRequest.groupName;
    }

    this.applicationGroupService
      .UpdateGroup(this.currentGroupApplication)
      .subscribe({
        next: () => {
          this.applicationGroupService.SuccessUpdate();
        },
        error: (error) => {
          this.applicationGroupService.ErrorUpdate();
        },
      });
  }

  updateNameGroup() {
    this.applicationGroupService
      .UpdateGroup(this.currentGroupApplication)
      .subscribe({
        next: () => {
          this.applicationGroupService.SuccessUpdate();
        },
        error: (error) => {
          this.applicationGroupService.ErrorUpdate();
        },
      });
  }

  showScreenOption(index: number) {
    var screens = document.getElementsByClassName(
      'screen-options'
    ) as HTMLCollection;

    for (var i = 0; i < screens.length; i++) {
      let item = screens.item(i) as HTMLElement;

      if (item.id == `${index}`) {
        if (item.classList.contains('show-screen')) {
          item.classList.remove('show-screen');
        } else {
          item.classList.add('show-screen');
        }
      } else {
        item.classList.remove('show-screen');
      }
    }
  }

  deleteSubGroup() {
    this.deleteClassification(this.indexCurrentClassification);
  }

  deleteClassification(indexClassification: number) {
    this.applicationFilterGroupsFiltered.splice(indexClassification, 1);
    this.update();
  }

  deleteFilter(indexClassification: number, indexFilter: number) {
    let descritivo: ApplicationFilterGroups =
      this.applicationFilterGroupsFiltered[indexClassification];
    descritivo.applicationFilter.splice(indexFilter, 1);
  }

  editInNewGroup(
    indexClassification: number,
    indexFilter: number,
    el: HTMLElement
  ) {
    this.showButtonAddFilter = true;
    this.currentAction = 'Atualizar Filtro';

    let descritivo: ApplicationFilterGroups =
      this.applicationFilterGroupsFiltered[indexClassification];
    let filter = descritivo.applicationFilter[indexFilter];

    (this.action = 'update'),
      (this.form = this.formBuilder.group({
        action: 'update',
        indexClassification: indexClassification,
        indexFilter: indexFilter,
        id: this.group.id,
        groupName: this.group.groupName,
        description: descritivo.description,
        category: descritivo.category,
        classification: descritivo.classification,
        ignoreIdleInputDevice: descritivo.ignoreIdleInputDevice,
        term: filter.term.join(', '),
        title: filter.origin.includes('Title'),
        url: filter.origin.includes('Url'),
        processName: filter.origin.includes('ProcessName'),
      }));

    this.toChipList(filter.term);

    this.scrollToTop(el);
  }

  toChipList(terms: string[]) {
    this.terms = terms.map((x) => {
      return { name: x };
    });
  }

  toStringWithDelimiter(terms: any): string[] {
    let array = terms.map((x: any) => {
      return x.name;
    });
    return array;
  }

  async edit(cardJourney: CardJourneyModel) {
    this.resetForm();

    this.group.groupName = cardJourney.title;
    this.action = 'addNewClassification';
    this.showApplicationArea = true;
    this.showButton = false;
    this.showNewGroup = true;
    this.showButtonAddFilter = true;

    this.applicationGroupService.applicationGroup$.subscribe({
      next: (result) => {
        let applicationGroup = result.find(
          (x) => x.groupName == cardJourney.title
        ) as ApplicationGroup;

        this.currentGroupApplication = applicationGroup;

        this.applicationFilterGroupsFiltered =
          applicationGroup.applicationFilterGroup;
      },
    });
  }

  private validatorAddFilter(applicationResquest: ApplicationRequest): boolean {
    if (
      applicationResquest.description == '' &&
      applicationResquest.category == '' &&
      applicationResquest.classification == ''
    ) {
      this.applicationGroupService.ErrorMensageFilterNull();
      return false;
    }

    if (applicationResquest.groupName == '') {
      this.applicationGroupService.ErrorMensageGroupNameNull();
      return false;
    }

    if (applicationResquest.description == '') {
      this.applicationGroupService.ErrorMensageDescritionNull();
      return false;
    }

    if (applicationResquest.category == '') {
      this.applicationGroupService.ErrorMensageCategoryNull();
      return false;
    }

    if (applicationResquest.classification == '') {
      this.applicationGroupService.ErrorMensageClassificationNull();
      return false;
    }

    if (this.terms.length == 0) {
      this.applicationGroupService.ErrorMensageTermNull();
      return false;
    }

    if (
      applicationResquest.url == false &&
      applicationResquest.title == false &&
      applicationResquest.processName == false
    ) {
      this.applicationGroupService.ErrorMensageOriginNull();
      return false;
    }

    return true;
  }

  private resetForm() {
    let groupName = this.form.get('groupName')!.value;
    this.form.reset({ groupName: groupName });
    this.terms = [];
  }

  private resetAllForm() {
    this.form.reset();
  }

  wordCloudClick(word: string) {
    if (!this.terms.some((term) => term.name === word)) {
      this.terms = this.terms.concat({ name: word });
      this.toastrService.success(
        `O termo [${word}] foi adicionado com sucesso.`
      );
    }
  }
}
