import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TableTeamReportComponent } from '../../components/table-team-report/table-team-report.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { EmployeesModule } from '@station/employees';
import { SpinnersModule } from '@station/spinners';
import { ModalOptionsModule } from '../modal-options/modal-options.module';


@NgModule({
  declarations: [
    TableTeamReportComponent,
  ],
  exports: [
    TableTeamReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatChipsModule,
    RouterModule,
    EmployeesModule,
    SpinnersModule,
    ModalOptionsModule
  ]
})
export class TableTeamReportModule { }
