import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoginRoutingModule } from './login-routing.module';
import { ViewComponent } from './view/view.component';
import { ForgoutPasswordComponent } from "../../shared/components/forgout-password/forgout-password.component";
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [
        ViewComponent,
        ForgoutPasswordComponent
    ],
    imports: [
        CommonModule,
        LoginRoutingModule,
        HttpClientModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        
    ]
})
export class LoginModule { }
