import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Observable, catchError, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";

const TOKEN_HEADER_KEY = 'Authorization';  // for Dotnet back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  //private blacklistEndpoints: string[] = ['/authenticate', '/checkTwoFactor', '/Notification']; // Adicione os endpoints que você quer na blacklist aqui
  private blacklistEndpoints: string[] = ['/Notification']; // Adicione os endpoints que você quer na blacklist aqui

  constructor(private authService: AuthService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const endpoint = req.url; // Obtém o endpoint da requisição

    // Verifica se o endpoint está na lista negra
    if (token != null && !this.isEndpointInBlacklist(endpoint)) {
      req = this.addTokenHeader(req, token);
    }

    // Adiciona o cabeçalho 'Access-Control-Allow-Origin' para minimizar problemas de CORS
    //req = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });

    return next.handle(req).pipe(catchError(x => this.handleAuthError(x)));
  }

  private isEndpointInBlacklist(endpoint: string): boolean {
    // Verifica se o endpoint está na lista negra
    return this.blacklistEndpoints.some(blacklistEndpoint => endpoint.includes(blacklistEndpoint));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.logout()
      return of(err.message);
    }
    return throwError(err);
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
     return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
  }
  private logout() {
    this.router.navigate(['logout']);
  }
}
