import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TimeFrameDataCountModel } from '../models/timeFrameDataCountModel.model';
import { ChartColumnGroupModel } from '@station/charts';

@Injectable({
  providedIn: 'root'
})
export class PainelAdministrativoService {

  constructor(private http: HttpClient) { }

  private comparativeJourney = new Subject<ChartColumnGroupModel>()
  comparativeJourney$ = this.comparativeJourney.asObservable()

  updateChartApplicationLog(data: any){
    this.comparativeJourney.next(data)
  }


  getApplicationTimeFrameDataCountByHour(dateFiltered: string): Observable<TimeFrameDataCountModel[]> {

    let url = `${environment.dashboardApi}${environment.endpoints.getApplicationTimeFrameDataCountByHour}/?referenceDate=${dateFiltered}`
    return this.http.get<TimeFrameDataCountModel[]>(url);
  }

}
