import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalOptionsComponent } from 'src/app/shared/components/modal-options/modal-options.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    imports: [
        MatIconModule,
        CommonModule
    ],
    exports:[
        ModalOptionsComponent
    ],
    declarations: [ModalOptionsComponent]
})
export class ModalOptionsModule { }
