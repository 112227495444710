import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class HubService {
  private hubConnection!: HubConnection;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private notificationService: NotificationService
  ) {
    this.createConnection();
    this.startConnection();
  }

  createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl("https://hub-station-6j3mawfg6q-uc.a.run.app/agentHub")
      .build();

    this.hubConnection.on('ReceiveMessage', (user:string, message:string) => {


      if (user.toLowerCase() == "server") return;

      this.sendMessageToScreen(`${user}:`, message);
      this.sendNotification(`${user} - ${message}`)
    });
  }
  startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        this.addToTeamGroup();
        //this.sendTeamMessage("Cheguei galera!")
      })
      .catch(() => {
        console.log('Station Hub connection failed')
      })
  }
  addToGroup(userId: string) {
    this.hubConnection.invoke("AddToGroup", userId);  //Register employee
  }
  addToTeamGroup() {
    var loggedUser = this.authService.getAuthUser();

    this.hubConnection.invoke("AddToGroup", `${loggedUser.id}`); //Register my group chiefId

    if (loggedUser.chiefId) {
      this.hubConnection.invoke("AddToGroup", `team-${loggedUser.chiefId}`); //Register group chiefId
    }
  }
  sendTeamMessage(message: string) {
    var loggedUser = this.authService.getAuthUser();

    this.hubConnection.invoke("SendMessageToGroup", `team-${loggedUser.id}`, `${loggedUser.firstName} ${loggedUser.lastName}`, message)
  }
  sendSingleUserMessage(userId: string, message: string) {
    var loggedUser = this.authService.getAuthUser();

    this.hubConnection.invoke("SendMessageToGroup", userId, `${loggedUser.firstName} ${loggedUser.lastName}`, message)
  }
  takeScreenshot(userId: string) {
    var loggedUser = this.authService.getAuthUser();

    this.hubConnection.invoke("SendMessageToGroup", userId, `${loggedUser.firstName} ${loggedUser.lastName}`, "#screenshot")
  }
  takeWebcamPicture(userId: string) {
    var loggedUser = this.authService.getAuthUser();

    this.hubConnection.invoke("SendMessageToGroup", userId, `${loggedUser.firstName} ${loggedUser.lastName}`, "#webcam")
  }
  remoteShutdown(userId: string) {
    var loggedUser = this.authService.getAuthUser();

    this.hubConnection.invoke("SendMessageToGroup", userId, `${loggedUser.firstName} ${loggedUser.lastName}`, "#shutdown")
  }
  remoteRestart(userId: string) {
    var loggedUser = this.authService.getAuthUser();

    this.hubConnection.invoke("SendMessageToGroup", userId, `${loggedUser.firstName} ${loggedUser.lastName}`, "#restart")
  }
  sendMessageToScreen(title: string, message: string) {
    // Obtém a data e hora atual
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    // Formata a data e hora no formato brasileiro (dd/mm/yyyy HH:mm)
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    this.toastr.info(
      `${formattedDate} - ${message}`,
      title,
      {
        closeButton: true,
        progressBar: false,
        disableTimeOut: true,
        enableHtml: true
      }
    )
  }
  private sendNotification(message: string, title:string = '[Station] Nova mensagem') {
    // Solicitar permissão de notificação (opcional, pode ser feito em outro lugar)
    this.notificationService.requestPermission()
      .then(permission => {
        if (permission === 'granted') {
          // Enviar notificação
          this.notificationService.sendNotification(title, {
            body: message,
            icon: './assets/img/myra-icon.png', // Opcional: caminho para um ícone da notificação
          });
        }
      });
  }
}
