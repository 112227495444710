import { CardJourneyModel } from "@station/employees";
import { Semaphore } from "./semaphore.model";

export class SemaphoreViewModel {

    cardJourneyModel: CardJourneyModel;
    semaphores: Semaphore;

    constructor(cardJourneyModel: CardJourneyModel, semaphores: Semaphore){

        this.cardJourneyModel = cardJourneyModel;
        this.semaphores = semaphores;

    }
}