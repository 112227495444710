export class Journey{
    id!:number;
    description!: string;
    hoursWork!: HoursWork[];
    dom!: boolean;
    seg!:boolean;
    ter!: boolean;
    qua!: boolean;
    qui!:boolean;
    sex!: boolean;
    sab!: boolean;
    fer!:boolean;
}

export class HoursWork{
    in!: Date;
    out!:Date;
}