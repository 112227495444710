import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import {
  ChartColumnGroupModel,
  ChartItemModel,
  ChartLineModel,
  ChartModel,
  ChartType,
  DashStyleOption,
  SerieColumnGroupModel,
  SerieLineModel,
  SerieModel,
} from '@station/charts';
import {
  AlertSetting,
  BigNumberConfig,
  BigNumberIcon,
  BigNumberModel,
  EmployeeDataModel,
  EmployeeModel,
  EmployeeRankingData,
  EmployeesRakingModel,
} from '@station/employees';
import { HeaderModel } from '@station/header';
import { TitleModel } from '@station/titles';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Application } from 'src/app/shared/components/table-aplicacoes/models/application.model';
import { ApplicationService } from 'src/app/shared/components/table-aplicacoes/services/application.service';

import { LoginResponse } from '../../login/model/login-response';
import { ColaboradorService } from '../../painel-colaborador/services/colaborador.service';
import { ProfileEmployeeService } from '../../profile-employees/services/profile-employees.service';
import { Periods } from '../models/teamAvailabilityToday.model';
import { DashboardService } from '../services/dashboard.service';
import * as moment from 'moment';
import { HubService } from 'src/app/services/hub.service';
import { UserNotification } from '../../painel-colaborador/models/userNotification.model';
import { TeamReportModel } from 'src/app/shared/components/table-team-report/models/team-report-mode';
import { TeamReportService } from 'src/app/shared/components/table-team-report/services/table-team-report.service';
import { format } from 'date-fns';
import { Meta } from '@angular/platform-browser';
import { SearchApplications } from '../models/SearchApplications.model';
import * as XLSX from 'xlsx';
import { EmployeeMininalModel } from '../models/employeeMininalModel';
import { TableTeamReportComponent } from 'src/app/shared/components/table-team-report/table-team-report.component';
import { TableAplicacoesComponent } from 'src/app/shared/components/table-aplicacoes/table-aplicacoes.component';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  @ViewChild(TableTeamReportComponent) teamReportComponent!: TableTeamReportComponent;

  constructor(private authService: AuthService,
    private colaboradorService: ColaboradorService,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private router: Router,
    private profileEmployeeService: ProfileEmployeeService,
    private applicationService: ApplicationService,
    private toastrService: ToastrService,
    private teamReportService: TeamReportService,
    private hubService: HubService,
    private meta: Meta
  ) { }


  employeesBadConnection: number = 0
  employeeId!: any;
  authUser!: LoginResponse
  profile!: EmployeeModel | null
  header: HeaderModel = new HeaderModel(this.authService.authUser.registrationId, this.authService.authUser.firstName + " " + this.authService.authUser.lastName, this.authService.authUser.jobPosition, false, this.authService.authUser.photoPath, false, "Buscar Funcionários", false, false);
  public employeeId$ = new Subject<string>();
  profile$!: Observable<EmployeeModel>
  employeeList: EmployeeMininalModel[] = []
  productivitychartData: any[] = []
  loggedychartData: any[] = []
  dateFiltered!: string;
  dateFilteredSubject$!: Subject<string>;
  dateFiltered$!: Observable<string>;
  maxDate!: string;
  isExport!: boolean;
  title: string = ""
  selectDate!: string;
  subTitle: string = ''
  lastUpdate!: Date;
  dayLabel: string = "Hoje"
  beforeDayLabel: string = "Ontem"
  Periods = Periods;
  availabilityChartsSelectOption: Periods = Periods.Day;
  productivityChartsSelectOption: Periods = Periods.Day;
  visualizationTypeEnabled: boolean = true;
  visualizationType: string = "gestao-direta";
  isProfile: boolean = false
  toggleRefreshEnabled: boolean = true;
  intervalId: any;
  showMessage!: boolean
  intervalValue = 5;
  subtitle!: string

  initalHour!: string
  finalHour!: string
  panelOpenState = false;

  chartLoginExpectedLogin: ChartLineModel | undefined

  productiveApplications: Application[] = []
  titleTopProdutivas: string = ''
  subTitleTopProdutivas: string = ''
  goToChiefPage!: boolean
  goToColaboratorPage!: boolean

  unproductiveApplications: Application[] = []
  titleTopImprodutivas: string = ''
  subTitleTopIprodutivas: string = ''

  productiveWithouthapplications: Application[] = []
  titleTopWithoutClassification: string = ''
  subTitleTopWithoutClassifications: string = ''

  userNotifications: UserNotification[] = []
  countUnreadUserNotification: number = 0;

  teamReport: TeamReportModel[] = [];

  alertSetting: AlertSetting = new AlertSetting(0, 0, 0);

  ngOnInit(): void {

    moment.locale('pt-br');

    this.dateFiltered = new Date().toFormattedString();

    this.setTopApplication();

    this.maxDate = new Date().toFormattedString();

    this.lastUpdate = new Date();

    this.authUser = this.authService.authUser;

    this.setSubtitle("00:00", "23:59")

    this.validateJobPosition();

    this.getCountUnreadUserNotifications();

    this.getUserNotifications();



    this.getUrlParameters().then(data => {

      this.employeeId = data

      this.getEmployee().then(authPromise => {

        this.authUser = authPromise
        this.employeeId$.next(this.authUser.id)
        this.profile$ = this.setProfile(authPromise);

        this.validateJobPosition();

        this.udpateBadSingal();

        this.updateTeamReportChart();

        this.updateTodayTeamHighLights();

        this.updateAvailabilityHighlights();

        this.updateProductivityHighlights();

        this.updateProductivityLoggedHighlights();

        this.updateProductivityCharts();

        this.updateRankingTopBottom();

        this.updateLoggedAndExpectedLoginCharts();

        this.updateUserApplicationList();

        this.setOpenGraphTahs();


      });


    });

  }
  setOpenGraphTahs() {

    this.meta.updateTag(
      { property: 'og:title', content: `${this.authUser.firstName} ${this.authUser.lastName}` },
    );
    this.meta.updateTag(
      { property: 'og:description', content: `Painel de gerencimaneto da equipe do *${this.authUser.jobPosition} ${this.authUser.firstName} ${this.authUser.lastName}*` },
    );

  }
  setSubtitle(initialHour: string, finalHour: string) {
    this.subtitle = `${this.dateFiltered} (${initialHour} até ${finalHour})`
  }

  getUserNotifications(pageNumber: number = 1) {
    let loggedUserId = this.authService.authUser.id;

    this.colaboradorService.getNotificationByUserId(loggedUserId, pageNumber).subscribe(response => {

      this.userNotifications.length == 0 ? this.userNotifications = response : this.userNotifications = this.userNotifications.concat(response)

    });

  }
  getCountUnreadUserNotifications() {
    let loggedUserId = this.authService.authUser.id;

    this.colaboradorService.getCountUnreadNotificationByUserId(loggedUserId).subscribe(response => {

      this.countUnreadUserNotification = response;

    });

  }
  loadMoreUserNotification(pageNumber: number) {
    this.getUserNotifications(pageNumber);
  }
  setTopApplication() {

    this.titleTopProdutivas = 'Top 20 | Aplicações Produtivas'
    this.subTitleTopProdutivas = `Aplicações mais acessadas classificadas como produtivas entre ${this.dateForWeek().week} e ${this.dateForWeek().day}`

    this.titleTopImprodutivas = 'Top 20 | Aplicações Improdutivas'
    this.subTitleTopIprodutivas = `Aplicações mais acessadas classificadas como improdutivas entre ${this.dateForWeek().week} e ${this.dateForWeek().day}`

    this.titleTopWithoutClassification = 'Top 20 | Aplicações Sem Classificação'
    this.subTitleTopWithoutClassifications = `Aplicações mais acessadas sem classificação entre ${this.dateForWeek().week} e ${this.dateForWeek().day}`

  }
  public toggleRefresh(e: any) {
    if (e.target.checked && this.intervalValue >= 5) {
      this.setMyTeamReportSetInterval()
    }
    else {
      this.stopMyTeamReportSetInterval()

    }
  }
  stopMyTeamReportSetInterval() {
    clearInterval(this.intervalId);
  }
  setMyTeamReportSetInterval() {
    this.updatePanels()
    this.intervalId = setInterval(() => {
      this.updatePanels()
    }, this.intervalValue * 60000)
  }
  validateJobPosition() {
    this.visualizationTypeEnabled = this.authUser.jobPosition.toUpperCase() != 'SUPERVISOR'
  }
  setProfile(data: any): Observable<EmployeeModel> {

    let profile: EmployeeModel

    // if (this.employeeId == null) return new Observable<EmployeeModel>(sub => sub.next(profile))

    profile = {
      name: `${data.firstName} ${data.lastName}`,
      picture: data.photoPath.length > 0 ? data.photoPath.length : "/assets/img/user-default.png",
      role: data.jobPosition,
      roles: data.roles,
      enabled: false,
      panel: false,
      badSignal: false,
      linkProfile: '',
      isOnline: data.isOnline,
      shouldBeCurrentlyWorking: false,
      linkDetail: '',
      hasFirstLogin: data.hasFirstLogin,
      teamIsOnline: data.teamIsOnline
    }


    return new Observable<EmployeeModel>(sub => sub.next(profile))

  }

  changeComparativeJourney(period: String) {

    switch (period) {

      case 'day':

        this.productivityChartsSelectOption = Periods.Day

        break;

      case 'week':

        this.productivityChartsSelectOption = Periods.Week

        break;

      case 'month':

        this.productivityChartsSelectOption = Periods.Month
        break;
    }

    this.updateProductivityCharts()
  }

  updateComparativeJourney(data: ChartColumnGroupModel) {

    this.dashboardService.updatecomparativeJourney(data)
  }

  chartItemOptionsStacked: Observable<ChartColumnGroupModel> = this.dashboardService.comparativeJourney$!!

  setComparativeJourney(data: any) {

    this.productivitychartData = data

    let estimatedJourneyData: number[] = []
    let availabilityData: number[] = []
    let productivityData: number[] = []
    let categoriesLabel: string[] = []
    let categoriesDate: string[] = []
    let loggedProductivityPercent: string[] = []
    let totalProductivityPercent: string[] = []
    let availabilityPercent: string[] = []

    for (let i = 0; i < data.length; i++) {

      if (data[i].referenceDate != null) categoriesDate.push(data[i].referenceDate)

      estimatedJourneyData.push(data[i].estimatedJourney)
      availabilityData.push(data[i].availability)
      productivityData.push(data[i].totalProductivity)
      categoriesLabel.push(data[i].label)
      loggedProductivityPercent.push(data[i].loggedProductivity == null ? '-' : `${data[i].loggedProductivity}%`)
      totalProductivityPercent.push(data[i].totalProductivityPercent == null ? '-' : `${data[i].totalProductivityPercent}%`)
      availabilityPercent.push(data[i].availabilityPercent == null ? '-' : `${data[i].availabilityPercent}%`)

    }

    let estimatedJourney = new SerieColumnGroupModel(estimatedJourneyData, 'Jornada de trabalho', ChartType.column, DashStyleOption.Solid, '#E3E9EF', 0, '', -0.2, true);
    let availability = new SerieColumnGroupModel(availabilityData, 'Tempo logado', ChartType.column, DashStyleOption.Solid, '#FF9300', 0, '');
    let productivity = new SerieColumnGroupModel(productivityData, 'Produtividade (total)', ChartType.column, DashStyleOption.Solid, '#F37021', 0);
    let series: SerieColumnGroupModel[] = [estimatedJourney, availability, productivity]

    let categoriesDateLabel = categoriesDate.length > 0 ? categoriesDate : []

    let model = new ChartColumnGroupModel(series, "Comparativo Histórico na Jornada", "", true, "", "", "Hora(s)", "Tempo logado", "", categoriesLabel, categoriesDateLabel, availabilityPercent, "Produtividade", "(no tempo logado)", loggedProductivityPercent, "Produtividade", "(total)", totalProductivityPercent, "500px");

    return model

  }


  employeesRakingProdutivo!: EmployeesRakingModel
  employeesRakingImprodutivo!: EmployeesRakingModel
  setRankingEmployees(data: any, title: string, subtitle: string, modalId?: string): EmployeesRakingModel {


    let titleTopRanking: TitleModel = new TitleModel(title, subtitle, modalId);
    let employeeRankingData: EmployeeRankingData[] = [];

    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')

    let danger = semaphore!.ranges.red
    let warning = semaphore!.ranges.yellow
    let success = semaphore!.ranges.green

    let alertSetting: AlertSetting = new AlertSetting(danger, warning, success);

    for (let i = 0; i < data.length; i++) {

      let employee: EmployeeModel = new EmployeeModel(data[i].fullName, data[i].jobPosition, true, `/painel-colaborador/${data[i].userId}?period=day&date_filter=${this.dateFiltered}`, data[i].photoPath, false, data[i].badSignal, '', false, false, false, '', true, 1);
      let employeeRankingModel: EmployeeRankingData = new EmployeeRankingData(
        employee,
        new EmployeeDataModel(data[i].totalProductivity, "%", alertSetting, true),
        new EmployeeDataModel(data[i].availability, "%", alertSetting, true),
        new EmployeeDataModel(data[i].productivityLoggedTime, "%", alertSetting, true));

      employeeRankingData.push(employeeRankingModel);
    }


    return new EmployeesRakingModel(titleTopRanking, employeeRankingData, "Produtividade", "sobre o tempo previsto", "Tempo logado", "sobre a previsão", "Produtividade", "sobre a disponibilidade");
  }


  bigNumberAvailabilityTodayTeam!: BigNumberModel[];
  bigNumberAvailabilityTodayTeams: BigNumberModel[] = [];
  bigNumberAvailabilityFullDayTeams: BigNumberModel[] = [];


  private async getUrlParameters() {

    const data = await new Promise((resolve) => {
      this.route.paramMap.subscribe((params: ParamMap) => resolve(params.get('id')))
    })

    this.route.paramMap.subscribe((params: ParamMap) => {

      this.employeeId = params.get('id');

      let dateFilter = this.validarFormatoData(this.getGetQueryParam('date_filter'));
      let period = this.getGetQueryParam('period');

      this.dateFiltered = dateFilter ?? new Date().toFormattedString();

      this.changeDateFilter();
      this.changeVisualization();
      this.changePeriodParam('day');

      if (period) {
        this.selectedPeriod(period);
      }
    });


    return data
  }

  validarFormatoData(data: string | null | undefined): string | null {
    if (data == null || data == undefined) {
      return null;
    }

    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(data)) {
      return data;
    } else {
      return null;
    }
  }

  getGetQueryParam(term: string) {
    let dateFilter = null;

    this.route.queryParams.subscribe((params) => {
      dateFilter = params[term];
    });

    return dateFilter;
  }

  changeDateFilter() {
    const queryParams = { ...this.route.snapshot.queryParams };

    queryParams['date_filter'] = this.dateFiltered;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  changePeriodParam(period: string) {
    const queryParams = { ...this.route.snapshot.queryParams };

    queryParams['period'] = period;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  changeVisualization() {
    const queryParams = { ...this.route.snapshot.queryParams };

    queryParams['visualization_type'] = this.visualizationType;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  chartConexao: Observable<ChartLineModel> = this.dashboardService.currentchartConnection$!!;

  setChartConexao(data: any): ChartLineModel {

    this.loggedychartData = data

    let logged = this.getDataChartlogged(data);
    let loggedOut = data.map((x: any) => { return x.loggedOut == null ? '-' : x.loggedOut });
    let expectedLogin = this.getDataCharExpectedLogin(data);
    let loggedPercent = data.map((x: any) => { return x.loggedPercent == null ? '-' : x.loggedPercent > 100 ? '>100%' : `${x.loggedPercent}%` });

    let serieHistoricoTempoLogadoLine4 = new SerieLineModel(
      logged.listData,
      'Logados',
      ChartType.line,
      DashStyleOption.Solid,
      '#5095AA',
      3
    ); //#AF372F
    let serieHistoricoTempoLogadoLine5 = new SerieLineModel(
      expectedLogin.listData,
      'Previsto',
      ChartType.line,
      DashStyleOption.Solid,
      '#4a4a4a',
      3
    ); //#E08976
    let seriesLine2: SerieLineModel[] = [
      serieHistoricoTempoLogadoLine5,
      serieHistoricoTempoLogadoLine4,
    ];

    let firstLabel =
      logged.firstLabel.length > expectedLogin.firstLabel.length
        ? logged.firstLabel
        : expectedLogin.firstLabel;
    let secondLabel =
      logged.secondLabel.length > expectedLogin.secondLabel.length
        ? logged.secondLabel
        : expectedLogin.secondLabel;

    return new ChartLineModel(seriesLine2, '', '', true, true, false, '', '', '', '', firstLabel, secondLabel, "%", "logados", loggedPercent, "Não", "logados", loggedOut);
  }

  private getDataChartlogged(data: any) {
    let listData: any = [];
    let firstLabel: any = [];
    let secondLabel: any = [];

    for (let i = 0; i < data.length; i++) {
      listData.push(data[i].logged);
      firstLabel.push(data[i].hour);
    }

    return { listData, firstLabel, secondLabel };
  }

  private getDataCharExpectedLogin(data: any) {
    let listData: any = [];
    let firstLabel: any = [];
    let secondLabel: any = [];

    for (let i = 0; i < data.length; i++) {
      listData.push(data[i].expectedLogin);
      firstLabel.push(data[i].hour);
    }

    return { listData, firstLabel, secondLabel };
  }


  private async getEmployee(): Promise<LoginResponse> {


    const data: LoginResponse = await new Promise(resolver => {

      if (this.employeeId != null) {

        this.colaboradorService.getAgentById(this.employeeId).subscribe(data => {
          data.roles = this.authService.getRoles()

          resolver(data)
        })

      }
      else {

        this.authService.authUser.roles = this.authService.getRoles()
        resolver(this.authService.authUser)

      }
    })

    return data


  }

  onClickScroll() {
    const targetElement = document.querySelector('.improdutivas');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onClickScrollTableMyTeam() {
    const targetElement = document.querySelector('.table-my-team');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  dateFilterClick(event: string[], nameComponent: string) {

    let now = new Date()

    let initialHour = moment(event[0], 'HH:mm', true);
    let finalHour = moment(event[0], 'HH:mm', true);

    let initalDate: Date = new Date(`${this.dateFiltered}T${initialHour.minute(0).second(0).format("HH:mm:ss")}`);
    let finalDate: Date = new Date(`${this.dateFiltered}T${finalHour.minute(59).second(59).format("HH:mm:ss")}`);

    let startDate: string = format(initalDate, "yyyy-MM-dd'T'HH:mm:ss")
    let endDate: string = format(finalDate, "yyyy-MM-dd'T'HH:mm:ss")

    if (initalDate.getDate() == now.getDate() && now.getHours() < initalDate.getHours()) {
      this.toastrService.info("A faixa horária escolhida ainda não possui dados, pois se trata de um horário futuro", "Atenção")
      return
    }

    switch (nameComponent) {
      case "station-chart-line-percentage-area":

        this.setSubtitle(format(initalDate, "HH:mm"), format(finalDate, "HH:mm"))
        this.updateTeamReportChartForHour(startDate, endDate)
        this.onClickScrollTableMyTeam()
        break;
      case "station-chart-column-group":

        if (event[0] !== null) {
          this.setSubtitle(format(initalDate, "HH:mm"), format(finalDate, "HH:mm"))
          this.updateTeamReportChartForHour(startDate, endDate)
          this.onClickScrollTableMyTeam()
        }
        else {
          let clickedData = event[0] as string;
          if (!clickedData.includes("/")) return
          let selectedDateFilter = clickedData.GetFormatedISODate()
          this.updateUserApplicationList(selectedDateFilter);
          this.onClickScroll()
        }
        break;
      default:
        break;
    }


  }
  checkEmployeeMenu(event: boolean) {
    this.updateProductivityCharts();
    this.updateLoggedAndExpectedLoginCharts();
  }
  setTodayTeam(data: any): BigNumberModel[] {

    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')

    let danger = semaphore!.ranges.red
    let warning = semaphore!.ranges.yellow
    let success = semaphore!.ranges.green

    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);
    let config1: BigNumberConfig = new BigNumberConfig(true, false, true);

    let teamAvailability: BigNumberModel[] = []

    let todayDate = new Date();

    let now = moment(todayDate).format('DD/MM/YYYY')
    let chosenDate = moment(this.dateFiltered).format('DD/MM/YYYY')

    if (chosenDate < now) {
      data.totalLogged = data.totalFullDay;
    }

    let totalTeam = new BigNumberModel(data.totalTeam, '', '', 'Equipe', BigNumberIcon.circle, bigNumberAlert, config1)


    let totalLogged = new BigNumberModel(data.totalLogged, '', '', 'Logado', BigNumberIcon.circle, bigNumberAlert, config1)


    // let totalEnabled = new BigNumberModel(data.totalEnabled, '', '', 'Ativo', BigNumberIcon.circle, bigNumberAlert, config1)

    // let totalScheduled = new BigNumberModel(data.totalScheduled, '', '', 'Escalado', BigNumberIcon.circle, bigNumberAlert, config1)

    let loggedPercent = (data.totalLogged / data.totalTeam) * 100
    let loggedOutPercent = ((data.totalTeam - data.totalLogged) / data.totalTeam) * 100

    let today = new BigNumberModel(loggedPercent, '', '%', '% Logado', BigNumberIcon.circle, bigNumberAlert, config1)
    let loggedOut = new BigNumberModel(loggedOutPercent, '', '%', '% Não logado', BigNumberIcon.circle, bigNumberAlert, config1)

    teamAvailability.push(totalTeam)

    // teamAvailability.push(totalEnabled)

    // teamAvailability.push(totalScheduled)

    teamAvailability.push(totalLogged)

    teamAvailability.push(today)

    teamAvailability.push(loggedOut)

    return teamAvailability

  }
  setFullDayTeam(data: any): BigNumberModel[] {

    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')

    let danger = semaphore!.ranges.red
    let warning = semaphore!.ranges.yellow
    let success = semaphore!.ranges.green

    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);
    let config1: BigNumberConfig = new BigNumberConfig(true, false, true);

    let teamAvailability: BigNumberModel[] = []


    let percent = (data.totalFullDay / data.totalTeam) * 100

    let fullDay = new BigNumberModel(data.totalFullDay, '', '', 'Consolidado', BigNumberIcon.circle, bigNumberAlert, config1)
    let fullDayPercent = new BigNumberModel(percent, '', '%', '% Dia', BigNumberIcon.circle, bigNumberAlert, config1)

    teamAvailability.push(fullDay)

    teamAvailability.push(fullDayPercent)

    return teamAvailability

  }
  bigNumberHighLightTodayTeam!: BigNumberModel[];

  setHighLightTodayTeam(data: any): BigNumberModel[] {


    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')

    let danger = semaphore!.ranges.red
    let warning = semaphore!.ranges.yellow
    let success = semaphore!.ranges.green

    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);
    let config3: BigNumberConfig = new BigNumberConfig(false, false, true);

    let teamAvailability: BigNumberModel[] = []

    let today = new BigNumberModel(data?.today, '', '%', this.dayLabel, BigNumberIcon.circle, bigNumberAlert, config3)
    teamAvailability.push(today)

    let yesterday = new BigNumberModel(data?.yesterday, '', '%', this.beforeDayLabel, BigNumberIcon.circle, bigNumberAlert, config3)
    teamAvailability.push(yesterday)

    let week = new BigNumberModel(data?.week, '', '%', 'Sem', BigNumberIcon.circle, bigNumberAlert, config3)
    teamAvailability.push(week)

    let month = new BigNumberModel(data?.month, '', '%', 'Mês', BigNumberIcon.circle, bigNumberAlert, config3)
    teamAvailability.push(month)


    return teamAvailability

  }

  employeeBigNumberProductivitys!: BigNumberModel[];


  employeeBigNumberHighlightsProductivity!: BigNumberModel[];
  employeeBigNumberHighlightsProductivityLogged!: BigNumberModel[];

  setHighlightsProductivityTeam(data: any): BigNumberModel[] {

    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade')

    let danger = semaphore!.ranges.red
    let warning = semaphore!.ranges.yellow
    let success = semaphore!.ranges.green

    let bigNumberAlert: AlertSetting = new AlertSetting(danger, warning, success);
    let config3: BigNumberConfig = new BigNumberConfig(false, false, true);

    let teamHighlightsProductivity: BigNumberModel[] = []


    let today = new BigNumberModel(data.today, 'Produtividade', '%', this.dayLabel, BigNumberIcon.circle, bigNumberAlert, config3)
    teamHighlightsProductivity.push(today)

    let yesterday = new BigNumberModel(data.yesterday, '', '%', this.beforeDayLabel, BigNumberIcon.circle, bigNumberAlert, config3)
    teamHighlightsProductivity.push(yesterday)

    let week = new BigNumberModel(data.week, '', '%', 'Sem', BigNumberIcon.circle, bigNumberAlert, config3)
    teamHighlightsProductivity.push(week)

    let month = new BigNumberModel(data.month, '', '%', 'Mês', BigNumberIcon.circle, bigNumberAlert, config3)
    teamHighlightsProductivity.push(month)

    return teamHighlightsProductivity

  }


  chartComparativoTempoLogado: Observable<ChartItemModel> = this.dashboardService.teamAnalysisTimeLogged$!!
  teamAnalysisTimeLoggedWeekly!: any
  teamAnalysisTimeLoggedMonthly!: any

  selectedPeriod(period: string) {
    this.changePeriodParam(period)
    this.changeAgentAnalysisTimeLogged(period)
    this.changeComparativeJourney(period)
    this.updateRankingTopBottom()
  }
  chartColumnGroupMenuItemClick(event: any) {
    switch (event) {
      case 'absoluto':

        break;
      case 'percentual':

        break;
    }
  }
  changeAgentAnalysisTimeLogged(period: string) {


    switch (period) {
      case 'day':
        this.availabilityChartsSelectOption = Periods.Day;

        break;

      case 'week':

        this.availabilityChartsSelectOption = Periods.Week;
        break;


      case 'month':
        this.availabilityChartsSelectOption = Periods.Month;
        break;

    }

  }




  getFullDayTeams(): number {
    return this.bigNumberAvailabilityFullDayTeams.length == 2 ? this.bigNumberAvailabilityFullDayTeams[1].value : 0;
  }



  setChartComparativoTempoLogado(data: any): ChartItemModel {


    let ListData: any[] = []
    let ListDataIdleness: any[] = []
    let firstLabel: any[] = []
    let secondLabel: any[] = []


    for (let i = 0; i < data.length; i++) {



      ListData.push(data[i].availability)
      ListDataIdleness.push(data[i].idleness)
      firstLabel.push(data[i].label)

      if (data[i].referenceDate != null) secondLabel.push(data[i].referenceDate)

    }


    let serieHistoricoAvailability = new SerieModel(ListData, "Disponibilidade", ChartType.column, DashStyleOption.Solid, '#C4CACF', 1);
    let serieHistoricoIdleness = new SerieModel(ListDataIdleness, "Ociosidade", ChartType.column, DashStyleOption.Solid, '#F77733', 1);

    let series: SerieModel[] = [];

    if (serieHistoricoIdleness.data.find(x => x != null) != undefined) {
      series.push(serieHistoricoIdleness)
    }

    if (serieHistoricoAvailability.data.find(x => x != null) != undefined) {
      series.push(serieHistoricoAvailability)
    }

    let secundLabelData = secondLabel.length > 0 ? secondLabel : []

    let model = new ChartItemModel(series, "Comparativo Histórico do Tempo Logado", "", true, "", "", "Hora(s)", firstLabel, secundLabelData);


    return model

  }
  resetDatasources()
  {
    this.productivitychartData = [];
    this.bigNumberAvailabilityTodayTeams = [];
    this.bigNumberAvailabilityFullDayTeams = [];
    this.chartConexao = this.colaboradorService.currentchartConnection$!!;
    this.employeesBadConnection = 0;
    this.dashboardService.updatecomparativeJourney(null);
  }

  udpateBadSingal() {
    this.dashboardService.getTotalBadSignalByChiefId(this.authUser.id, this.dateFiltered).subscribe(data => this.employeesBadConnection = data);
  }
  updateTeamAnalysisTimeLogged(data: ChartItemModel) {

    this.dashboardService.updateTeamAnalysisTimeLogged(data)


  }
  updateTodayTeamHighLights() {
    switch (this.visualizationType) {
      case "gestao-direta":
        this.dashboardService.getTeamLogged(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.bigNumberAvailabilityTodayTeams = this.setTodayTeam(data)
            this.bigNumberAvailabilityFullDayTeams = this.setFullDayTeam(data)
          })
        break;
      case "todo-time":
        this.dashboardService.getTeamLoggedManager(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.bigNumberAvailabilityTodayTeams = this.setTodayTeam(data)
            this.bigNumberAvailabilityFullDayTeams = this.setFullDayTeam(data)
          })
        break;
      default:
        break;
    }
  }

  updateLoggedAndExpectedLoginCharts() {
    //Limpa o dados para ativar a animacao do loading
    this.dashboardService.updateChartConnection(null)

    switch (this.visualizationType) {
      case "gestao-direta":
        this.dashboardService.getComparisonLoggedInAndExpectedLogin(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.dashboardService.updateChartConnection(this.setChartConexao(data));
          })
        break;
      case "todo-time":
        this.dashboardService.getComparisonLoggedInAndExpectedLoginManager(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.dashboardService.updateChartConnection(this.setChartConexao(data));
          })
        break;
      default:
        break;
    }

  }

  updateAvailabilityHighlights() {
    switch (this.visualizationType) {
      case "gestao-direta":
        this.dashboardService.getTeamAvailability(this.authUser.id, this.dateFiltered).subscribe(
          data => {

            this.bigNumberHighLightTodayTeam = this.setHighLightTodayTeam(data)

          })
        break;
      case "todo-time":
        this.dashboardService.getTeamAvailabilityManager(this.authUser.id, this.dateFiltered).subscribe(
          data => {

            this.bigNumberHighLightTodayTeam = this.setHighLightTodayTeam(data)
          })
        break;
      default:
        break;
    }

  }

  updateProductivityHighlights() {
    switch (this.visualizationType) {
      case "gestao-direta":
        this.dashboardService.getProductivityTeam(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.employeeBigNumberHighlightsProductivity = this.setHighlightsProductivityTeam(data)
          })
        break;
      case "todo-time":
        this.dashboardService.getProductivityTeamManager(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.employeeBigNumberHighlightsProductivity = this.setHighlightsProductivityTeam(data)

          })
        break;
      default:
        break;
    }

  }
  updateProductivityLoggedHighlights() {
    switch (this.visualizationType) {
      case "gestao-direta":
        this.dashboardService.getProductivityLoggedTeam(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.employeeBigNumberHighlightsProductivityLogged = this.setHighlightsProductivityTeam(data)
          })
        break;
      case "todo-time":
        this.dashboardService.getProductivityLoggedTeamManager(this.authUser.id, this.dateFiltered).subscribe(
          data => {
            this.employeeBigNumberHighlightsProductivityLogged = this.setHighlightsProductivityTeam(data)

          })
        break;
      default:
        break;
    }

  }
  updateTeamReportChart() {

    let settings = this.authService.getSemaphores();

    this.alertSetting = settings.length > 0 ? new AlertSetting(settings[0].ranges.red, settings[0].ranges.yellow, settings[0].ranges.red) : new AlertSetting(0, 0, 0);

    switch (this.visualizationType) {
      case "gestao-direta":
        this.teamReportService.getTeamReport(this.authUser.id, this.dateFiltered, this.dateFiltered).subscribe(
          data => {
            this.teamReport = data
          })
        break;
      case "todo-time":
        this.teamReportService.getTeamReportManager(this.authUser.id, this.dateFiltered, this.dateFiltered).subscribe(
          data => {
            this.teamReport = data
          })
        break;
      default:
        break;
    }
  }


  updateTeamReportChartForHour(startDate: string, endDate: string) {

    this.teamReportService.updateGetTeamReport(null)
    switch (this.visualizationType) {
      case "gestao-direta":
        this.teamReportService.getTeamReport(this.authUser.id, startDate, endDate, true).subscribe(
          data => {
            this.teamReport = data
          })
        break;
      case "todo-time":
        this.teamReportService.getTeamReportManager(this.authUser.id, startDate, endDate, true).subscribe(
          data => {
            this.teamReport = data
          })
        break;
      default:
        break;
    }
  }




  updateProductivityCharts() {

    //Limpa o dados para ativar a animacao do loading
    this.dashboardService.updatecomparativeJourney(null)

    switch (this.productivityChartsSelectOption) {
      case Periods.Day:
        switch (this.visualizationType) {
          case "gestao-direta":
            this.dashboardService.getComparativeJourneyDailyByChiefId(this.authUser.id, this.dateFiltered).subscribe(data => this.updateComparativeJourney(this.setComparativeJourney(data)));
            break;
          case "todo-time":
            this.dashboardService.getComparativeJourneyDailyByChiefIdManager(this.authUser.id, this.dateFiltered).subscribe(data => this.updateComparativeJourney(this.setComparativeJourney(data)));
            break;
          default:
            break;
        }
        break;
      case Periods.Week:
        switch (this.visualizationType) {
          case "gestao-direta":
            this.dashboardService.getComparativeJourneyWeeklyByChiefId(this.authUser.id, this.dateFiltered).subscribe(data => this.updateComparativeJourney(this.setComparativeJourney(data)));
            break;
          case "todo-time":
            this.dashboardService.getComparativeJourneyWeeklyByChiefIdManager(this.authUser.id, this.dateFiltered).subscribe(data => this.updateComparativeJourney(this.setComparativeJourney(data)));
            break;
          default:
            break;
        }
        break;
      case Periods.Month:
        switch (this.visualizationType) {
          case "gestao-direta":
            this.dashboardService.getComparativeJourneyMonthlyByChiefId(this.authUser.id, this.dateFiltered).subscribe(data => this.updateComparativeJourney(this.setComparativeJourney(data)));
            break;
          case "todo-time":
            this.dashboardService.getComparativeJourneyMonthlyByChiefIdManager(this.authUser.id, this.dateFiltered).subscribe(data => this.updateComparativeJourney(this.setComparativeJourney(data)));
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

  }
  updateRankingTopBottom() {

    let startDate = new Date();
    let startDateFormatted = this.dateFiltered;
    let endDateFormatted = this.dateFiltered;
    let periodTopText = 'Colaboradores com a maior produtividade';
    let periodBottomText = 'Colaboradores com a menor produtividade';

    switch (this.productivityChartsSelectOption) {
      case Periods.Day:
        startDateFormatted = this.dateFiltered;
        periodTopText += ` no dia ${moment(this.dateFiltered).format('DD/MM/YYYY')}`;
        periodBottomText += ` no dia ${moment(this.dateFiltered).format('DD/MM/YYYY')}`;
        break;
      case Periods.Week:
        startDate = new Date(this.dateFiltered);
        startDate.setDate(startDate.getDate() - 13);
        startDateFormatted = moment(new Date(startDate)).format('YYYY-MM-DD');
        periodTopText += ` entre ${moment(startDate).format('DD/MM/YYYY')} e ${moment(this.dateFiltered).format('DD/MM/YYYY')}`;
        periodBottomText += ` entre ${moment(startDate).format('DD/MM/YYYY')} e ${moment(this.dateFiltered).format('DD/MM/YYYY')}`;
        break;
      case Periods.Month:
        startDate = new Date(this.dateFiltered);
        startDate.setDate(1);
        startDateFormatted = moment(new Date(startDate)).format('YYYY-MM-DD');
        periodTopText += ` no mês de ${moment(startDate).format('MMMM YYYY')}`;
        periodBottomText += ` no mês de ${moment(startDate).format('MMMM YYYY')}`;
        break;
      default:
        // Defina um comportamento padrão se necessário
        break;
    }



    switch (this.visualizationType) {
      case "gestao-direta":
        this.dashboardService.getRankingEmployeeByChiefId(this.authUser.id, startDateFormatted, endDateFormatted).subscribe(data => {
          this.employeesRakingProdutivo = this.setRankingEmployees(data?.top, "", periodTopText, "modalRankingTop");
          this.employeesRakingImprodutivo = this.setRankingEmployees(data?.bottom, "", periodBottomText, "modalRankingBottom");
        });
        break;
      case "todo-time":
        this.dashboardService.getRankingEmployeeByChiefIdManager(this.authUser.id, startDateFormatted, endDateFormatted).subscribe(data => {
          this.employeesRakingProdutivo = this.setRankingEmployees(data?.top, "", periodTopText, data.semaphore);
          this.employeesRakingImprodutivo = this.setRankingEmployees(data?.bottom, "", periodBottomText, data.semaphore);
        });
        break;
      default:
        // Defina um comportamento padrão se necessário
        break;
    }
  }

  updateUserApplicationList(dateFiltered: string = this.dateFiltered) {
    switch (this.visualizationType) {
      case "gestao-direta":
        let applications = this.applicationService.getApplications(this.authUser.id, dateFiltered);

        const observables = [applications];
        const join = forkJoin(observables);

        join.subscribe({
          next: result => {
            this.productiveApplications = result[0]?.productive;
            this.unproductiveApplications = result[0]?.unproductive;
            this.productiveWithouthapplications = result[0]?.withoutClassification;
          }
        });
        break;
      case "todo-time":

        let applicationsFullTeam = this.applicationService.getApplicationsFullTeam(this.authUser.id, dateFiltered);

        const observablesAllTeam = [applicationsFullTeam];
        const joinAllTeam = forkJoin(observablesAllTeam);

        joinAllTeam.subscribe({
          next: result => {

            this.productiveApplications = result[0]?.productive;
            this.unproductiveApplications = result[0]?.unproductive;
            this.productiveWithouthapplications = result[0]?.withoutClassification;
          }
        });
        break;
      default:
        break;
    }

  }
  updateLabels() {
    this.dayLabel = this.dateFiltered == new Date().toFormattedString() ? "Hoje" : "Dia"
    this.beforeDayLabel = this.dateFiltered == new Date().toFormattedString() ? "Ontem" : "Dia anterior"
  }
  validateDateFiltered() {
    let today = new Date()
    let selectedDate = new Date(this.dateFiltered).addDaysDate(1)

    today.setHours(0, 0, 0, 0)
    selectedDate.setHours(0, 0, 0, 0)

    if (selectedDate.getTime() > today.getTime()) {
      this.toastrService.info("Não é possível informar uma data futura", "Atenção")
      this.dateFiltered = new Date().toFormattedString()
    }
  }
  validateLastUpdate(): boolean {
    let today = new Date()

    let diff = today.diffInSeconds(this.lastUpdate);


    if (diff < 3) {
      this.toastrService.info("Aguarde pelo menos 3 segundos para informar uma nova data de referência", "Atenção")
      this.dateFiltered = today.toFormattedString()
      return false
    }

    this.lastUpdate = today
    return true
  }

  searchApplication(event: any) {
    
    let pattern = /.*(?=,([^,]*$))/;
    let information = event.match(pattern);
    let title = information[0]
    this.subTitle = information[0]
    let searchApplication = new SearchApplications(this.authUser.id, title, this.dateFiltered)
    this.employeeList = []
    this.title = ''
    this.showMessage = false
    switch (this.visualizationType) {
      case "gestao-direta":
          this.title = information[1]
          this.dashboardService.getEmployeeListThatUseApplication(searchApplication).subscribe({
            next: (response:EmployeeMininalModel[]) => {

              this.employeeList = response
              this.employeeList.forEach(x=> x.selectDate = "");
            },
            complete:() => {

              if(this.employeeList.length == 0){
                this.showMessage = true
              }
            }
          })

        break;
      case "todo-time":
        this.title = information[1]
        this.dashboardService.getEmployeeListThatUseApplication(searchApplication).subscribe({
          next: response => {
            this.employeeList = response
          },
          complete:() => {

            if(this.employeeList.length == 0){
              this.showMessage = true
            }
          }
        })

        break;
      default:
        break;
    }
  }


  getMyTeamReportByRangeTime(hour: any) {

    let now = new Date();

    let initialHour = moment(hour, 'HH:mm', true);
    let finalHour = moment(hour, 'HH:mm', true);

    let initalDate: Date = new Date(`${this.dateFiltered}T${initialHour.minute(0).second(0).format("HH:mm:ss")}`);
    let finalDate: Date = new Date(`${this.dateFiltered}T${finalHour.minute(59).second(59).format("HH:mm:ss")}`);

    let startDate: string = format(initalDate, "yyyy-MM-dd'T'HH:mm:ss")
    let endDate: string = format(finalDate, "yyyy-MM-dd'T'HH:mm:ss")

    if (initalDate.getDate() == now.getDate() && now.getHours() < initalDate.getHours()) {
      this.toastrService.info("A faixa horária escolhida ainda não possui dados, pois se trata de um horário futuro", "Atenção")
      return
    }

    this.setSubtitle(format(initalDate, "HH:mm"), format(finalDate, "HH:mm"))
    this.updateTeamReportChartForHour(startDate, endDate)

  }

  goToSomePage(employee: EmployeeMininalModel){

    if(employee.selectDate == '') return

    const queryParams = {
      period: this.getGetQueryParam('period'),
      date_filter: employee.selectDate
    };

    switch (this.visualizationType) {
      case "gestao-direta":
          this.openColaboratorPage(queryParams,employee.id)
        break;
      case "todo-time":
        employee.openChiefPage ? this.openChiefPage(queryParams,employee.chiefid) : this.openColaboratorPage(queryParams,employee.id)
        break;
      default:
        break;
    }

  }

  exportToExcelproductivity(){

    const label = this.productivitychartData.map(label => {return label.label})
    const referenceDate = this.productivitychartData.map(referenceDate => {return referenceDate.referenceDate})

    const empty = [""];
    const result: string[] = [...empty, ...label];
    const wsData: string[][] = [result];

    const response: string[][] = [
      ["", ...referenceDate],
      ["Jornada de trabalho", ...this.productivitychartData.map(x => x.estimatedJourney || 0)],
      ["Tempo logado", ...this.productivitychartData.map(x => x.availability || 0)],
      ["Produtividade Total", ...this.productivitychartData.map(x => x.totalProductivity || 0)],
      ["Tempo logado (%)",   ...this.productivitychartData.map(x => x.availabilityPercent != null ? String(`${x.availabilityPercent} %`) : "0 %")],
      ["Produtividade no tempo logado (%)",  ...this.productivitychartData.map(x => x.loggedProductivity != null ? String(`${x.loggedProductivity} %`) : "0 %")],
      ["Produtividade Total (%)",  ...this.productivitychartData.map(x => x.totalProductivityPercent != null ? String(`${x.totalProductivityPercent} %`) : "0 %")],
    ];


    for (let i = 0; i < 7; i++) {
      wsData.push([...response[i], ...label.map(() => "")]);
    }

   // Criar uma planilha
      const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Criar um livro de trabalho
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Funcionários');

    // Exportar a planilha para um arquivo Excel
    XLSX.writeFile(wb, `Relatório-Produtividade.xlsx`);
  }


  exportToExcelLogged(){

    const hourOfDay = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0') + ":00");
    const empty = [""];

    const result: string[] = [...empty, ...hourOfDay];

    const wsData: string[][] = [result];


    const response: string[][] = [
      [""],
      ["Previsto", ...this.loggedychartData.map(x => x.expectedLogin || 0)],
      ["Logado", ...this.loggedychartData.map(x => x.logged || 0)],
      ["Logados (%)", ...this.loggedychartData.map(x => ( x.loggedPercent === null ? "0 %" : (x.loggedPercent > 100 ? "100 %" : String(`${x.loggedPercent} %`))))],
      ["Não logado", ...this.loggedychartData.map(x => x.loggedOut || 0)],
    ];


    for (let i = 0; i < 5; i++) {
      wsData.push([...response[i], ...hourOfDay.map(() => "")]);
    }

   // Criar uma planilha
      const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Criar um livro de trabalho
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Funcionários');

    // Exportar a planilha para um arquivo Excel
    XLSX.writeFile(wb, `Relatório-Logado-previsto.xlsx`);

  }

  exportToExcelTopRanking(name: string, topRaking: EmployeesRakingModel): void {
    const colNames = [
      'Nome Completo',
      'Gargo',
      'Produtividade Total',
      'Disponibilidade (total logado)',
      'Produtividade (Total disponivel)',
    ];


    const data = topRaking.employeesData.map((detail) => [
      detail.employee.name || '',
      detail.employee.role || '',
      String(detail.firstEmployeeData.value + " %")  || '0%',
      String(detail.secondEmployeeData.value + " %") || '0%',
      String(detail.thirdEmployeeData.value + " %")  || '0%',
    ]);

    // Adicionar o cabeçalho aos dados
    const wsData = [colNames, ...data];

    // Criar uma planilha
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(wsData);

    // Criar um livro de trabalho
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Funcionários');

    // Exportar a planilha para um arquivo Excel
    XLSX.writeFile(wb, `${name}.xlsx`);



  }

  setGoToChiefPage(){
    this.goToChiefPage = !this.goToChiefPage
  }

  setGoToColaboratorPage(){
    this.goToColaboratorPage = !this.goToColaboratorPage
  }

  openColaboratorPage(queryParams: object, id?: string){
    const url = this.router.createUrlTree(['/painel-colaborador', id], { queryParams }).toString();
    window.open(url, '_blank');
  }

  openChiefPage(queryParams: object, id?: string){
    const url = this.router.createUrlTree(['/painel-supervisor', id], { queryParams }).toString()
    window.open(url, '_blank');
  }


  exportToExcelAllVision(){
    if (this.teamReportComponent) {
      this.teamReportComponent.exportToExcel();
    }
  }

  exportToExcelApplication(idTable: string , title: string){

    const table = document.getElementById(idTable) as HTMLElement

    const ws = XLSX.utils.table_to_sheet(table);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Aplicações');
    XLSX.writeFile(wb, `${title}.xlsx`);

  }

  updateVisualization() {
    if (!this.validateLastUpdate()) {
      return
    }
    this.changeVisualization();
    this.updateTeamReportChart();
    this.validateDateFiltered()
    this.udpateBadSingal()
    this.updateTodayTeamHighLights()
    this.updateAvailabilityHighlights()
    this.updateProductivityHighlights()
    this.updateProductivityLoggedHighlights()
    this.updateProductivityCharts()
    this.updateRankingTopBottom()
    this.updateUserApplicationList()
    this.updateLoggedAndExpectedLoginCharts();
    this.setSubtitle("00:00", "23:59")
    this.updateLabels()
    this.setTopApplication()
  }

  updatePanels() {
    if (!this.validateLastUpdate()) {
      return
    }
    this.updateTeamReportChart();
    this.validateDateFiltered();
    this.udpateBadSingal();
    this.updateTodayTeamHighLights();
    this.updateAvailabilityHighlights();
    this.updateProductivityHighlights();
    this.updateProductivityLoggedHighlights();
    this.updateProductivityCharts();
    this.updateRankingTopBottom();
    this.updateUserApplicationList();
    this.setSubtitle("00:00", "23:59");
    this.updateLoggedAndExpectedLoginCharts();
    this.updateLabels();
    this.setTopApplication();
  }

  updateDateFilter() {
    if (!this.validateLastUpdate()) {
      return
    }
    this.changeDateFilter();
    this.updateTeamReportChart();
    this.validateDateFiltered()
    this.udpateBadSingal()
    this.updateTodayTeamHighLights()
    this.updateAvailabilityHighlights()
    this.updateProductivityHighlights()
    this.updateProductivityLoggedHighlights()
    this.updateProductivityCharts()
    this.updateRankingTopBottom()
    this.updateLoggedAndExpectedLoginCharts();
    this.updateUserApplicationList()
    this.setSubtitle("00:00", "23:59")
    this.updateLabels()
    this.setTopApplication()
  }

  resetReport(event: any) {
    this.updateTeamReportChart()
  }


  dateForWeek() {
    let date = new Date(this.dateFiltered);
    let day = date.addDays(1);
    let week = date.addDays(-6);

    return {
      "day": moment(day).format('DD/MM/YYYY'),
      "week": moment(week).format('DD/MM/YYYY'),
      "month": moment(week).format('MMMM YYYY') // Retorna o nome do mês e o ano
    };
  }
  productivityTabsClick(event: any) {

  }
}

