import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ImplementationModel } from '../model/implementation.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImplementationService {

  constructor(private http: HttpClient) {}

  getLoginLogoutLogs(): Observable<ImplementationModel[]> {
    return this.http.get<ImplementationModel[]>(`${environment.agentApi}/api/v1/agent/Dashboard/GetImplementationReport`);
  }
}
