export class LoginTwoFactorRequest {

    userName: string;
    password: string;
    twoFactorCode: string;


    constructor(userName: string, password: string, twoFactorCode:string){

        this.userName = userName;
        this.password = password;
        this.twoFactorCode = twoFactorCode;
    }
}