<div class="container mt-4 mb-4">
  <div class="section-button-action">
    <button type="button" class="btn btn-light m-1 btn-excel" (click)="exportToExcel()">
      <span>
        Exportar planilha
      </span>
      <span>
        <mat-icon>file_copy</mat-icon>
      </span>
    </button>
    <button (click)="showModal()" class="btn m-1 btn-label btn-filter filter">
      Filtrar
      <mat-icon class="filter">filter_list_alt</mat-icon>
    </button>
  </div>
  <div id="modal-filter" class="info-filter" *ngIf="showModalFilter">
    <div class="filters">
      <div class="row-filter">
        <div>
          <label>Gestor:</label>
          <select [(ngModel)]="selectedChief" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let chief of uniqueChiefs" [value]="chief">{{ chief }}</option>
          </select>
        </div>
        <div>
          <label>Colaborador:</label>
          <select [(ngModel)]="selectedFullName" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let fullName of uniqueFullNames" [value]="fullName">{{ fullName }}</option>
          </select>
        </div>
      </div>
      <div class="row-filter">
        <div>
          <label>Plataforma:</label>
          <select [(ngModel)]="selectedPlatform" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let platform of uniquePlatform" [value]="platform">{{ platform }}</option>
          </select>
        </div>
        <div>
          <label>NetworkUser</label>
          <select [(ngModel)]="selectedNetworkUser" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let networkUser of uniqueNetworkUser" [value]="networkUser">{{ networkUser }}</option>
          </select>
        </div>
      </div>
      <div class="row-filter">
        <div>
          <label>Grupo de ferramentas:</label>
          <select [(ngModel)]="selectedGroupName" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let groupName of uniqueGroupNames" [value]="groupName">{{ groupName }}</option>
          </select>
        </div>
        <div>
          <label>Versão do Python: </label>
          <select [(ngModel)]="selectedPythonVersion" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let pythonVersion of uniquePythonVersion" [value]="pythonVersion">{{ pythonVersion }}
            </option>
          </select>
        </div>
      </div>
      <div class="row-filter">
        <div>
          <label>Versão do Agent:</label>
          <select [(ngModel)]="selectedAgentVersion" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let agentVersion of uniqueAgentVersion" [value]="agentVersion">{{ agentVersion }}
            </option>
          </select>
        </div>
        <div>
          <label>Hostname:</label>
          <select [(ngModel)]="selectedHostName" (change)="applyFilters()" class="form-select">
            <option value="">Todos</option>
            <option *ngFor="let hostName of uniqueHostName" [value]="hostName">{{ hostName }}
            </option>
          </select>
        </div>
      </div>
      <div class="row-filter-footer">
        <div>
          <button (click)="clearFilters()" class="btn btn-light"
            style="display: flex;"><mat-icon>close</mat-icon>Limpar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-group">
    <mat-card class="card">
      <mat-card-content>
        <div class="d-flex align-items-center">
          <mat-icon class="card-icon">groups</mat-icon>
          <div class="card-number">{{implementationData?.length}}</div>
        </div>
        <div class="card-title">Logins (48H)</div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <mat-card-content>
        <div class="d-flex align-items-center">
          <mat-icon class="card-icon">power</mat-icon>
          <div class="card-number">{{getLoggedUsersCount()}}</div>
        </div>
        <div class="card-title">Logados</div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <mat-card-content>
        <div class="d-flex align-items-center">
          <mat-icon class="card-icon">power_off</mat-icon>
          <div class="card-number">{{getOfflineUsersCount()}}</div>
        </div>
        <div class="card-title">Deslogados</div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card" *ngFor="let version of objectEntries(globalVersionCounts)">
      <mat-card-content>
        <div class="d-flex align-items-center">
          <mat-icon class="card-icon">arrow_downward</mat-icon>
          <div class="card-number">{{ version[1]}}</div>
        </div>
        <div class="card-title">Agent ({{version[0] }})</div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <mat-card-content>
        <div class="d-flex align-items-center">
          <mat-icon class="card-icon">keyboard</mat-icon>
          <div class="card-number">{{countLatestPythonVersion}}</div>
        </div>
        <div class="card-title" title="Python">Py ({{latestPythonVersion}})</div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <mat-card-content>
        <div class="d-flex align-items-center">
          <mat-icon class="card-icon">mouse</mat-icon>
          <div class="card-number">{{countLatestPlatform}}</div>
        </div>
        <div class="card-title" [title]="latestPlatform">({{truncate(latestPlatform, 10)}})</div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="row">
    <div *ngFor="let item of filteredimplementationData; let i = index" class="col-6 mb-4">
      <mat-card>
        <mat-card-header>
          <mat-card-subtitle><mat-icon>computer</mat-icon>{{ item.hostname }}</mat-card-subtitle>
          <mat-card-subtitle><mat-icon>groups</mat-icon>{{ item.applicationGroup }}</mat-card-subtitle>
          <mat-card-title class="title" [title]="item.fullname?.toUpperCase()"><mat-icon>person</mat-icon>{{
            truncate(item.fullname.toUpperCase(), 25) }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ul>
            <li><strong>Agent Version:</strong> {{ item.agentVersion }}</li>
            <li><strong>Python Version:</strong> {{ item.pythonVersion }}</li>
            <li><strong>Platform:</strong> {{ item.platform }}</li>
            <li><strong>Start Date:</strong> <span class="bg-startDate"
                [ngClass]="{'bg-primary badge': isDateToday(item.startDate)}">{{ item.formattedStartDate |
                date:'dd/MM/yyyy HH:mm:ss' }}</span></li>
            <li><strong>End Date:</strong> {{ item.endDate != null ? (item.formattedEndDate | date:'dd/MM/yyyy HH:mm:ss') : "" }}</li>
            <li><strong>Duration:</strong> {{ item.duration != null ? item.duration : "" }}</li>
            <li><strong>Network User:</strong> {{ item.networkUser }}</li>
            <li><strong>Chief:</strong> {{ item.chief }}</li>
          </ul>
          <mat-divider></mat-divider>
        </mat-card-content>
        <mat-card-actions>
          <div *ngIf="item.endDate == null" class="actions">
            <mat-icon>power</mat-icon>
            <p>Online - Coletando logs...</p>
          </div>
          <div *ngIf="item.endDate != null" class="actions">
            <mat-icon>power_off</mat-icon>
            <p>Offline</p>
          </div>
        </mat-card-actions>
        <mat-card-footer>
          <mat-progress-bar *ngIf="item.endDate" mode="determinate" value="0"></mat-progress-bar>
          <mat-progress-bar *ngIf="!item.endDate" mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>