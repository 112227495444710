import { Component, OnInit } from '@angular/core';
import { HeaderModel } from '@station/header';
import { AuthService } from 'src/app/services/auth.service';
import { UserNotification } from '../../painel-colaborador/models/userNotification.model';
import { ColaboradorService } from '../../painel-colaborador/services/colaborador.service';
import { LoginResponse } from '../../login/model/login-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmployeeModel } from '@station/employees';
import { Periods } from '../../painel-supervisor/models/teamAvailabilityToday.model';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PainelAdministrativoService } from '../services/painel-administrativo.service';
import { TimeFrameDataCountModel } from '../models/timeFrameDataCountModel.model';
import { ChartColumnGroupModel, ChartType, DashStyleOption, SerieColumnGroupModel } from '@station/charts';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class PainelAdministrativoComponent implements OnInit {
  Periods: any;
  lastUpdate!: Date;
  dayLabel: string = 'Hoje';
  beforeDayLabel: string = 'Ontem';



  maxDate: any;
  dateFiltered: any;

  updatePanels() {
    this.updateChartApplicationLog()
  }

  header: HeaderModel = new HeaderModel(
    '300062',
    'Heverton pablo',
    'Colaborador',
    false,
    'https://picsum.photos/100',
    true,
    'Buscar Funcionários'
  );
  authUser!: LoginResponse;
  authUser$!: BehaviorSubject<LoginResponse>;
  profile$!: Observable<EmployeeModel>;
  employeeProfile!: EmployeeModel;
  employeeId!: string | null;
  applicationLogChartsSelectOption: Periods = Periods.Day;
  userNotifications: UserNotification[] = [];
  timeFrameDataCountModel: TimeFrameDataCountModel[] = []
  chartApplicationLog: any[] = []
  countUnreadUserNotification: number = 0;
  showEmployeeList: boolean = false;

  constructor(
    private authService: AuthService,
    private colaboradorService: ColaboradorService,
    private route: ActivatedRoute,
    private router: Router,
    private service: PainelAdministrativoService
  ) { }

  ngOnInit(): void {

    this.lastUpdate = new Date();

    this.getUrlParameters();

    this.getEmployee();

    this.updateChartApplicationLog();
  }
  setProfile(data: any): Observable<EmployeeModel> {
    let profile: EmployeeModel = {
      name: `${data.firstName} ${data.lastName}`,
      picture:
        data.photoPath.length > 0
          ? data.photoPath.length
          : '/assets/img/user-default.png',
      role: data.jobPosition,
      roles: this.authService.getRoles(),
      enabled: data.enabled,
      panel: false,
      badSignal: false,
      linkProfile: '',
      isOnline: data.isOnline,
      sleep: data.sleep,
      shouldBeCurrentlyWorking: false,
      linkDetail: '',
      hasFirstLogin: false,
      teamIsOnline: 0,
    };

    return new Observable<EmployeeModel>((sub: any) => sub.next(profile));
  }
  getEmployee() {
    if (this.employeeId != null) {
      this.colaboradorService
        .getAgentById(this.employeeId)
        .subscribe((data: any) => {
          this.authUser = data;
          this.profile$ = this.setProfile(data);
        });
    } else {
      this.authUser = this.authService.authUser;
      this.setProfile(this.authService.authUser);
    }
  }
  validarFormatoData(data: string | null | undefined): string | null {
    if (data == null || data == undefined) {
      return null;
    }

    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(data)) {
      return data;
    } else {
      return null;
    }
  }
  getGetQueryParam(term: string) {
    let dateFilter = null;

    this.route.queryParams.subscribe((params: any) => {
      dateFilter = params[term];
    });

    return dateFilter;
  }
  changeDateFilter() {
    const queryParams = { ...this.route.snapshot.queryParams };

    queryParams['date_filter'] = this.dateFiltered;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
  changePeriodParam(period: string) {
    const queryParams = { ...this.route.snapshot.queryParams };

    queryParams['period'] = period;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  chartColumnGroupMenuItemClick(event: any) {
    switch (event) {
      case 'absoluto':

        break;
      case 'percentual':

        break;
    }
  }

  dateFilterClick(event: string[], nameComponent: string) {

    console.log(nameComponent)
  }



  chartItemOptionsStacked: Observable<ChartColumnGroupModel> = this.service.comparativeJourney$!!

  updateSetChartApplicationLog(data: ChartColumnGroupModel) {
    this.service.updateChartApplicationLog(data)
  }


  SetChartApplicationLog(data: TimeFrameDataCountModel[]) {

    this.chartApplicationLog = data

    let processedCount: number [] = []
    let capturedCount: number [] = []
    let period: string [] = []


    data.forEach((item) => {
      processedCount.push(item.processedCount as number)
      capturedCount.push(item.capturedCount as number)
      period.push(item.period as string)
    })

    let capturedCountSerie = new SerieColumnGroupModel(capturedCount, 'Capturadas', ChartType.column, DashStyleOption.Solid, '#E3E9EF', 0, '', -0.2,true);
    let processedCountSerie = new SerieColumnGroupModel(processedCount, 'Processadas', ChartType.column, DashStyleOption.Solid, '#FF9300', 0, '',1,true);

    let series: SerieColumnGroupModel[] = [capturedCountSerie, processedCountSerie]

    let categoriesDateLabel = period.length > 0 ? period : []


    let model = new ChartColumnGroupModel(series, "Comparativo captura", "",true, "Processmento", "Logs", "", "", "",categoriesDateLabel)



    return model

  }


  selectedPeriod(period: string) {
    this.changechangeChartApplicationLog(period)
  }


  changechangeChartApplicationLog(period: string) {

    switch (period) {
      case 'day':
        this.applicationLogChartsSelectOption = Periods.Day;
        break;

      case 'week':
        this.applicationLogChartsSelectOption = Periods.Week;
        break;

      case 'month':
        this.applicationLogChartsSelectOption = Periods.Month;
        break;

    }

    this.updateChartApplicationLog()

  }



  updateChartApplicationLog() {

    //Limpa o dados para ativar a animacao do loading
    this.service.updateChartApplicationLog(null)

    switch (this.applicationLogChartsSelectOption) {
      case Periods.Day:
        this.service.getApplicationTimeFrameDataCountByHour(this.dateFiltered).subscribe(data => {
          this.updateSetChartApplicationLog(this.SetChartApplicationLog(data))
        })
        break;
      case Periods.Week:
        this.service.getApplicationTimeFrameDataCountByHour(this.dateFiltered).subscribe(data => {
          this.updateSetChartApplicationLog(this.SetChartApplicationLog(data))
        })
        break;
      case Periods.Month:
        this.service.getApplicationTimeFrameDataCountByHour(this.dateFiltered).subscribe(data => {
          this.updateSetChartApplicationLog(this.SetChartApplicationLog(data))
        })
        break;
      default:
        break;
    }

}


  private getUrlParameters(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.employeeId = params.get('id');
      let dateFilter = this.validarFormatoData(
        this.getGetQueryParam('date_filter')
      );
      let period = this.getGetQueryParam('period');

      this.dateFiltered = dateFilter ?? new Date().toFormattedString();

      this.changeDateFilter();
      this.changePeriodParam('day');

      if (period) {
        this.selectedPeriod(period);
      }
    });
  }
  loadMoreUserNotification(pageNumber: number) {
    this.getUserNotifications(pageNumber);
  }
  getUserNotifications(pageNumber: number = 1) {
    let loggedUserId = this.authService.authUser.id;

    this.colaboradorService
      .getNotificationByUserId(loggedUserId, pageNumber)
      .subscribe((response: any) => {
        this.userNotifications.length == 0
          ? (this.userNotifications = response)
          : (this.userNotifications = this.userNotifications.concat(response));
      });
  }
}
