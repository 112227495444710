<div class="painel-semaphore">

  <station-header [headerModel]="header" class="station-header"></station-header>

  <div class="painel-semaphore_content">

    <station-sidebar></station-sidebar>

    <div class="content">

      <div class="sub-header">
        <div>
          <p>Configurações</p>
        </div>

      </div>

      <div class="range-semaphore_content">

        <mat-tab-group >
          <mat-tab label="Semáforo" *ngIf="hasRole('configuracoes-semaforo')">

            <div class="box-semaphore">
              <div class="list-card ">
                <div class="show-inatives">
                  <label>Exibir inativos</label>
                  <input class="station-form-control checkboxInactive" ngModel (ngModelChange)="inativos($event)"
                    type="checkbox">
                </div>

                <div #clickForm class="list-container-range">

                  <div *ngFor="let semaphore of semaphoreFiltered">
                    <div class="item-direction-range " (click)="renderForm(semaphore.semaphores)">
                      <station-employee-card-journey [CardJourney]="semaphore.cardJourneyModel">
                      </station-employee-card-journey>
                    </div>
                  </div>

                </div>
              </div>


              <form [formGroup]="formGroup" (ngSubmit)="submit()" *ngIf="form" class="station-form-group">

                <div class="range-description">
                  <span class="station-form-label label">Descritivo:</span>
                  <input class="station-form-control descripition" formControlName="description" type="text" readonly>
                </div>

                <div class="range-description">
                  <span class="station-form-label label">Habilitado:</span>
                  <input class="station-form-control" formControlName="enable" type="checkbox">
                </div>

                <div class="range-description">
                  <span class="station-form-label label">de 0 até:</span>
                  <span class="semaphore-color red"></span>
                  <input class="station-form-control" formControlName="danger" (change)="changeInputRange('danger')"
                    type="number" min="0" step="any">
                </div>

                <div class="range-description ">
                  <span class="station-form-label label">de {{ red }} até:</span>
                  <span class="semaphore-color yellow"></span>
                  <input class="station-form-control" formControlName="warning" (change)="changeInputRange('warning')"
                    type="number" min="0" step="any">
                </div>

                <div class="range-description ">
                  <span class="station-form-label label">de {{ yellow }} até:</span>
                  <span class="semaphore-color green"></span>
                  <input class="station-form-control" formControlName="success" (change)="changeInputRange('success')"
                    type="number" min="0" step="any">
                </div>

                <div class="submit">
                  <button type="submit">Salvar</button>
                </div>

              </form>

            </div>



          </mat-tab>
          <mat-tab label="Ociosidade" *ngIf="hasRole('configuracoes-ociosidade')">
            <div class="content-form-ociosidade">
              <form [formGroup]="formAgentSetingsGroup" (ngSubmit)="submitAgentSettings()" action="">

                <div class="content-form">
                  <div class="content-input">

                    <div class="station-form-group">
                      <div class="station-form-flex">
                        <div>
                          <label class="station-form-label-bold" for="">Mudança de janela</label>
                        </div>
                        <div>
                          <input type="number" min="0" step="1"  oninput="this.value = parseInt(this.value) || ''" formControlName="intervalCaptureWindowChange"
                            class="station-form-control text-capitalize">

                        </div>
                      </div>
                    </div>

                    

                    <div class="station-form-group">
                      <div class="station-form-flex">
                        <div>
                          <label class="station-form-label-bold" for="">Velocidade da internet</label>
                        </div>
                        <div>
                          <input type="number" min="0" oninput="this.value = parseInt(this.value) || ''" formControlName="intervalMeasurementInternetSpeed"
                            class="station-form-control text-capitalize">

                        </div>
                      </div>
                    </div>

                   

                    <div class="station-form-group">
                      <div class="station-form-flex">
                        <div>
                          <label class="station-form-label-bold" for="">Ociosidade</label>
                        </div>
                        <div>
                          <input type="number" min="0" oninput="this.value = parseInt(this.value) || ''" formControlName="intervalCaptureIdleKeyboard"
                            class="station-form-control text-capitalize">
                        </div>
                      </div>
                    </div>
                    <p class="station-form-label" style="width: 90%;">Os novos valores alterados só serão validos na próxima inicialização do sistema agent no computador do colaborador.</p>
                    
                  </div>

                  <div class="content-checkbox">

                    <div class="content-checkbox-item">
                      <input type="checkbox" formControlName="captureWindowChange">
                      <label class="station-form-label-bold">Capturar janela?</label>
                    </div> 

                    <div class="content-checkbox-item">
                      <input type="checkbox" formControlName="captureUrl">
                      <label class="station-form-label-bold">Capturar URL?</label>
                    </div> 

                    <div class="content-checkbox-item">
                      <input type="checkbox" formControlName="measurementLatency">
                      <label class="station-form-label-bold">Capturar medição de latência?</label>
                    </div>  

                    <div class="content-checkbox-item">
                      <input type="checkbox" formControlName="measurementInternetSpeed">
                      <label class="station-form-label-bold">Capturar medição da velocidade da internet?</label>
                    </div> 

                    <div class="content-checkbox-item">
                      <input type="checkbox" formControlName="captureIdleMouse">
                      <label class="station-form-label-bold">Capturar ociosidade do mouse?</label>
                    </div> 

                    <div class="content-checkbox-item">
                      <input type="checkbox" formControlName="captureIdleKeyboard">
                      <label class="station-form-label-bold">Capturar ociosidade do teclado?</label>
                    </div>               
                   

                  </div>
                </div>

                <div class="d-flex mt-2">

                  <button type="submit" class="btn btn-light button-form " style="margin-right: 0;">Enviar</button>

                </div>
              </form>
            </div>
          </mat-tab>
        </mat-tab-group>


        <!-- {
          "_id" : ObjectId("63dabaf2a01308e0792ecd5e"),
          "windowSettings" : {
              "captureWindowChange" : true,
              "intervalCaptureWindowChange" : NumberInt(1),
              "captureUrl" : true
          },
          "measurementSettings" : {
              "measurementLatency" : true,
              "intervalMeasurementLatency" : NumberInt(300),
              "measurementInternetSpeed" : true,
              "intervalMeasurementInternetSpeed" : NumberInt(900)
          },
          "inputDeviceSettings" : {
              "captureIdleMouse" : true,
              "intervalCaptureIdleMouse" : NumberInt(300),
              "captureIdleKeyboard" : true,
              "intervalCaptureIdleKeyboard" : NumberInt(300)
          }
      } -->






      </div>
    </div>
  </div>
</div>