import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Application, ApplicationsData } from '../models/application.model';
import { ApplicationDetailsRequest } from 'src/app/shared/model/application-details-request';
import { ApplicationDetailsResponse } from 'src/app/shared/model/application-details-response';
import { ApplicationTimeline } from 'src/app/shared/model/application-timeline';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient) { }

  getApplications(): Observable<ApplicationsData> {

    return this.http.get<ApplicationsData>(`${environment.applicationsUtility}`)
  }
  getApplicationsDetailsByUserId(applicationDetailsRequest:ApplicationDetailsRequest): Observable<ApplicationDetailsResponse[]> {

    return this.http.post<ApplicationDetailsResponse[]>(`${environment.agentApi}${environment.endpoints.getApplicationDetailsByUserId}`, applicationDetailsRequest)
  }
  public getApplicationsUser(userId: string, dateFilter:string): Observable<Application[]> {

    const url = `${environment.dashboardApi}${environment.endpoints.dashProductivyApplicationUser}/${userId}?referenceDate=${dateFilter}`

    return this.http.get<Application[]>(url).pipe(map(resp => this.factorAplication(resp)))
  }

  public factorAplication(data: any): Application[] {

    const array: Application[] = [];

    for (let obj of data) {

      array.push({
        url: obj.url,
        windowTitle: obj.windowTitle,
        term: obj.term,
        title: obj.processName,
        category: obj.category,
        classification: obj.classification,
        totalTime: obj.totalDuration,
        date: obj.date,
        subCategory: "",
        description: obj.description
      })
    }

    return array
  }
  getApplicationTimeline(userId: String, startDate:String, endDate:String): Observable<ApplicationTimeline[]> {

    return this.http.get<any>(`${environment.agentApi}${environment.endpoints.getApplicationTimeline}/${userId}?startDate=${startDate}&endDate=${endDate}`)

  }

}
