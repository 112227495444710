import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Application } from './models/application.model';
import * as XLSX from 'xlsx';


@Component({
  selector: 'station-table-aplicacoes',
  templateUrl: './table-aplicacoes.component.html',
  styleUrls: ['./table-aplicacoes.component.scss']
})
export class TableAplicacoesComponent implements OnInit {
  
  @Input() applications: Application[] = [];
  @Input() title: string = '';
  @Input() IdTable: string = '' 
  @Input() subTitle: string = '';
  @Input() modalId?: string;
  @Input() modalIdPesquisaColaborador?: string;
  @Output() searchApplication = new EventEmitter<string>();

  mostrarIcone = false;
  mostrarOutroIcone = false;


  constructor() { }


  ngOnInit(): void {

  }

  formatTime(timeInMinutes: number): string {
    if (timeInMinutes < 60) {
      return timeInMinutes + 'min';
    } else if (timeInMinutes < 1440) {
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = timeInMinutes % 60;
      return hours + 'h' + minutes + 'min';
    } else if (timeInMinutes < 10080) {
      const days = Math.floor(timeInMinutes / 1440);
      const hours = Math.floor((timeInMinutes % 1440) / 60);
      return days + 'd' + hours + 'h';
    } else if (timeInMinutes < 40320) {
      const weeks = Math.floor(timeInMinutes / 10080);
      const days = Math.floor((timeInMinutes % 10080) / 1440);
      return weeks + 'sem' + days + 'd';
    } else {
      const months = Math.floor(timeInMinutes / 40320);
      const weeks = Math.floor((timeInMinutes % 40320) / 10080);
      return months + 'mês' + weeks + 'sem';
    }
  }

  searchEmployeeApplication(title: string){

    this.searchApplication.emit(`${title},${this.title}`)
  }

 

}
