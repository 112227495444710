import { Component, Input, OnInit } from '@angular/core';
import { EmployeeModel } from '@station/employees';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'station_dash-header',
  templateUrl: './dash-header.component.html',
  styleUrls: ['./dash-header.component.scss']
})
export class DashHeaderComponent implements OnInit {
  
  @Input() enabledBadConnection: boolean = true;
  @Input() isProfile: boolean = true;
  @Input() employeesBadConnection: number = 0
  @Input() employee$!: Observable<EmployeeModel>;

  success: number = 0
  warning: number = 0
  danger: number = 0



  constructor(private authService: AuthService) { }

  ngOnInit(): void {   

    let semaphore = this.authService.geSemaphoreByDescription('Disponibilidade e Produtividade') 
    this.success = semaphore!.ranges.green
    this.warning = semaphore!.ranges.yellow
    this.danger = semaphore!.ranges.red   
    

  }

}
