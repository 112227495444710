import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, ParamMap, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ListaPessoasGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, public route: ActivatedRoute) {}
  canActivate(): boolean {

    this.auth.checkToken().subscribe(()=>{
      
        if (this.auth.decodePayloadJWT()?.jobPosition.toUpperCase() == "COLABORADOR") {
          this.router.navigate(['painel-colaborador']);
          return false;
        }

        return true;
    });

    return true;
  }
}