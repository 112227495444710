import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CardJourneyModel, SubtitleCardJourneyModel } from '@station/employees';
import { HeaderModel } from '@station/header';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

import { Semaphore } from '../models/semaphore.model';
import { SemaphoreViewModel } from '../models/semaphore.view.model';
import { SemaphoreService } from '../services/semaphore.service';
import { RequestSemaphoreRanges } from './../models/request-semahore-ranges.model';
import { RequestSemaphore } from './../models/request-semaphore.model';
import { AgentSettings } from '../models/agent-settings.model';


@Component({

  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private semaphoreService: SemaphoreService,
    private toastr: ToastrService,
    private authService: AuthService
   
  ) { }

  header: HeaderModel = new HeaderModel(this.authService.authUser.registrationId, this.authService.authUser.firstName + " " + this.authService.authUser.lastName, this.authService.authUser.jobPosition, false, this.authService.authUser.photoPath, true, "Buscar Funcionários");



  formGroup!: FormGroup;
  currentDescription!: string
  newRange() {

    this.red = 2
    this.yellow = 4
    this.form = true;

    this.formGroup = this.fb.group({

      description: ['', Validators.required],
      enable: true,
      danger: [1, Validators.required],
      warning: [3, Validators.required],
      success: [5, Validators.required],
    });
  }

  red: number = 0
  yellow: number = 0

  form: boolean = false;
  renderForm(semaphore: Semaphore) {

    this.currentDescription = semaphore.description
    this.red = semaphore.ranges.red + 0.01
    this.red = Number(this.red.toFixed(2))
    this.yellow = semaphore.ranges.yellow + 0.01
    this.yellow = Number(this.yellow.toFixed(2))

    this.form = true;
    this.formGroup = this.fb.group({
      id: new FormControl(semaphore.id),
      description: new FormControl(semaphore.description),
      enable: new FormControl(semaphore.enable),
      danger: new FormControl(semaphore.ranges.red),
      warning: new FormControl(semaphore.ranges.yellow),
      success: new FormControl(semaphore.ranges.green)
    });
  }

  semaphores: SemaphoreViewModel[] = [];

  ngOnInit(): void {

    this.initialFormAgentSetingsGroup()

    this.semaphoreService.getAgentSettings().subscribe(data => {

      let agentSettings:AgentSettings = this.setAgentSettings(data)
      this.agentSettings = agentSettings
      this.renderFormAgentSetingsGroup(agentSettings)


      
    })

    this.semaphoreService.getAllSemaphore().subscribe({
      next: (data) => this.semaphores = this.setDataSemaphore(data)
    })

  }

  
  agentSettings!:AgentSettings 
  
  private setAgentSettings(data: AgentSettings[]): AgentSettings {
    
    return data[0]
    
  }
  
  formAgentSetingsGroup!: FormGroup
  initialFormAgentSetingsGroup(){


    this.formAgentSetingsGroup = this.fb.group({
      id: new FormControl(),
      autoLogin: new FormControl(),
      intervalToTryLogin: new FormControl(),
      intervalCheckLoginIsOpen: new FormControl(),
      captureWindowChange: new FormControl(),
      intervalCaptureWindowChange: new FormControl(),
      captureUrl: new FormControl(),
      measurementLatency: new FormControl(),
      intervalMeasurementLatency: new FormControl(),
      measurementInternetSpeed: new FormControl(),
      intervalMeasurementInternetSpeed: new FormControl(),
      captureIdleMouse: new FormControl(),
      intervalCaptureIdleMouse: new FormControl(),
      captureIdleKeyboard: new FormControl(),
      intervalCaptureIdleKeyboard: new FormControl(),
      allowedIdleTime: new FormControl(),
      idleLimit: new FormControl()
    });

  }
  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }
  renderFormAgentSetingsGroup(agentSettings:AgentSettings ){

    this.formAgentSetingsGroup = this.fb.group({
      id: new FormControl(agentSettings.id),
      autoLogin: new FormControl(agentSettings.autoLogin),
      intervalToTryLogin: new FormControl(agentSettings.intervalToTryLogin),
      intervalCheckLoginIsOpen: new FormControl(agentSettings.intervalCheckLoginIsOpen),
      captureWindowChange: new FormControl(agentSettings.windowSettings.captureWindowChange),
      intervalCaptureWindowChange: new FormControl(agentSettings.windowSettings.intervalCaptureWindowChange),
      captureUrl: new FormControl(agentSettings.windowSettings.captureUrl),


      measurementLatency: new FormControl(agentSettings.measurementSettings.measurementLatency),    
      intervalMeasurementLatency: new FormControl(agentSettings.measurementSettings.intervalMeasurementLatency),
      measurementInternetSpeed: new FormControl(agentSettings.measurementSettings.measurementInternetSpeed),
      intervalMeasurementInternetSpeed: new FormControl(agentSettings.measurementSettings.intervalMeasurementInternetSpeed),

      captureIdleMouse: new FormControl(agentSettings.inputDeviceSettings.captureIdleMouse),
      intervalCaptureIdleMouse: new FormControl(agentSettings.inputDeviceSettings.intervalCaptureIdleMouse),
      captureIdleKeyboard: new FormControl(agentSettings.inputDeviceSettings.captureIdleKeyboard),
      intervalCaptureIdleKeyboard: new FormControl(agentSettings.inputDeviceSettings.intervalCaptureIdleKeyboard),
      allowedIdleTime: new FormControl(agentSettings.inputDeviceSettings.allowedIdleTime),
      idleLimit: new FormControl(agentSettings.inputDeviceSettings.idleLimit)
    });

  }

  submitAgentSettings(){


    let inputsValues = this.instanceAgentSettings()
    this.semaphoreService.updateAgentSettings(inputsValues).subscribe(() => this.toastr.success("Atualização realizada com sucesso."))

    

  }

  instanceAgentSettings() :AgentSettings {

    let formValue = this.formAgentSetingsGroup.value

    let agentSettings:AgentSettings = {
      id : formValue.id,
      autoLogin: formValue.autoLogin,
      intervalToTryLogin: formValue.intervalToTryLogin,
      intervalCheckLoginIsOpen: formValue.intervalCheckLoginIsOpen,
      windowSettings: {
        captureUrl: formValue.captureUrl,
        captureWindowChange : formValue.captureWindowChange,
        intervalCaptureWindowChange: formValue.intervalCaptureWindowChange
      },
      inputDeviceSettings : {
        captureIdleKeyboard: formValue.captureIdleKeyboard,
        captureIdleMouse: formValue.captureIdleMouse,
        intervalCaptureIdleKeyboard : formValue.intervalCaptureIdleKeyboard,
        intervalCaptureIdleMouse : formValue.intervalCaptureIdleMouse,
        allowedIdleTime : formValue.allowedIdleTime,
        idleLimit : formValue.idleLimit
      },
      measurementSettings: {
        intervalMeasurementInternetSpeed: formValue.intervalMeasurementInternetSpeed,
        intervalMeasurementLatency: formValue.intervalMeasurementLatency,
        measurementInternetSpeed: formValue.measurementInternetSpeed,
        measurementLatency: formValue.measurementLatency

      }
    }

    return agentSettings

  }



  filter: boolean = false;

  get semaphoreFiltered(): SemaphoreViewModel[] {

    if (!this.filter) {
      return this.semaphores.filter(s => s.semaphores.enable == true)
    }

    return this.semaphores;
  }


  inativos($event: any) {

    this.filter = $event

  }


  get formGroupControls() {

    return this.formGroup.controls
  }

  submit() {   


    if (this.formGroup.invalid) {
      this.toastr.info("Alguns campos são obrigatórios.")
      return;
    }


    let id = this.formGroupControls['id'] ? this.formGroupControls['id'].value : null;
    let ranges = new RequestSemaphoreRanges(this.formGroupControls['danger'].value, this.formGroupControls['warning'].value, this.formGroupControls['success'].value)

    let requestSemaphore = new RequestSemaphore(this.currentDescription, this.formGroupControls['enable'].value, ranges, id) 

    if(this.formGroupControls['id']){

      this.updateSemaphore(requestSemaphore);
      this.toastr.success("Semáforo atualizado com sucesso.")
      return;
    }    

    this.createSemaphore(requestSemaphore);
    this.toastr.success("Semáforo criado com sucesso.")


  }

  private createSemaphore(requestSemaphore: RequestSemaphore){   

    this.semaphoreService.createSemaphore(requestSemaphore).subscribe((data) => this.semaphores.push(this.pushSemaphore(data)))

  }

  private updateSemaphore(requestSemaphore: RequestSemaphore){
    

    this.semaphoreService.updateSemaphore(requestSemaphore).subscribe(() => {

      let semaphore = this.semaphores.findIndex(s => s.semaphores.id == requestSemaphore.id)
     
      this.semaphores[semaphore] = this.pushSemaphore(requestSemaphore as Semaphore) 

    })

  }

  private setDataSemaphore(semaphores: Semaphore[]): SemaphoreViewModel[] {

    let semaphoresList: SemaphoreViewModel[] = []

    semaphores.forEach((semaphore: Semaphore) => {

      semaphoresList.push(this.pushSemaphore(semaphore))

    })

    return semaphoresList
  }

  changeInputRange(range: string) {

    

    switch (range) {
      case 'danger':              
        this.red = this.validateValue('danger') 
        break;

      case 'warning':        
        this.yellow = this.validateValue('warning')
        break;

        case 'success':        
        this.validateValue('success')
        break;

    }

  }

  private validateValue(key: string){

    let dangerValue = this.formGroup.get(key)?.value

   

    if(dangerValue < 0){
      this.formGroup.get(key)?.setValue(0)
    }

    
    return Number((this.formGroupControls[key].value + 0.01).toFixed(2)) 

   

    

  }

  private pushSemaphore(semaphore: Semaphore): SemaphoreViewModel {

    let subTitle: string = `Vermelho ${semaphore.ranges.red} | Amarelo ${semaphore.ranges.yellow} | Verde ${semaphore.ranges.green}`
    let apps: SubtitleCardJourneyModel[] = [
      new SubtitleCardJourneyModel(subTitle, true, true),
    ]
    let card = new CardJourneyModel(semaphore.description, apps, "", "")
    let semaphoreViewModel = new SemaphoreViewModel(card, semaphore)

    return semaphoreViewModel

  }


}