import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';
import { ProgressModule } from '@station/progress';
import { TitlesModule } from '@station/titles';

import { DashHeaderModule } from '../../shared/modules/dash-header/dash-header.module';
import { CardHierarchyComponent } from './components/card-hierarchy/card-hierarchy.component';
import { PainelHierarquiaRoutingModule } from './painel-hierarquia-routing.module';
import { ViewComponent } from './view/view.component';
import { EmployeesModule } from '@station/employees';




@NgModule({
  declarations: [
    ViewComponent,
    CardHierarchyComponent,


  ],
  imports: [
    CommonModule,
    PainelHierarquiaRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ProgressModule,
    TitlesModule,
    EmployeesModule,
    MenuModule,
    HeaderModule,
    DragDropModule,
    DashHeaderModule

  ]
})
export class PainelHierarquiaModule { }
