import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'station-modal-user-clone',
  templateUrl: './modal-user-clone.component.html',
  styleUrls: ['./modal-user-clone.component.scss']
})
export class ModalUserCloneComponent implements OnInit {
  public userCloneCount: number = 1;
  @Output() getUserCloneCount = new EventEmitter<number>()

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  modalClose()
  {
    this.getUserCloneCount.emit(this.userCloneCount);
    this.activeModal.close(this.userCloneCount);
  }

}
