import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from '@station/charts';
import { EmployeesModule } from '@station/employees';
import { HeaderModule } from '@station/header';
import { ProgressModule } from '@station/progress';
import { TitlesModule } from '@station/titles';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { DashHeaderModule } from '../../shared/modules/dash-header/dash-header.module';
import { RemoveLinkDirective } from './directives/remove-link.directive';
import { ProfileEmployeesFormComponent } from './profile-employees-form/profile-employees-form.component';
import { ProfileEmployeesPageComponent } from './profile-employees-page/profile-employees-page.component';
import { ProfileEmployessRoutingModule } from './profile-employees-routing.module';
import { ProfileEmployeeService } from './services/profile-employees.service';
import { MatIconModule } from '@angular/material/icon';
import { ModalUserCloneComponent } from 'src/app/shared/components/modal-user-clone/modal-user-clone.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTableModule} from '@angular/material/table';

@NgModule({
  declarations: [
    ProfileEmployeesPageComponent,
    ProfileEmployeesFormComponent,
    ModalUserCloneComponent,
    RemoveLinkDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ProfileEmployessRoutingModule,
    EmployeesModule,
    HeaderModule,
    ProgressModule,
    TitlesModule,
    ChartsModule,
    NgxMaskModule.forChild(),
    DashHeaderModule,
    ToastrModule.forRoot(),
    MatIconModule,
    MatRadioModule,
    MatExpansionModule,
    MatTableModule

  ],
  exports: [
    ProfileEmployeesPageComponent,
    ProfileEmployeesFormComponent,
  ],
  providers: [ProfileEmployeeService]

})
export class ProfileEmployeesModule { }
