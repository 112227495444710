import { ApplicationFilterGroups } from "./ApplicationFilterGroup.model";

export class ApplicationGroup{

    public id?: string;
    public groupName!: string;
    public isActive!: boolean;
    public applicationFilterGroup!: ApplicationFilterGroups[]

    constructor(groupName: string, isActive: boolean, applicationFilterGroups: ApplicationFilterGroups[], id?: string) {
        this.groupName = groupName
        this.isActive = isActive
        this.applicationFilterGroup = applicationFilterGroups
        this.id = id
    }

}