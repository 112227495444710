import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeesModule } from '@station/employees';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';

import { DashHeaderModule } from '../../shared/modules/dash-header/dash-header.module';
import { JornadaDeTrabalhoRoutingModule } from './jornada-de-trabalho-routing.module';
import { ViewComponent } from './view/view.component';


@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    CommonModule,
    JornadaDeTrabalhoRoutingModule,
    HeaderModule,
    MenuModule,
    EmployeesModule,
    FormsModule,
    ReactiveFormsModule,
    DashHeaderModule,
  ]
})
export class JornadaDeTrabalhoModule { }