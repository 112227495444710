import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListaPessoasGuardService } from './services/lista-de-pessoas-guard.service';
import { ViewComponent } from './view/view.component';

const routes: Routes = [
  {path: '', component: ViewComponent, canActivate: [ListaPessoasGuardService]}
]; 

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ListaPessoasGuardService]
})
export class ListaDePessoasRoutingModule { }
 