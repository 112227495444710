<div class="sidebar-content">
  <station-menu [dataMenu]="dataMenu"></station-menu>
  <div class="show-employees-button" *ngIf="!showEmployeeList" (click)="showEmployees()">
    <span class="employee-swipe-button">chevron_right</span>
  </div>
  <div class="employees" *ngIf="isEmployees && showEmployeeList" [ngClass]="{'hide-employees': isEmployeesHidden}">
    <div class="employee-profile" *ngIf="isEmployees">
      <div class="employee-swipe" *ngIf="showEmployeeList">
        <span class="employee-swipe-button" (click)="hideEmployees()">chevron_left</span>
      </div>
      <station-employee-card-profile
        [employee]="profile"
      ></station-employee-card-profile>
    </div>
    <div class="employee-list" *ngIf="showEmployeeList">
      <station-employee-list [employees]="employees"></station-employee-list>
    </div>
    <!-- <button  >Pressione por alguns segundos</button> -->

    <div
      appHoldable
      class="plus-button"
      *ngIf="pageNumber < totalPages"
      (click)="loadMoreEmployees()"
      (hold)="onHold()"
      [class.bg-success]="alternativeEmployeesButton"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="white"
        width="24px"
        height="24px"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
      </svg>
      <span class="page-info">{{ pageNumber }}/{{ totalPages }}</span>
    </div>
  </div>
</div>
