import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { RequestSemaphore } from '../models/request-semaphore.model';
import { Semaphore } from '../models/semaphore.model';
import { AgentSettings } from '../models/agent-settings.model';


@Injectable({
  providedIn: 'root'
})
export class SemaphoreService {

  constructor(private http: HttpClient) { }


  getAllSemaphore(): Observable<Semaphore[]>{

    return this.http.get<Semaphore[]>(`${environment.settingsApi}/api/v1/semaphore`)
  }

  createSemaphore(requestSemaphore: RequestSemaphore):Observable<Semaphore> {

    return this.http.post<Semaphore>(`${environment.settingsApi}/api/v1/semaphore`, requestSemaphore)
  }

  updateSemaphore(requestSemaphore: RequestSemaphore,): Observable<Semaphore> {

    return this.http.put<Semaphore>(`${environment.settingsApi}/api/v1/semaphore/${requestSemaphore.id}`, requestSemaphore)
  }

  getAgentSettings(): Observable<AgentSettings[]> {

    return this.http.get<AgentSettings[]>(`${environment.settingsApi}/api/v1/agentSettings`)
  }

  updateAgentSettings(agentSettings: AgentSettings): Observable<any>{

    return this.http.put(`${environment.settingsApi}/api/v1/agentSettings/${agentSettings.id}`, agentSettings)
  }
}