import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeesModule } from '@station/employees';
import { HeaderModule } from '@station/header';
import { MenuModule } from '@station/menus';
import { ToastrModule } from 'ngx-toastr';

import { DashHeaderModule } from './../../shared/modules/dash-header/dash-header.module';
import { RangeSemaforoRoutingModule } from './range-semaforo-routing.module';
import { ViewComponent } from './view/view.component';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    CommonModule,
    RangeSemaforoRoutingModule,
    EmployeesModule,
    HeaderModule,
    MenuModule,
    FormsModule,
    ReactiveFormsModule,
    DashHeaderModule,
    ToastrModule.forRoot(),
    MatTabsModule
  ]
})
export class RangeSemaforoModule { }
