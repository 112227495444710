import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, RequiredValidator } from '@angular/forms';
import { HeaderModel } from '@station/header';
import { AuthService } from 'src/app/services/auth.service';

import { Company } from '../models/company.models';
import { CompanyService } from '../services/company.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {



  company!: Company
  form!: FormGroup

  constructor(private companyService: CompanyService, private formBuilder: FormBuilder, private authService: AuthService) { }

  header: HeaderModel = new HeaderModel(this.authService.authUser.registrationId, this.authService.authUser.firstName + " " + this.authService.authUser.lastName, this.authService.authUser.jobPosition, false, this.authService.authUser.photoPath, true, "Buscar Funcionários");


  public GetCompany() {
    this.companyService.GetCompany().subscribe(
      (data) => {

        if (data.length > 0) {
          this.company = data[0];

          this.InitForm();
          return;
        }
        this.InitEmptyForm();
      },
      error => {
        this.companyService.ErrorMessage()
      }
    )
  }

  public SendCompany() {
    if(!this.form.invalid) {
      this.form.setErrors({ ...this.form.errors, 'yourErrorName': true });
      return;
    }
    this.company = Object.assign(this.form.value)

    if (this.company.id == null) {
      this.companyService.SendCompany(this.company).subscribe(
        (data) => {
          this.company.id = data.id;
          this.companyService.SuccessMessage()
          this.InitForm();
        },
        error => {
          this.companyService.ErrorMessage()
        }
      )
      return;
    }

    this.companyService.UpdateCompany(this.company).subscribe(
      () => {
        this.companyService.SuccessMessage()
      },
      error => {
        this.companyService.ErrorMessage()
      }
    )
    ;
  }

  public InitForm() {

    this.form = new FormGroup({
      id: new FormControl(this.company.id),
      name:  new FormControl(this.company.name),
      photoPath:  new FormControl(this.company.photoPath),
      cnpj:  new FormControl(this.company.cnpj),
      phone:  new FormControl(this.company.phone),
      contact:  new FormControl(this.company.contact),
      email:  new FormControl(this.company.email),
      segment:  new FormControl(this.company.segment),
      description:  new FormControl(this.company.description),
      logoPath:  new FormControl(this.company.logoPath),
      twoFactorEnabled:  new FormControl(this.company.twoFactorEnabled),
      subscription:  new FormControl(this.company.subscription)
    })
  }
  public InitEmptyForm() {
    this.form = new FormGroup({
      id: new FormControl(''),
      name:  new FormControl(''),
      photoPath:  new FormControl(''),
      cnpj:  new FormControl(''),
      phone:  new FormControl(''),
      contact:  new FormControl(''),
      email:  new FormControl(''),
      segment:  new FormControl(''),
      description:  new FormControl(''),
      logoPath:  new FormControl(''),
      subscription:  new FormControl('')
    })
  }
  hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }
  ngOnInit(): void {
    this.GetCompany();
  }

}
