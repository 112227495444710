import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

import { TwoFactorRegisterResponse } from '../view/model/two-factor-response';

@Injectable({
  providedIn: 'root'
})
export class TwoFactorRegisterService {

  constructor(private http: HttpClient, private authService:AuthService) { }

  emailForgout(email: any) {
    return this.http.put(`${environment.authApi}/users/forgotPassword/${email}`, null);
  }

  register(): Observable<TwoFactorRegisterResponse> {
    
    let token = this.authService.getToken()

    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`})
    };

    return this.http.post<TwoFactorRegisterResponse>(`${environment.authApi}${environment.endpoints.twoFactorRegister}`, null, httpOptions);

  }
}
