import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImplantacaoComponent } from './view/implantacao.component';



const routes: Routes = [
    {path: '', component: ImplantacaoComponent}
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ImplantacaoRoutingModule { }
