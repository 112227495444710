import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { JornadaDeTrabalhoModule } from './pages/jornada-de-trabalho/jornada-de-trabalho.module';
import { ListaDePessoasModule as pessoas } from './pages/lista-de-pessoas/lista-de-pessoas.module';
import { LoginModule as login } from './pages/login/login.module';
import { ForgotPasswordModule as forgotPassword } from './pages/forgot-password/forgot-password.module';
import { PainelColaboradorModule as colaborador } from './pages/painel-colaborador/painel-colaborador.module';
import { PainelHierarquiaModule as hierarquia } from './pages/painel-hierarquia/painel-hierarquia.module';
import { PainelSupervisorModule as supervisor } from './pages/painel-supervisor/painel-supervisor.module';
import { PainelAdministrativoModule as administrativo } from './pages/painel-administrativo/painel-administrativo.module';
import { PerfilDaEmpresaModule as empresa } from './pages/perfil-da-empresa/perfil-da-empresa.module';
import { ProfileEmployeesModule as usuarios } from './pages/profile-employees/profile-employees.module';
import { GrupoDeFerramentaModule as ferramenta } from './pages/grupo-de-ferramenta/grupo-de-ferramenta.module'
import { RangeSemaforoModule as configuracoes } from './pages/range-semaforo/range-semaforo.module';
import { AlterarSenhaModule  as alterarSenha} from './pages/alterar-senha/alterar-senha.module'
import { AuthGuardService as AuthGuard} from './services/auth-guard.service';
import {Logout as logout } from './shared/components/logout.component';
import { TwoFactorRegisterModule as twoFactor } from './pages/two-factor-register/twoFactorRegister.module';
import { ImplantacaoModule as implantacao } from './pages/implantacao/implantacao.module';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => login },
  { path: 'forgotPassword', loadChildren: () => forgotPassword },
  { path: 'cadastro-duplo-fator', loadChildren: () => twoFactor },
  { path: 'painel-implantacao', loadChildren: () => implantacao, canActivate: [AuthGuard],  data: { expectedRole: 'painel-de-implantacao-leitura' } },
  { path: 'painel-supervisor', loadChildren: () => supervisor, canActivate: [AuthGuard], pathMatch: 'full',  data: { expectedRole: 'painel-do-supervisor-leitura' }   },
  { path: 'painel-supervisor/:id', loadChildren: () => supervisor, canActivate: [AuthGuard], pathMatch: 'full',  data: { expectedRole: 'painel-do-supervisor-leitura' }  },
  { path: 'painel-colaborador', loadChildren: () => colaborador, canActivate: [AuthGuard], pathMatch: 'full',  data: { expectedRole: 'painel-do-colaborador-leitura' } },
  { path: 'painel-colaborador/:id', loadChildren: () => colaborador, canActivate: [AuthGuard], pathMatch: 'full',  data: { expectedRole: 'painel-do-colaborador-leitura' } },
  { path: 'painel-administrativo', loadChildren: () => administrativo, canActivate: [AuthGuard],  data: { expectedRole: 'painel-administrativo-leitura' }},
  { path: 'lista-de-pessoas', loadChildren: () => pessoas, canActivate: [AuthGuard],  data: { expectedRole: 'painel-do-meu-time-leitura' } },
  { path: 'perfil-da-empresa', loadChildren: () => empresa, canActivate: [AuthGuard],  data: { expectedRole: "perfil-da-empresa-leitura"} },
  { path: 'perfil-do-usuario', loadChildren: () => usuarios, canActivate: [AuthGuard],  data: { expectedRole: "perfil-do-usuario-leitura" } },
  { path: 'perfil-do-usuario/:id', loadChildren: () => usuarios, canActivate: [AuthGuard],  data: { expectedRole: "perfil-do-usuario-leitura" } },
  { path: 'jornada-de-trabalho', loadChildren: () => JornadaDeTrabalhoModule, canActivate: [AuthGuard],  data: { expectedRole: "painel-jornada-de-trabalho-leitura" } },
  { path: 'configuracoes', loadChildren: () => configuracoes, canActivate: [AuthGuard], data: { expectedRole: "painel-de-configuracao-leitura" } },
  { path: 'hierarquia', loadChildren: () => hierarquia, canActivate: [AuthGuard],  data: { expectedRole: "perfil-de-acesso-leitura" } },
  { path: 'grupo-de-ferramenta', loadChildren: () => ferramenta, canActivate: [AuthGuard],  data: { expectedRole: 'painel-do-grupo-de-ferramentas-leitura' }  },
  { path: 'alterar-senha', loadChildren: () => alterarSenha, canActivate: [AuthGuard] },
  { path: 'logout', component : logout }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
