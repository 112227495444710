export class HierarchyRequest {
  order: number;
  role: string;
  access: boolean;
  deactive: boolean;
  roles?:string[];

  constructor(order: number, role: string, access: boolean, deactive: boolean, roles?:string[]) {
    this.order = order;
    this.role = role;
    this.access = access;
    this.deactive = deactive;
    this.roles = roles;
  }
}
