import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { Application } from './models/application.model';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationDetailsResponse } from '../../model/application-details-response';
import { ApplicationService } from './services/application.service';
import { ApplicationDetailsRequest } from '../../model/application-details-request';

@Component({
  selector: 'station-table-aplications-utility',
  templateUrl: './table-aplications-utility.component.html',
  styleUrls: ['./table-aplicacoes.component.scss']
})
export class TableAplicationUtilityComponent implements OnInit, OnChanges {
  @Input() productiveApplications: any[] = [];
  @Input() productiveApplicationsFiltered: any[] = [];
  @Input() userId!: string;
  @Input()dateFilter!: string;
  productiveApplicationsDetails: ApplicationDetailsResponse[] = [];
  mostrarIcone = true;
  mostrarOutroIcone = true;
  total: number = 0;
  totalMin: string = '';
  @ViewChild('modalContent') modalContent!: TemplateRef<any>;
  @ViewChild('modalFooter') modalFooter!: TemplateRef<any>;
  @ViewChild('excelTable', { static: false }) excelTable!: ElementRef;
  displayedColumns = ['Data Início', 'Data Fim', 'Duração (Segundos)', 'Título', 'Url', 'Termo (Match)'];

  constructor(
    private modalService: NgbModal,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {

    this.updateTotal();
  }
  ngOnChanges(changes: SimpleChanges) {

    this.updateTotal();
  }
  updateTotal() {
    this.total = this.productiveApplications.length;
    this.totalMin = this.calculateTotalTime(this.productiveApplications);
  }
  calculateTotalTime(applications: Application[]) {

    let totalTime = applications.reduce(
      (accumulator, currentValue) => accumulator + currentValue.totalTime,
      0
    );

    return this.formatTime(totalTime);
  }
  listFilterKeyUp(event: any) {
    let search = event.target.value.toLowerCase();

    if (search !== '') {


      let list = this.productiveApplications.filter(
        (fu) =>
          fu.title.toLowerCase().includes(search) ||
          fu.category.toLowerCase().includes(search) ||
          fu.classification.toLowerCase().includes(search) ||
          fu.description.toLowerCase().includes(search)
      );


      this.productiveApplicationsFiltered = list.length
        ? list
        : this.productiveApplications;
      this.total = list.length
        ? list.length
        : this.productiveApplications.length;
      this.totalMin = list.length
        ? this.calculateTotalTime(this.productiveApplicationsFiltered)
        : this.calculateTotalTime(this.productiveApplications);
    } else {
      this.productiveApplicationsFiltered = this.productiveApplications;
      this.total = this.productiveApplications.length;
      this.totalMin = this.calculateTotalTime(this.productiveApplications);
    }
  }

  formatTime(timeInSeconds: number): string {
    const timeInMinutes = Math.ceil(timeInSeconds / 60); // Arredondar para cima


    if (timeInSeconds < 60) {
      return '< 1min';
    }
    else if (timeInMinutes < 60) {
      return timeInMinutes + 'min';
    }
    else if (timeInMinutes < 1440) {
      const hours = Math.floor(timeInMinutes / 60);
      const minutes = timeInMinutes % 60;
      return hours + 'h' + minutes + 'min';
    } else if (timeInMinutes < 10080) {
      const days = Math.floor(timeInMinutes / 1440);
      const hours = Math.floor((timeInMinutes % 1440) / 60);
      return days + 'd' + hours + 'h';
    } else if (timeInMinutes < 40320) {
      const weeks = Math.floor(timeInMinutes / 10080);
      const days = Math.floor((timeInMinutes % 10080) / 1440);
      return weeks + 'sem' + days + 'd';
    } else {
      const months = Math.floor(timeInMinutes / 40320);
      const weeks = Math.floor((timeInMinutes % 40320) / 10080);
      return months + 'mês' + weeks + 'sem';
    }
  }


  showDetails(selectedItem: Application) {
    const applicationRequest: ApplicationDetailsRequest =
      this.transformApplicationToRequest(selectedItem, this.userId);

    this.applicationService
      .getApplicationsDetailsByUserId(applicationRequest)
      .subscribe(
        (response) => {

          // Verificar e definir um valor padrão ou válido para term no response
          response.forEach((detail) => {

            detail.duration > 0 ? detail.duration / 60 : 0;

            if (detail.term === undefined ||detail.term === null || typeof detail.term !== 'string') {
              detail.term = '';
            }


          });

          this.productiveApplicationsDetails = response;

          // Faça algo com a resposta do serviço, se necessário
          this.openModal(
            `Logs - ${selectedItem.title} (${this.formatTime(selectedItem.totalTime)})`,
          );
        },
        (error) => {
          console.error('Erro ao obter detalhes da aplicação:', error);
        }
      );
  }

  openModal(title: string) {
    const options: NgbModalOptions = {
      size: 'xl' // Ou 'xl' para uma largura ainda maior
    };

    const modalRef = this.modalService.open(ModalContentComponent, options);

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.modalContent = this.modalContent;
    modalRef.componentInstance.modalFooter = this.modalFooter;


  }
  downloadCSV(option:string) {

    const csvContent = option == "modal" ? this.generateModalCSV() : this.generateCSV();

    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvContent], {
      type: 'text/csv; charset=utf-8',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'StationLogAplicacoes.csv';
    a.click();

    window.URL.revokeObjectURL(url);
  }
  exportAsExcel() {
    const uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="UTF-8"><style>table{border-collapse:collapse;width:100%}th,td{border:1px solid black;padding:8px;text-align:left}th{background-color:#f2f2f2}</style></head><body><table>{table}</table></body></html>`;
    const base64 = function(s: string) { return window.btoa(unescape(encodeURIComponent(s))); };
    const format = function(s: string, c: any) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }); };

      const table = this.excelTable.nativeElement;
      const ctx = { table: table.innerHTML };

    const link = document.createElement('a');
    link.download = `StationLogAplicacoes.xls`;
    link.href = uri + base64(format(template, ctx));
    link.click();
  }
  generateModalCSV() {
    const csvRows = [];
    const headers = ['Data Início', 'Data Fim', 'Duração (Segundos)', 'Título', 'Url', 'Termo'];
    const rows = this.productiveApplicationsDetails.map((detail) => [
      detail.startDate,
      detail.endDate,
      detail.duration,
      detail.windowTitle,
      detail.url,
      detail.term,
    ]);

    csvRows.push(headers.join(','));
    for (const row of rows) {
      csvRows.push(row.map((item) => `"${item}"`).join(','));
    }

    return csvRows.join('\n');
  }
  generateCSV() {
    const csvRows = [];
    const headers = ['Termo', 'Descrição', 'Duração (segundos)', 'Título', 'Categoria', 'Classificação'];
    const rows = this.productiveApplicationsFiltered.map((detail) => [
      detail.title,
      detail.description,
      detail.totalTime,
      detail.windowTitle,
      detail.category,
      detail.classification,
    ]);

    csvRows.push(headers.join(','));
    for (const row of rows) {
      csvRows.push(row.map((item) => `"${item}"`).join(','));
    }

    return csvRows.join('\n');
  }
  transformApplicationToRequest(
    application: Application,
    userId: string
  ): ApplicationDetailsRequest {

    const windowTitle = application.windowTitle ?? '';
    const processName = (application.title ?? '').split(' | ')[0].trim();

    return {
      userId: userId,
      term: application.term,
      dateFilter: this.dateFilter,
      windowTitle: windowTitle,
      processName: processName,
    };
  }
}
