import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PainelAdministrativoComponent } from './view/view.component';

const routes: Routes = [
  {path: '', component: PainelAdministrativoComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PainelAdministrativoRoutingModule { }
