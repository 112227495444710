import { ApplicationFilters } from "./ApplicationFilter.model";

export class ApplicationFilterGroups {

    public description!: string;
    public category!: string;
    public classification!: string;
    public applicationFilter: ApplicationFilters[] = []
    public ignoreIdleInputDevice!: boolean;

    constructor(description: string, category: string, classification: string, ignoreIdleInputDevice: boolean, applicationFilters: ApplicationFilters) {
        this.description = description;
        this.category = category;
        this.classification = classification;
        this.applicationFilter.push(applicationFilters);
        this.ignoreIdleInputDevice = ignoreIdleInputDevice;
    }
    
}